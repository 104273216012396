export type PathwayActionCategory = PathwayActionCategoryIs;

export enum PathwayActionCategoryIs {
  APPOINTMENT = 'APPOINTMENT',
  COMMUNICATION = 'COMMUNICATION',
  DIAGNOSIS = 'DIAGNOSIS',
  DRUG = 'DRUG',
  EXAM = 'EXAM',
  MANEUVER = 'MANEUVER',
  MONITORING = 'MONITORING',
  REFERRAL = 'REFERRAL',
  REGISTRY = 'REGISTRY',
  SPECIALITY = 'SPECIALITY',
  THERAPEUTIC = 'THERAPEUTIC',
}
