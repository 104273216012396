import { PatientInteraction } from '@/src/models/journey/contact/PatientInteraction';
import { Service } from '@uphillhealth/react-service-provider';

export class PatientContactService extends Service {
  usePostCall() {
    return this.useMutation({
      method: 'POST',
      url: '/[institutionId]/patient-sessions/[patientSessionId]/call',
    });
  }
  useStartChat() {
    return this.useMutation({
      method: 'POST',
      url: '/[institutionId]/patient-sessions/[patientSessionId]/chat',
    });
  }
  useCloseChat() {
    return this.useMutation({
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[patientSessionId]/chat',
    });
  }
  useHistory() {
    return this.useQuery<PatientInteraction[]>({
      method: 'GET',
      url: '/[institutionId]/patient-sessions/[patientSessionId]/contact-history',
    });
  }
}
