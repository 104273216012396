import React, { ReactElement } from 'react';
import { Button } from '@/uphillhealth/components';
// self
import { ButtonProps } from './types';

export const ButtonCircular = ({ children, ...props }: Omit<ButtonProps, 'leftContent' | 'rightContent'>): ReactElement => {
  return (
    <Button
      css={{
        padding: '0',
        height: 40,
        width: 40,
        fontSize: '$extraLarge',
        borderRadius: '$full',
        'i[class^="icon-"], i[class*=" icon-"]': {
          fontSize: '$extraLarge',
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
