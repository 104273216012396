import React, { ReactElement } from 'react';
// self
import { AccordionItemProps } from './types';
import { AccordionContent, AccordionItemHeader, AccordionItemStyled, AccordionTrigger, Indicator } from './styles';
import { FlexContainer } from '@/src/theme/commons';
import { EditButton } from '@/src/components/RouteElements/Sidebar/RouteElementExtraKeys/styles';

export const AccordionItem = ({ children, label, editable, onEditClick, ...accordionItemProps }: AccordionItemProps): ReactElement => {
  return (
    <AccordionItemStyled data-testid="AccordionItem" {...accordionItemProps}>
      <AccordionItemHeader>
        <AccordionTrigger>
          {label}
          <FlexContainer>
            <Indicator>
              <i className="icon-keyboard_arrow_down" />
            </Indicator>

            {editable && (
              <EditButton disabled={!onEditClick} onClick={onEditClick}>
                <i className="icon-edit" />
              </EditButton>
            )}
          </FlexContainer>
        </AccordionTrigger>
      </AccordionItemHeader>
      <AccordionContent>{children}</AccordionContent>
    </AccordionItemStyled>
  );
};
