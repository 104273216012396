import React, { ReactElement, ReactNode } from 'react';
import * as SelectPrimitives from '@radix-ui/react-select';
import { StitchesProps } from '@uphillhealth/theme';
// self
import { SelectTriggerStyled } from './styles';

interface SelectTriggerProps extends StitchesProps {
  children?: ReactNode | ReactNode[];
  placeholder: ReactNode;
}

export const SelectTrigger = ({ children, placeholder, ...props }: SelectTriggerProps): ReactElement => {
  return (
    <SelectTriggerStyled {...props} data-testid="SelectTrigger">
      <SelectPrimitives.Value placeholder={placeholder}>{children}</SelectPrimitives.Value>
    </SelectTriggerStyled>
  );
};
