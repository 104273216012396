import { styled } from '@/src/theme';

export const ErrorContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Separator = styled('div', {
  margin: '$8 0 $4',
  width: '100%',
  height: 1,
  backgroundColor: '$neutral300',
});

export const Label = styled('p', {
  maxHeight: '60vh',
  color: '$neutral600',
  wordBreak: 'break-word',
  overflow: 'hidden',
});
