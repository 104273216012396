import { styled } from '@uphillhealth/theme';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const basicButtonStyle = {
  marginLeft: 'auto',
  cursor: 'pointer',
  '&:hover > *': {
    color: '$primary500',
  },
};

export const DropdownMenuTriggerStyled = styled(DropdownMenu.Trigger, basicButtonStyle);

export const DropdownMenuPortalStyled = styled(DropdownMenu.Portal, {});

export const DropdownMenuContentStyled = styled(DropdownMenu.Content, {
  padding: '$2',
  backgroundColor: 'white',
  borderRadius: '$small',
  boxShadow: '$routeElement',
  zIndex: 19,
});

export const DropdownMenuItemStyled = styled(DropdownMenu.Item, {
  ...basicButtonStyle,
  display: 'flex',
  alignItems: 'baseline',
  gap: '$2',
  '& > p': {
    color: '$error400',
  },
  '&:hover': {
    '& > p': {
      color: '$error600',
    },
  },
  '&[data-disabled] > p': {
    color: '$neutral400',
    cursor: 'initial',
  },
});
