import React, { ReactElement } from 'react';
import * as TooltipPrimitives from '@radix-ui/react-tooltip';
// self
import { TooltipProps } from './types';
import { TooltipContent, TooltipArrow, TooltipTrigger } from './styles';

/* Implementation from radix primitives.
 * https://www.radix-ui.com/docs/primitives/components/tooltip
 */
export const Tooltip = ({
  align,
  children,
  collisionBoundary,
  collisionPadding,
  content,
  defaultOpen,
  maxWidth = 250,
  margin = 'inherit',
  onEscapeKeyDown,
  onOpenChange,
  onPointerDownOutside,
  open,
  side,
  triggerAsChild,
}: TooltipProps): ReactElement => {
  return (
    <TooltipPrimitives.Root defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={open} data-testid="Tooltip">
      <TooltipTrigger data-testid="Tooltip-Trigger" asChild={triggerAsChild} autoFocus={false} css={{ margin }}>
        {children}
      </TooltipTrigger>
      <TooltipPrimitives.Portal>
        <TooltipContent
          align={align}
          collisionBoundary={collisionBoundary}
          collisionPadding={collisionPadding}
          css={{ maxWidth }}
          onEscapeKeyDown={onEscapeKeyDown}
          onPointerDownOutside={onPointerDownOutside}
          side={side}
        >
          <TooltipArrow />
          {content}
        </TooltipContent>
      </TooltipPrimitives.Portal>
    </TooltipPrimitives.Root>
  );
};
