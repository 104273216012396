type ComputeNameInitials = (name?: string | null) => string;

export const computeNameInitials: ComputeNameInitials = (name) => {
  if (!name) {
    return '';
  }

  const split = name.trim().split(' ');
  const firstInitial = split[0][0];
  return (split.length === 1 ? firstInitial : [firstInitial, split.slice(-1)[0][0]].join('')).toUpperCase();
};
