import PathwayElements from '@/models/PathwayElements';
import { PathwayAction, PathwayActionCategoryIs } from '@/models';

enum LegacyElementStatusUpHill {
  completed = 'completed',
  inProgress = 'inProgress',
  notDoneCancelled = 'notDoneCancelled',
  paused = 'paused',
}

export interface PathwayCompiledStatuses {
  [LegacyElementStatusUpHill.completed]: string[];
  [LegacyElementStatusUpHill.inProgress]: string[];
  [LegacyElementStatusUpHill.notDoneCancelled]: string[];
  [LegacyElementStatusUpHill.paused]: string[];
}

export const pathwayCompiledStatus: PathwayCompiledStatuses = {
  [LegacyElementStatusUpHill.completed]: ['COMPLETED', 'CONFIRMED', 'FULFILLED'],
  [LegacyElementStatusUpHill.inProgress]: ['ACTIVE', 'BOOKED', 'DIFFERENTIAL', 'INPROGRESS', 'PROVISIONAL'],
  [LegacyElementStatusUpHill.notDoneCancelled]: ['CANCELLED', 'NOSHOW', 'REFUTED', 'REVOKED', 'STOPPED'],
  [LegacyElementStatusUpHill.paused]: ['ONHOLD', 'UNCONFIRMED'],
};

const pathwayStatusAppointment = ['FULFILLED', 'BOOKED', 'NOSHOW', 'CANCELLED'];

/*
 * Medication request is drug related
 */
const pathwayStatusMedicationRequest = ['COMPLETED', 'ACTIVE', 'ONHOLD', 'CANCELLED'];

/*
 * Service Request is one of exam, maneuver, monitoring or therapeutics
 */
const pathwayStatusServiceRequest = ['COMPLETED', 'ACTIVE', 'ONHOLD', 'REVOKED'];
const pathwayReferralStatusServiceRequest = ['COMPLETED', 'ACTIVE', 'REVOKED'];

const pathwayStatusCommunication = ['COMPLETED', 'INPROGRESS', 'ONHOLD', 'STOPPED'];

/*
 * Conditions is diagnosis related
 */
const pathwayStatusConditions = ['CONFIRMED', 'DIFFERENTIAL', 'PROVISIONAL', 'UNCONFIRMED', 'REFUTED'];

export const pathwayStatusByActionCategory: Record<string, string[]> = {
  APPOINTMENT: pathwayStatusAppointment,
  COMMUNICATION: pathwayStatusCommunication,
  DIAGNOSIS: pathwayStatusConditions,
  DRUG: pathwayStatusMedicationRequest,
  EXAM: pathwayStatusServiceRequest,
  MANEUVER: pathwayStatusServiceRequest,
  MONITORING: pathwayStatusServiceRequest,
  REGISTRY: [],
  SPECIALITY: [],
  THERAPEUTIC: pathwayStatusServiceRequest,
  REFERRAL: pathwayReferralStatusServiceRequest,
};

export const elementStatusPerUphillStatus = (category: PathwayActionCategoryIs): PathwayCompiledStatuses => {
  const foundStatus = pathwayStatusByActionCategory[String(category)];

  return Object.entries(pathwayCompiledStatus).reduce((merge, entry: [string, string[]]) => {
    const [uphillStatus, statuses] = entry;
    return {
      ...merge,
      [uphillStatus]: statuses.filter((status) => foundStatus.includes(status)),
    };
  }, pathwayCompiledStatus);
};

export const statusOptionsOrdered = (category: string, statusLabels: Record<string, string>): { label: string; status: string }[] => {
  const foundLabels = pathwayStatusByActionCategory[String(category)];
  return foundLabels.map((status) => ({ label: statusLabels[String(status)], status }));
};

export const injectStatusLabelsByType = (pathElement: PathwayAction, statusLabels: PathwayElements['statusLabels']): PathwayAction => {
  if (!pathElement.category || !statusLabels) {
    return {
      ...pathElement,
      statusLabels: { status: {}, reasons: {} },
      statusPerType: {
        [LegacyElementStatusUpHill.completed]: [],
        [LegacyElementStatusUpHill.inProgress]: [],
        [LegacyElementStatusUpHill.notDoneCancelled]: [],
        [LegacyElementStatusUpHill.paused]: [],
      },
    };
  }

  const foundLabels = Object.prototype.hasOwnProperty.call(statusLabels, pathElement.category)
    ? statusLabels[String(pathElement.category) as PathwayActionCategoryIs]
    : { status: {}, statusReasons: {} };

  const statusPerType = elementStatusPerUphillStatus(pathElement.category);

  return { ...pathElement, statusLabels: foundLabels, statusPerType };
};

export const isPathwayActionSkipped = (status: string, action?: PathwayAction): boolean =>
  action?.completeStatus
    ? !action.completeStatus.includes(status)
    : !pathwayCompiledStatus[LegacyElementStatusUpHill.completed].includes(status);

export const pathwayActionStatusByItems = (pathElement: PathwayAction): { status: string; skipped: boolean } => {
  const { statusPerType } = pathElement as Required<PathwayAction>;

  const hasEveryItemCompleted = pathElement.actionItems.every((actionItem) =>
    pathwayCompiledStatus[LegacyElementStatusUpHill.completed].includes(String(actionItem.status)),
  );
  const hasSomeItemsInAnyState = pathElement.actionItems.some((actionItem) => Boolean(actionItem.status));

  let decidedStatus = '';
  if (hasSomeItemsInAnyState) {
    // check to see NotDoneCancelled is in every item status
    const hasEveryItemNotDoneCancelled = pathElement.actionItems.every((actionItem) =>
      pathwayCompiledStatus.notDoneCancelled.includes(String(actionItem.status)),
    );
    // check to see paused is in every item status
    const hasEveryItemPaused = pathElement.actionItems.every((actionItem) =>
      pathwayCompiledStatus.paused.includes(String(actionItem.status)),
    );

    decidedStatus = statusPerType.inProgress[0];
    if (hasEveryItemNotDoneCancelled) {
      decidedStatus = statusPerType.notDoneCancelled[0];
    }
    if (hasEveryItemPaused) {
      decidedStatus = statusPerType.paused[0];
    }
  }
  if (hasEveryItemCompleted) {
    decidedStatus = statusPerType.completed[0];
  }

  return {
    status: decidedStatus,
    skipped: isPathwayActionSkipped(decidedStatus, pathElement),
  };
};

export const pathwayStatusCanHaveReason = (status: string, category: PathwayActionCategoryIs): boolean => {
  const communicationOnHold = category === PathwayActionCategoryIs.COMMUNICATION && status === 'ONHOLD';
  const drugCancelledOrOnHold = category === PathwayActionCategoryIs.DRUG && ['ONHOLD', 'CANCELLED'].includes(status);

  return communicationOnHold || drugCancelledOrOnHold;
};
