import { styled } from './index';

export const BaseStyle = {
  textAlign: 'left',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.5em',
  display: 'flex',
};

export const BaseVariantsStyle = {
  raleway: {
    true: {
      fontFamily: 'Raleway',
    },
  },
  light: {
    true: {
      fontWeight: 300,
    },
  },
  medium: {
    true: {
      fontWeight: 500,
    },
  },
  semibold: {
    true: {
      fontWeight: 600,
    },
  },
  bold: {
    true: {
      fontWeight: 700,
    },
  },
  ellipsis: {
    true: {
      display: 'inline',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  critical: {
    true: {
      color: '$error400',
    },
  },
};

export const TextBaseStyle = {
  ...BaseStyle,
  fontSize: '$base',
  '& > a': {
    color: '$primary500',
    '&:hover': {
      color: '$primary800',
    },
  },
  variants: {
    ...BaseVariantsStyle,
    title: {
      true: {
        fontSize: '$extraLarge',
      },
    },
    subtitle: {
      true: {
        fontSize: '$large',
      },
    },
    small: {
      true: {
        fontSize: '$extraSmall',
      },
    },
    caption: {
      true: {
        fontSize: '$small',
      },
    },
  },
  compoundVariants: [
    {
      small: true,
      caption: true,
      css: {
        fontSize: '$micro',
      },
    },
  ],
};

export const Text = styled('p', {
  ...BaseStyle,
  fontSize: '$base',
  '& > a': {
    color: '$primary500',
    '&:hover': {
      color: '$primary800',
    },
  },
  variants: {
    ...BaseVariantsStyle,
    title: {
      true: {
        fontSize: '$extraLarge',
      },
    },
    subtitle: {
      true: {
        fontSize: '$large',
      },
    },
    small: {
      true: {
        fontSize: '$extraSmall',
      },
    },
    caption: {
      true: {
        fontSize: '$small',
      },
    },
  },
  compoundVariants: [
    {
      small: true,
      caption: true,
      css: {
        fontSize: '$micro',
      },
    },
  ],
});

export const TextDesk = styled(Text, {
  fontSize: '$base',
  color: '#536075',
  variants: {
    ...BaseVariantsStyle,
    title: {
      true: {
        fontSize: '$extraLarge',
      },
    },
    subtitle: {
      true: {
        fontSize: '$large',
      },
    },
    small: {
      true: {
        fontSize: '13px !important',
      },
    },
    xsmall: {
      true: {
        fontSize: '$extraSmall !important',
      },
    },
    caption: {
      true: {
        fontSize: '$small',
      },
    },
  },
});

export const TextDeskOverflow = styled(TextDesk, {
  display: 'initial !important',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

export const H1 = styled('h1', {
  fontSize: '$h1',
});
export const H2 = styled('h2', {
  fontSize: '$h2',
});
export const H3 = styled('h3', {
  fontSize: '$h3',
});
export const H4 = styled('h4', {
  fontSize: '$h4',
});

export const FlexContainer = styled('div', {
  display: 'flex',
  variants: {
    center: {
      true: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    xCenter: {
      true: {
        justifyContent: 'center',
      },
    },
    xStart: {
      true: {
        justifyContent: 'start',
      },
    },
    xEnd: {
      true: {
        justifyContent: 'end',
      },
    },
    yCenter: {
      true: {
        alignItems: 'center',
      },
    },
    yStart: {
      true: {
        alignItems: 'start',
      },
    },
    yEnd: {
      true: {
        alignItems: 'end',
      },
    },
    spaceAround: {
      true: {
        justifyContent: 'space-around',
      },
    },
    spaceBetween: {
      true: {
        justifyContent: 'space-between',
      },
    },
    wrap: {
      true: {
        flexWrap: 'wrap',
      },
    },
    mLeft: {
      true: {
        marginLeft: 'auto',
      },
    },
    column: {
      true: {
        flexDirection: 'column',
      },
    },
  },
});

// It is better to do a Icon component instead with a global css injector
//TODO: Not working because the font face config
export const Icon = styled('i', {
  fontFamily: 'uphill-icons',
  fontSize: '$base',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 'initial',

  '&:before': {
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 'normal',
    speak: 'never',

    textDecoration: 'inherit',
    width: '1em',
    textAlign: 'center',

    /* For safety - reset parent styles, that can break glyph codes*/
    fontVariant: 'normal',
    textTransform: 'none',

    /* Font smoothing. That was taken from TWBS */
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },

  variants: {
    name: {
      error: { '&:before': { content: 'ebfd' } },
    },
  },
});

export const Label = styled('label', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});
