import { styled } from '@uphillhealth/theme';

/*
 * Variants must be added to component's interface and then added as prop to the appropriated styled component.
 */

const variantColors = {
  critical: {
    true: {
      backgroundColor: '$error400',
      '&:hover, &:focus': {
        backgroundColor: '$error700',
      },
    },
  },
  neutral: {
    true: {
      backgroundColor: '$neutral900',
      '&:hover, &:focus': {
        color: '$neutral900',
        backgroundColor: 'transparent',
        borderColor: '$neutral900',
      },
    },
  },
};

const variantSizes = {
  medium: {
    true: {
      padding: '$3 $5',
      fontSize: '$base',
    },
  },
};

export const buttonTemplateStyle = {
  padding: '$2 $3',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$neutral100',
  fontSize: '$small',
  lineHeight: '$fontSizes$base',
  backgroundColor: '$primary500',
  border: '1px solid transparent',
  borderRadius: '$pill',
  cursor: 'pointer',
  transition: '175ms',
  '&:hover, &:focus': {
    backgroundColor: '$primary700',
  },
  '&:disabled': {
    backgroundColor: '$neutral300',
    cursor: 'not-allowed',
  },
  'i[class^="icon-"], i[class*=" icon-"]': {
    color: 'inherit !important',
    fontSize: '1.15rem',
    lineHeight: 0,
    cursor: 'inherit',
  },
  variants: {
    ...variantColors,
    ...variantSizes,
    anchor: {
      true: {
        padding: '0',
        color: '$primary500',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        '&:hover, &:focus': {
          color: '$primary900',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
        '&:disabled': {
          color: '$neutral400',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          cursor: 'initial',
        },
      },
    },
    fit: {
      true: {
        height: 'fit-content',
        width: 'fit-content',
      },
    },
    invert: {
      true: {
        color: '$neutral100',
        backgroundColor: 'transparent',
        borderColor: '$neutral100',
        '&:hover, &:focus': {
          backgroundColor: '$primary700',
        },
        '&:disabled': {
          backgroundColor: '$primary400',
          cursor: 'not-allowed',
        },
      },
    },
    outlined: {
      true: {
        color: '$primary500',
        backgroundColor: 'transparent',
        borderColor: '$primary500',
        '&:hover, &:focus': {
          backgroundColor: '$primary50',
        },
        '&:disabled': {
          color: '$neutral400',
          borderColor: '$neutral400',
          backgroundColor: 'transparent',
          cursor: 'initial',
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    square: {
      true: {
        borderRadius: '$small',
      },
    },
  },
  compoundVariants: [
    {
      critical: true,
      outlined: true,
      css: {
        color: '$error400',
        backgroundColor: '$neutral100',
        borderColor: '$error400',
        '&:hover, &:focus': {
          backgroundColor: '$error50',
        },
      },
    },
    {
      neutral: true,
      outlined: true,
      css: {
        color: '$neutral900',
        backgroundColor: 'transparent',
        borderColor: '$neutral900',
        '&:hover, &:focus': {
          color: '$neutral100',
          backgroundColor: '$neutral900',
        },
      },
    },
  ],
};

export const ButtonStyled = styled('button', buttonTemplateStyle);
export const ButtonVisual = styled('div', buttonTemplateStyle);
