import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { countries } from 'country-data';

type FindCountryByCallingCodeOrPhone = (args: { callingCode?: string; phoneNumber?: string }) => {
  callingCode: string;
  countryCode: CountryCode;
  phoneNumber?: string;
};

export const findCountryByCallingCodeOrPhone: FindCountryByCallingCodeOrPhone = ({ callingCode, phoneNumber }) => {
  if (callingCode) {
    const callingCodeWithPlus = callingCode.startsWith('+') ? callingCode : `+${callingCode}`;
    const foundCountry = countries.all
      .filter((country) => country.alpha2 && country.countryCallingCodes.length)
      .find((country) => country.countryCallingCodes.some((countryCallingCode) => countryCallingCode === callingCodeWithPlus));

    if (foundCountry) {
      return { callingCode: callingCode.replace('+', ''), countryCode: foundCountry.alpha2 as CountryCode, phoneNumber };
    }
  }

  if (phoneNumber) {
    const parseInstance = parsePhoneNumber(phoneNumber);
    if (parseInstance && parseInstance.country) {
      return {
        callingCode: parseInstance.countryCallingCode,
        countryCode: parseInstance.country,
        phoneNumber: parseInstance.nationalNumber,
      };
    }
  }

  return { callingCode: '351', countryCode: 'PT', phoneNumber };
};
