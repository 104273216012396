/* eslint-disable max-lines */
import { format, formatISO } from 'date-fns';
import moment from 'moment';
import { Action } from 'redux-actions';

import { criticalElementsAmount, getAlertLoopElements } from '@/helpers/pathAlertsHelper';
import Pathway, { PathwayElementPosition, PathwayImpactCategory, PathwayImpactType } from '@/models/Pathway';
import PathwayAction from '@/models/PathwayAction';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REDUX_STATUS } from '@/constants';
import { COMMUNICATION_STATUS, COMMUNICATION_TYPES, INTERACTION_STATUS } from '@/constants/communication';
import { PATHWAY_ELEMENT_TYPES, PATHWAY_GATEWAY_TYPES } from '@/constants/pathway';
import { calculateScore, updateQuestionChoices, updateQuestionText, validateVisibleQuestions } from '@/helpers/calculatorHelper';
import { getNextElementsFromGateway } from '@/helpers/pathwayGatewayHelper';
import pathwayHelper, { getElementByPosition } from '@/helpers/pathwayHelper';
import { Gender } from '@/models/Common';
import { Communication, CommunicationFactory, INBOUND_STATUS } from '@/models/Communication';
import PathwayCalculator from '@/models/PathwayCalculator';
import { PathwayCommunication } from '@/models/PathwayCommunication';
import { PathwayElement } from '@/models/PathwayElement';
import PathwayGateway from '@/models/PathwayGateway';
import PathwayGatewayOption from '@/models/PathwayGatewayOption';
import { PathwayPanelItem } from '@/models/PathwayPanelItem';
import PathwayPhase from '@/models/PathwayPhase';
import { ProgressStepLog } from '@/models/PathwayProgress';
import PathwayReference from '@/models/PathwayReference';
import { PatientSession, PatientSessionDTO, PatientSessionPathwayImpact, Reminder, RESOLUTION_MECHANISM } from '@/models/PatientSession';
import { patientSessionHelper } from '@/services/patientSessionHelper';
import { fetchCommunicationAnswers } from '../algorithmCommunications/api';
import { getPathwayProgressToSetIds, putPathwayProgress } from '../progress/slice';
import { fetchAlgorithm, fetchDeleteReminderToAlgorithmStep, fetchPatientSession, fetchReminderToAlgorithmStep } from './api';
import { AlgorithmsState, DETAILS_TYPES } from './types';
import { findElementCommunicationSent } from '@/helpers/communicationSentHelper';
import { PatientData, PatientTelecom } from '@/src/models';

const initialState: AlgorithmsState = {
  alertsMetadata: {
    byType: {
      criticals: 0,
      moderated: 0,
    },
    widgetActions: {
      index: 0,
      elementId: '',
    },
  },
  status: REDUX_STATUS.IDLE,
  sessionStatus: REDUX_STATUS.IDLE,
  fullInfo: null,
  path: null,
  session: undefined,
  progressStatus: REDUX_STATUS.IDLE,
  elementOpenedDetails: null,
  openCommunicationAnswers: false,
  startingId: undefined,
  detailsOpened: false,
  detailsType: null,
  reminder: null,
  reminders: null,
  sessionDetails: {
    age: '',
    touched: false,
    created: false,
  },
  communication: null,
  communicationInitialDone: false,
};

export const slicePath = ({
  element,
  elementIndex,
  options,
  state,
}: {
  element: PathwayGateway;
  elementIndex: number;
  options: any;
  state: AlgorithmsState;
}): PathwayElement[] => {
  const path = state.path as PathwayElement[];

  const firstSlice = path.slice(0, elementIndex);
  const newGatewayParts = getNextElementsFromGateway(path, element, options, state.fullInfo as Pathway, null, path, state.session);

  return [...firstSlice, ...newGatewayParts];
};

export const algorithmSlice = createSlice({
  name: 'algorithmSlice',
  initialState,
  reducers: {
    restartAlgorithmStore: () => initialState,
    resetSession: (state) => {
      state.session = undefined;
      state.alertsMetadata = initialState.alertsMetadata;
    },
    resetSessionDetails: (state) => {
      state.sessionDetails.age = '';
      state.sessionDetails.gender = Gender.UNKNOWN;
      state.sessionDetails.created = false;
      state.sessionDetails.touched = false;
    },
    openSidePanel: (state, action: PayloadAction<{ open: boolean }>) => {
      const { open } = action.payload;
      state.detailsOpened = open;
    },
    setSessionGender: (state, action: PayloadAction<{ gender?: Gender; withoutSaving?: boolean }>) => {
      const { gender, withoutSaving } = action.payload;
      state.sessionDetails.gender = gender;
      if (!state.sessionDetails.touched && !withoutSaving) {
        state.sessionDetails.touched = true;
      }
    },
    setSessionAge: (state, action: PayloadAction<{ age: string; withoutSaving?: boolean }>) => {
      const { age, withoutSaving } = action.payload;
      state.sessionDetails.age = age;
      if (!state.sessionDetails.touched && !withoutSaving) {
        state.sessionDetails.touched = true;
      }
    },
    setSessionBirthdate: (state, action: PayloadAction<{ birthdate: string; withoutSaving?: boolean }>) => {
      const { birthdate, withoutSaving } = action.payload;
      state.sessionDetails.birthdate = birthdate;
      if (!state.sessionDetails.touched && !withoutSaving) {
        state.sessionDetails.touched = true;
      }
    },
    setSessionCreated: (state) => {
      state.sessionDetails.created = true;
    },
    setPathStepToRemind: (state, action: PayloadAction<{ id: string; index: number; parentIndex?: number; subPathSelected?: string }>) => {
      const { id, index, parentIndex, subPathSelected } = action.payload;
      state.reminder = { id, index, parentIndex, subPathSelected };
    },
    setPathStepToRemindNull: (state) => {
      state.reminder = null;
    },
    startAddingReminders: (state) => {
      state.reminders = [];
    },
    cancelAddingReminders: (state) => {
      state.reminders = null;
    },
    addStepToRemind: (state, action: PayloadAction<{ id: string; index: number; parentIndex?: number; subPathSelected?: string }>) => {
      if (state.reminders) {
        state.reminders.push(action.payload);
      }
    },
    removeStepToRemind: (state, action: PayloadAction<{ id: string; index: number; parentIndex?: number; subPathSelected?: string }>) => {
      if (state.reminders) {
        const { id, index, parentIndex, subPathSelected } = action.payload;
        state.reminders = state.reminders.filter(
          (ele) => !(ele.id === id && ele.index === index && ele.parentIndex === parentIndex && ele.subPathSelected === subPathSelected),
        );
      }
    },
    toggleActionExpansion: (state, action: PayloadAction<{ index: number; parentIndex?: number }>) => {
      const { index: actionIndex, parentIndex } = action.payload;
      if (state.path && actionIndex >= 0) {
        if (parentIndex !== undefined && state.path[Number(parentIndex)]?.type === PATHWAY_ELEMENT_TYPES.GATEWAY) {
          const gateway = state.path[Number(parentIndex)] as PathwayGateway;
          const actionEl = gateway.subPathSelected ? gateway.subPaths?.[String(gateway.subPathSelected)]?.path[Number(actionIndex)] : null;
          if (
            actionEl?.type === PATHWAY_ELEMENT_TYPES.ACTION &&
            gateway.subPathSelected &&
            gateway.subPaths?.[String(gateway.subPathSelected)].path[Number(actionIndex)]
          ) {
            gateway.subPaths[String(gateway.subPathSelected)].path[Number(actionIndex)] = {
              ...actionEl,
              isExpanded: !actionEl.isExpanded,
            };
          }
        } else {
          const actionEl = state.path[Number(actionIndex)];
          if (actionEl.type === PATHWAY_ELEMENT_TYPES.ACTION) {
            state.path[Number(actionIndex)] = {
              ...actionEl,
              isExpanded: !actionEl.isExpanded,
            };
          }
        }
      }
    },
    openCommunicationAnswers: (state) => {
      state.openCommunicationAnswers = true;
    },
    closeCommunicationAnswers: (state) => {
      state.openCommunicationAnswers = false;
    },
    setReferenceParentOptionCompleted: (state, action: PayloadAction<{ index: number; parentIndex?: number }>) => {
      const { parentIndex, index } = action.payload;
      if (parentIndex !== undefined) {
        const gateway = state.path?.[Number(parentIndex)] as PathwayGateway;
        if (gateway.subPathSelected && gateway.subPaths?.[String(gateway.subPathSelected)]) {
          const pathRef = gateway.subPaths?.[String(gateway.subPathSelected)].path[Number(index)];
          if (pathRef?.type === PATHWAY_ELEMENT_TYPES.PATHWAY_REFERENCE) {
            pathRef.skipped = false;
            pathRef.lastModifiedDate = new Date().toString();
          }
        }
        if (gateway && gateway.options) {
          gateway.options = gateway.options?.map((ele) => {
            if (ele.selected) {
              ele.completed = true;
            }
            return ele;
          });
          if (gateway.subtype === PATHWAY_GATEWAY_TYPES.INCLUSIVE) {
            gateway.isCompleted = true;
          } else if (gateway.options.every((opt) => opt.completed)) {
            gateway.isCompleted = true;
            gateway.allOptionsCompleted = true;
          }
          if (gateway.isCompleted && state.path && state.path.length - 1 === parentIndex && state.fullInfo?.pathway) {
            state.path = [
              ...state.path,
              ...getNextElementsFromGateway(
                [gateway],
                gateway,
                gateway.options.filter((opt) => opt.selected),
                state.fullInfo,
                gateway.nextElementId,
                state.path,
                state.session,
              ).slice(1),
            ];
          }
        }
      }
      const ref = state.path?.[Number(index)] as PathwayReference;
      ref.skipped = false;
      ref.lastModifiedDate = new Date().toString();
    },
    setElementsArraySkipped: (state, action: PayloadAction<{ indexEnd: number; subPathIndexEnd?: number; skipped?: boolean }>) => {
      const { indexEnd, subPathIndexEnd, skipped } = action.payload;
      for (let index = 0; index < indexEnd; index++) {
        if (state.path && state.path[Number(index)]) {
          const element = state.path[Number(index)];
          element.skipped = element.skipped ?? skipped ?? false;
          element.lastModifiedDate = element.lastModifiedDate ?? new Date().toString();
        }
      }
      const element = state.path?.[Number(indexEnd)] as PathwayGateway;
      if (subPathIndexEnd !== undefined && element?.subPaths) {
        Object.keys(element.subPaths).forEach((path) => {
          for (let index = 0; index < subPathIndexEnd; index++) {
            if (state.path && state.path[Number(index)]) {
              const subElement = element.subPaths?.[String(path)].path[Number(index)];
              if (subElement) {
                subElement.skipped = subElement.skipped ?? skipped ?? false;
                subElement.lastModifiedDate = subElement.lastModifiedDate ?? new Date().toString();
              }
            }
          }
        });
      }
    },
    changePathwayPhase: (state, action: PayloadAction<{ phase: PathwayPhase }>) => {
      const phase = action.payload.phase;
      const elFromPhase = state.path?.find((el) => el.phaseId === phase.id);
      if (state.fullInfo && state.progress?.phaseSelected.id !== phase.id && !elFromPhase) {
        const pathway = pathwayHelper.initPathway(state.fullInfo, phase.initialElementId, {
          builtPath: state.path as PathwayElement[],
          session: state.session,
        });
        state.path = pathway;
        state.startingId = phase.initialElementId;
        state.progress = {
          phaseSelected: phase,
          total: pathwayHelper.calcAlgorithmProgress(pathway, 0),
        };
      }
    },
    setElementOpenedDetails: (state, action: PayloadAction<PathwayPanelItem | null>) => {
      const details = action.payload;
      if (state.path) {
        algorithmSlice.caseReducers.resetSidePanel(state);
        state.elementOpenedDetails = details;
        state.detailsOpened = !!details;
        state.detailsType = DETAILS_TYPES.ACTION;
      }
    },
    setCommunicationOpened: (state, action: PayloadAction<{ type: DETAILS_TYPES; details: string }>) => {
      if (state.path) {
        algorithmSlice.caseReducers.resetSidePanel(state);
        state.detailsType = action.payload.type;
        if (state.communication) {
          state.communication.details = action.payload.details;
        } else {
          state.communication = { details: action.payload.details };
        }
        state.detailsOpened = !!action.payload;
      }
    },
    setCalculatorSelected: (
      state,
      action: PayloadAction<{
        calculator: PathwayCalculator;
        index: number;
        subPathIndex?: number;
        subPathName?: string;
        opened?: boolean;
      }>,
    ) => {
      algorithmSlice.caseReducers.resetSidePanel(state);
      state.detailsType = DETAILS_TYPES.CALCULATOR;
      const { calculator, index, subPathIndex, subPathName } = action.payload;
      state.calculatorSelected = {
        calculator: {
          ...calculator,
          questions: validateVisibleQuestions(calculator.questions),
        },
        index,
        subPathIndex,
        subPathName,
      };
      state.detailsOpened = action.payload.opened ?? state.detailsOpened;
    },
    setQuestionAnswerSelected: (state, action: PayloadAction<{ questionId: number; answerId: number }>) => {
      if (!state.calculatorSelected) {
        return;
      }
      const { questionId, answerId } = action.payload;
      const { calculator: currCalculator } = state.calculatorSelected;
      const calculator = updateQuestionChoices(questionId, answerId, currCalculator);
      calculator.totalScore = calculateScore(calculator);
      state.calculatorSelected.calculator = calculator;
    },
    setQuestionAnswerText: (state, action: PayloadAction<{ questionId: number; value: string }>) => {
      if (!state.calculatorSelected) {
        return;
      }
      const { questionId, value } = action.payload;
      const { calculator: currCalculator } = state.calculatorSelected;
      const calculator = updateQuestionText(questionId, value, currCalculator);
      calculator.totalScore = calculateScore(calculator);
      state.calculatorSelected.calculator = calculator;
    },
    submitCalculatorSelectedScore: (state) => {
      if (state.fullInfo && state.path && state.calculatorSelected?.index !== undefined) {
        const { path, session } = state;
        const { calculator, index, subPathIndex, subPathName } = state.calculatorSelected;

        const nextEls = pathwayHelper
          .getNextElementIdFromCalculator(calculator, state.fullInfo, { builtPath: path, session })
          ?.map((ele) => findElementCommunicationSent(ele, session as PatientSession));

        if (nextEls) {
          let parentGateway;
          if (subPathIndex !== undefined) {
            parentGateway = state.path[Number(index)] as PathwayGateway;
          }

          const subPath = subPathName ?? parentGateway?.subPathSelected;

          const finalGateway = nextEls.find((ele) => (ele as PathwayGateway).isFinal);

          if (finalGateway) {
            const lastIndex = state.path
              .map(
                (ele) =>
                  ele.id ===
                  (nextEls[nextEls.map((l) => (l as PathwayGateway).isFinal).lastIndexOf(true)] as PathwayGateway).initialGatewayId,
              )
              .lastIndexOf(true);
            const initialGateway = state.path[Number(lastIndex)];
            if (initialGateway?.type === PATHWAY_ELEMENT_TYPES.GATEWAY && initialGateway?.options) {
              initialGateway.options.forEach((o) => {
                if (o.selected) {
                  o.completed = true;
                }
              });

              const subPath = subPathName ?? parentGateway?.subPathSelected;

              if (parentGateway?.subtype === PATHWAY_GATEWAY_TYPES.PARALLEL) {
                if (
                  parentGateway &&
                  subPath &&
                  parentGateway?.subPaths?.[String(subPath)] &&
                  parentGateway.options?.every((o) => o.completed)
                ) {
                  const newSubPath = [...parentGateway.subPaths[String(subPath)].path.slice(0, subPathIndex), ...nextEls];
                  parentGateway.subPaths[String(subPath)].path = newSubPath;

                  parentGateway.isCompleted = true;
                  parentGateway.allOptionsCompleted = true;
                  if (index === path.length - 1) {
                    state.path = [
                      ...state.path,
                      ...pathwayHelper
                        .getNextPathwayElements(state.fullInfo, parentGateway.nextElementId, {
                          session: state.session,
                          builtPath: state.path,
                        })
                        .map((ele) => findElementCommunicationSent(ele, session as PatientSession)),
                    ];
                  }
                } else if (parentGateway && subPath && parentGateway?.subPaths?.[String(subPath)]) {
                  const newSubPath = [...parentGateway.subPaths[String(subPath)].path.slice(0, subPathIndex), ...nextEls];
                  parentGateway.subPaths[String(subPath)].path = newSubPath;
                } else {
                  state.path = [
                    ...state.path.slice(0, index),
                    ...nextEls,
                    ...pathwayHelper
                      .getNextPathwayElements(state.fullInfo, nextEls[nextEls.length - 1].nextElementId, {
                        session: state.session,
                        builtPath: state.path,
                      })
                      .map((ele) => findElementCommunicationSent(ele, session as PatientSession)),
                  ];
                }
              } else if (parentGateway?.subtype === PATHWAY_GATEWAY_TYPES.INCLUSIVE) {
                if (parentGateway && subPath && parentGateway?.subPaths?.[String(subPath)]) {
                  const calculatorFinalIndex = nextEls.findIndex((ele) => (ele as PathwayGateway).isFinal);

                  if (calculatorFinalIndex >= 0) {
                    const newSubPath = [
                      ...parentGateway.subPaths[String(subPath)].path.slice(0, subPathIndex),
                      ...nextEls
                        .slice(0, calculatorFinalIndex + 1)
                        .map((ele) => findElementCommunicationSent(ele, session as PatientSession)),
                    ];

                    parentGateway.subPaths[String(subPath)].path = newSubPath;
                  }

                  if (parentGateway.options && parentGateway.options.some((o) => o.completed)) {
                    if (index === state.path.length - 1) {
                      state.path = [
                        ...state.path,
                        ...pathwayHelper
                          .getNextPathwayElements(state.fullInfo, parentGateway.nextElementId, {
                            session: state.session,
                            builtPath: state.path,
                          })
                          .map((ele) => findElementCommunicationSent(ele, session as PatientSession)),
                      ];
                    }
                    if (parentGateway.options.some((o) => o.completed)) {
                      parentGateway.isCompleted = true;

                      parentGateway.allOptionsCompleted = parentGateway.options?.every((o) => o.completed);
                    }
                  } else {
                    parentGateway.isCompleted = false;
                    parentGateway.allOptionsCompleted = false;
                  }
                }
              } else {
                state.path = [
                  ...state.path.slice(0, index),
                  ...nextEls,
                  ...pathwayHelper
                    .getNextPathwayElements(state.fullInfo, nextEls[nextEls.length - 1].nextElementId, {
                      session: state.session,
                      builtPath: state.path,
                    })
                    .map((ele) => findElementCommunicationSent(ele, session as PatientSession)),
                ];
              }
            } else if (parentGateway && subPath && parentGateway?.subPaths?.[String(subPath)]) {
              const newSubPath = [...parentGateway.subPaths[String(subPath)].path.slice(0, subPathIndex), ...nextEls];
              parentGateway.subPaths[String(subPath)].path = newSubPath;
            } else {
              state.path = [...state.path.slice(0, index), ...nextEls];
            }
          } else if (parentGateway && subPath && parentGateway?.subPaths?.[String(subPath)]) {
            const newSubPath = [...parentGateway.subPaths[String(subPath)].path.slice(0, subPathIndex), ...nextEls];
            parentGateway.subPaths[String(subPath)].path = newSubPath;
          } else {
            state.path = [...state.path.slice(0, index), ...nextEls];
          }

          const lastElPhase = state.path[state.path.length - 1].phaseId;
          const phase = state.fullInfo.phases.find((p) => p.id === lastElPhase);
          if (phase) {
            state.progress = {
              phaseSelected: phase,
              total: pathwayHelper.calcAlgorithmProgress(state.path, 0),
            };
          }
        }
        algorithmSlice.caseReducers.clearSidePanel(state);
      }
    },
    resetSidePanel: (state) => {
      state.calculatorSelected = undefined;
      state.elementOpenedDetails = null;
      state.detailsType = null;
    },
    clearSidePanel: (state) => {
      state.calculatorSelected = undefined;
      state.elementOpenedDetails = null;
      state.detailsOpened = false;
      state.detailsType = null;
    },
    setOpenDetails: (state, action: Action<{ opened: boolean; type: DETAILS_TYPES }>) => {
      state.detailsOpened = action.payload.opened;
      state.detailsType = action.payload.type;
    },
    setReminders: (state) => {
      if (state.session) {
        const { reminders } = state.session;
        if (reminders) {
          reminders.forEach((rem) => {
            if (!rem.completed) {
              rem.reminderElements.forEach((ele) => {
                const { elementId, elementStepNumber, parentStepNumber, firstElementOfSubPath } = ele;
                if (firstElementOfSubPath && parentStepNumber !== undefined) {
                  const subEle = (state.path?.[Number(parentStepNumber)] as PathwayGateway)?.subPaths?.[String(firstElementOfSubPath)]
                    ?.path[Number(elementStepNumber)];
                  if (subEle && subEle.id === elementId && (state.path?.[Number(parentStepNumber)] as PathwayGateway)?.subPaths) {
                    subEle.reminder = rem;
                    const subPaths = (state.path?.[Number(parentStepNumber)] as PathwayGateway).subPaths;
                    if (subPaths && state.path?.[Number(parentStepNumber)]) {
                      subPaths[String(firstElementOfSubPath)].path[Number(elementStepNumber)] = subEle;
                      state.path[Number(parentStepNumber)] = {
                        ...state.path[Number(parentStepNumber)],
                        subPaths,
                      } as PathwayGateway;
                    }
                  }
                } else {
                  if (state.path?.[Number(elementStepNumber)] && state.path[Number(elementStepNumber)].id === elementId) {
                    state.path[Number(elementStepNumber)].reminder = rem;
                  }
                }
              });
            }
          });
        }
      }
    },
    setAlertsMetadata: (state) => {
      if (state.session) {
        const { pathwayImpacts: sessionImpacts } = state.session;

        type WidgetActionSorting = {
          date: string;
          id: string;
          index: number;
          solved?: boolean;
        };

        const metadata = sessionImpacts?.reduce(
          (merge, sessionImpact) => {
            if (!sessionImpact.resolutionMechanism && sessionImpact.type === PathwayImpactType.alert) {
              const typeKey = sessionImpact.category === PathwayImpactCategory.critical ? 'criticals' : 'moderated';
              merge.byType = {
                ...merge.byType,
                [typeKey]: merge.byType[String(typeKey) as 'criticals' | 'moderated'] + 1,
              };
            }

            const {
              date,
              position: { elementId, elementStepNumber },
            } = sessionImpact;

            if (sessionImpact.type === PathwayImpactType.alert) {
              merge.sessionImpacts = {
                ...merge.sessionImpacts,
                [elementId]: { index: elementStepNumber, id: elementId, date },
              };
            }

            return merge;
          },
          { ...initialState.alertsMetadata, sessionImpacts: {} },
        );

        state.alertsMetadata.byType = metadata.byType;

        const sorted = Object.values(metadata.sessionImpacts)[0] as WidgetActionSorting;

        if (sorted) {
          state.alertsMetadata.widgetActions = {
            index: sorted.index,
            elementId: sorted.id,
          };
        }
      }
    },
    hideAlertShortcut: (state) => {
      state.alertsMetadata.widgetActions = initialState.alertsMetadata.widgetActions;
    },
    updatePathPathwayImpact: (state, action: Action<PatientSessionPathwayImpact>) => {
      const { payload: currentImpact } = action;

      const amount = criticalElementsAmount(currentImpact);

      const solvedImpact = {
        ...currentImpact,
        criticalElementsCompleted: Array(amount).fill(''),
        resolutionMechanism: RESOLUTION_MECHANISM.SOLVED_HCP_DIRECT,
        resolutionDate: new Date().getTime(),

        solvedDate: new Date().toDateString(),
      } as PatientSessionPathwayImpact;
      const pathPosition = currentImpact.position.elementStepNumber;

      if (state.path && state.path[Number(pathPosition)]) {
        state.path[Number(pathPosition)].pathwayImpact = solvedImpact;
      }

      if (state.session && state.session?.pathwayImpacts) {
        (state.session as PatientSession).pathwayImpacts = state.session.pathwayImpacts.map((sessionImpact) => {
          if (
            sessionImpact.communicationId === solvedImpact.communicationId &&
            sessionImpact.communicationElementId === solvedImpact.communicationElementId &&
            sessionImpact.matcherId === solvedImpact.matcherId &&
            sessionImpact.type === solvedImpact.type
          ) {
            return solvedImpact;
          }
          return sessionImpact;
        });
      }
    },
    setPatientContacts: (state, action: Action<{ telecom: PatientTelecom[] }>) => {
      const { telecom } = action.payload;
      if (state.session && state.session.patient) {
        state.session.patient.telecom = telecom;
      }
    },
    setCommunications: ({ session, path, communicationInitialDone }) => {
      if (session) {
        const { communications } = session;
        if (communications) {
          communications.forEach((comm) => {
            if (path) {
              const { position } = comm;

              let element: PathwayCommunication | PathwayAction | undefined = undefined;

              if (position.subPathSelected && position.parentIndex !== undefined) {
                const gateway = path[position.parentIndex] as PathwayGateway;
                if (gateway?.subtype !== PATHWAY_GATEWAY_TYPES.EXCLUSIVE) {
                  element = gateway.subPaths?.[position.subPathSelected].path[position.index] as PathwayCommunication | PathwayAction;
                }
              } else {
                element = path[position.index] as PathwayCommunication | PathwayAction;
              }

              if (
                element &&
                element.id === position.elementId &&
                (element.type === COMMUNICATION_TYPES.KEY_MOMENT ||
                  element.type === COMMUNICATION_TYPES.ACTION_LINKED ||
                  element.type === COMMUNICATION_TYPES.PRO_ACTIVE ||
                  element.type === PATHWAY_ELEMENT_TYPES.ACTION)
              ) {
                element.communicationSent = comm;
                if (element.communicationSent) {
                  element.skipped = false;
                }
                element.communicationSentStatus = REDUX_STATUS.SUCCEEDED;
              }
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          communicationInitialDone = true;
        }
      }
    },
    setCommunicationCountdown: (state, action: Action<{ position: PathwayElementPosition; value: number | null }>) => {
      const { position, value } = action.payload;
      const { index, parentIndex, subPathSelected } = position;

      let element;
      if (subPathSelected && parentIndex) {
        element = (state.path?.[Number(parentIndex)] as PathwayGateway)?.subPaths?.[String(subPathSelected)].path[Number(index)] as
          | PathwayAction
          | PathwayCommunication;
      } else {
        element = state.path?.[Number(index)] as PathwayAction | PathwayCommunication;
      }

      if (element) {
        element.communicationCountdown = value;
      }
    },
    setProgressIdsToPath: (
      state,
      action: Action<{
        progress: {
          subPath?: { key: string; path: ({ id?: string; createdDate?: string } | undefined)[] | undefined }[];
          id?: string;
          createdDate?: string;
        }[];
      }>,
    ) => {
      const { progress } = action.payload;

      if (progress) {
        progress.forEach((step, index) => {
          if (state.path?.[Number(index)]) {
            state.path[Number(index)].progressId = step?.id;

            if (step?.subPath) {
              const gateway = state.path[Number(index)] as PathwayGateway;
              step.subPath.forEach((spElem, j) => {
                if (gateway.subPaths?.[String(spElem.key)].path && spElem.path) {
                  gateway.subPaths[String(spElem.key)].path[Number(j)].progressId = spElem.path[Number(j)]?.id;
                }
              });
              state.path[Number(index)] = gateway;
            }
          }
        });
      }
    },
    setGatewaySubpathStartStepNumber: (
      state,
      action: Action<{ parentIndex: number; startStepNumber: number; subPathSelected?: string }>,
    ) => {
      const { startStepNumber, parentIndex, subPathSelected } = action.payload;
      const gateway = state.path?.[Number(parentIndex)] as PathwayGateway;

      if (state.path && gateway && subPathSelected && gateway.subPaths?.[String(subPathSelected)]) {
        gateway.subPaths[String(subPathSelected)].startStepNumber = startStepNumber;
        state.path[Number(parentIndex)] = gateway;
      }
    },
    setPatientSessionProgressIds: (state) => {
      if (state.session?.steps && state.path) {
        state.path = state.path.map((ele, i) => {
          if (state.session?.steps?.[Number(i)]) {
            ele.progressId = state.session?.steps[Number(i)].id;
            ele.skipped = state.session?.steps[Number(i)].skipped;
            if (ele.type === PATHWAY_ELEMENT_TYPES.GATEWAY && ele.subPaths) {
              Object.keys(ele.subPaths).forEach((key) => {
                if (ele.subPaths) {
                  ele.subPaths[String(key)].path = ele.subPaths[String(key)].path.map((subEle, j) => {
                    if (ele.subPathSelected && state.session?.steps?.[Number(i)].subPath?.[String(key)]?.[Number(j)]) {
                      subEle.progressId = state.session?.steps[Number(i)].subPath[String(key)][Number(j)].id;
                      subEle.skipped = state.session?.steps[Number(i)].subPath[String(key)][Number(j)].skipped;
                    }
                    return subEle;
                  });
                }
              });
            }
          }
          return ele;
        });
      }
    },
    updateReminderOnStep: (state, action) => {
      const { index, parentIndex, subPathSelected, description, date } = action.payload;

      let reminder: Reminder | undefined;
      if (index !== undefined && state.path) {
        const gateway = state.path?.[Number(parentIndex)] as PathwayGateway;
        if (parentIndex !== undefined && subPathSelected !== undefined && gateway.subPaths) {
          reminder = gateway.subPaths[String(subPathSelected)].path[Number(index)].reminder.description;
          state.path[Number(parentIndex)] = gateway;
        } else {
          reminder = state.path[Number(index)].reminder;
        }
      }

      if (reminder) {
        reminder = { ...reminder, description, date };
        reminder.reminderElements.forEach((ele) => {
          if (state.path && reminder) {
            if (ele.parentStepNumber !== null && ele.parentStepNumber !== undefined) {
              const gateway = state.path[ele.parentStepNumber] as PathwayGateway;
              if (
                gateway?.subPathSelected &&
                gateway.subPaths?.[String(gateway.subPathSelected)] &&
                gateway.subPaths[String(gateway.subPathSelected)].path[ele.elementStepNumber].reminder.id === reminder.id
              ) {
                gateway.subPaths[String(gateway.subPathSelected)].path[ele.elementStepNumber].reminder = reminder;
              }
            } else if (state.path[ele.elementStepNumber].reminder.id === reminder.id) {
              state.path[ele.elementStepNumber].reminder = reminder;
            }
          }
        });
      }
    },
    setGatewatInclusiveEditing: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      const element = state.path?.[Number(index)];
      if (state.path && element && element.type === PATHWAY_ELEMENT_TYPES.GATEWAY) {
        element.isEditing = false;
        state.path = [...state.path];
      }
    },
    unblockElement: (state, action: PayloadAction<{ index: number; subPathSelected?: string; parentIndex?: number }>) => {
      const { index, subPathSelected, parentIndex } = action.payload;

      let element;
      if (subPathSelected && parentIndex !== undefined) {
        element = (state.path?.[Number(parentIndex)] as PathwayGateway)?.subPaths?.[String(subPathSelected)].path[Number(index)] as
          | PathwayCalculator
          | PathwayGateway;
      } else {
        element = state.path?.[Number(index)] as PathwayCalculator | PathwayGateway;
      }
      if (element) {
        element.isBlocked = false;
      }
    },
    addCommunicationToSession: (state, action: PayloadAction<Communication>) => {
      state.session?.communications?.push(action.payload);
    },
    setCommunicationSuspended: (state, { payload }: PayloadAction<{ position: PathwayElementPosition }>) => {
      if (state.path) {
        const element = getElementByPosition(state.path, payload.position);
        if (
          element &&
          (element.type === PATHWAY_ELEMENT_TYPES.ACTION ||
            element.type === COMMUNICATION_TYPES.ACTION_LINKED ||
            element.type === COMMUNICATION_TYPES.PRO_ACTIVE ||
            element.type === COMMUNICATION_TYPES.KEY_MOMENT) &&
          element.communicationSent
        ) {
          element.communicationSent.overviewState = COMMUNICATION_STATUS.SUSPENDED;
          element.communicationSent.suspensionDateTime = new Date().toUTCString();
          state.alertsMetadata = initialState.alertsMetadata;
          if (element.communicationSent?.inboundStatus === INBOUND_STATUS.TRIGGERED) {
            element.communicationSent.inboundStatus = INBOUND_STATUS.ACTIVE;
          }
        }
      }
    },
    setInboundChannelSuspended: (state, { payload }: PayloadAction<{ position: PathwayElementPosition }>) => {
      if (state.path) {
        const element = getElementByPosition(state.path, payload.position);

        if (element && element.type === COMMUNICATION_TYPES.KEY_MOMENT && element.communicationSent) {
          element.communicationSent.inboundStatus = INBOUND_STATUS.CANCELLED;
          element.communicationSent.overviewState = COMMUNICATION_STATUS.SUSPENDED;
          element.communicationSent.inboundEndDateTime = new Date().toUTCString();
        }
      }
    },
    setPatientData: (state, action: PayloadAction<PatientData>) => {
      if (state.session) {
        const { birthDate, name, telecom, gender, communication } = action.payload;
        const age = birthDate ? patientSessionHelper.calcAge(birthDate) : undefined;
        state.session.age = age;
        state.session.avatar = patientSessionHelper.getAvatar(gender as Gender, age);
        state.session.patient.birthDate = birthDate;
        state.session.patient.name = name;
        state.session.patient.telecom = telecom;
        state.session.patient.gender = gender;
        state.session.patient.communication = communication;
      }
    },
    setGatewayAnsweredByHilly: (
      state,
      {
        payload: { index, lastCommunication, options, sessionImpact },
      }: PayloadAction<{
        index: number;
        lastCommunication?: boolean;
        options: PathwayGatewayOption[];
        sessionImpact: PatientSessionPathwayImpact;
      }>,
    ) => {
      if (state?.path?.[Number(index)]) {
        const referencedGateway = state?.path[Number(index)] as PathwayGateway;
        referencedGateway.hillyAnswered = true;
        referencedGateway.hillyAnswers = options;
        referencedGateway.sessionImpact = sessionImpact;
        referencedGateway.isLocked = !lastCommunication;
      }
    },
    slicePathAfterCommunicationResetProgress: (
      state,
      { payload: { communicationIndex, sliceIndex } }: PayloadAction<{ communicationIndex: number; sliceIndex: number }>,
    ) => {
      if (state?.path) {
        const gatewayElement = state.path.slice(sliceIndex, sliceIndex + 1)[0];
        const blockedGatewayElement = {
          ...gatewayElement,
          skipped: undefined,
          isBlocked: true,
          overrideBlockage: true,
          blockedBy: { index: communicationIndex },
        };

        state.path = [...state.path.slice(0, sliceIndex), blockedGatewayElement];
      }
    },
    resolveRecurrentCommunicationAlert: (
      state,
      { payload: { blockedByIndex, index } }: PayloadAction<{ blockedByIndex?: number; index: number }>,
    ) => {
      if (blockedByIndex && state.path?.[Number(blockedByIndex)].pathwayImpact) {
        const { communicationSent, pathwayImpact } = state.path[Number(blockedByIndex)] as Required<PathwayCommunication>;

        if (communicationSent.overviewState === COMMUNICATION_STATUS.FINISHED || !state.path) {
          return;
        }

        const loopElements = getAlertLoopElements({
          context: {
            session: state.session,
            builtPath: state.path.slice(0, -1),
            createFromLoop: true,
            alertId: pathwayImpact.id,
          },
          pathway: state.fullInfo as Pathway,
          pathwayImpact,
        });
        const beforeGateway: PathwayElement[] = state.path.slice(0, index);
        const inclusiveAfter: PathwayElement[] = state.path.slice(index);

        (state.path[Number(index)] as PathwayGateway).overrideBlockage = true;
        (state.path[Number(index)] as PathwayGateway).lastModifiedDate = new Date().toString();

        state.path = beforeGateway.concat(loopElements, inclusiveAfter);
      }
    },
    setSendCommunication: (
      state,
      action: PayloadAction<{
        communicationId: string;
        position: PathwayElementPosition;
        scheduledDate?: string;
        error?: string;
      }>,
    ) => {
      if (state.path) {
        const { position, error, scheduledDate, communicationId } = action.payload;
        const { index, subPathSelected, parentIndex } = position;
        let communication: PathwayCommunication;

        if (
          parentIndex !== undefined &&
          subPathSelected &&
          (state.path[Number(parentIndex)] as PathwayGateway).subPaths?.[String(subPathSelected)].path[Number(index)]
        ) {
          communication = (state.path[Number(parentIndex)] as PathwayGateway).subPaths?.[String(subPathSelected)].path[
            Number(index)
          ] as PathwayCommunication;
        } else {
          communication = state.path[Number(index)] as PathwayCommunication;
        }

        if (error) {
          communication.communicationSentStatus = REDUX_STATUS.FAILED;
          communication.communicationSentError = error;
        } else if (scheduledDate) {
          communication.communicationSentStatus = REDUX_STATUS.SUCCEEDED;
          const date = new Date(scheduledDate.replace(' ', 'T'));
          communication.skipped = false;
          communication.communicationSent = {
            id: '',
            communicationId: communicationId,
            firstCommunicationSentDateTime: null,
            lastCommunicationSentDateTime: null,
            lastUserResponseDateTime: null,
            nextContactDateTime: moment(date).format(),
            overviewState: COMMUNICATION_STATUS.SCHEDULED,
            interactionState: INTERACTION_STATUS.ACTIVE,
            position,
            userResponsesState: REDUX_STATUS.IDLE,
            userResponses: [],
            suspensionDateTime: null,
            numberOfCurrentSentCommunications: 0,
            numberOfCurrentAnsweredCommunications: 0,
            inboundStatus: communication?.inboundConfiguration?.active ? INBOUND_STATUS.ACTIVE : undefined,
            createdDate: moment(date).format(),
          };
        }
      }
    },
    updateCommunicationIdFromSocket: (
      state,
      action: PayloadAction<{
        communicationId: string;
      }>,
    ) => {
      if (state.path) {
        const elementIndex = state.path.findIndex((pathElement) => {
          const commsElement = pathElement as PathwayCommunication;
          return commsElement.communicationSent?.overviewState === COMMUNICATION_STATUS.SCHEDULED && !commsElement.communicationSent?.id;
        });

        if (elementIndex === -1) {
          return;
        }

        const referencedElement = state.path[Number(elementIndex)] as PathwayCommunication;
        state.path[Number(elementIndex)] = {
          ...referencedElement,
          communicationSent: { ...referencedElement.communicationSent, id: action.payload.communicationId },
        } as PathwayCommunication;
      }
    },
    restartAlgorithmProgress: (state) => {
      if (state.fullInfo) {
        state.path = pathwayHelper.initPathway(state.fullInfo, undefined, {
          builtPath: state.path as PathwayElement[],
          session: state.session,
        });
        state.startingId = undefined;
        if (state.fullInfo.patientSessionConfiguration) {
          const { defaultAge, defaultGender } = state.fullInfo.patientSessionConfiguration;
          state.sessionDetails.gender = defaultGender ?? Gender.UNKNOWN;
          state.sessionDetails.age = defaultAge ?? '';
        }
        state.progress = {
          phaseSelected: state.fullInfo.phases[0],
          total: pathwayHelper.calcAlgorithmProgress(state.path, 0),
        };
      }
    },
    setPathwayElementStepLogs: (
      state,
      action: PayloadAction<{
        elementIndex: number;
        stepLogs: ProgressStepLog[];
      }>,
    ) => {
      const { elementIndex, stepLogs } = action.payload;

      // TODO: previous status for items & for action when having multiple items
      const pathwayAction = (state.path as PathwayElement[])[Number(elementIndex)] as PathwayAction;

      if (pathwayAction.allSelectable && pathwayAction.actionItems.length === 1) {
        const lastLog = stepLogs.slice(-1)[0];
        pathwayAction.previousStatus = lastLog.oldStatus;
      } else {
        const previousStatusByItemId = stepLogs.reduce((merge: Record<string, string>, stepLog) => {
          return stepLog.itemId ? { ...merge, [stepLog.itemId]: stepLog.oldStatus ?? '' } : merge;
        }, {});

        pathwayAction.actionItems = pathwayAction.actionItems.map((actionItem) => {
          const modifiedGroups = actionItem.groups.map((actionItemGroup) => {
            const modifiedItems = actionItemGroup.items.map((actionItemGroupItem) => {
              const previousStatus = previousStatusByItemId[String(actionItemGroupItem.id)] ?? undefined;
              return { ...actionItemGroupItem, previousStatus };
            });
            return { ...actionItemGroup, items: modifiedItems };
          });
          return { ...actionItem, groups: modifiedGroups };
        });
      }

      pathwayAction.stepLogs = stepLogs;
      pathwayAction.stepLogsFethTime = new Date().getTime();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatientSession.fulfilled, (state, action) => {
      if (action.payload) {
        state.session = action.payload ?? null;
        const { patient } = action.payload;
        const age = patient.birthDate ? patientSessionHelper.calcAge(patient.birthDate) : undefined;
        state.session.age = age;
        state.session.avatar = patientSessionHelper.getAvatar(patient.gender as Gender, age);
        if (age !== undefined) {
          state.sessionDetails.age = age.toString();
          state.sessionDetails.birthdate = patient.birthDate ? format(new Date(patient.birthDate), 'dd/MM/yyyy') : '';
          state.sessionDetails.gender = patient.gender;
        }
        state.sessionStatus = REDUX_STATUS.SUCCEEDED;
      }
    });
    builder.addCase(fetchPatientSession.rejected, (state, action) => {
      if (action?.error?.message?.includes('status code 403')) {
        state.sessionStatus = REDUX_STATUS.UNAUTHORIZED;
      } else {
        state.sessionStatus = REDUX_STATUS.FAILED;
      }
    });
    builder.addCase(fetchAlgorithm.pending, (state, action) => {
      state.fullInfo = null;
      state.path = null;
      state.status = REDUX_STATUS.LOADING;
      if (!action.meta.arg.isPatientSession) {
        state.sessionDetails = {};
      }
    });
    builder.addCase(fetchAlgorithm.fulfilled, (state, action) => {
      const { payload } = action;
      state.status = REDUX_STATUS.SUCCEEDED;

      if (payload.data) {
        state.fullInfo = payload.data;
        state.path = pathwayHelper.initPathway(payload.data, undefined, {
          builtPath: state.path as PathwayElement[],
          session: state.session,
        });
        state.startingId = undefined;
        state.progress = {
          phaseSelected: payload.data.phases[0],
          total: pathwayHelper.calcAlgorithmProgress(state.path, 0),
        };
        if (!state.sessionDetails.age) {
          state.sessionDetails.age = payload.data?.patientSessionConfiguration?.defaultAge ?? '';
        }
        if (!state.sessionDetails.gender || state.sessionDetails.gender === Gender.UNKNOWN) {
          state.sessionDetails.gender = payload.data?.patientSessionConfiguration?.defaultGender ?? Gender.UNKNOWN;
        }
        state.sessionDetails.created = false;
        state.sessionDetails.touched = false;
      }
    });
    builder.addCase(fetchAlgorithm.rejected, (state, action) => {
      // TODO - 403 should be available on action.error.code but is not. Did this workaround. Refactor when possible.
      if (action?.error?.message?.includes('status code 403')) {
        state.status = REDUX_STATUS.UNAUTHORIZED;
      } else {
        state.status = REDUX_STATUS.FAILED;
      }
      state.fullInfo = null;
    });
    builder.addCase(fetchReminderToAlgorithmStep.fulfilled, (state, action) => {
      const reminder = action.payload;

      if (state.path && reminder) {
        reminder.reminderElements.forEach((ele) => {
          if (state.path && ele.elementStepNumber !== undefined) {
            if (ele.parentStepNumber !== undefined) {
              const gateway = state.path[ele.parentStepNumber] as PathwayGateway;
              if (gateway?.subPathSelected && gateway.subPaths?.[String(gateway.subPathSelected)]) {
                gateway.subPaths[String(gateway.subPathSelected)].path[ele.elementStepNumber].reminder = reminder;
              }
            } else {
              state.path[ele.elementStepNumber].reminder = reminder;
            }
          }
        });
      }

      state.reminder = null;
      state.reminders = null;
    });
    builder.addCase(fetchDeleteReminderToAlgorithmStep.fulfilled, (state, action) => {
      const { index, parentIndex, subPathSelected } = action.payload;
      let reminder: Reminder | undefined;
      if (state.path && index !== undefined) {
        if (subPathSelected && parentIndex !== undefined) {
          const gateway = state.path[Number(parentIndex)] as PathwayGateway;
          if (gateway.subPaths?.[String(subPathSelected)]) {
            reminder = { ...gateway.subPaths[String(subPathSelected)].path[Number(index)].reminder };
          }
        } else {
          reminder = { ...state.path[Number(index)].reminder };
        }

        if (reminder) {
          reminder.reminderElements.forEach((ele) => {
            if (state.path && reminder) {
              if (ele.parentStepNumber !== null && ele.parentStepNumber !== undefined) {
                const gateway = state.path[ele.parentStepNumber] as PathwayGateway;
                if (
                  gateway?.subPathSelected &&
                  gateway.subPaths?.[String(gateway.subPathSelected)] &&
                  gateway.subPaths[String(gateway.subPathSelected)].path[ele.elementStepNumber].reminder.id === reminder.id
                ) {
                  gateway.subPaths[String(gateway.subPathSelected)].path[ele.elementStepNumber].reminder = null;
                }
              } else if (state.path[ele.elementStepNumber].reminder.id === reminder.id) {
                state.path[ele.elementStepNumber].reminder = null;
              }
            }
          });
        }
      }
    });
    builder.addCase(fetchCommunicationAnswers.pending, (state, { meta }) => {
      if (state.path) {
        const element = getElementByPosition(state.path, meta.arg.position);
        if (
          element &&
          (element.type === PATHWAY_ELEMENT_TYPES.ACTION ||
            element.type === COMMUNICATION_TYPES.ACTION_LINKED ||
            element.type === COMMUNICATION_TYPES.PRO_ACTIVE ||
            element.type === COMMUNICATION_TYPES.KEY_MOMENT) &&
          element.communicationSent
        ) {
          element.communicationSent.userResponsesState = REDUX_STATUS.LOADING;
        }
      }
    });
    builder.addCase(fetchCommunicationAnswers.fulfilled, (state, { meta, payload }) => {
      if (state.path) {
        const { userResponses, error } = payload;
        const element = getElementByPosition(state.path, meta.arg.position);

        if (
          element &&
          (element.type === PATHWAY_ELEMENT_TYPES.ACTION ||
            element.type === COMMUNICATION_TYPES.ACTION_LINKED ||
            element.type === COMMUNICATION_TYPES.PRO_ACTIVE ||
            element.type === COMMUNICATION_TYPES.KEY_MOMENT) &&
          element.communicationSent
        ) {
          if (error) {
            element.communicationSent.userResponsesState = REDUX_STATUS.FAILED;
          } else {
            element.communicationSent.userResponsesState = REDUX_STATUS.SUCCEEDED;
            element.communicationSent.userResponses = userResponses;
          }
        }
      }
    });
    builder.addCase(fetchCommunicationAnswers.rejected, (state, { meta }) => {
      if (state.path) {
        const element = getElementByPosition(state.path, meta.arg.position);
        if (
          element &&
          (element.type === PATHWAY_ELEMENT_TYPES.ACTION ||
            element.type === COMMUNICATION_TYPES.ACTION_LINKED ||
            element.type === COMMUNICATION_TYPES.PRO_ACTIVE ||
            element.type === COMMUNICATION_TYPES.KEY_MOMENT) &&
          element.communicationSent
        ) {
          element.communicationSent.userResponsesState = REDUX_STATUS.FAILED;
        }
      }
    });
    builder.addCase(putPathwayProgress.fulfilled, (state, action) => {
      const { data } = action.payload as {
        index: number;
        data?: { modifiedAlerts: { alertId: string; alertSolved: boolean; communicationSuspended: boolean }[] };
      };
      if (data?.modifiedAlerts && Array.isArray(data?.modifiedAlerts)) {
        data.modifiedAlerts.forEach((modified) => {
          const { alertId, alertSolved, communicationSuspended } = modified;
          if (state.session?.pathwayImpacts) {
            const solvedIndex = state.session.pathwayImpacts.findIndex((sessionImpact) => sessionImpact.id === alertId);

            if (solvedIndex >= 0) {
              const currentSessionImpact = state.session.pathwayImpacts[Number(solvedIndex)];

              const solvedSessionImpact = {
                ...currentSessionImpact,
                solved: alertSolved,
                resolutionMechanism: alertSolved ? RESOLUTION_MECHANISM.SOLVED_HCP_PROGRESS : currentSessionImpact.resolutionMechanism,
                criticalElementsCompleted: Array.isArray(currentSessionImpact.criticalElementsCompleted)
                  ? currentSessionImpact.criticalElementsCompleted
                  : [],
                solvedDate: alertSolved ? formatISO(new Date()) : '',
              };

              const totalToResolve = criticalElementsAmount(solvedSessionImpact);
              if ((currentSessionImpact.criticalElementsCompleted?.length ?? 0) < totalToResolve) {
                solvedSessionImpact.criticalElementsCompleted.push('');
              }

              if (alertSolved) {
                solvedSessionImpact.criticalElementsCompleted = Array(totalToResolve).fill('');
                const category = solvedSessionImpact.category === PathwayImpactCategory.critical ? 'criticals' : 'moderated';

                type ByType = 'criticals' | 'moderated';
                if (state.alertsMetadata.byType[category as ByType] > 0) {
                  const totalResolved = data.modifiedAlerts.length;
                  const minusResolved = state.alertsMetadata.byType[category as ByType] - totalResolved;
                  state.alertsMetadata.byType[category as ByType] = minusResolved < 0 ? 0 : minusResolved;
                }
              }

              state.session.pathwayImpacts[Number(solvedIndex)] = solvedSessionImpact;
              if (
                state.path?.[solvedSessionImpact.position.elementStepNumber] &&
                state.path?.[solvedSessionImpact.position.elementStepNumber].pathwayImpact?.id === solvedSessionImpact.id
              ) {
                const referencedCommunication = state.path[solvedSessionImpact.position.elementStepNumber] as PathwayCommunication;
                referencedCommunication.pathwayImpact = {
                  ...solvedSessionImpact,
                  isLast: referencedCommunication.pathwayImpact?.isLast ?? false,
                };

                if (communicationSuspended) {
                  referencedCommunication.communicationSent = {
                    ...((referencedCommunication.communicationSent ? referencedCommunication.communicationSent : {}) as Communication),
                    overviewState: COMMUNICATION_STATUS.SUSPENDED,
                  };

                  /*
                   * Remove blocked gateway (last one) if
                   * communication gets suspended when solving a loop by path
                   * It MUST end the path
                   */
                  state.path.pop();
                }
              }
            }
          }
        });
      }
    });
    builder.addCase(putPathwayProgress.rejected, (state) => {
      state.progressStatus = REDUX_STATUS.FAILED;
    });
    builder.addCase(getPathwayProgressToSetIds.fulfilled, (state, action) => {
      const { communications } = action.payload as PatientSessionDTO;
      if (state.session && communications) {
        state.session.communications = communications.map(CommunicationFactory.fromDTO);
      }
    });
  },
});

export const {
  toggleActionExpansion,
  setElementOpenedDetails,
  setCalculatorSelected,
  setQuestionAnswerSelected,
  setQuestionAnswerText,
  submitCalculatorSelectedScore,
  changePathwayPhase,
  setOpenDetails,
  clearSidePanel,
  openSidePanel,
  setSessionGender,
  setSessionAge,
  setSessionBirthdate,
  setElementsArraySkipped,
  resetSession,
  setPathStepToRemind,
  setPathStepToRemindNull,
  setReminders,
  setAlertsMetadata,
  hideAlertShortcut,
  updatePathPathwayImpact,
  setSessionCreated,
  restartAlgorithmStore,
  setProgressIdsToPath,
  setGatewaySubpathStartStepNumber,
  setPatientSessionProgressIds,
  updateReminderOnStep,
  setReferenceParentOptionCompleted,
  setCommunicationOpened,
  setGatewatInclusiveEditing,
  resetSessionDetails,
  unblockElement,
  startAddingReminders,
  cancelAddingReminders,
  addStepToRemind,
  removeStepToRemind,
  setCommunications,
  setPatientContacts,
  addCommunicationToSession,
  setCommunicationSuspended,
  setInboundChannelSuspended,
  setPatientData,
  setGatewayAnsweredByHilly,
  resolveRecurrentCommunicationAlert,
  slicePathAfterCommunicationResetProgress,
  openCommunicationAnswers,
  closeCommunicationAnswers,
  setSendCommunication,
  updateCommunicationIdFromSocket,
  setCommunicationCountdown,
  restartAlgorithmProgress,
  setPathwayElementStepLogs,
} = algorithmSlice.actions;

export default algorithmSlice.reducer;
