import { OnlineUser } from '@/models/Team';
import { CareTeam } from '@/src/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TeamInitialState } from './types';

export const initialState: TeamInitialState = {
  onlineUsers: [],
  careTeam: {
    name: null,
    practitioners: {},
  },
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    changeOnlineUsers: (state: TeamInitialState, action: PayloadAction<OnlineUser[]>) => {
      state.onlineUsers = action.payload;
    },
    addTeam: (state: TeamInitialState, action: PayloadAction<CareTeam>) => {
      state.careTeam = action.payload;
    },
    clearOnlineUsers: (state: TeamInitialState) => {
      state.onlineUsers = [];
    },
    clearTeamReducer: () => initialState,
    userLogout: () => initialState,
  },
});

export const { changeOnlineUsers, addTeam, clearTeamReducer, clearOnlineUsers } = teamSlice.actions;

export default teamSlice.reducer;
