import React, { ComponentProps, ReactElement, ReactNode } from 'react';
import { LinkProps } from 'next/link';
import { AnchorStyled } from '@/uphillhealth/components/Anchor/styles';
import { StitchesProps } from '@uphillhealth/theme';
import { LeftContent, RightContent } from '@/uphillhealth/components/commons';

interface AnchorProps extends Omit<LinkProps, 'as'>, ComponentProps<typeof AnchorStyled>, StitchesProps {
  children: ReactNode | ReactNode[];
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

export const Anchor = ({ children, leftContent, rightContent, ...props }: AnchorProps): ReactElement => {
  return (
    <AnchorStyled {...props} data-testid="Anchor" as="a">
      {leftContent && <LeftContent css={{ marginRight: '$3' }}>{leftContent}</LeftContent>}
      {children}
      {rightContent && <RightContent css={{ marginLeft: '$3' }}>{rightContent}</RightContent>}
    </AnchorStyled>
  );
};
