import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { translate } from '@uphillhealth/i18n';
import { Anchor } from '@/uphillhealth/components';
import { ServiceResponseError } from '@uphillhealth/react-service-provider';
import { AxiosError } from 'axios';
// app
import { useApplicationContext } from '@/src/providers';
// self
import { ContentContainer, InstitutionLogo } from './styles';
import { ErrorContainer, Label, LoggedMessage } from '../partials';

interface ServerErrorProps {
  error?: AxiosError<ServiceResponseError>;
}

export const ServerError = ({ error }: ServerErrorProps): ReactElement => {
  const { currentInstitution, currentUser } = useApplicationContext();
  const router = useRouter();

  const institutionImage = currentInstitution?.mainImageUrl || currentInstitution?.imageUrl || '/images/png/no-results.png';

  return (
    <ErrorContainer>
      <ContentContainer>
        <InstitutionLogo>
          <Image
            src={institutionImage}
            alt={currentInstitution?.name ?? 'Institution'}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </InstitutionLogo>

        {error && <Label>{error.response?.data.error ? error.response.data.error : error.message}</Label>}

        <Anchor css={{ marginTop: '$4' }} fit href={`/${router.query.workspace}`}>
          {translate('GoBack')}
        </Anchor>

        <LoggedMessage currentUser={currentUser} />
      </ContentContainer>
    </ErrorContainer>
  );
};
