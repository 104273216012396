/* eslint-disable react/no-children-prop */
import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { StitchesProps } from '@uphillhealth/theme';
// self
import { MarkdownStyled } from './styles';

interface MarkdownProps extends StitchesProps {
  value: string;
}

export const Markdown = ({ css, value }: MarkdownProps): ReactElement => {
  return (
    <MarkdownStyled css={css} data-testid="Markdown">
      <ReactMarkdown
        children={value.replaceAll(/(\n)(?!(-| -))/g, ' \n\n ')}
        remarkPlugins={[]}
        className="react-markdown"
        components={{
          blockquote({ children }) {
            return <u>{children}</u>;
          },
        }}
      />
    </MarkdownStyled>
  );
};
