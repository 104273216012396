import { COMMUNICATION_STATUS } from '@/constants/communication';
import Pathway from '@/models/Pathway';
import { PathwayCommunication } from '@/models/PathwayCommunication';
import { PatientSession } from '@/models/PatientSession';

import { PATHWAY_ELEMENT_TYPES } from '@/constants/pathway';
import { PathwayElement } from '@/models/PathwayElement';
import PathwayGateway from '../models/PathwayGateway';
import PathwayGatewayOption from '../models/PathwayGatewayOption';
import pathwayHelper from './pathwayHelper';

export const getNextElementsFromGateway = (
  prevPathway: PathwayElement[],
  gateway: PathwayGateway,
  options: PathwayGatewayOption[],
  pathway: Pathway,
  initialElementOfNextElements: string | null,
  builtPath: PathwayElement[],
  session?: PatientSession,
): PathwayElement[] => {
  let nextElementId;

  if (initialElementOfNextElements) {
    nextElementId = initialElementOfNextElements;
  } else {
    const optionsSelectedIds = options ? options.map((opt) => opt.id) : [];
    const findConditions = !gateway?.conditions
      ? []
      : gateway.conditions.filter((cond) => {
          return cond.selectedOptions.some((opt) => {
            const matchCount = opt.optionsIds.reduce((acc, opt2) => acc + (optionsSelectedIds.includes(opt2) ? 1 : 0), 0);
            return matchCount >= opt.minOptions;
          });
        });
    let findCondition;
    if (findConditions.length === 0) {
      return [gateway];
    } else if (findConditions.length === 1) {
      findCondition = findConditions[0];
    } else {
      findCondition = findConditions.reduce((prev, curr) => (prev.selectedOptions.length >= curr.selectedOptions.length ? prev : curr));
    }

    nextElementId = findCondition.nextElementId;
  }

  const nextPathwayElements = pathwayHelper.getNextPathwayElements(pathway, nextElementId, {
    builtPath,
    session,
    alertId: gateway.alertId,
    createFromLoop: gateway.createFromLoop,
    skipCriticals: gateway.skipCriticals,
  });

  nextPathwayElements
    .filter((element) => element.type === PATHWAY_ELEMENT_TYPES.GATEWAY && element.isFinal)
    .forEach((element) => {
      const finalGateway = element as PathwayGateway;
      try {
        const findGateway = prevPathway.find(
          (prevEl: PathwayElement) => prevEl.id === finalGateway.initialGatewayId && prevEl.type === PATHWAY_ELEMENT_TYPES.GATEWAY,
        ) as PathwayGateway;
        const initialGateway = gateway.id === findGateway.id ? gateway : findGateway;
        finalGateway.isCompleted = initialGateway.options
          ? initialGateway.options.reduce((acc: boolean, option) => acc && Boolean(option.completed), true)
          : false;
      } catch (e) {
        console.warn('That final gateway (' + finalGateway.initialGatewayId + ') doesn`t have an initial gateway!');
      }
    });

  let nextElements = [gateway, ...nextPathwayElements];

  //TODO: Improve this — added to solve a quick problem with pathway progression after PARALLEL gateways
  const lastItem = nextElements[nextElements.length - 1];
  if (lastItem.type === PATHWAY_ELEMENT_TYPES.GATEWAY && lastItem.isFinal && lastItem.isCompleted) {
    nextElements = nextElements.concat(pathwayHelper.getNextPathwayElements(pathway, lastItem.nextElementId, { builtPath, session }));
  }

  const lastElementBuiltPath = builtPath.slice(-1)[0];
  if (
    lastElementBuiltPath &&
    lastElementBuiltPath.type === PATHWAY_ELEMENT_TYPES.GATEWAY &&
    lastElementBuiltPath.isBlocked &&
    lastElementBuiltPath.blockedBy?.index !== undefined
  ) {
    const commsElement = builtPath[lastElementBuiltPath.blockedBy.index] as PathwayCommunication;

    if (
      commsElement?.pathwayImpact &&
      commsElement?.communicationSent?.overviewState &&
      ![COMMUNICATION_STATUS.FINISHED, COMMUNICATION_STATUS.SUSPENDED].includes(
        String(commsElement?.communicationSent?.overviewState) as COMMUNICATION_STATUS,
      )
    ) {
      nextElements.push(lastElementBuiltPath);
    }
  }

  return nextElements;
};

export const updateRulesGateway = (prevPathway: PathwayElement[], index: number, optionSelected: PathwayGatewayOption) => {
  const updateGroup = (g: any) => {
    let options = [...g.options];
    if (!g.id || g.id === optionSelected.groupId) {
      options = g.options.map((opt: PathwayGatewayOption) => (opt.id === optionSelected.id ? { ...opt, selected: !opt.selected } : opt));
    }
    return { ...g, options };
  };

  let gateway = prevPathway[index] as PathwayGateway;
  if (gateway && !gateway.isLocked) {
    gateway = {
      ...gateway,
      groups: gateway.groups.map(updateGroup),
    };
    return Object.assign([], prevPathway, { [index]: gateway });
  } else {
    return prevPathway;
  }
};

export default {
  getNextElementsFromGateway,
  updateRulesGateway,
};
