/* eslint-disable no-unused-vars */
export const WORKSPACE_PERMISSIONS_KEYS = [
  'articles',
  'calculators',
  'courses',
  'events',
  'pathways',
  'resources',
  'collections',
  'pathwayPatientSessions',
];

export const LOCAL_KEY_WORKSPACE = 'active-workspace';

export enum PERMISSION {
  PATHWAY = 'pathways',
  CALCULATOR = 'calculators',
  EVENT = 'events',
  COURSE = 'courses',
  RESOURCE = 'resources',
  ARTICLE = 'articles',
  COLLECTIONS = 'collections',
  SESSIONS = 'pathwayPatientSessions',
}

export const CARE_WORKSPACES = ['100', '101'];
