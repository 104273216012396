import { styled } from '@uphillhealth/theme';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export const ToggleRootStyle = styled(ToggleGroup.Root, {
  display: 'inline-flex',
});

export const ToggleItemStyle = styled(ToggleGroup.Item, {
  padding: '$4 $4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$neutral700',
  fontSize: '$small',
  lineHeight: '$fontSizes$base',
  backgroundColor: '$neutral100',
  border: '1px solid $neutral300',
  outline: 'none',
  '& + &': {
    borderLeftColor: 'transparent',
  },
  '&:hover, &:focus': {
    backgroundColor: '$neutral200',
  },
  '&:disabled': {
    backgroundColor: '$neutral200',
    cursor: 'not-allowed',
    '&:hover, &:focus': {
      backgroundColor: '$neutral200',
    },
    '&[data-state="on"]': {
      color: '$primary400',
      backgroundColor: '$primary50',
      borderColor: '$primary300',
    },
  },
  '&[data-state="on"]': {
    color: '$primary500',
    backgroundColor: '$primary100',
    borderColor: '$primary500',
  },
  '&:first-child': {
    borderTopLeftRadius: '$medium',
    borderBottomLeftRadius: '$medium',
  },
  '&:last-child': {
    borderTopRightRadius: '$medium',
    borderBottomRightRadius: '$medium',
  },
});
