export interface JourneyFile {
  fileKey: string;
  createdDate: string;
  id: string;
  patientSessionId: string;
  position: {
    elementId: string;
    elementStepNumber: number;
    parentStepNumber: null;
    fistElementOfSubPath: null;
    firstElementOfSubPath: null;
  };
  type: JourneyFileTypes;
}

export enum JourneyFileTypes {
  EXAM_REQUEST = 'EXAM_REQUEST',
  FILE = 'FILE',
}
