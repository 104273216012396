import { styled } from '@uphillhealth/theme';

export const ScrollAreaStyled = styled('div', {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
  overscrollBehavior: 'contain',
  variants: {
    orientation: {
      horizontal: {
        overflow: 'auto hidden',
      },
      vertical: { overflow: 'hidden auto' },
    },
  },
});
