import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { CombinedType } from '..';
import { ReminderHelper } from '../../models/Reminder';
import { RemindersAPI } from '../../services/api/reminders';
import { updateReminderOnStep } from '../algorithm/slice';
import { setDate } from './slice';
import { FetchRemindersProps } from './types';

export const fetchReminders = createAsyncThunk(
  'reminders/fetchReminders',
  async ({ institutionId, fromDate, toDate, completed = false }: FetchRemindersProps, { dispatch, getState }) => {
    const state = getState() as CombinedType;
    const fixedInstitutionId = institutionId ? institutionId : state.activeInstitution?.data?.id ?? -1;

    const reminders = await RemindersAPI.getReminders({
      institutionId: fixedInstitutionId,
      fromDate: fromDate.toString(),
      toDate: toDate.toString(),
      completed,
    });

    const data = reminders?.data.map((element) => ReminderHelper.fromDto(element));
    dispatch(setDate({ date: fromDate.toString() }));
    return { data };
  },
);

export const updateReminder = createAsyncThunk(
  'reminders/updateReminder',
  async (data: { description: string; date: Date }, { getState, dispatch }) => {
    const state = getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;
    const { selectedReminderToEdit, selectedReminderStepIndex, selectedReminderStepParentIndex, selectedReminderStepSubpathIndex } =
      state.reminders;

    if (selectedReminderToEdit) {
      const { description, date } = data;
      const { id, completed } = selectedReminderToEdit;

      await RemindersAPI.putReminder({
        completed: completed ?? false,
        date: moment(date).format('YYYY-MM-DD'),
        description,
        id,
        institutionId,
      });

      dispatch(
        updateReminderOnStep({
          index: selectedReminderStepIndex,
          parentIndex: selectedReminderStepParentIndex,
          subPathSelected: selectedReminderStepSubpathIndex,
          description: data.description,
          date: data.date,
        }),
      );
    }
    return data;
  },
);

export const setReminderCompleted = createAsyncThunk(
  'reminders/setReminderCompleted',
  async ({ id }: { id: string | number }, { getState }) => {
    const state = getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;

    await RemindersAPI.putReminderCompleted(institutionId, id);
    return { id };
  },
);
