import { styled } from '@uphillhealth/theme';
import * as DropdownPrimitives from '@radix-ui/react-dropdown-menu';

export const TriggerIcon = styled('span', {
  marginLeft: '$2',
  color: '$neutral500',
  fontSize: '$base',
  '> i': {
    transition: 'transform 175ms',
    lineHeight: 0,
  },
});

export const DropdownTriggerStyled = styled(DropdownPrimitives.Trigger, {
  padding: '$3 $2 $3 $3',
  display: 'inline-flex',
  alignItems: 'center',
  color: '$neutral800',
  border: '1px solid $neutral300',
  borderRadius: '$small',
  '&[data-state="open"]': {
    [`> ${TriggerIcon} > i`]: {
      transform: 'rotate(180deg)',
    },
  },
});
