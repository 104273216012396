import { MouseEventHandler, useCallback } from 'react';

type UseClipboardCallback = <Element>(args: {
  onClipboardError?: () => void;
  onClipboardWrite?: () => void;
  toCopy: string;
}) => MouseEventHandler<Element>;

export const useClipboardCallback: UseClipboardCallback = ({ onClipboardError, onClipboardWrite, toCopy }) => {
  return useCallback(() => {
    navigator.clipboard
      .writeText(toCopy)
      .catch(() => {
        onClipboardError && onClipboardError();
      })
      .then(() => {
        onClipboardWrite && onClipboardWrite();
      });
  }, [onClipboardError, onClipboardWrite, toCopy]);
};
