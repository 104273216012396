import { Gender } from '@/models/Common';

type ComputeAvatarFromAgeGender = (args: { age?: number; gender?: Gender }) => string;

export const computeAvatarFromAgeGender: ComputeAvatarFromAgeGender = ({ age, gender }) => {
  const avatarPrefix = '/icons/avatars/';
  let avatarImage = '';

  if (gender && age) {
    switch (gender) {
      case Gender.MALE: {
        if (age < 5) avatarImage = 'men-0-4.svg';
        else if (age < 11) avatarImage = 'men-5-10.svg';
        else if (age < 26) avatarImage = 'men-11-25.svg';
        else if (age < 56) avatarImage = 'men-26-55.svg';
        else if (age < 66) avatarImage = 'men-56-65.svg';
        else avatarImage = 'men-66-99.svg';
        break;
      }
      case Gender.FEMALE: {
        if (age < 5) avatarImage = 'woman-0-4.svg';
        else if (age < 11) avatarImage = 'woman-5-10.svg';
        else if (age < 26) avatarImage = 'woman-11-25.svg';
        else if (age < 56) avatarImage = 'woman-26-55.svg';
        else if (age < 66) avatarImage = 'woman-56-65.svg';
        else avatarImage = 'woman-66-99.svg';
        break;
      }
    }
  }

  return avatarImage ? avatarPrefix + avatarImage : '';
};
