import React, { ReactElement, useCallback } from 'react';
import { Dropdown, DropdownContent, DropdownItem, DropdownTrigger } from '@/uphillhealth/components';
import { translate } from '@uphillhealth/i18n';
import { FlexContainer } from '@/src/theme/commons';

interface LanguageSelectorProps {
  currentLanguage: string;
  languages: string[];
  setLanguage?: (lang: string) => void;
}

export const LanguageSelector = ({ currentLanguage = '', languages, setLanguage }: LanguageSelectorProps): ReactElement => {
  const setLanguageHandler = useCallback(
    (language: string) => () => {
      setLanguage && setLanguage(language);
    },
    [setLanguage],
  );

  return (
    <Dropdown>
      <DropdownTrigger css={{ padding: '$2 $2 $2 $3' }}>
        <FlexContainer>{translate(currentLanguage.slice(0, 2))}</FlexContainer>
      </DropdownTrigger>
      <DropdownContent align="start">
        {languages.map((language) => {
          return (
            <DropdownItem key={language} onClick={setLanguageHandler(language)}>
              {translate(language.slice(0, 2))}
            </DropdownItem>
          );
        })}
      </DropdownContent>
    </Dropdown>
  );
};
