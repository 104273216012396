import { ReactNode } from 'react';
import * as TooltipPrimitives from '@radix-ui/react-tooltip';

export enum TooltipAlign {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum TooltipSide {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export interface TooltipProps
  extends Pick<TooltipPrimitives.TooltipProps, 'defaultOpen' | 'onOpenChange' | 'open'>,
    Pick<TooltipPrimitives.TooltipContentProps, 'collisionBoundary' | 'collisionPadding' | 'onEscapeKeyDown' | 'onPointerDownOutside'> {
  align?: TooltipAlign;
  children: ReactNode | ReactNode[];
  content: ReactNode | ReactNode[];
  defaultOpen?: boolean;
  maxWidth?: number;
  side?: TooltipSide;
  triggerAsChild?: boolean;
  margin?: string;
}
