import { Service } from '@uphillhealth/react-service-provider';
// self
import {
  UseGetInteroperabilityContextParams,
  UseGetInteroperabilityContextResponse,
  UseGetInteroperabilityPreParams,
  UseGetInteroperabilityPreResponse,
  UseGetPatientProfileResponse,
  UseGetProfileUsersByIdsParams,
} from './types';
import { Practitioner } from '@/src/models';

export class PatientPreSessionService extends Service {
  useGetPatientProfile(initialData?: UseGetPatientProfileResponse) {
    return this.useQuery<UseGetPatientProfileResponse>(
      {
        method: 'GET',
        url: '/institutions/[institutionId]/patients/[patientId]/profile',
      },
      { enabled: !initialData, initialData },
    );
  }

  useGetPatientProfileShort(enabled: boolean, params?: { currentPatientSessionId: string }) {
    return this.useQuery<UseGetPatientProfileResponse>(
      {
        method: 'GET',
        params,
        url: '/institutions/[institutionId]/patients/[patientId]/profile/short',
      },
      {
        enabled,
      },
    );
  }

  useGetInteroperabilityContext(params: UseGetInteroperabilityContextParams, enableRequest: boolean) {
    const headers = { ...(params.t ? { Authorization: null } : {}) };

    return this.useQuery<UseGetInteroperabilityContextResponse>(
      { headers, method: 'GET', params, url: '/interop/[institutionId]/ctx/v2' },
      {
        enabled: Boolean(enableRequest && this.getProp('institutionId')),
      },
    );
  }

  useGetInteroperabilityPre(params: UseGetInteroperabilityPreParams) {
    return this.useQuery<UseGetInteroperabilityPreResponse>({
      method: 'GET',
      params,
      url: `/interop/[institutionId]/pre`,
    });
  }

  useGetProfileUsersByIds() {
    return this.useMutation<Practitioner[], UseGetProfileUsersByIdsParams>({
      method: 'GET',
      url: '/institutions/[institutionId]/profile/users',
    });
  }
}
