export enum PATHWAY_ELEMENT_TYPES {
  GATEWAY = 'GATEWAY',
  EVENT = 'EVENT',
  ACTION = 'ACTION',
  CRITERIAS = 'CRITERIAS',
  CALCULATOR_REFERENCE = 'CALCULATOR_REFERENCE',
  PATHWAY_REFERENCE = 'PATHWAY_REFERENCE',
  INBOUND = 'INBOUND',
}

export enum PATHWAY_GATEWAY_TYPES {
  EXCLUSIVE = 'EXCLUSIVE',
  PARALLEL = 'PARALLEL',
  INCLUSIVE = 'INCLUSIVE',
}
export enum PATHWAY_EVENT_TYPES {
  INITIAL = 'INITIAL',
  INTERMEDIATE = 'INTERMEDIATE',
  FINAL = 'FINAL',
}

export const PATHWAY_ELEMENTS_TRANSITION = 'width 200ms, border 300ms';
