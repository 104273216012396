import { JourneyStatus } from '@/src/models';
import { styled } from '@/src/theme';

export const BadgePhaseStyled = styled('div', {
  padding: '$1 $2',
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$neutral100',
  fontSize: '$extraSmall',
  fontWeight: '700',
  lineHeight: '$fontSizes$small',
  textAlign: 'center',
  textTransform: 'uppercase',
  backgroundColor: '$neutral600',
  borderRadius: '$medium',
  variants: {
    status: {
      [JourneyStatus.revoked]: {
        backgroundColor: '$neutral600 !important',
      },
      [JourneyStatus.completed]: {
        color: '#455373',
        backgroundColor: '$neutral300 !important',
      },
    },
  },
});
