import { styled } from '@uphillhealth/theme';

export const PageSelectorStyled = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
});

export const PageButton = styled('button', {
  color: '$neutral600',
  fontSize: '$base',
  border: 'none',
  variants: { active: { true: { color: '$neutral900' } } },
});

export const PageSeparator = styled('div', {
  alignSelf: 'flex-end',
  color: '$neutral600',
  fontSize: '$base',
});
