import { styled } from '@uphillhealth/theme';
import * as SelectPrimitives from '@radix-ui/react-select';

export const SelectItemStyled = styled(SelectPrimitives.Item, {
  position: 'relative',
  padding: '$3 $4',
  color: '$neutral900',
  fontSize: '$small',
  cursor: 'pointer',
  outline: 'none',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: '$neutral200',
  },
  '&[data-state="checked"]': {
    color: '$primary500',
    backgroundColor: '$primary100',
  },
  '&[data-disabled]': {
    color: '$neutral600',
    pointerEvents: 'none',
  },
  '&[data-highlighted]': {
    backgroundColor: '$neutral200',
  },
  variants: {
    critical: {
      true: {
        color: '$error500',
      },
    },
  },
});
