export enum COMMUNICATION_CHANNEL_TYPES {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum COMMUNICATION_STATUS {
  SCHEDULED = 'SCHEDULED',
  WAITING_RESPONSE = 'WAITING_RESPONSE',
  WAITING_TRIGGER = 'WAITING_TRIGGER',
  BLOCKED = 'BLOCKED',
  FINISHED = 'FINISHED',
  SUSPENDED = 'SUSPENDED',
  ERROR = 'ERROR',
}

export enum INTERACTION_STATUS {
  ACTIVE = 'ACTIVE',
  MISSING = 'MISSING',
  ANSWERED = 'ANSWERED',
}

export enum COMMUNICATION_TYPES {
  PRO_ACTIVE = 'PROACTIVE',
  KEY_MOMENT = 'KEY_MOMENT',
  ACTION_LINKED = 'ACTION_LINKED',
  INBOUND = 'INBOUND',
}

export enum COMMUNICATION_SUBTYPES {
  ANTICIPATION = 'ANTICIPATION',
  EDUCATIONAL = 'EDUCATIONAL',
  FOLLOW_UP = 'FOLLOW_UP',
}

export enum USER_RESPONSES_CHANNEL {
  WEB_FORM = 'WEB_FORM',
  VOICE = 'VOICE',
}

export enum INBOUND_CHANNEL_TYPE {
  ACTIVE = 'ACTIVE',
  TRIGGERED = 'TRIGERRED',
  DONE = 'DONE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
}

export const LANGUAGE_LIST = [
  {
    prefix: '🇬🇧',
    label: 'English',
    value: 'en',
  },
  {
    prefix: '󠁳󠁣󠁴󠁿',
    label: 'Catalan',
    value: 'ca',
  },
  {
    prefix: '🇪🇸',
    label: 'Español',
    value: 'es',
  },
  {
    prefix: '🇩🇪',
    label: 'Deutsch',
    value: 'de',
  },
  {
    prefix: '🇫🇷',
    label: 'Français',
    value: 'fr',
  },
  {
    prefix: '🇵🇹',
    label: 'Português',
    value: 'pt',
  },
];
