import { styled } from '@uphillhealth/theme';

const tableCellsBase = {
  padding: '$5 $4',
  color: '$neutral900',
  fontSize: '$small',
  textAlign: 'left',
  borderBottom: '1px solid $neutral300',
};

export const TableCell = styled('td', { ...tableCellsBase });

export const TableHeadCell = styled('th', {
  ...tableCellsBase,
  paddingTop: '$4',
  paddingBottom: '$4',
  color: '$primary900',
});

export const TableRow = styled('tr', {
  backgroundColor: '$neutral100',
  '&:hover': {
    backgroundColor: '$primary50',
  },
  [`& > ${TableHeadCell}`]: {
    '&:first-child': {
      borderTopLeftRadius: '$large',
    },
    '&:last-child': {
      borderTopRightRadius: '$large',
    },
  },
  '&:last-child': {
    [`& > ${TableCell}`]: {
      borderBottom: 'none',
      '&:first-child': {
        borderBottomLeftRadius: '$large',
      },
      '&:last-child': {
        borderBottomRightRadius: '$large',
      },
    },
  },
});

export const TableHead = styled('thead', {
  borderCollapse: 'separate',
  verticalAlign: 'middle',
  [`> ${TableRow}`]: {
    '&:hover': {
      backgroundColor: '$neutral100',
    },
  },
});

export const TableFoot = styled('tfoot', {
  borderCollapse: 'separate',
  verticalAlign: 'middle',
});

export const TableBody = styled('tbody', {
  borderCollapse: 'separate',
  verticalAlign: 'middle',
});

export const Table = styled('table', {
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
});

export const TableResponsive = styled('div', {
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  overflowX: 'auto',
});
