export * from './Dialog';
export {
  ButtonDialogClose,
  DialogButtonClose,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogHeaderDescription,
  DialogHeaderTitle,
} from './styles';
