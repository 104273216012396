import { styled } from '@uphillhealth/theme';

export const PractitionersContainer = styled('div', {
  paddingLeft: '$5',
  display: 'flex',
  alignItems: 'center',
});

export const AvatarRim = styled('div', {
  marginLeft: `-$space$3`,
  border: '2px solid $neutral100',
  borderRadius: '$full',
});
