import { Action } from '../../helpers/store';
import { createSlice } from '@reduxjs/toolkit';
import { SearchSliceType } from './types';

export const initialState: SearchSliceType = {
  history: [],
  isOpen: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    addSearchHistory: (state: SearchSliceType, action: Action<string>) => {
      state.history.unshift(action.payload);
    },
    setOpen: (state: SearchSliceType, action: Action<boolean>) => {
      state.isOpen = action.payload;
    },
    userLogout: () => initialState,
  },
});

export const { addSearchHistory, setOpen } = searchSlice.actions;

export default searchSlice.reducer;
