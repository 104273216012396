import { keyframes, styled } from '@uphillhealth/theme';

const loader_2_1 = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const loader_2_2 = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const loader_2_3 = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const OuterBox = styled('div', {
  display: 'block',
  height: '$space$8',
  width: '$space$8',
  animation: `${loader_2_1} 3s linear infinite`,
  position: 'relative',

  '& span': {
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    margin: 'auto',
    height: '$space$8',
    width: '$space$8',
    clip: 'rect(16px, 32px, 32px, 0)',
    animation: `${loader_2_2} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite`,
    '&::before': {
      content: '',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      margin: 'auto',
      height: '$8',
      width: '$8',
      border: '3px solid $colors$primary300',
      borderTop: '3px solid $colors$primary500',
      borderRadius: '50%',
      animation: `${loader_2_3} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite`,
    },
    '&::after': {
      content: '',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      margin: 'auto',
      height: '$space$8',
      width: '$space$8',
      border: '3px solid transparent',
      borderRadius: '50%',
    },
  },
});
