import { COMMUNICATION_STATUS, INTERACTION_STATUS } from '@/constants/communication';
import { Gender } from '../models/Common';
import { CategorySession, PatientSessionAPIContent } from '../models/PatientSession';
import { patientSessionHelper } from '../services/patientSessionHelper';
import { JourneyObservations } from '@/src/models';

export interface SessionToTableData {
  id: number | string;
  name: string;
  pathway: string;
  pathwayId: string;
  phase: string;
  date: string;
  category: CategorySession;
  categoryId: string | null;
  gender: Gender;
  age?: string | number;
  patientId: string | number;
  moderateAlerts: number;
  criticalAlerts: number;
  communicationStatus: COMMUNICATION_STATUS;
  interactionStatus: INTERACTION_STATUS;
  patientNNU?: string;
  lastExamResultDate: string | null;
  observations: JourneyObservations[];
}

export const getSessionTypeToTableData = (sessions: PatientSessionAPIContent[]): SessionToTableData[] =>
  sessions?.map((session) => ({
    age: session.patient?.birthDate ? patientSessionHelper.calcAge(session.patient.birthDate) : '',
    category: session.category,
    categoryId: session.categoryId,
    communicationStatus: session.communicationStatus,
    interactionStatus: session.interactionStatus,
    criticalAlerts: session.criticalAlerts,
    date: session.lastModifiedDate,
    id: session.id,
    gender: getCorrectGenderByString(session.patient?.gender),
    lastUserResponseDate: session.lastUserResponseDate,
    moderateAlerts: session.moderateAlerts,
    name: session.patient?.name?.[0]?.text,
    observations: session.observations,
    patientId: session.patient?.id,
    pathway: `${session.pathwayTitle}${
      session.additionalInformation && Object.values(session.additionalInformation).length
        ? ` - ${Object.values(session.additionalInformation)
            .map((element) => element.dciptName)
            .join(', ')}`
        : ''
    }`,
    pathwayId: session.pathwayId,
    phase: session.phaseDescription,
    phaseId: session.phaseId,
    userResponsible: session.userResponsible,
    hasMeasurements: session.hasMeasurements,
    patientNNU: session.patient.identifier?.find((ele) => ele.system === 'urn:uh-ident-patient')?.value,
    lastExamResultDate: session.lastExamResultDate,
  }));

export const getCategoryColorByCategory = (category: CategorySession): string => {
  const colorCategories = {
    [CategorySession.RED]: '#DE7469',
    [CategorySession.ORANGE]: '#E8935F',
    [CategorySession.YELLOW]: '#EDC081',
    [CategorySession.GREEN]: '#68CFD2',
    [CategorySession.BLUE]: '#7F8DDF',
  };

  return colorCategories[category] ?? '#F5F6F9';
};

export const getCorrectGenderByString = (gender: string): Gender => {
  const genders: Record<string, Gender> = {
    male: Gender.MALE,
    female: Gender.FEMALE,
  };

  return genders[gender] ?? Gender.UNKNOWN;
};
