import React, { ReactElement, useCallback, useMemo } from 'react';
// self
import { PaginationProps } from '../../types';
import { PageButton, PageSelectorStyled, PageSeparator } from './styles';

enum PaginateType {
  page = 1,
  separator = 2,
}

export const PageSelector = ({ css, currentPage, setCurrentPage, totalPages }: PaginationProps): ReactElement => {
  const pagination = useMemo(() => {
    // NOTE: currentPage starts at 0 and totalPages acts like an Array.length
    const zeroedTotalPages = totalPages - 1;
    const paginate: { page: number; type: PaginateType }[] = [{ page: 0, type: PaginateType.page }];

    if (currentPage === 0 && totalPages === 1) return paginate;

    const totalItems = 3;
    if (currentPage > totalItems) {
      paginate.push({ page: 0, type: PaginateType.separator });
    }

    const currentMinusTotal = currentPage - totalItems;
    const currentPlusTotal = currentPage + totalItems;

    for (
      let iterationPage = currentMinusTotal > 1 ? currentMinusTotal : 1;
      iterationPage <= Math.min(zeroedTotalPages, currentPlusTotal);
      iterationPage++
    ) {
      paginate.push({ page: iterationPage, type: PaginateType.page });
    }

    if (currentPlusTotal < zeroedTotalPages && currentPlusTotal + 1 !== zeroedTotalPages) {
      paginate.push({ page: 0, type: PaginateType.separator });
    }

    if (currentPlusTotal < zeroedTotalPages) {
      paginate.push({ page: zeroedTotalPages, type: PaginateType.page });
    }

    return paginate;
  }, [currentPage, totalPages]);

  const onClickFactory = useCallback((isCurrent: boolean, pageNumber: number): undefined | (() => void) => {
    return isCurrent ? undefined : () => setCurrentPage(pageNumber);
  }, []);

  return (
    <PageSelectorStyled css={css}>
      {pagination.map(({ page, type }) => {
        const isCurrent = page === currentPage;

        return type === PaginateType.page ? (
          <PageButton key={`${type}-${page}`} onClick={onClickFactory(isCurrent, page)} active={isCurrent}>
            {page + 1}
          </PageButton>
        ) : (
          <PageSeparator key={`${type}-${page}`}>...</PageSeparator>
        );
      })}
    </PageSelectorStyled>
  );
};
