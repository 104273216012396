import { styled, keyframes } from '@uphillhealth/theme';

export const ContainerStyled = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'inherit',
  backdropFilter: 'blur(2px)',
  zIndex: 5,
});

const backgroundAnimation = keyframes({
  '0%': { backgroundPosition: '100% 0%' },
  '100%': { backgroundPosition: '0% 0%' },
});

export const Background = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  animation: `${backgroundAnimation} 1s linear infinite`,
  backgroundImage: 'linear-gradient(90deg, $primary500, $primary800, $primary100, $primary500, $primary800)',
  backgroundSize: '400% 100%',
  borderRadius: 'inherit',
  opacity: '0.4',
});

export const SvgContainer = styled('div', {
  width: 40,
  '> svg': {
    transform: 'translateY(2px)',
  },
});
