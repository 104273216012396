import moment from 'moment';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { REDUX_STATUS } from '../../constants';
import { Reminder } from '../../models/Reminder';
import { RemindersState } from './types';
import { fetchReminders, setReminderCompleted, updateReminder } from './api';

const initialState: RemindersState = {
  status: REDUX_STATUS.IDLE,
  searchDate: null,
  list: [],
  selectedReminderToEdit: null,
  selectedReminderStatus: REDUX_STATUS.IDLE,
};

export const remindersSlice = createSlice({
  name: 'remindersSlice',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<{ date: string }>) => {
      const { date } = action.payload;
      state.searchDate = date;
    },
    setSelectedReminderToEdit: (
      state,
      action: PayloadAction<{ reminder?: Reminder; index?: number; parentIndex?: number; subPathSelected?: string }>,
    ) => {
      const { reminder, index, parentIndex, subPathSelected } = action.payload;
      state.selectedReminderToEdit = reminder ?? null;
      state.selectedReminderStepIndex = index;
      state.selectedReminderStepParentIndex = parentIndex;
      state.selectedReminderStepSubpathIndex = subPathSelected;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReminders.pending, (state) => {
      state.status = REDUX_STATUS.LOADING;
    });
    builder.addCase(fetchReminders.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.status = REDUX_STATUS.SUCCEEDED;
      state.list = data ?? [];
    });
    builder.addCase(fetchReminders.rejected, (state, action) => {
      // TODO - 403 should be available on action.error.code but is not. Did this workaround. Refactor when possible.
      if (action?.error?.message?.includes('status code 403')) {
        state.status = REDUX_STATUS.UNAUTHORIZED;
      } else {
        state.status = REDUX_STATUS.FAILED;
      }
    });
    builder.addCase(setReminderCompleted.pending, (state, action) => {
      const { id } = action.meta.arg;
      const element = state.list.find((ele) => ele.id === id);
      if (element) element.completedStatus = REDUX_STATUS.LOADING;
    });
    builder.addCase(setReminderCompleted.fulfilled, (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((ele) => ele.id === id);
      state.list.splice(index, 1);
    });
    builder.addCase(updateReminder.pending, (state) => {
      state.selectedReminderStatus = REDUX_STATUS.LOADING;
    });
    builder.addCase(updateReminder.rejected, (state) => {
      state.selectedReminderStatus = REDUX_STATUS.FAILED;
    });
    builder.addCase(updateReminder.fulfilled, (state, action) => {
      const { list, selectedReminderToEdit, searchDate } = state;
      if (selectedReminderToEdit && searchDate) {
        const { id } = selectedReminderToEdit;
        const elementIndex = state.list.findIndex((ele) => ele.id === id);
        if (elementIndex !== undefined) {
          const { description, date } = action.payload;
          const element = list[elementIndex];
          if (moment(date).format('YYYY-MM-DD') !== moment(searchDate).format('YYYY-MM-DD')) {
            list.splice(elementIndex, 1);
          } else {
            element.description = description;
            element.date = date;
          }
        }
      }
      state.selectedReminderToEdit = null;
      state.selectedReminderStatus = REDUX_STATUS.SUCCEEDED;
    });
  },
});

export const { setSelectedReminderToEdit, setDate } = remindersSlice.actions;

export default remindersSlice.reducer;
