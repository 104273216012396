export type InstitutionId = number;

export type JourneyId = string;
export type PathwayId = string;

export type ElementUniqueId = string;

export const HillyUserId = 3;

export const PersistentDoctorsDeskPersistentFilters = 'persist.doctorsDesk.state';
export const SessionPersistentJourneyDirectoryState = 'persist.journeyDirectory.state';
export const PersitentSessionTabStorageKey = 'persistent.session.tab';

export const PersistentLocalStorageHillyPopup = 'HiHillyPopupData';

export const PersistentSessionProfileResponse = 'persistent.profile.response';
