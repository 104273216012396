import { AxiosResponse, CancelTokenSource } from 'axios';

import { authRequestOptions } from '../../helpers/api';
import { deleteRequest, getRequest, postRequest } from './index';

export const getUserBookmarks = async (institutionId: number, cancelToken?: CancelTokenSource): Promise<AxiosResponse | undefined> =>
  getRequest('bookmarks?institutionId=' + institutionId, cancelToken);

export const addUserBookmark = async (
  resourceId: number | string,
  resourceType: string,
  name: string,
  institutionId: number,
  cancelToken?: CancelTokenSource,
): Promise<AxiosResponse | undefined> => {
  const body = {
    resourceId,
    resourceType,
    originalName: name,
  };
  return postRequest('bookmarks', { ...body, institutionId }, authRequestOptions(cancelToken));
};

export const deleteUserBookmark = async (
  bookmarkId: number | string,
  institutionId: number,
  cancelToken?: CancelTokenSource,
): Promise<AxiosResponse | undefined> =>
  deleteRequest('bookmarks/' + bookmarkId + '?institutionId=' + institutionId, authRequestOptions(cancelToken));
