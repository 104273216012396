import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { authRequestOptions } from '@/helpers/api';

// must be any in this case because we cannot infer each type value of an object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectToQueryParam = (queryParamsObj: Record<string, any>): string => {
  const searchParams = new URLSearchParams();
  for (const entry of Object.entries(queryParamsObj)) {
    const [param, value] = entry;
    if (value) {
      searchParams.append(param, String(value));
    }
  }
  return searchParams.toString();
};

export const getRequest = async <T = any>(
  endpoint: string,
  cancelToken?: CancelTokenSource,
  ctx?: NextPageContext | GetServerSidePropsContext,
  headers?: { [key: string]: string },
): Promise<AxiosResponse<T> | undefined> => {
  try {
    return await axios.get(process.env.NEXT_PUBLIC_TEST_API + '/' + endpoint, {
      baseURL: '',
      ...authRequestOptions(cancelToken, ctx),
      headers: { ...authRequestOptions(cancelToken, ctx).headers, ...headers },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postRequest = async <T = any>(
  endpoint: string,
  body: Record<string, unknown>,
  cancelToken?: CancelTokenSource,
  token?: string,
  headers?: { [key: string]: string },
): Promise<AxiosResponse<T> | undefined> => {
  try {
    return await axios.post(process.env.NEXT_PUBLIC_TEST_API + '/' + endpoint, body, {
      baseURL: '',
      ...authRequestOptions(cancelToken, undefined, token),
      headers: { ...authRequestOptions(cancelToken, undefined, token).headers, ...headers },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putRequest = async <Response = any, Payload = Record<string, unknown>>(
  endpoint: string,
  body: Payload,
  cancelToken?: CancelTokenSource,
  base?: string,
  headers?: { [key: string]: string },
): Promise<AxiosResponse<Response> | undefined> => {
  try {
    return await axios.put((base ?? process.env.NEXT_PUBLIC_TEST_API) + '/' + endpoint, body, {
      baseURL: '',
      ...authRequestOptions(cancelToken),
      headers: { ...authRequestOptions(cancelToken).headers, ...headers },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const patchRequest = async <T = any>(
  endpoint: string,
  body?: Record<string, unknown>,
  cancelToken?: CancelTokenSource,
): Promise<AxiosResponse<T> | undefined> => {
  try {
    return await axios.patch(process.env.NEXT_PUBLIC_TEST_API + '/' + endpoint, body, { baseURL: '', ...authRequestOptions(cancelToken) });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteRequest = async <T = any>(endpoint: string, cancelToken?: CancelTokenSource): Promise<AxiosResponse<T> | undefined> => {
  try {
    return await axios.delete(process.env.NEXT_PUBLIC_TEST_API + '/' + endpoint, { baseURL: '', ...authRequestOptions(cancelToken) });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const basicAuth = () => ({
  headers: {
    Authorization: 'Basic ' + btoa('uphill:uphill'),
  },
});
