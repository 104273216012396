/*
 * This turns an Array of Object into a Map using one of the Object property value as key.
 * @param source The Array of Objects to be converted to Map
 * @param propertyInSource The property to be used as Map's key. This value MUST be a string.
 */
export const convertArrayOfObjectsToMap = <SourceUnit = Record<string, unknown>>(
  source: SourceUnit[],
  propertyInSource: string,
): Map<string, SourceUnit> => {
  const filteredMapped: [string, SourceUnit][] = source
    .filter((sourceValue) => {
      if (Object.prototype.hasOwnProperty.call(sourceValue, String(propertyInSource))) {
        return typeof (sourceValue as Record<string, unknown>)[String(propertyInSource)] === 'string';
      }
      return false;
    })
    .map((sourceValue) => {
      const mapKey: string = (sourceValue as Record<string, string>)[String(propertyInSource)] as string;
      return [mapKey, sourceValue];
    });

  return new Map(filteredMapped);
};
