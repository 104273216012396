// constants
import { PATHWAY_ELEMENT_TYPES } from '@/constants/pathway';
import { COMMUNICATION_TYPES } from '@/constants/communication';
// models
import { PathwayElement } from '@/models/PathwayElement';
import { PatientSession } from '@/models/PatientSession';

export const findElementCommunicationSent = (pathwayElement: PathwayElement, session: PatientSession): PathwayElement => {
  if (
    (pathwayElement.type === PATHWAY_ELEMENT_TYPES.ACTION || pathwayElement.type === COMMUNICATION_TYPES.KEY_MOMENT) &&
    pathwayElement.communication
  ) {
    pathwayElement.communicationSent = session?.communications.find(
      (communication) => communication.communicationId === pathwayElement.communication?.id,
    );
    if (pathwayElement.communicationSent) {
      pathwayElement.skipped = false;
    }
  }
  return pathwayElement;
};
