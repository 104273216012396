import React, { ReactElement } from 'react';
// self
import { BadgeIconContainer, BadgeStyled } from './styles';
import { BadgeProps, BadgeType } from './types';
// icons
import CriticalIcon from './icons/critical.svg';
import WarningIcon from './icons/warning.svg';

const badgeIcons: Record<BadgeType, ReactElement> = {
  [BadgeType.critical]: <CriticalIcon />,
  [BadgeType.warning]: <WarningIcon />,
};

export const Badge = ({ children, type }: BadgeProps): ReactElement => {
  return (
    <BadgeStyled type={type} data-testid="Badge">
      {type && <BadgeIconContainer>{badgeIcons[String(type) as BadgeType]}</BadgeIconContainer>} {children}
    </BadgeStyled>
  );
};
