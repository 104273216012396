import { PathwayActionCategoryIs } from '@/models';

export const WHITE = '#ffffff';
export const BLACK = '#000000';

export const MENU_BACKGROUND = '#ffffff';
export const MENU_LINE_SEPARATOR = '#E2E3E7';
export const MENU_ITEM_HOVER = '#5B6477';

export const WORKSPACE_SELECTED_BACKGROUND = '#CFD8F3';
export const WORKSPACE_SELECTED_BACKGROUND_HOVER = '#ACB7D8';
export const WORKSPACE_BACKGROUND_HOVER = '#ECF0FA';

export const TITLE_MENU = '#2E3A53';

export const SEARCH_BACKGROUND = '#F6F7F9';
export const SEARCH_LABEL = '#838B9E';

export const ACTIVE_LINK_BACKGROUND = 'rgba(207, 216, 243, 0.6)';
export const ACTIVE_LINK_BORDER = '#3F65CE';

export const DISEASE_TAG_BACKGROUND = '#E2E8F8';
export const ACTIVE_MENU_BACKGROUND = '#E2E8F8';

export const PATHWAY_COLOR = '#894EAC';
export const EVENT_COLOR = '#1F8D8E';

export const BACKGROUND_PAGE = '#fafbfc';

export const DRAWER_HOVER = '#021F59';
export const DRAWER_BACKGROUND_HOVER = '#f5f6f9';

export const WORKSPACE_LOGO_HOVER = '#B5BAC5';

export const SEPARATOR_COLOR = '#EBEBEB';

export const PATHWAY_BACKGROUND = '#f6f7f8';

export const FORM_ELEMENT_BACKGROUND = '#D2D8F2';

export const PLACEHOLDER_COLOR = '#757575';

export const ERROR_COLOR = '#df2029';
export const NEW_ERROR_COLOR = '#DE7469';

export const HILLY_BACKGROUND_COLOR = 'linear-gradient(to bottom, #7E8EE0 0.7%, #AE7BD6 99.11%)';
export const KEY_MOMENTS_BACKGROUND_COLOR = 'linear-gradient(to right, #7E8EE0 0.7%, #AE7BD6 99.11%)';
export const SUSPENDED_BACKGROUND_COLOR_TO_RIGHT = 'linear-gradient(to right, #8686DC 0.7%, #69D8D1 99.11%)';
export const SUPENDED_GRADIENT_COLOR_TOP_BOTTOM = 'linear-gradient(0deg, #8686DC 0%, #69D8D1 100%)';
export const PROACTIVE_BACKGROUND_COLOR = 'linear-gradient(90deg, #5760E8 0.7%, #2D9ADD 99.07%)';
export const GATEWAY_SUGGESTION_HEADER = 'linear-gradient(90deg, #7E8EE0 0.7%, #AE7BD6 99.11%)';

export const COMMUNICATION_MESSAGE_COLOR = '#F1F3FA';

export enum PATIENT_SESSION_CATEGORIES_COLORS {
  RED = '#DE7469',
  ORANGE = '#E8935F',
  YELLOW = '#EDC081',
  GREEN = '#68CFD2',
  BLUE = '#7F8DDF',
  GREY = '#9FA8BF',
}
export enum PATIENT_SESSION_CATEGORIES_BACKGROUND {
  RED = '#F7EEF0',
  ORANGE = '#fff6f0',
  GREEN = '#F3FFFC',
  BLUE = '#E8EEFF',
  GREY = '#F5F6F9',
}

export const ALGORITHM_STEPS_COLORS: Record<string, string> = {
  [PathwayActionCategoryIs.APPOINTMENT]: 'linear-gradient(180deg, #efc081, #dd7167)',
  [PathwayActionCategoryIs.COMMUNICATION]: 'linear-gradient(180deg, #08dbfe, #07aaff)',
  [PathwayActionCategoryIs.DIAGNOSIS]: 'linear-gradient(180deg, #2534b7, rgba(44, 99, 176, 0.8))',
  [PathwayActionCategoryIs.DRUG]: 'linear-gradient(180deg, #2d9add, #5760e8)',
  [PathwayActionCategoryIs.EXAM]: 'linear-gradient(180deg, #65d7b5, #52b4b1)',
  [PathwayActionCategoryIs.MANEUVER]: 'linear-gradient(180deg, #f3b095, #ed6e83)',
  [PathwayActionCategoryIs.MONITORING]: 'linear-gradient(180deg, #08dafe, #07abff)',
  [PathwayActionCategoryIs.REFERRAL]: 'linear-gradient(180deg, #efc081, #dd7167)',
  [PathwayActionCategoryIs.REGISTRY]: 'linear-gradient(#08dbfe, #07aaff)',
  [PathwayActionCategoryIs.SPECIALITY]: 'linear-gradient(#08dbfe, #07aaff)',
  [PathwayActionCategoryIs.THERAPEUTIC]: 'linear-gradient(180deg, #ae7bd6, #7e8ee0)',
  default: 'linear-gradient(#08dbfe, #07aaff)',
};

export type ALGORITHM_STEPS_COLORS_TYPE =
  | 'MANEUVER'
  | 'EXAM'
  | 'THERAPEUTIC'
  | 'DRUG'
  | 'DIAGNOSIS'
  | 'SPECIALITY'
  | 'APPOINTMENT'
  | 'MONITORING'
  | 'REGISTRY'
  | 'default';

export const COMMUNICATION_STATUS_COLORS = {
  ONGOING: '#feefdb',
  ONGOING_DARK: '#EDC081',
  DONE: '#C8F5F6',
};

export const COOKIE_TITLE = '#232d40';

export const BUTTON_LIGHT_FONT = '#7484A6';

export const HILLY_GRADIENT = 'linear-gradient(90deg, #FBBE75 0%, #D57EEB 100%)';
export const HILLY_GRADIENT_HOVER = 'linear-gradient(90deg, #FBBE75A6 0%, #D57EEBA6 100%)';

export const BACKGROUND_ACTIVE_BUTTON = '#E8EEFF';
export const TITLE_TEAM_GRAY = '#455373';
export const DISABLE_LETTER = '#536180';

export const CRITICAL_ALERT = '#E3655B';

export const SUCCESS = '#00BF93';
export const ERROR = '#E3655B';
export const ERROR_BACKGROUND = '#F7EEF0';

export enum PathwayStatusColors {
  completed = '#68CFD2',
  inProgress = '#1790E8',
  notDoneCancelled = '#B5BAC5',
  paused = '#FCBA5F',
}

export const FASE_FONT_COLOR = {
  DIAGNOSIS: '#2A65BC',
  TREATMENT: '#0E9140',
  PREVENTION: '#CB6508',
  FOLLOW_UP: '#737808',
  REFERENCING: '#780D8D',
};

export const FASE_BACKGROUND_COLOR = {
  DIAGNOSIS: '#EBF3FF',
  TREATMENT: '#EAFBF1',
  PREVENTION: '#FBEEE3',
  FOLLOW_UP: '#FAFBE3',
  REFERENCING: '#F7E3FB',
};
