import React, { Fragment, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { translate } from '@uphillhealth/i18n';
import { Anchor } from '@/uphillhealth/components';
import { ServiceResponseError } from '@uphillhealth/react-service-provider';
import { AxiosError } from 'axios';
// app
import { useApplicationContext } from '@/src/providers';
// self
import { ContentContainer, InstitutionLogo } from './styles';
import { ErrorContainer, Label, LoggedMessage } from '../partials';

interface ContextErrorProps {
  error?: AxiosError<ServiceResponseError>;
}

export const ContextError = ({ error }: ContextErrorProps): ReactElement => {
  const { currentInstitution, currentUser } = useApplicationContext();
  const router = useRouter();

  const institutionImage = currentInstitution?.mainImageUrl || currentInstitution?.imageUrl || '/images/png/no-results.png';

  const hideBackButtonWhen = [4110].includes(Number(error?.response?.data?.errorNumber));

  return (
    <ErrorContainer>
      <ContentContainer>
        <InstitutionLogo>
          <img src={institutionImage} alt={currentInstitution?.name ?? 'Institution'} />
        </InstitutionLogo>

        {error && (
          <Fragment>
            <Label>{error.response?.data.error ? error.response.data.error : error.message}</Label>
            {error.response?.data.errorNumber && (
              <Label css={{ marginTop: '$4' }}>
                {translate('Error number')}: {error.response.data.errorNumber}
              </Label>
            )}
          </Fragment>
        )}

        {hideBackButtonWhen && (
          <Anchor css={{ marginTop: '$4' }} fit href={`/${router.query.workspace}`}>
            {translate('GoBack')}
          </Anchor>
        )}

        <LoggedMessage currentUser={currentUser} />
      </ContentContainer>
    </ErrorContainer>
  );
};
