import { Note } from '@/src/services/PatientNotesService/types';
import { Service } from '@uphillhealth/react-service-provider';

export class PatientNotesService extends Service {
  useGetNotes(params: { patientSessionId?: string | number }) {
    return this.useQuery<Note[]>({
      method: 'get',
      params,
      url: '/[institutionId]/notes',
    });
  }
  usePostNotes() {
    return this.useMutation<unknown, Note>({
      method: 'POST',
      url: '[institutionId]/notes',
    });
  }
}
