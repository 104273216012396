import React, { ReactElement } from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
// self
import { SwitchRoot, SwitchThumb } from './styles';
import { StitchesProps } from '@uphillhealth/theme';

interface SwitchProps extends SwitchPrimitives.SwitchProps, StitchesProps {}

export const Switch = (props: SwitchProps): ReactElement => {
  return (
    <SwitchRoot {...props} data-testid="Switch">
      <SwitchThumb data-testid="SwitchThumb" />
    </SwitchRoot>
  );
};
