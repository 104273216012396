import { styled } from '@stitches/react';

export const BaseHeaderTitle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  color: '$neutral800',
  fontSize: '$base',
  fontWeight: 500,
  variants: {
    critical: {
      true: {
        color: '$error500',
      },
    },
  },
};

export const BaseHeaderDescription = {
  color: '$neutral700',
  fontSize: '$small',
  lineHeight: '$fontSizes$large',
};

export const BaseCloseIcon = {
  all: 'unset',
  width: 26,
  height: 26,
  color: '$neutral600',
  fontSize: '$large',
  borderRadius: '$medium',
  display: 'grid',
  placeItems: 'center',
  border: '1px solid transparent',
  '&:hover': {
    color: '$primary500',
  },
  '&:focus': {
    borderColor: '$neutral100',
    outline: '1px solid $primary700',
  },
};

export const BaseFooter = {
  padding: '$4',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$2',
  borderTop: '1px solid $neutral300',
};

export const BaseContent = {
  padding: '$4',
  '& + &': {
    paddingTop: 0,
  },
};

export const BaseHeader = {
  padding: '$4 $14 $4 $4',
  display: 'flex',
  flexDirection: 'column',
  color: '$neutral900',
  borderBottom: '1px solid $neutral300',
};

export const LeftContent = styled('span', {
  display: 'flex',
  flexDirection: 'row',
  justifySelf: 'flex-end',
  color: 'inherit',
  fontSize: 'inherit',
  backgroundColor: 'inherit',
});

export const RightContent = styled('span', {
  display: 'flex',
  flexDirection: 'row',
  justifySelf: 'flex-end',
  color: 'inherit',
  fontSize: 'inherit',
  backgroundColor: 'inherit',
});

export const InputMarginBottom = styled('div', {
  marginBottom: '$4',
  width: '100%',
});

export const IconContainer = styled('div', {});

export const TextOverflow = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
