/* eslint-disable max-lines */
import { REDUX_STATUS } from '@/constants';
import { COMMUNICATION_STATUS, COMMUNICATION_SUBTYPES, COMMUNICATION_TYPES, INTERACTION_STATUS } from '@/constants/communication';

import { PathwayElementPosition } from './Pathway';

export interface HillyCommunication {
  id: string;
  nextElementId: string;
  type: COMMUNICATION_TYPES;
  active: boolean;
  title: string;
  communicationType: string;
  communicationTypeDescription: string;
  goal: string;
  languages: string[];
  recurrence: RecurrenceType;
  recurrenceConfiguration: RecurrenceConfiguration;
  process: string[];
  phaseId?: string;
}

export interface CommunicationDTO {
  id: string;
  position: {
    elementId: string;
    elementStepNumber: number;
    parentStepNumber?: number;
    firstElementOfSubPath?: string;
  };
  communicationId: string;
  numberOfcurrentSentCommunications: number;
  numberOfCurrentAnsweredCommunications: number;
  firstCommunicationSentDateTime: Date | null;
  lastCommunicationSentDateTime: Date | null;
  lastUserResponseDateTime: Date | null;
  nextContactDateTime: Date | null;
  overviewState: COMMUNICATION_STATUS;
  userResponses: UserResponses[] | null;
  interactionState: INTERACTION_STATUS;
  createdDate: string;
  suspensionDateTime: string | null;
  numberOfCurrentSentCommunications: number;
}

export interface Communication {
  communicationId: string;
  firstCommunicationSentDateTime: string | null;
  id: string;
  lastCommunicationSentDateTime: string | null;
  lastUserResponseDateTime: string | null;
  nextContactDateTime: string | null;
  numberOfCurrentSentCommunications: number;
  numberOfCurrentAnsweredCommunications: number;
  overviewState: COMMUNICATION_STATUS;
  interactionState: INTERACTION_STATUS;
  inboundStatus?: INBOUND_STATUS;
  inboundStartDateTime?: string;
  inboundEndDateTime?: string;
  triggerOccurrences?: TriggerOcurrence[];
  numberOfCurrentTriggers?: number;
  position: PathwayElementPosition;
  suspensionDateTime: string | null;
  userResponsesState: REDUX_STATUS;
  userResponses?: UserResponses[];
  webForm?: string;
  createdDate: string;
}

export interface UserResponses {
  alertId?: string;
  communicationSentId?: string;
  responseDate: string;
  triggerDateTime?: string;
  responses: UserResponse[];
  responseBy?: number;
}

export interface UserResponse {
  id: string;
  question: string;
  type: 'NUMERIC_INPUT' | 'TEXT_INPUT' | 'MULTIPLE_CHOICE' | 'ONE_CHOICE' | 'DROPDOWN' | 'SCALE';
  maxChoice: string | null;
  maxChoiceDescription: string | null;
  minChoice: string | null;
  minChoiceDescription: string | null;
  separator: string | null;
  units: { label: string }[] | null;
  answers: {
    id: string | null;
    answer: string;
  }[];
  hidden: boolean;
  conditionalQuestion: boolean;
}

export interface QuestionAnswer {
  response: UserResponse;
  index?: number;
}

export interface CommunicationData {
  id: string;
  scheduledDate?: string;
  elementId: string;
  position: PathwayElementPosition;
}

export interface CommunicationObject {
  clinicalEvidences: {
    name: string;
    url: string;
  }[];
  communicationType: COMMUNICATION_SUBTYPES;
  communicationTypeDescription: string;
  goal: string;
  id: string;
  process: string[];
  title: string;
}

export interface RecurrenceConfigurationItem {
  extraDeliveriesIndexes?: null;
  interval: string;
  intervalValue: number;
  startInInterval: string;
  startInValue: number;
  totalDuration: number;
  type?: null;
}

export enum RecurrenceConfigurationType {
  oneTime = 'ONE_TIME',
  recurrent = 'RECURRENT',
}

export interface RecurrenceConfigurationOneTime {
  startInInterval: string;
  startInValue: number;
  type: RecurrenceConfigurationType.oneTime;
}

export interface RecurrenceConfigurationRecurrent {
  recurrence: Record<number, RecurrenceConfigurationItem>;
  type: RecurrenceConfigurationType.recurrent;
}

export interface RecurrenceConfiguration
  extends Omit<RecurrenceConfigurationOneTime, 'type'>,
    Omit<RecurrenceConfigurationRecurrent, 'type'> {
  type: RecurrenceConfigurationType;
}

/**
 * @deprecated This inteface will be replaced by RecurrenceConfiguration
 */
export interface RecurrenceType {
  interval: string | null;
  intervalValue: number;
  startInDays: number;
  type: 'ONE_TIME' | 'RECURRENT';
  totalDuration: number;
}
export const CommunicationFactory = {
  fromDTO: (dto: CommunicationDTO): Communication => {
    const responsesLength = dto.userResponses?.length ?? 0;
    return {
      ...dto,
      position: {
        elementId: dto.position.elementId,
        index: dto.position.elementStepNumber,
        parentIndex: dto.position.parentStepNumber,
        subPathSelected: dto.position.firstElementOfSubPath,
      },
      userResponses: responsesLength
        ? (Array(responsesLength).fill({ responses: [], responseDate: new Date().toISOString() }) as UserResponses[])
        : undefined,
      userResponsesState: REDUX_STATUS.IDLE,
      firstCommunicationSentDateTime: dto.firstCommunicationSentDateTime ? dto.firstCommunicationSentDateTime?.toString() : null,
      lastCommunicationSentDateTime: dto.lastCommunicationSentDateTime ? dto.lastCommunicationSentDateTime?.toString() : null,
      lastUserResponseDateTime: dto.lastUserResponseDateTime ? dto.lastUserResponseDateTime?.toString() : null,
      nextContactDateTime: dto.nextContactDateTime ? dto.nextContactDateTime?.toString() : null,
    };
  },
};

export enum INBOUND_STATUS {
  ACTIVE = 'ACTIVE',
  TRIGGERED = 'TRIGGERED',
  DONE = 'DONE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
}

export interface TriggerOcurrence {
  phoneNumber: string;
  occurrenceType: string;
  occurrenceDatetime: number;
}
