/* eslint-disable max-len */
export const CONTENT_FORM = 'https://uphill028621.typeform.com/to/d9ES4d';
export const PAGE_TOTAL = 10;
export const ALGORITHM_FORM_URL = 'https://uphill028621.typeform.com/to/XVtiJe';
export const IOS_APP_STORE_URL = 'https://apps.apple.com/app/uphill/id1482792545';
export const ANDROID_APP_STORE_URL = 'https://play.google.com/store/apps/details?id=pt.uphill.app';
export const JOIN_SESSION_URL = 'https://uphill028621.typeform.com/to/snWYYB';
export const CONTACT_SALES_FORM = 'https://pipedrivewebforms.com/form/e2f53f2a107ce632f63c052d9791b10c4934191';
export const BASE_UPID_LOGIN = `${process.env.UPID_APP}/signin?continue=${process.env.UPHILL_APP_URL}/afterLogin&service=uphillhealth.com`;
export const UPID_LOGIN = `${process.env.UPID_APP}/signin?continue=${process.env.UPHILL_APP_URL}/afterLogin&service=uphillhealth.com&redirectTo=${process.env.UPHILL_APP_URL}`;
export const CUSTOMER_SUPPORT = 'https://support.uphill.pt/';
export const COMMUNITY_UPHILLHEALTH = 'https://community.uphillhealth.com/';
export const BLOG_UPHILL = 'https://blog.uphillhealth.com/';

export const ALLOWED_LANGS = {
  pt: { id: 'pt-PT', label: 'Português', prefix: '🇵🇹' },
  en: { id: 'en-GB', label: 'English', prefix: '🇬🇧' },
  fr: { id: 'fr-FR', label: 'Français', prefix: '🇫🇷' },
  es: { id: 'es-ES', label: 'Español', prefix: '🇪🇸' },
  de: { id: 'de-DE', label: 'Deutsch', prefix: '🇩🇪' },
  ca: { id: 'ca', label: 'Catalan', prefix: '' },
};

export const ALLOWED_LANGS_SELECTOR = {
  'pt-PT': 'Português',
  'en-GB': 'English',
};

export const MOBILE_APP_URL = 'https://uphill.app.link/';

export const LANDING_TOP_BREAKPOINT = '980px';
