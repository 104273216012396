import React, { memo, ReactElement, useCallback, useMemo, useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger } from '@/uphillhealth/components';
import * as SelectPrimitives from '@radix-ui/react-select';
import { countries } from 'country-data';
// self
import { CountryDisplay, DisplayCallingCode, DisplayEmoji, TriggerDisplay } from './styles';

interface SelectCallingCodeProps extends SelectPrimitives.SelectProps {
  placeholder?: string;
}

type CountryMeta = { callingCode: string; countryCode: string; display: string };

export const SelectCallingCode = memo(
  ({ defaultValue, onValueChange, placeholder = '', value, ...props }: SelectCallingCodeProps): ReactElement => {
    // data manipulation to country list and metadata
    const { countriesList, countryMetaByCallingCode } = useMemo(() => {
      const filteredCountries = countries.all.filter((country) => {
        const { alpha2, countryCallingCodes } = country;
        const callingCodeToUse = countryCallingCodes[0];
        return alpha2 && countryCallingCodes.length && !callingCodeToUse.includes(' ');
      });

      const countryMetaByCallingCode = filteredCountries.reduce((merge: Record<string, CountryMeta>, country) => {
        const { alpha2, countryCallingCodes, emoji } = country;
        const callingCodeToUse = countryCallingCodes[0];
        return {
          ...merge,
          [callingCodeToUse]: {
            callingCode: callingCodeToUse,
            countryCode: alpha2,
            display: `${emoji} ${callingCodeToUse}`,
          },
        };
      }, {});

      const orderedCountries = [...filteredCountries].sort((countryA, countryB) => countryA.name.localeCompare(countryB.name));

      return { countriesList: orderedCountries, countryMetaByCallingCode };
    }, []);

    const initialCallingCode = defaultValue || value || '+351';
    const plusCallingCode = initialCallingCode.startsWith('+') ? initialCallingCode : `+${initialCallingCode}`;
    const initialCountryMeta = Object.prototype.hasOwnProperty.call(countryMetaByCallingCode, plusCallingCode)
      ? countryMetaByCallingCode[String(plusCallingCode)]
      : countryMetaByCallingCode['+351'];

    const [countryMeta, setCountryMeta] = useState<CountryMeta>(initialCountryMeta);

    const onValueChangeHandler = useCallback((changedCallingCode: string) => {
      const changedCountryMeta = countryMetaByCallingCode[String(changedCallingCode)];
      setCountryMeta(changedCountryMeta);

      onValueChange && onValueChange(changedCountryMeta.callingCode);
    }, []);

    return (
      <Select {...props} value={countryMeta.callingCode} onValueChange={onValueChangeHandler}>
        <SelectTrigger placeholder={placeholder}>
          <TriggerDisplay>{countryMeta.display}</TriggerDisplay>
        </SelectTrigger>
        <SelectContent>
          {countriesList.map((country) => {
            const { alpha2, countryCallingCodes, emoji, name } = country;
            const callingCodeToUse = countryCallingCodes[0];

            return (
              <SelectItem key={alpha2} value={String(callingCodeToUse)}>
                <CountryDisplay>
                  <DisplayEmoji>{emoji}</DisplayEmoji> <DisplayCallingCode>{callingCodeToUse}</DisplayCallingCode> {name}
                </CountryDisplay>
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    );
  },
);
