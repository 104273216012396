export interface CourseSimplified {
  attemptsStatistics: {
    numCompleted: number;
    numInProgress: number;
  } | null;
  createdDate: number;
  id: number;
  published: boolean;
  name: string;
  visible: boolean;
}
export interface Course {
  id?: string;
  attemptsStatistics: {
    numCompleted: number;
    numInProgress: number;
  } | null;
  externalId?: number;
  name: string;
  description: string;
  imageUrl?: string;
  createdDate?: Date;
  institution: number;
  specialityId: number | null;
  minScore: number | null;
  language: string;
  visible: boolean;
  deleted: boolean;
  published: boolean;
  evaluation: boolean;
}

export interface CourseForm {
  id: number | null;
  name: string;
  description: string;
  specialityId: string;
  hasMinScore: boolean;
  minScore: string | null;
  imageUrl: string | undefined;
  language: string;
  visible: boolean;
  institution: number | null;
  evaluation: boolean;
  attemptsStatistics: { numCompleted: number; numInProgress: number } | null;
}

export const CourseHelper = {
  formToCourseDTO: (courseForm: CourseForm): Course => {
    const course: any = {
      ...courseForm,
    };
    delete course.id;
    delete course.hasMinScore;
    delete course.published;
    delete course.specialityId;
    return {
      ...course,
      ...(courseForm.specialityId && { specialityId: courseForm.specialityId }),
      minScore: courseForm.minScore ? +courseForm.minScore : null,
      evaluation: courseForm.evaluation,
    };
  },
};
