import React from 'react';
import { ToggleRootStyle } from './style';
import type * as Radix from '@radix-ui/react-primitive';
import * as RovingFocusGroup from '@radix-ui/react-roving-focus';
import { StitchesProps } from '@uphillhealth/theme';

type ToggleGroupImplElement = React.ElementRef<typeof Radix.Primitive.div>;
type ToggleGroupImplSingleElement = ToggleGroupImplElement;
type ToggleGroupImplMultipleElement = ToggleGroupImplElement;
type PrimitiveDivProps = Radix.ComponentPropsWithoutRef<typeof Radix.Primitive.div>;
type RovingFocusGroupProps = Radix.ComponentPropsWithoutRef<typeof RovingFocusGroup.Root>;

interface ToggleGroupImplProps extends PrimitiveDivProps, StitchesProps {
  /**
   * Whether the group is disabled from user interaction.
   * @defaultValue false
   */
  disabled?: boolean;
  /**
   * Whether the group should maintain roving focus of its buttons.
   * @defaultValue true
   */
  rovingFocus?: boolean;
  loop?: RovingFocusGroupProps['loop'];
  orientation?: RovingFocusGroupProps['orientation'];
  dir?: RovingFocusGroupProps['dir'];
}
interface ToggleGroupImplSingleProps extends ToggleGroupImplProps {
  /**
   * The controlled stateful value of the item that is pressed.
   */
  value?: string;
  /**
   * The value of the item that is pressed when initially rendered. Use
   * `defaultValue` if you do not need to control the state of a toggle group.
   */
  defaultValue?: string;
  /**
   * The callback that fires when the value of the toggle group changes.
   */
  onValueChange?(value: string): void;
}
interface ToggleGroupImplMultipleProps extends ToggleGroupImplProps {
  /**
   * The controlled stateful value of the items that are pressed.
   */
  value?: string[];
  /**
   * The value of the items that are pressed when initially rendered. Use
   * `defaultValue` if you do not need to control the state of a toggle group.
   */
  defaultValue?: string[];
  /**
   * The callback that fires when the state of the toggle group changes.
   */
  onValueChange?(value: string[]): void;
}
interface ToggleGroupSingleProps extends ToggleGroupImplSingleProps {
  type: 'single';
}
interface ToggleGroupMultipleProps extends ToggleGroupImplMultipleProps {
  type: 'multiple';
}

type ToggleGroupElement = ToggleGroupImplSingleElement | ToggleGroupImplMultipleElement;
/* istanbul ignore next */
export const Toggle = React.forwardRef<ToggleGroupElement, ToggleGroupSingleProps | ToggleGroupMultipleProps>((props, forwardedRef) => {
  return (
    <ToggleRootStyle {...props} ref={forwardedRef} data-testid="Toggle">
      {props.children}
    </ToggleRootStyle>
  );
});
