import { REDUX_STATUS } from '../../constants';
import { Gender } from '../../models/Common';
import Pathway from '../../models/Pathway';
import PathwayCalculator from '../../models/PathwayCalculator';
import { PathwayElement } from '../../models/PathwayElement';
import { PathwayPanelItem } from '../../models/PathwayPanelItem';
import PathwayPhase from '../../models/PathwayPhase';
import { PatientSession } from '../../models/PatientSession';

export interface AlgorithmStateAlertsMetadata {
  byType: {
    criticals: number;
    moderated: number;
  };
  widgetActions: {
    elementId: string;
    index: number;
    wasSolved?: boolean;
  };
}
export interface AlgorithmsState {
  alertsMetadata: AlgorithmStateAlertsMetadata;
  status: REDUX_STATUS;
  sessionStatus: REDUX_STATUS;
  fullInfo: null | Pathway;
  path: null | PathwayElement[];
  session?: PatientSession;
  elementOpenedDetails: null | PathwayPanelItem;
  openCommunicationAnswers: boolean;
  calculatorSelected?: {
    calculator: PathwayCalculator;
    index?: number;
    subPathIndex?: number;
    subPathName?: string;
  };
  progress?: {
    phaseSelected: PathwayPhase;
    total: number;
  };
  progressStatus: REDUX_STATUS;
  startingId?: string;
  detailsOpened: boolean;
  detailsType: DETAILS_TYPES | null;
  reminder: { id: string; index: number; parentIndex?: number; subPathSelected?: string } | null;
  reminders: { id: string; index: number; parentIndex?: number; subPathSelected?: string }[] | null;
  sessionDetails: {
    gender?: Gender;
    age?: string;
    touched?: boolean;
    created?: boolean;
    birthdate?: string;
  };
  communication: {
    keyMomentCommunications?: {
      [key: string]: CommunicationProps;
    };
    proactiveCommunications?: CommunicationProps[];
    details?: string; // change type later
  } | null;
  communicationInitialDone: boolean;
}

export interface CommunicationProps {
  id: number;
  title: string;
}

export enum DETAILS_TYPES {
  CALCULATOR = 'calculator',
  ACTION = 'action',
  COMMUNICATION = 'communication',
  PRO_COMMUNICATION = 'pro_communication',
}

export interface setDetailsTypeProps {
  type: DETAILS_TYPES;
}
