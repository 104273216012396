import React, { ReactElement } from 'react';
import * as AccordionPrimitives from '@radix-ui/react-accordion';
// self
import { AccordionMultipleProps } from './types';

/* Implementation from radix primitives.
 * https://www.radix-ui.com/docs/primitives/components/accordion
 */
export const AccordionMultiple = ({ children, ...accordionProps }: AccordionMultipleProps): ReactElement => {
  return (
    <AccordionPrimitives.Root type="multiple" data-testid="Accordion" {...accordionProps}>
      {children}
    </AccordionPrimitives.Root>
  );
};
