/*
 * Replaces the context in the given permission.
 *
 * @example
 * replacePermissionContext('WORKSPACE_PATIENTMANAGEMENT_[institutionId]', { institutionDomain: 'uphill', institutionId: 112 })
 * // returns 'WORKSPACE_PATIENTMANAGEMENT_112'
 */
export const replacePermissionContext = (permission: string, context: Record<string, number | string>): string => {
  return Object.entries(context).reduce((merge, entry) => {
    const [entryKey, entryValue] = entry;
    try {
      return merge.replaceAll(`[${entryKey}]`, String(entryValue));
    } catch (_error) {
      /* istanbul ignore next */
      const regex = new RegExp(`\\[${entryKey}]`, 'g');
      /* istanbul ignore next */
      return merge.replace(regex, String(entryValue));
    }
  }, permission);
};
