import { REDUX_STATUS } from '@/constants';

import PathwayAction from '../models/PathwayAction';
import { PathwayActionCategory, PathwayActionCategoryIs } from '@/models/PathwayActionCategory';
import { PathwayActionGroup } from '@/models/PathwayActionGroup';
import { PathwayActionItem } from '@/models/PathwayActionItem';
import { PathwayActionItemItem } from '@/models';
import { ProgressStepActionItems } from '@/models/PathwayProgress';
import { StepLogActivity } from '@/models';

const parseActionItems = (actionItems: PathwayActionItem[]) => {
  return actionItems.map((actionItem) => {
    const items = actionItem.items;
    let groups: PathwayActionGroup[];
    if (actionItem.groups?.length) {
      groups = actionItem.groups.map((g) => ({
        ...g,
        items: items.filter((i) => i.groupId === g.id),
      }));
    } else {
      groups = [
        {
          id: null,
          value: '',
          items: items,
        },
      ];
    }
    return {
      ...actionItem,
      items,
      groups,
    };
  });
};

export const mapAction = (action: PathwayAction): PathwayAction => {
  let description = action.description;
  let hasActionItems = true;
  let hasDetails = false;
  let { actionItems } = action;
  if (actionItems.length === 0) {
    hasActionItems = false;
  } else if (actionItems.length === 1 && actionItems[0].items.length === 1) {
    description = actionItems[0].items[0].name;
    hasActionItems = false;
    hasDetails = actionItems[0].items[0].extraKeys != null;
  }
  if (action.communication) {
    action.communicationSkipped = false;
    action.communicationSentStatus = REDUX_STATUS.IDLE;
  }

  actionItems = parseActionItems(actionItems);
  return {
    ...action,
    actionItems,
    description,
    hasActionItems,
    hasDetails,
    allSelectable: actionItems.every((ele) => ele.items.length === 1),
    categoryColor: getActionColorFromCategory(action.category),
  };
};

export const getActionSelectedStateUpdated = (
  action: PathwayAction,
  selectedAction?: PathwayAction,
  actionItemItem?: PathwayActionItemItem,
) => {
  let isExpanded = action.isExpanded;
  if (selectedAction && !actionItemItem && action.id == selectedAction.id && action.hasActionItems) {
    isExpanded = !isExpanded;
  }
  const actionItems = action.actionItems.map((actionItem) => {
    const groups = actionItem.groups.map((group) => ({
      ...group,
      items: group.items.map((itemItem) => {
        const isItemSelected = actionItemItem && itemItem.id == actionItemItem.id;
        return { ...itemItem, isSelected: isItemSelected };
      }),
    }));
    return { ...actionItem, groups };
  });
  return { ...action, isExpanded, actionItems };
};

const getActionColorFromCategory = (category: PathwayActionCategory): string => {
  const categoryColors: Record<string, string> = {
    [PathwayActionCategoryIs.APPOINTMENT]: '#07aaff',
    [PathwayActionCategoryIs.COMMUNICATION]: '#3F65CE',
    [PathwayActionCategoryIs.DIAGNOSIS]: '#07aaff',
    [PathwayActionCategoryIs.DRUG]: '#7445e2',
    [PathwayActionCategoryIs.EXAM]: '#04b6b2',
    [PathwayActionCategoryIs.MANEUVER]: '#07aaff',
    [PathwayActionCategoryIs.MONITORING]: '#07aaff',
    [PathwayActionCategoryIs.REFERRAL]: '#07aaff',
    [PathwayActionCategoryIs.REGISTRY]: '#07aaff',
    [PathwayActionCategoryIs.SPECIALITY]: '#07aaff',
    [PathwayActionCategoryIs.THERAPEUTIC]: '#ff6481',
  };

  return Object.prototype.hasOwnProperty.call(categoryColors, category) ? categoryColors[String(category)] : '#3F65CE';
};

export const pathwayActionSelectedItemIds = (pathwayAction: PathwayAction): string[] => {
  if (!pathwayAction.actionItems) return [];
  return pathwayAction.actionItems.filter((actionItem) => actionItem.selectedItemId).map((ele) => ele.selectedItemId?.toString() ?? '');
};

export const pathwayActionActionItemsProgress = (pathwayAction: PathwayAction): ProgressStepActionItems[] => {
  const { actionItems, category } = pathwayAction;

  return actionItems
    .reduce((merge: ProgressStepActionItems[], actionItem, actionItemIndex) => {
      const actionGroupItems = actionItem.groups.reduce(
        (mergeActionItemGroup: ProgressStepActionItems[], actionItemGroup, actionItemGroupIndex) => {
          const groupItems = actionItemGroup.items.map(
            (actionItemGroupItem): ProgressStepActionItems => ({
              actionItemIndex,
              actionItemGroupIndex,
              category,
              id: actionItemGroupItem.id.toString(),
              logActivity: actionItemGroupItem.logActivity as StepLogActivity,
              status: actionItemGroupItem.status ?? '',
              statusReason: actionItemGroupItem.statusReason,
            }),
          );
          return [...mergeActionItemGroup, ...groupItems];
        },
        [],
      );

      return [...merge, ...actionGroupItems];
    }, [])
    .filter((stepActionItem) => stepActionItem.status);
};

export default {
  mapAction,
  getActionSelectedStateUpdated,
  getActionColorFromCategory,
};
