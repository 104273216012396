import { Gender } from '../models/Common';

export const patientSessionHelper = {
  calcAge: (patientBirthDate: string): number => {
    const [date] = patientBirthDate.split ? patientBirthDate.split('T') : patientBirthDate;
    const [year, month, day] = date.split ? date.split('-') : date;
    return Math.floor((new Date().getTime() - new Date(+year, +month - 1, +day).getTime()) / 31556926000);
  },
  getAvatar: (patientGender?: Gender, age?: number): string => {
    let avatar = '/icons/avatars/';
    if (patientGender && age !== undefined) {
      switch (patientGender) {
        case Gender.MALE: {
          if (age < 5) avatar += 'men-0-4.svg';
          else if (age < 11) avatar += 'men-5-10.svg';
          else if (age < 26) avatar += 'men-11-25.svg';
          else if (age < 56) avatar += 'men-26-55.svg';
          else if (age < 66) avatar += 'men-56-65.svg';
          else avatar += 'men-66-99.svg';
          break;
        }
        case Gender.FEMALE: {
          if (age < 5) avatar += 'woman-0-4.svg';
          else if (age < 11) avatar += 'woman-5-10.svg';
          else if (age < 26) avatar += 'woman-11-25.svg';
          else if (age < 56) avatar += 'woman-26-55.svg';
          else if (age < 66) avatar += 'woman-56-65.svg';
          else avatar += 'woman-66-99.svg';
          break;
        }
        default:
          avatar += 'empty-state.svg';
      }
    } else {
      avatar += 'empty-state.svg';
    }
    return avatar;
  },
};
