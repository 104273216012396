import { styled } from '@uphillhealth/theme';

export const AlertStyle = styled('div', {
  width: '100%',
  padding: '$4',
  display: 'flex',
  gap: '$4',
  borderRadius: '$small',
  variants: {
    type: {
      error: {
        backgroundColor: '$error50',
        '& > i': {
          color: '$error500',
        },
        '& > p': {
          color: '$error900',
        },
      },
      warning: {
        backgroundColor: '$warning50',
        '& > i': {
          color: '$warning500',
        },
        '& > p': {
          color: '$warning900',
        },
      },
      info: {
        backgroundColor: '$primary50',
        '& > i': {
          color: '$neutral600',
        },
        '& > p': {
          color: '$primary900',
        },
      },
    },
  },
});
