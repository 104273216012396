import moment from 'moment';

import { COMMUNICATION_STATUS } from '@/constants/communication';
import { CommunicationFactory, RecurrenceConfigurationType } from '@/models/Communication';
import { PathwayImpactImpacts, PathwayImpactType } from '@/models/Pathway';
import { PathwayCommunication } from '@/models/PathwayCommunication';
import { PathwayElement } from '@/models/PathwayElement';
import PathwayGateway from '@/models/PathwayGateway';
import { PatientSession, PatientSessionPathwayImpact } from '@/models/PatientSession';
import { getFullPathwayById, getFullPathwayByIdAndEmbed } from '@/services/api/pathways';
import { ReminderSessionCreationProps, RemindersAPI } from '@/services/api/reminders';
import { SessionAPI, SessionImpactAPI } from '@/services/api/sessions';
import { UserApi } from '@/services/api/user';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { restartAlgorithm } from '../progress/slice';
import { addTeam } from '../teams/slice';
import { restartAlgorithmProgress } from './slice';
import { CombinedType } from '..';
import PathwayGatewayOption from '@/models/PathwayGatewayOption';
import { InstitutionId } from '@/src/models';

export const fetchAlgorithm = createAsyncThunk(
  'algorithm/fetchAlgorithm',
  async ({
    id,
    embed,
    referrer,
    version,
    institutionId,
    institutionKey,
    language,
  }: {
    id: string;
    embed: boolean;
    referrer: string;
    version?: number;
    institutionId: InstitutionId;
    institutionKey?: string;
    isPatientSession?: boolean;
    language?: string | null;
  }) => {
    const algorithm = embed
      ? await getFullPathwayByIdAndEmbed(id, referrer)
      : await getFullPathwayById({ pathwayId: id, version, institutionId, institutionKey, language });
    return { data: algorithm?.data };
  },
);

export const fetchPatientSession = createAsyncThunk(
  'algorithm/fetchSession',
  async ({ id, institutionId, language }: { id?: string; institutionId?: number; language: string | null }, thunkAPI) => {
    const state = thunkAPI.getState() as CombinedType;
    const sessionId = id ? id : state.algorithm?.session?.id ? state.algorithm.session.id : '';
    const fixedInstitutionId = institutionId ?? state.activeInstitution?.data?.id ?? -1;
    const session = await SessionAPI.getSession(fixedInstitutionId, sessionId, language);
    if (session?.data.careTeam) {
      thunkAPI.dispatch(addTeam(session?.data.careTeam));
    }

    return {
      ...session?.data,
      communications: session ? session.data.communications.map((ele) => CommunicationFactory.fromDTO(ele)) : [],
    } as PatientSession;
  },
);

export const fetchReminderToAlgorithmStep = createAsyncThunk(
  'algorithm/fetchReminderToAlgorithmStep',
  async ({ description, date }: { description: string; date: Date }, thunkAPI) => {
    const state = thunkAPI.getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;

    const body: ReminderSessionCreationProps = {
      date: moment(date).format('YYYY-MM-DD'),
      description,
      institutionId,
      patientSessionId: state.algorithm.session?.id ?? '0',
      reminderElements: state.algorithm.reminders
        ? state.algorithm.reminders.map((ele) => ({
            elementId: ele?.id,
            elementStepNumber: ele?.index,
            parentStepNumber: ele?.parentIndex,
            firstElementOfSubPath: ele?.subPathSelected,
          }))
        : [
            {
              elementId: state.algorithm.reminder?.id,
              elementStepNumber: state.algorithm.reminder?.index,
              parentStepNumber: state.algorithm.reminder?.parentIndex,
              firstElementOfSubPath: state.algorithm.reminder?.subPathSelected,
            },
          ],
    };
    const id = (await RemindersAPI.createReminder(body))?.data;

    return { ...body, id };
  },
);

export const fetchDeleteReminderToAlgorithmStep = createAsyncThunk(
  'algorithm/fetchDeleteReminderToAlgorithmStep',
  async (
    { id, index, parentIndex, subPathSelected }: { id: number; index: number; parentIndex?: number; subPathSelected?: string },
    { getState },
  ) => {
    const state = getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;

    await RemindersAPI.deleteReminder(institutionId, id);
    return { index, parentIndex, subPathSelected };
  },
);

export const fetchRestartAlgorithmProgress = createAsyncThunk(
  'algorithm/restartAlgorithmProgres',
  async (data: { id: string } | undefined, { dispatch }) => {
    if (data) {
      await UserApi.resetPathwayProgress(data.id);
    }
    dispatch(restartAlgorithmProgress());
    dispatch(restartAlgorithm());
    return null;
  },
);

export const fetchSessionStepLogs = createAsyncThunk('algorithm/fetchSessionStepLogs', async (): Promise<void> => {
  //   nope
});

interface GatewayImpactAnswerParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any;
  id: string;
  impact?: PathwayImpactImpacts;
  index: number;
  lastCommunication?: boolean;
  parentIndex?: number;
  path: PathwayElement[];
  pathwayElement: PathwayGateway;
  sessionImpact?: PatientSessionPathwayImpact;
  subPathSelected?: string;
  state: CombinedType;
}

interface AnswerGatewayFromOptions extends Omit<GatewayImpactAnswerParams, 'impact' | 'lastCommunication' | 'path'> {
  options: PathwayGatewayOption[];
}

const answerGatewayFromOptions = (_: AnswerGatewayFromOptions) => {};

const gatewayImpactAnswer = ({
  dispatch,
  id,
  impact,
  index,
  parentIndex,
  pathwayElement,
  sessionImpact,
  subPathSelected,
  state,
}: GatewayImpactAnswerParams) => {
  if (sessionImpact && impact) {
    const options = pathwayElement.options?.filter((option) => impact?.optionsIds.includes(option.id)) ?? [];

    const progressFound = state.progress.loadedSteps.find(
      (loadedStep) => loadedStep.elementId === pathwayElement.id && index === loadedStep.number,
    );
    if (!progressFound || !progressFound.optionsIds?.length) {
      answerGatewayFromOptions({
        dispatch,
        id,
        index,
        options,
        parentIndex,
        pathwayElement,
        subPathSelected,
        state,
        sessionImpact,
      });
    }
  }
};

export const pathwayImpactAutoAnswer = createAsyncThunk(
  'algorithm/pathwayImpactAutoAnswer',
  async (
    payload: {
      id: string;
      index: number;
      parentIndex?: number;
      pathwayElement: PathwayGateway;
      subPathSelected?: string;
    },
    action,
  ) => {
    const { pathwayElement } = payload;
    const { getState, dispatch } = action;

    setTimeout(() => {
      const state = getState() as CombinedType;
      if (pathwayElement.blockedBy?.index !== undefined && state.algorithm.path) {
        const communication = state.algorithm.path?.[pathwayElement.blockedBy.index] as PathwayCommunication;

        const lastCommunication = communication?.communicationSent?.overviewState === COMMUNICATION_STATUS.FINISHED;

        const sessionImpact = communication?.pathwayImpact;
        const lastImpact = state.algorithm.session?.pathwayImpacts
          .filter((pathwayImpact) => pathwayImpact.communicationId === communication?.communicationSent?.communicationId)
          ?.slice(-1)[0];

        let payloadImpact;
        let payloadSessionImpact;

        if (
          communication?.recurrenceConfiguration &&
          !communication?.inboundConfiguration?.active &&
          (communication.recurrenceConfiguration.type === RecurrenceConfigurationType.oneTime ||
            (communication.recurrenceConfiguration.type === RecurrenceConfigurationType.recurrent && lastCommunication))
        ) {
          if (sessionImpact && (!lastImpact || lastImpact.type === PathwayImpactType.alert)) {
            payloadImpact = sessionImpact?.impacts.find((impact) => impact.elementId === pathwayElement.id);
            payloadSessionImpact = sessionImpact;
          } else {
            payloadImpact = lastImpact?.impacts.find((impact) => impact.elementId === pathwayElement.id);
            payloadSessionImpact = lastImpact;
          }
        }

        if (payloadImpact && payloadSessionImpact) {
          gatewayImpactAnswer({
            ...payload,
            path: state.algorithm.path,
            dispatch,
            impact: payloadImpact,
            sessionImpact: payloadSessionImpact,
            state,
            lastCommunication,
          });
        }
      } else {
        if (state.algorithm.session?.communications?.length) {
          // collateral for elements that are not in a loop
          let collateral = null;
          if (!pathwayElement.createFromLoop) {
            for (const communication of state.algorithm.session.communications) {
              const filtered = state.algorithm.session?.pathwayImpacts.filter((pathwayImpact) => {
                return (
                  communication.overviewState === COMMUNICATION_STATUS.FINISHED &&
                  pathwayImpact.communicationId === communication.communicationId &&
                  pathwayImpact.impacts.find((impact) => impact.elementId === pathwayElement.id)
                );
              });

              const elementImpactFound = filtered?.slice(-1)[0];
              if (elementImpactFound) {
                collateral = elementImpactFound;
                break;
              }
            }
          }

          // collateral for elements that are inside a loop
          if (pathwayElement.alertId) {
            const filtered = state.algorithm.session?.pathwayImpacts.filter((pathwayImpact) => {
              return (
                pathwayElement.alertId === pathwayImpact.id &&
                pathwayImpact.impacts.find((impact) => impact.elementId === pathwayElement.id)
              );
            });

            collateral = filtered?.slice(-1)[0];
          }

          if (collateral) {
            gatewayImpactAnswer({
              ...payload,
              path: state.algorithm.path ?? [],
              dispatch,
              impact: collateral.impacts.find((impact) => impact.elementId === pathwayElement.id),
              sessionImpact: collateral,
              state,
              lastCommunication: true,
            });
          }
        }
      }
    }, 850);

    return pathwayElement;
  },
);

export const resolveSessionImpact = createAsyncThunk<PatientSessionPathwayImpact, PatientSessionPathwayImpact>(
  'algorithm/resolveSessionImpact',
  async (payload, { getState }) => {
    const state = getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;

    const { id, patientSessionId } = payload;
    await SessionImpactAPI.resolve(institutionId, patientSessionId, id);

    return payload;
  },
);
