import React, { ChangeEvent, memo, ReactElement, ReactNode, useCallback, useMemo, useReducer } from 'react';
import { InputBase, InputBaseProps } from '@/uphillhealth/components/InputBase';
import { SelectCallingCode } from '@/uphillhealth/components';
import examples from 'libphonenumber-js/mobile/examples';
import parsePhoneNumber, { AsYouType, CountryCode, getExampleNumber } from 'libphonenumber-js';
// lib
import { simpleReducer } from '../../methods';
// self
import { InputPhoneContainer, InputPhoneStyled, SelectContainer } from './styles';
import { findCountryByCallingCodeOrPhone } from './findCountryByCallingCodeOrPhone';

interface InputCallingPhoneProps
  extends Omit<InputBaseProps, 'children' | 'defaultValue' | 'leftContent' | 'onChange' | 'rightContent' | 'value'> {
  defaultCallingCode?: string;
  defaultValue?: string;
  onChange?: (args: { callingCode: string; countryCode: string; phoneNumber: string }) => void;
  onErrorComponents?: {
    impossible?: ReactNode;
    invalid?: ReactNode;
  };
  value?: string;
}

interface InputCallingPhoneState {
  callingCode: string;
  countryCode: CountryCode;
  inputPhoneNumber: string;
}

export const InputCallingPhone = memo(
  ({ defaultCallingCode, defaultValue, disabled, onChange, onErrorComponents, value, ...props }: InputCallingPhoneProps): ReactElement => {
    const initialPhoneNumber = defaultValue || value || '';
    const initialPossibilities = findCountryByCallingCodeOrPhone({ callingCode: defaultCallingCode, phoneNumber: initialPhoneNumber });

    const [{ callingCode, countryCode, inputPhoneNumber }, dispatcher] = useReducer(simpleReducer<InputCallingPhoneState>, {
      ...initialPossibilities,
      inputPhoneNumber: initialPossibilities.phoneNumber || initialPhoneNumber,
    });

    // library instances
    const phoneNumberParameters = {
      defaultCallingCode: callingCode,
      defaultCountry: countryCode,
    };

    const asYouTypeInstance = useMemo(() => new AsYouType(phoneNumberParameters), [phoneNumberParameters]);
    const phoneNumberInstance = useMemo(
      () => parsePhoneNumber(inputPhoneNumber, phoneNumberParameters),
      [inputPhoneNumber, phoneNumberParameters],
    );

    // placeholder
    const phoneNumberExample = useMemo(() => {
      if (countryCode) {
        const exampleFormatted = getExampleNumber(countryCode, examples)?.formatNational();
        return exampleFormatted || '';
      }
    }, [countryCode]);

    // internal changes
    const onCallingCodeChange = useCallback((callingCode: string) => {
      const possibilitiesForNewCallingCode = findCountryByCallingCodeOrPhone({ callingCode });

      dispatcher({ ...possibilitiesForNewCallingCode });
    }, []);

    const onPhoneNumberChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value;

        // onChange
        const onChangePhoneNumnberInstance = parsePhoneNumber(phoneNumber, phoneNumberParameters);
        const isPhoneNumberValid = Boolean(onChangePhoneNumnberInstance?.isValid() && onChangePhoneNumnberInstance?.isPossible());
        if (isPhoneNumberValid && onChangePhoneNumnberInstance) {
          onChange &&
            onChange({
              callingCode: onChangePhoneNumnberInstance.countryCallingCode,
              countryCode: onChangePhoneNumnberInstance.country as string,
              phoneNumber: onChangePhoneNumnberInstance.number,
            });
        }

        dispatcher({ inputPhoneNumber: phoneNumber });
      },
      [phoneNumberParameters],
    );

    // visual phone number
    asYouTypeInstance.reset();
    const parsedPhoneNumber = asYouTypeInstance.input(inputPhoneNumber);

    // error components
    const showNumberImpossibleError = Boolean(
      onErrorComponents &&
        onErrorComponents.impossible &&
        phoneNumberInstance &&
        phoneNumberInstance.isValid() &&
        !phoneNumberInstance.isPossible(),
    );
    const showNumberInvalidError = Boolean(
      onErrorComponents &&
        onErrorComponents.invalid &&
        phoneNumberInstance &&
        !phoneNumberInstance.isValid() &&
        !phoneNumberInstance.isPossible(),
    );

    return (
      <InputPhoneContainer>
        <InputPhoneStyled>
          <SelectContainer>
            <SelectCallingCode defaultValue={callingCode} disabled={disabled} onValueChange={onCallingCodeChange} />
          </SelectContainer>
          <InputBase
            {...props}
            disabled={disabled}
            onChange={onPhoneNumberChange}
            placeholder={phoneNumberExample}
            value={parsedPhoneNumber}
          />
        </InputPhoneStyled>
        {showNumberImpossibleError && onErrorComponents && onErrorComponents.impossible}
        {showNumberInvalidError && onErrorComponents && onErrorComponents.invalid}
      </InputPhoneContainer>
    );
  },
);
