import React, { ReactElement } from 'react';
// self
import { Background, ContainerStyled, SvgContainer } from './styles';
import UpHillSymbol from './UpHillSymbol.svg';

export const Loading = (): ReactElement => (
  <ContainerStyled data-testid="Loading">
    <SvgContainer>
      <UpHillSymbol />
    </SvgContainer>
    <Background />
  </ContainerStyled>
);
