import React, { ReactElement } from 'react';
import { Avatar, AvatarSize, Tooltip } from '@/uphillhealth/components';
// app
import { Practitioner } from '@/src/models';
import { computeNameInitials } from '@/src/helpers/common';
// self
import { AvatarRim, PractitionersContainer } from './styles';
import { FlexContainer } from '@/src/theme/commons';

interface CareTeamsPractitionersProps {
  // @default - 4
  avatarAmount?: number;
  practitioners: Practitioner[];
}

export const CareTeamsPractitioners = ({ avatarAmount = 4, practitioners }: CareTeamsPractitionersProps): ReactElement => {
  const sortedPractitioners = [...practitioners].sort((a, b) => a.fullName.localeCompare(b.fullName));

  const avatarsToShow = sortedPractitioners.slice(0, avatarAmount);
  const avatarsHidden = sortedPractitioners.slice(avatarAmount + 1);

  return (
    <PractitionersContainer>
      {avatarsToShow.map((practitioner, practitionerIndex) => {
        return (
          <AvatarRim
            key={`practitioner-${practitionerIndex}-${practitioner.email}`}
            css={{ zIndex: practitioners.length - practitionerIndex }}
          >
            <Tooltip content={practitioner.fullName}>
              <Avatar
                key={practitioner.email}
                avatar={practitioner.photoPath}
                initials={computeNameInitials(practitioner.fullName)}
                size={AvatarSize.large}
              />
            </Tooltip>
          </AvatarRim>
        );
      })}
      {Boolean(avatarsHidden.length) && (
        <Tooltip
          content={
            <FlexContainer css={{ gap: '$2' }} column>
              {avatarsHidden.map((practitioner) => {
                return (
                  <FlexContainer key={practitioner.email} css={{ gap: '$2' }} yCenter>
                    <Avatar avatar={practitioner.photoPath} initials={computeNameInitials(practitioner.fullName)} size={AvatarSize.small} />
                    {practitioner.fullName}
                  </FlexContainer>
                );
              })}
            </FlexContainer>
          }
        >
          <AvatarRim css={{ zIndex: 1 }}>
            <Avatar initials="+" size={AvatarSize.large} />
          </AvatarRim>
        </Tooltip>
      )}
    </PractitionersContainer>
  );
};
