import React, { ReactElement, ReactNode } from 'react';
import * as SelectPrimitives from '@radix-ui/react-select';
// self
import { SelectContentStyled } from './styles';

interface SelectContentProps extends SelectPrimitives.SelectContentProps {
  children: ReactNode | ReactNode[];
}

export const SelectContent = ({ children, ...contentProps }: SelectContentProps): ReactElement => {
  return (
    <SelectPrimitives.Portal>
      <SelectContentStyled {...contentProps} data-testid="SelectContent">
        <SelectPrimitives.Viewport>{children}</SelectPrimitives.Viewport>
      </SelectContentStyled>
    </SelectPrimitives.Portal>
  );
};
