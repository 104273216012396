import { REDUX_STATUS } from '@/constants';
import Institution from '@/models/Institution';
import { getInstitutions } from '@/services/api/institutions';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PublicInstitutionsReducerType } from './types';
import { Action } from 'redux-actions';

export const initialState: PublicInstitutionsReducerType = {
  status: REDUX_STATUS.IDLE,
  data: [],
};

export const fetchPublicInstitutions = createAsyncThunk('publicInstitutions/fetchPublicInstitutions', async () => {
  const res = await getInstitutions();
  return res?.data as Institution[];
});

const publicInstitutionsSlice = createSlice({
  name: 'publicInstitutions',
  initialState,
  reducers: {
    setPublicInstitutions: (state: PublicInstitutionsReducerType, action: Action<{ institutions: Institution[] }>) => {
      state.data = action.payload.institutions;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPublicInstitutions.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = REDUX_STATUS.SUCCEEDED;
    });
  },
});

export const { setPublicInstitutions } = publicInstitutionsSlice.actions;

export default publicInstitutionsSlice.reducer;
