import { createContext, useContext } from 'react';
// self
import { WorkspaceContextValues } from './types';

const voidFunction = () => {
  return;
};

export const initialContextValue: WorkspaceContextValues = {
  alerts: {
    careTeam: 0,
    self: 0,
  },
  cleanJourneyUpdateState: voidFunction,
  setJourneyUpdate: voidFunction,
  setOnJourneyDirectoryUpdateCallback: voidFunction,
  setWorkspaceAlerts: voidFunction,
};

export const WorkspaceContext = createContext<WorkspaceContextValues>(initialContextValue);

export const useWorkspaceContext = (): WorkspaceContextValues => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error("useWorkspaceContext can only be called inside WorkspaceContextProvider's instance");
  }
  return context;
};
