import { styled } from '@/src/theme';

export const ApplicationPageContainer = styled('div', {
  height: '100%',
  width: '100%',
  padding: '$4',
  overflow: 'auto',
  backgroundColor: '$neutral200',
  '@medium': {
    padding: '$8',
  },
});

export const ApplicationPageTitle = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  color: '$neutral900',
  marginBottom: '$3',
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '$extraLarge',
  lineHeight: '$fontSize$extraLarge',
});

export const ApplicationPageTitleAddendum = styled('div', {
  color: '$neutral400',
  fontFamily: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
});
