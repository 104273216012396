import React, { memo, ReactElement } from 'react';
import { AvatarFallback, AvatarImage, AvatarRoot } from './styles';
import { AvatarProps, AvatarSize } from './types';

export const Avatar = memo(({ avatar = 'none', initials, size = AvatarSize.base, light }: AvatarProps): ReactElement => {
  return (
    <AvatarRoot light={light} size={size} data-testid="Avatar">
      <AvatarImage src={avatar} alt={initials} />
      <AvatarFallback delayMs={175}>{initials}</AvatarFallback>
    </AvatarRoot>
  );
});
