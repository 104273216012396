import React, { ReactElement } from 'react';
import { OuterBox } from './style';

export const CircleLoader = (): ReactElement => {
  return (
    <OuterBox data-testid="CircleLoader">
      <span />
    </OuterBox>
  );
};
