import { styled } from '@uphillhealth/theme';

export const InputPhoneContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const InputPhoneStyled = styled('div', {
  width: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  border: '1px solid $neutral300',
  borderRadius: '$medium',
  '> :last-child > div > input': {
    border: 'none',
    borderLeft: '1px solid $neutral300',
    borderTopRightRadius: '$medium',
    borderBottomRightRadius: '$medium',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
});

export const SelectContainer = styled('div', {
  width: 'auto',
  minWidth: 106,
  '> button': {
    border: 'none',
  },
});
