/* eslint-disable max-lines */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// constants
import { PATHWAY_ELEMENT_TYPES, PATHWAY_GATEWAY_TYPES } from '@/constants/pathway';
import { REDUX_STATUS } from '@/constants';
import { ProgressSliceType } from './types';
// models
import PathwayAction from '@/models/PathwayAction';
import PathwayGateway from '@/models/PathwayGateway';
// helpers
import { getLastAlertId } from '@/helpers/pathAlertsHelper';
import { pathToSteps } from '@/helpers/sendProgress';
import { pathwayActionStatusByItems } from '@/helpers/pathwayStatusesHelper';
import { pathwayActionActionItemsProgress, pathwayActionSelectedItemIds } from '@/helpers/pathwayActionHelper';
// services
import { SessionAPI } from '@/services/api/sessions';
import { UserApi } from '@/services/api/user';
// store
import { setGatewaySubpathStartStepNumber, setProgressIdsToPath } from '../algorithm/slice';
import { CombinedType } from '..';

export const initialState: ProgressSliceType = {
  isProgressValid: true,
  fetchStatus: REDUX_STATUS.IDLE,
  isProgressInitDone: false,
  currentPathwayIndex: -1,
  initialSteps: [],
  loadedSteps: [],
  progressBody: {
    initialPhaseId: null,
    startStepNumber: 0,
    steps: [],
  },
};

export const getPathwayProgress = createAsyncThunk('pathway/getProgressData', async ({ pathwayId }: { pathwayId: string }, thunkAPI) => {
  const state = thunkAPI.getState() as CombinedType;
  if (state.algorithm.session) {
    const res = new Promise((resolve) => {
      resolve(state.algorithm.session);
    });
    return await res;
  } else {
    const res = await UserApi.getPathwayProgress(pathwayId);
    return res?.data;
  }
});

export const getPathwayProgressToSetIds = createAsyncThunk(
  'pathway/getPathwayProgressSetIds',
  async ({ pathwayId }: { pathwayId?: string }, thunkAPI) => {
    const state = thunkAPI.getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;
    let res;

    try {
      if (state.algorithm.session) {
        const sessionId = pathwayId ?? state.algorithm?.session?.id ? state.algorithm.session.id.toString() : '';

        res = (await SessionAPI.getSession(institutionId, sessionId, state.workspace.contentLanguage))?.data;
      } else {
        res = (await UserApi.getPathwayProgress(pathwayId ?? (state?.algorithm?.fullInfo?.id ? state.algorithm.fullInfo.id : '')))?.data;
      }

      if (res && Array.isArray(res.steps)) {
        thunkAPI.dispatch(
          setProgressIdsToPath({
            progress: res.steps.map((step) => ({
              id: step.id,
              createdDate: step.createdDate,
              ...(step.subPath && {
                subPath: Object.keys(step.subPath).map((key) => ({
                  key,
                  path: step.subPath?.[Number(key)].map((sp) => ({ id: sp.id, createdDate: sp.createdDate })),
                })),
              }),
            })),
          }),
        );
      }
    } catch (e) {
      console.error(e);
    }

    return res;
  },
);

export const putPathwayProgress = createAsyncThunk<
  unknown,
  {
    pathwayId: string;
    nextElementId: string | null;
    phaseId: string;
    elementIndex: number;
    subPathIndex?: number;
    removeDefaultSkipped?: boolean;
    ignoreStepId?: boolean;
  }
>(
  'pathway/putPathwayProgress',
  async ({ pathwayId, nextElementId, phaseId, elementIndex, subPathIndex, removeDefaultSkipped, ignoreStepId }, thunkAPI) => {
    const state = thunkAPI.getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;

    if (state.algorithm.path) {
      let index = 0;
      let steps;
      let parentIndex = 0;
      let parentStep;
      let firstElementOfSubPath;

      const alertId = getLastAlertId(state.algorithm.session?.pathwayImpacts);
      const pathElement = state.algorithm.path[Number(elementIndex)];

      if (subPathIndex !== undefined) {
        const parentElement = pathElement as PathwayGateway;

        const { subPaths, subPathSelected } = parentElement;
        firstElementOfSubPath = subPathSelected;
        const subPath = subPaths && subPathSelected ? subPaths[String(subPathSelected)] : null;

        if (subPath) {
          index = subPathIndex <= subPath.startStepNumber ? subPathIndex : subPath.startStepNumber;
          steps = pathToSteps(subPath.path.slice(index, subPathIndex + 1), removeDefaultSkipped);
          parentIndex =
            elementIndex <= state.progress.progressBody.startStepNumber ? elementIndex : state.progress.progressBody.startStepNumber;
          parentStep = pathToSteps(state.algorithm.path.slice(parentIndex, parentIndex + 1), removeDefaultSkipped);
        } else {
          throw 'error';
        }
      } else {
        index = elementIndex <= state.progress.progressBody.startStepNumber ? elementIndex : state.progress.progressBody.startStepNumber;
        steps = pathToSteps(state.algorithm.path.slice(index, elementIndex + 1), removeDefaultSkipped);
      }

      if (!steps.length) {
        return {};
      }

      let sessionProgressResponse = undefined;
      try {
        if (state.algorithm.session) {
          // HAS SUB PATH INDEX
          if (subPathIndex !== undefined) {
            const pathwayGateway = state.algorithm.path[Number(elementIndex)] as PathwayGateway;
            let subElement;

            if (pathwayGateway.subPathSelected && pathwayGateway.subPaths?.[pathwayGateway.subPathSelected]) {
              subElement = pathwayGateway.subPaths[pathwayGateway.subPathSelected].path[Number(subPathIndex)];
            }

            // PATHWAY_ELEMENT_TYPES.ACTION
            if (steps[0] && subElement?.type === PATHWAY_ELEMENT_TYPES.ACTION) {
              const stepId = subElement?.progressId;

              // HAS STEP ID
              const { skipped: subElementSkipped } = pathwayActionStatusByItems(subElement);
              if (stepId) {
                sessionProgressResponse = await SessionAPI.putSessionStepsItems(
                  institutionId,
                  state.algorithm.session.id,
                  stepId ? stepId : '-1',
                  {
                    actionItems: pathwayActionActionItemsProgress(subElement),
                    alertId,
                    communicationSkipped: subElement.communicationSkipped,
                    elementId: subElement.id,
                    elementType: subElement.type,
                    itemsIds: pathwayActionSelectedItemIds(subElement),
                    logActivity: subElement.logActivity,
                    previousStatus: subElement.previousStatus,
                    skipped: subElementSkipped,
                    status: subElement.status,
                    statusReason: subElement.statusReason,
                  },
                );
              }

              // DOES NOT HAVE STEP ID
              if (!stepId) {
                if (pathwayGateway.progressId) {
                  sessionProgressResponse = await SessionAPI.putSessionStepsItems(
                    institutionId,
                    state.algorithm.session.id,
                    pathwayGateway.progressId ? pathwayGateway.progressId : '-1',
                    {
                      actionItems: pathwayActionActionItemsProgress(subElement),
                      alertId,
                      elementId: pathwayGateway.id,
                      elementType: pathwayGateway.type,
                      optionsIds: parentStep[0].optionsIds,
                      logActivity: subElement.logActivity,
                      previousStatus: subElement.previousStatus,
                      skipped: subElementSkipped,
                      status: subElement.status,
                      statusReason: subElement.statusReason,
                    },
                  );
                }

                sessionProgressResponse = await SessionAPI.putSessionProgress(institutionId, state.algorithm.session.id, {
                  ...state.progress.progressBody,
                  alertId,
                  firstElementOfSubPath,
                  initialPhaseId: state.algorithm.path.find((ele) => ele.phaseId)?.phaseId ?? '',
                  nextElementId,
                  parentStepNumber: elementIndex,
                  phaseId,
                  startStepNumber: index,
                  steps,
                });
              }
              //  EVERY OTHER TYPE EXCEPT ACTION
            } else {
              if (pathwayGateway.progressId) {
                sessionProgressResponse = await SessionAPI.putSessionStepsItems(
                  institutionId,
                  state.algorithm.session.id,
                  pathwayGateway.progressId ? pathwayGateway.progressId : '-1',
                  {
                    alertId,
                    elementId: pathwayGateway.id,
                    elementType: pathwayGateway.type,
                    skipped: false,
                    optionsIds: parentStep[0].optionsIds,
                  },
                );
              }

              sessionProgressResponse = await SessionAPI.putSessionProgress(institutionId, state.algorithm.session.id, {
                ...state.progress.progressBody,
                alertId,
                phaseId,
                nextElementId,
                steps,
                initialPhaseId: state.algorithm.path.find((ele) => ele.phaseId)?.phaseId ?? '',
                startStepNumber: index,
                parentStepNumber: elementIndex,
                firstElementOfSubPath,
              });
            }
            thunkAPI.dispatch(setGatewaySubpathStartStepNumber({ startStepNumber: index + steps.length, parentIndex: elementIndex }));
            thunkAPI.dispatch(getPathwayProgressToSetIds({}));

            // DOES NOT HAVE SUB PATH INDEX
          } else {
            const stepId = state.algorithm.path[Number(elementIndex)]?.progressId;

            // PATHWAY_ELEMENT_TYPES.ACTION
            if (steps[0] && state.algorithm.path[Number(elementIndex)].type === PATHWAY_ELEMENT_TYPES.ACTION) {
              if (stepId && !ignoreStepId) {
                const pathwayAction = state.algorithm.path[Number(elementIndex)] as PathwayAction;

                const { skipped } = pathwayActionStatusByItems(pathwayAction);

                sessionProgressResponse = await SessionAPI.putSessionStepsItems(institutionId, state.algorithm.session.id, stepId ?? '-1', {
                  actionItems: pathwayActionActionItemsProgress(pathwayAction),
                  alertId,
                  communicationSkipped: pathwayAction.communicationSkipped,
                  elementId: pathwayAction.id,
                  elementType: pathwayAction.type,
                  itemsIds: pathwayActionSelectedItemIds(pathwayAction),
                  logActivity: pathwayAction.logActivity,
                  previousStatus: pathwayAction.previousStatus,
                  skipped,
                  status: pathwayAction.status,
                  statusReason: pathwayAction.statusReason,
                });
              } else {
                sessionProgressResponse = await SessionAPI.putSessionProgress(institutionId, state.algorithm.session.id, {
                  ...state.progress.progressBody,
                  alertId,
                  phaseId,
                  nextElementId,
                  steps,
                  initialPhaseId: state.algorithm.path.find((ele) => ele.phaseId)?.phaseId ?? '',
                  startStepNumber: index,
                });
                thunkAPI.dispatch(getPathwayProgressToSetIds({}));
              }

              //  EVERY OTHER SCENARIO
            } else {
              const pathwayElement = state.algorithm.path[Number(elementIndex)];

              // PATHWAY_ELEMENT_TYPES.GATEWAY EXCEPT PATHWAY_GATEWAY_TYPES.EXCLUSIVE
              if (
                pathwayElement.progressId &&
                pathwayElement.type === PATHWAY_ELEMENT_TYPES.GATEWAY &&
                pathwayElement.subtype !== PATHWAY_GATEWAY_TYPES.EXCLUSIVE
              ) {
                sessionProgressResponse = await SessionAPI.putSessionStepsItems(
                  institutionId,
                  state.algorithm.session.id,
                  pathwayElement.progressId,
                  {
                    alertId,
                    elementId: pathwayElement.id,
                    elementType: pathwayElement.type,
                    skipped: false,
                    optionsIds: steps[0].optionsIds,
                  },
                );
                //  ALL OTHER TYPES & SUBTYPES
              } else {
                sessionProgressResponse = await SessionAPI.putSessionProgress(institutionId, state.algorithm.session.id, {
                  ...state.progress.progressBody,
                  alertId,
                  initialPhaseId: state.algorithm.path.find((ele) => ele.phaseId)?.phaseId ?? '',
                  nextElementId,
                  phaseId,
                  startStepNumber: index,
                  steps,
                });
                thunkAPI.dispatch(getPathwayProgressToSetIds({}));
              }
            }
          }
        } else {
          if (subPathIndex !== undefined) {
            const step = state.algorithm.path[Number(elementIndex)];
            if (step.progressId) {
              await UserApi.putStepPathwayProgress(pathwayId, step.progressId, {
                elementId: step.id,
                elementType: step.type,
                skipped: false,
                optionsIds: parentStep[0].optionsIds,
              });
            }
            await UserApi.putPathwayProgress(pathwayId, {
              ...state.progress.progressBody,
              firstElementOfSubPath,
              initialPhaseId: state.algorithm.path.find((ele) => ele.phaseId)?.phaseId ?? '',
              nextElementId,
              parentStepNumber: elementIndex,
              phaseId,
              startStepNumber: index,
              steps,
            });

            thunkAPI.dispatch(
              setGatewaySubpathStartStepNumber({
                startStepNumber: index + steps.length,
                parentIndex: elementIndex,
                subPathSelected: firstElementOfSubPath,
              }),
            );
            thunkAPI.dispatch(getPathwayProgressToSetIds({}));
          } else {
            const step = state.algorithm.path[Number(elementIndex)];
            if (step.progressId && step.type === PATHWAY_ELEMENT_TYPES.GATEWAY && step.subtype !== PATHWAY_GATEWAY_TYPES.EXCLUSIVE) {
              await UserApi.putStepPathwayProgress(pathwayId, step.progressId, {
                elementId: step.id,
                elementType: step.type,
                skipped: false,
                optionsIds: steps[0].optionsIds,
              });
            } else {
              await UserApi.putPathwayProgress(pathwayId, {
                ...state.progress.progressBody,
                initialPhaseId: state.algorithm.path.find((ele) => ele.phaseId)?.phaseId ?? '',
                nextElementId,
                phaseId,
                startStepNumber: index,
                steps,
              });
              thunkAPI.dispatch(getPathwayProgressToSetIds({}));
            }
          }
        }
        return {
          startStepNumber: subPathIndex !== undefined ? undefined : index + steps.length,
          data: sessionProgressResponse?.data,
          elementIndex,
        };
      } catch (error) {
        return {};
      }
    }
  },
);

const progressSlice = createSlice({
  name: 'progressSlice',
  initialState,
  reducers: {
    restartProgressStore: () => initialState,
    removeInitialStep: (state) => {
      state.initialSteps = state.initialSteps.slice(1);
    },
    setIsProgressInitDone: (state, action: PayloadAction<number | undefined>) => {
      const startStepNumber = action.payload;
      state.isProgressInitDone = true;
      if (startStepNumber !== undefined) {
        state.progressBody.startStepNumber = startStepNumber;
      }
    },
    setIsProgressValid: (state) => {
      state.isProgressValid = false;
    },
    restartAlgorithm: (state) => {
      state.progressBody.startStepNumber = 0;
    },
  },
  extraReducers: {
    [getPathwayProgress.pending.toString()]: (state) => {
      state.fetchStatus = REDUX_STATUS.LOADING;
    },
    [getPathwayProgress.fulfilled.toString()]: (state, action) => {
      state.fetchStatus = REDUX_STATUS.SUCCEEDED;
      state.progressBody.version = action.payload.version;
      state.progressBody.initialPhaseId = action.payload.initialPhaseId;
      state.progressBody.phaseId = action.payload.phaseId;
      state.progressBody.nextElementId = action.payload.nextElementId;
      state.initialSteps = action.payload.steps ?? [];
      state.loadedSteps = action.payload.steps ?? [];
      state.progressBody.startStepNumber = (state.initialSteps ?? [])?.length;
    },
    [getPathwayProgress.rejected.toString()]: (state) => {
      state.fetchStatus = REDUX_STATUS.FAILED;
      state.isProgressValid = false;
      state.isProgressInitDone = true;
    },
    [putPathwayProgress.fulfilled.toString()]: (state, action: PayloadAction<{ startStepNumber?: number }>) => {
      const { startStepNumber } = action.payload;
      if (startStepNumber !== undefined) {
        state.progressBody.startStepNumber = startStepNumber;
        state.progressBody.steps = [];
      }
    },
    [putPathwayProgress.rejected.toString()]: (state) => {
      state.isProgressValid = false;
    },
    resetSession: () => initialState,
  },
});

export const { removeInitialStep, setIsProgressInitDone, setIsProgressValid, restartProgressStore, restartAlgorithm } =
  progressSlice.actions;

export default progressSlice.reducer;
