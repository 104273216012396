type ComputePatientTagName = (patientFullName: string, full?: boolean) => string;

export const computePatientTagName: ComputePatientTagName = (patientFullName, full) => {
  const namePieces = patientFullName.split(' ').filter((onlyValid) => onlyValid);

  const name = namePieces[0];
  if (namePieces.length > 1) {
    const surname = namePieces.slice(-1)[0];
    return surname.length > 1 ? `${name} ${full ? surname : surname[0] + '.'}` : name;
  }

  return name;
};
