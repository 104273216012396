import { styled } from '@uphillhealth/theme';

export const InputContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const InputBaseStyled = styled('div', {
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
});

export const InputLabel = styled('label', {
  position: 'absolute',
  top: 'calc($4 + 1px)',
  left: '$3',
  padding: '0 $1',
  color: '$neutral700',
  fontSize: '$small',
  transition: '175ms ease-in-out',
});

const InputLabelMinimized = {
  fontSize: '$extraSmall',
  transform: 'translateY(calc(-$space$2 - 1px))',
};

export const InputStyled = styled('input', {
  padding: 'calc($6) $4 $2',
  width: '100%',
  height: 'max-content',
  color: '$neutral900',
  fontSize: '$small',
  lineHeight: '$fontSizes$base',
  backgroundColor: '$neutral100',
  border: '1px solid $neutral300',
  borderRadius: '$medium',
  outline: 'none',
  transition: '175ms ease-in-out',
  '&:disabled': {
    backgroundColor: '$neutral200',
    cursor: 'not-allowed',
    [`& + ${InputLabel}`]: { cursor: 'not-allowed' },
  },
  '&:focus': {
    borderColor: '$primary500',
    '&::placeholder': {
      color: '$neutral500',
    },
    [`& + ${InputLabel}`]: { ...InputLabelMinimized, color: '$primary500' },
  },
  '&:not(:placeholder-shown)': {
    [`& + ${InputLabel}`]: { ...InputLabelMinimized },
  },
  '&::placeholder': {
    color: 'transparent',
    transition: '175ms ease-in-out',
  },
  variants: {
    hasError: {
      true: {
        [`& + ${InputLabel}`]: { color: '$error500' },
        borderColor: '$error500',
      },
    },
  },
});
