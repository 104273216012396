import cookie from 'js-cookie';

import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/auth';
import { LOCAL_KEY_WORKSPACE } from '../constants/workspaces';

const DataStorage = {
  setAccessToken: (accessToken: string) => {
    if (cookie) {
      cookie.set(ACCESS_TOKEN, accessToken, { domain: `${process.env.COOKIE_DOMAIN}` });
    }
  },
  getAccessToken: () => {
    if (cookie) {
      const token = cookie.get(ACCESS_TOKEN);
      if (token) {
        return token;
      }
    }
    return null;
  },
  getRefreshToken: () => {
    if (cookie) {
      return cookie.get(REFRESH_TOKEN);
    }
    return null;
  },
  deleteAccessToken: (): void => {
    if (cookie) {
      cookie.remove(ACCESS_TOKEN, { domain: `${process.env.COOKIE_DOMAIN}` });
    }

    if (typeof window !== 'undefined' && window.sessionStorage) {
      window.sessionStorage.removeItem(ACCESS_TOKEN);
    }
  },
  deleteRefreshToken: (): void => {
    if (cookie) {
      cookie.remove(REFRESH_TOKEN, { domain: `${process.env.COOKIE_DOMAIN}` });
    }
  },
  setUser: (user: any) => {
    sessionStorage.setItem('user', JSON.stringify(user));
  },
  getUser: () => {
    let user;
    try {
      user = sessionStorage.getItem('user');
    } catch (e) {
      user = null;
    }
    return user ? JSON.parse(user) : null;
  },
  clearUser: () => {
    sessionStorage.removeItem('user');
  },
  setFormLanguage: (lang: string) => {
    cookie.set('i18n-form', lang, { domain: `${process.env.COOKIE_DOMAIN}`, expires: 10000 });
  },
  getFormLanguage: () => {
    return cookie.get('i18n-form');
  },
  setUserLanguage: (lang: string) => {
    cookie.set('i18n', lang.slice(0, 2), { domain: `${process.env.COOKIE_DOMAIN}`, expires: 10000 });
  },
  getUserLanguage: () => {
    return cookie.get('i18n');
  },
  setIsTester: (isTester: string) => {
    cookie.set('isTester', isTester);
  },
  getIsTester: () => {
    return cookie.get('isTester');
  },
  getKeyHelpViewed: (): boolean => {
    if (localStorage) {
      return localStorage.getItem('key-help-viewed') == 'true';
    }
    return false;
  },
  setKeyHelpViewed: (keyHelpViewed: boolean) => {
    if (localStorage) {
      localStorage.setItem('key-help-viewed', keyHelpViewed.toString());
    }
  },
  getPathwayMobileAppViewed: (): boolean => {
    if (localStorage) {
      return localStorage.getItem('pathwayMobileApp') == 'true';
    }
    return false;
  },
  setPathwayMobileAppViewed: (pathwayMobileApp: boolean) => {
    if (localStorage) {
      localStorage.setItem('pathwayMobileApp', pathwayMobileApp.toString());
    }
  },
  addToRecentSearches: (search: string) => {
    if (localStorage) {
      let recentSearch = JSON.parse(localStorage.getItem('recentSearch') || '[]');
      recentSearch = recentSearch.filter((s: string) => s != search).splice(0, 4);
      recentSearch.unshift(search);
      localStorage.setItem('recentSearch', JSON.stringify(recentSearch));
    }
  },
  getRecentSearch: () => {
    if (typeof localStorage != 'undefined') {
      return JSON.parse(localStorage.getItem('recentSearch') || 'null');
    }
    return false;
  },
  setActiveWorkspaceId: (value: string) => {
    return cookie.set(LOCAL_KEY_WORKSPACE, value);
  },
  getActiveWorkspaceId: () => {
    return cookie.get(LOCAL_KEY_WORKSPACE);
  },
  getPathwayPatientSessionTooltip: () => {
    return cookie.get('showPathwayPatientSessionToolip');
  },
  setPathwayPatientSessionTooltip: () => {
    return cookie.set('showPathwayPatientSessionToolip', 'false');
  },
  getPathwayPatientSessionCommunicationBlockTooltip: () => {
    return cookie.get('showPathwayPatientSessionCommunicationBlockTooltip');
  },
  setPathwayPatientSessionCommunicationBlockTooltip: () => {
    return cookie.set('showPathwayPatientSessionCommunicationBlockTooltip', 'false');
  },
};

export default DataStorage;
