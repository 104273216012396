import { styled } from '@/src/theme';

export const MarkdownStyled = styled('div', {
  '> .react-markdown': {
    '> * ': {
      color: '$neutral800',
    },
    'p, li': {
      fontSize: '$small',
      color: '$neutral700',
      fontWeight: 'normal',
      lineHeight: '$fontSizes$extraLarge',
    },
    a: {
      display: 'inline',
      color: '$neutral500',
      fontWeight: 500,
      '&:hover': {
        color: '$primary500',
      },
    },
    'ol, ul': {
      marginLeft: '$4',
    },
    ul: {
      listStyle: 'initial',
    },
    img: {
      maxWidth: '100%',
    },
  },
});
