import { getToken } from '@/helpers/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const uphillApi = createApi({
  reducerPath: 'uphillApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_TEST_API,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
