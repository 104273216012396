import { createContext, useContext } from 'react';
// self
import { ApplicationContextValues } from './types';

const voidFunction = () => {
  return;
};

export const initialContextValue: ApplicationContextValues = {
  currentUserCareTeams: [],
  currentUserHasPermission: () => false,
  publicInstitutions: [],
  resolveAndSetCurrentUser: voidFunction,
  setCurrentInstitution: voidFunction,
  unsetCurrentUser: voidFunction,
};

export const ApplicationContext = createContext<ApplicationContextValues>(initialContextValue);

export const useApplicationContext = (): ApplicationContextValues => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error("useApplicationContext can only be called inside ApplicationContextProvider's instance");
  }
  return context;
};
