import { Service } from '@uphillhealth/react-service-provider';

export class ExamsService extends Service {
  useGetExamUrl() {
    return this.useMutation<string[], undefined>({
      method: 'GET',
      url: '/[institutionId]/exams/[examId]/url',
    });
  }
}
