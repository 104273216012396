import { AxiosResponse } from 'axios';
import moment from 'moment';
import { ReminderDTO } from '@/models/Reminder';
import { deleteRequest, getRequest, postRequest, putRequest } from './index';
// app
import { InstitutionId } from '@/src/models';

export interface ReminderSessionCreationProps {
  date: string;
  description: string;
  institutionId: InstitutionId;
  patientSessionId: string;
  reminderElements: {
    elementId?: string;
    elementStepNumber?: number;
    firstElementOfSubPath?: string;
    parentStepNumber?: number;
  }[];
}

export const RemindersAPI = {
  getReminders: ({
    institutionId,
    fromDate,
    toDate,
    completed = false,
  }: {
    institutionId: InstitutionId;
    fromDate: string;
    toDate: string;
    completed?: boolean;
  }): Promise<AxiosResponse<ReminderDTO[]> | undefined> => {
    return getRequest<ReminderDTO[]>(
      `${institutionId}/reminders?fromDate=` +
        moment(fromDate).format('YYYY-MM-DD') +
        '&toDate=' +
        moment(toDate).format('YYYY-MM-DD') +
        '&completed=' +
        completed,
    );
  },
  createReminder: (body: ReminderSessionCreationProps) => {
    const { institutionId, ...payload } = body;
    return postRequest(`${institutionId}/reminders`, { ...payload });
  },
  putReminder: ({
    completed,
    date,
    description,
    id,
    institutionId,
  }: {
    institutionId: InstitutionId;
    id: number | string | null;
    description: string;
    date: string;
    completed: boolean;
  }) => {
    return putRequest(`${institutionId}/reminders/${id}`, { description, date, completed });
  },
  deleteReminder: (institutionId: InstitutionId, id: number | string) => {
    return deleteRequest(`${institutionId}/reminders/${id}`);
  },
  putReminderCompleted: (institutionId: InstitutionId, id: number | string) => {
    return putRequest(`${institutionId}/reminders/${id}/completed`, {});
  },
};
