import React, { ReactElement, ReactNode } from 'react';
import { StitchesProps } from '@uphillhealth/theme';
// self
import { ScrollAreaStyled } from './styles';

interface ScrollAreaVerticalProps extends StitchesProps {
  children: ReactNode | ReactNode[];
  maxHeight?: string;
}

export const ScrollAreaVertical = ({ children, css = {}, maxHeight = '100vh' }: ScrollAreaVerticalProps): ReactElement => {
  return (
    <ScrollAreaStyled css={{ ...css, maxHeight }} data-testid="ScrollArea" orientation="vertical">
      {children}
    </ScrollAreaStyled>
  );
};
