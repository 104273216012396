import React, { ReactElement } from 'react';
import Image from 'next/image';
import { translate } from '@uphillhealth/i18n';
import { Anchor } from '@/uphillhealth/components';
// app
import { useApplicationContext } from '@/src/providers';
// self
import { ContentContainer, InstitutionLogo } from './styles';
import { ErrorContainer, Label, LoggedMessage } from '../partials';

export const BrokenLink = (): ReactElement => {
  const { currentInstitution, currentUser } = useApplicationContext();

  const institutionImage = currentInstitution?.mainImageUrl || currentInstitution?.imageUrl || '/images/png/no-results.png';

  return (
    <ErrorContainer>
      <ContentContainer>
        <InstitutionLogo>
          <Image
            src={institutionImage}
            alt={currentInstitution?.name ?? 'Institution'}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </InstitutionLogo>

        <Label>{translate('NoContent')}</Label>

        <Anchor css={{ marginTop: '$4' }} fit href={`/${currentInstitution?.domain}`}>
          {translate('GoBack')}
        </Anchor>

        <LoggedMessage currentUser={currentUser} hideAnotherLogin={true} />
      </ContentContainer>
    </ErrorContainer>
  );
};

export default BrokenLink;
