import { styled } from '@/src/theme';

export const ContentContainer = styled('div', {
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  '@large': {
    width: '22vw',
  },
});

export const InstitutionLogo = styled('div', {
  margin: '0 auto',
  position: 'relative',
  width: 150,
  height: 150,
  '> img': {
    width: '100%',
    mixBlendMode: 'multiply',
  },
});
