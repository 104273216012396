import { styled } from '@uphillhealth/theme';

export const TextAreaStyled = styled('textarea', {
  padding: '$4 $3',
  width: '100%',
  color: '$neutral900',
  fontSize: '$small',
  lineHeight: '$fontSizes$base',
  backgroundColor: '$neutral100',
  border: '1px solid $neutral300',
  borderRadius: '$medium',
  outline: 'none',
  resize: 'none',
  '&::placeholder': {
    color: '$neutral700',
  },
  variants: {
    hasError: {
      true: {
        borderColor: '$error500',
      },
    },
  },
});
