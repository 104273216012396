import { styled } from '@uphillhealth/theme';
import * as DropdownPrimitives from '@radix-ui/react-dropdown-menu';

export const TriggerIcon = styled('span', {
  marginLeft: '$2',
  color: '$neutral500',
  fontSize: '$base',
  '> i': {
    transition: 'transform 175ms',
    lineHeight: 0,
  },
});

export const DropdownSelectTriggerStyled = styled(DropdownPrimitives.Trigger, {
  padding: '$4',
  width: '100%',
  maxHeight: 50,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '$neutral900',
  fontSize: '$small',
  lineHeight: '$fontSizes$base',
  textAlign: 'left',
  backgroundColor: '$neutral100',
  border: '1px solid $neutral300',
  borderRadius: '$medium',
  '&[data-state="open"]': {
    [`> ${TriggerIcon} > i`]: {
      transform: 'rotate(180deg)',
    },
  },
});
