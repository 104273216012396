var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"uphill-2-web@3.0.5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable @typescript-eslint/no-var-requires */
const Sentry = require('@sentry/nextjs');
const { Replay } = require('@sentry/browser');
const { Integrations } = require('@sentry/tracing');
const packageJson = require('./package.json');

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const ignoreErrors = [/otBannerSdk.js$/, /reading '(cssRules|document|removeChild)'/];
const denyUrls = ['https://cdn.cookielaw.org', 'https://cdn.segment.com', /otBannerSdk.js$/, /extensions\//i, /^chrome:\/\//i];

const initializeSentry = () => {
  if (['development', 'production', 'staging'].includes(process.env.BUILD_ENV)) {
    Sentry.init({
      environment: process.env.ENV,
      denyUrls,
      dsn: SENTRY_DSN || 'https://6f3edf9723114575b0bb8fa183583473@o1055486.ingest.sentry.io/6045377',
      ignoreErrors,
      integrations: [new Integrations.BrowserTracing(), new Replay()],
      normalizeDepth: 10,
      release: `${packageJson.name}@${packageJson.version}`,
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      tracesSampleRate: 1.0,
    });
  }
};

initializeSentry();

module.exports = initializeSentry;
