import { styled } from '@uphillhealth/theme';
import { ButtonStyled } from '@/uphillhealth/components';
import * as DropdownPrimitives from '@radix-ui/react-dropdown-menu';

export const DropdownTriggerButton = styled(DropdownPrimitives.Trigger, ButtonStyled);

export const DropdownTriggerIcon = styled(DropdownPrimitives.Trigger, {
  color: '$neutral600',
  fontSize: '$large',
  '&:hover, &:focus': {
    color: '$primary200',
  },
  '&[data-state=open]': {
    color: '$primary500',
  },
});

const DropdownItemBaseStyle = {
  padding: '$3 $4',
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  color: '$neutral900',
  fontSize: '$small',
  '&:hover': {
    backgroundColor: '$neutral200',
  },
  variants: {
    critical: {
      true: {
        color: '$error500',
      },
    },
    active: {
      true: {
        color: '$primary500',
        backgroundColor: '$neutral200',
      },
    },
  },
};

export const DropdownItemStyled = styled(DropdownPrimitives.Item, DropdownItemBaseStyle);

export const DropdownSeparator = styled(DropdownPrimitives.Separator, {
  height: 1,
  backgroundColor: '$neutral300',
});

export const DropdownLabel = styled(DropdownPrimitives.Label, {
  padding: '$3 $4 $1',
  color: '$neutral600',
  fontSize: '$small',
});
