import { AxiosResponse, CancelTokenSource } from 'axios';
import { NextPageContext } from 'next';
import { getRequest } from './index';
import { InstitutionId } from '@/src/models';

export const getInstitutionById = async (
  institutionId: InstitutionId,
  cancelToken?: CancelTokenSource,
  ctx?: NextPageContext,
): Promise<AxiosResponse | undefined> => getRequest('institutions/' + institutionId, cancelToken, ctx);

export const getInstitutionByDomain = async (
  domain: string,
  cancelToken?: CancelTokenSource,
  ctx?: NextPageContext,
): Promise<AxiosResponse | undefined> => getRequest('institutions/domain/' + domain, cancelToken, ctx);

export const getInstitutions = async (cancelToken?: CancelTokenSource, ctx?: NextPageContext): Promise<AxiosResponse | undefined> =>
  getRequest('institutions', cancelToken, ctx);
