import React, { ReactElement, ReactNode } from 'react';
import * as SelectPrimitives from '@radix-ui/react-select';

interface SelectProps extends SelectPrimitives.SelectProps {
  children: ReactNode | ReactNode[];
}

export const Select = ({ children, ...selectProps }: SelectProps): ReactElement => {
  return (
    <SelectPrimitives.Root {...selectProps} data-testid="Select">
      {children}
    </SelectPrimitives.Root>
  );
};
