import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { translate } from '@uphillhealth/i18n';
import { Anchor } from '@/uphillhealth/components';
// app
import { Institution } from '@/src/models';
import { useApplicationContext } from '@/src/providers';
// self
import { ContentContainer, InstitutionLogo } from './styles';
import { ErrorContainer, Label, LoggedMessage } from '../partials';

export const ExclusiveContent = (): ReactElement => {
  const { currentInstitution, currentUser } = useApplicationContext();
  const router = useRouter();

  const institutionImage = currentInstitution?.mainImageUrl || currentInstitution?.imageUrl || '/images/png/no-results.png';

  return (
    <ErrorContainer>
      <ContentContainer>
        <InstitutionLogo>
          <Image
            src={institutionImage}
            alt={currentInstitution?.name ?? 'Institution'}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </InstitutionLogo>

        <Label>{translate('ExclusiveContent0')}</Label>
        {Boolean(currentInstitution?.name) && (
          <Label>
            {translate('ExclusiveContent1')}
            {` ${(currentInstitution as Institution).name}. `}
          </Label>
        )}
        <Label>{translate('ExclusiveContent2')}</Label>

        <Anchor css={{ marginTop: '$4' }} fit href={`/${router.query.workspace}`}>
          {translate('GoBack')}
        </Anchor>

        <LoggedMessage currentUser={currentUser} />
      </ContentContainer>
    </ErrorContainer>
  );
};
