import { styled } from '@uphillhealth/theme';
import * as SwitchPrimitives from '@radix-ui/react-switch';

export const SwitchRoot = styled(SwitchPrimitives.Root, {
  all: 'unset',
  position: 'relative',
  minWidth: 32,
  width: 32,
  height: 18,
  backgroundColor: '$neutral400',
  borderRadius: '$large',
  '&:disabled, [data-disabled]': {
    backgroundColor: '$neutral200',
    cursor: 'not-allowed',
  },
  '&[data-state="checked"]': { backgroundColor: '$primary500' },
  '&[data-state="checked"]:disabled, &[data-state="checked"][data-disabled]': {
    backgroundColor: '$primary400',
    cursor: 'not-allowed',
  },
});

export const SwitchThumb = styled(SwitchPrimitives.Thumb, {
  display: 'block',
  width: 14,
  height: 14,
  backgroundColor: '$neutral100',
  borderRadius: '$full',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1)',
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&:disabled, [data-disabled]': {
    backgroundColor: '$neutral300',
    cursor: 'not-allowed',
  },
  '&[data-state="checked"]': { transform: 'translateX(16px)' },
});
