import React, { ReactElement, ReactNode, useMemo } from 'react';
import { Text } from '@/src/theme/commons';
// self
import { AlertStyle } from './styles';

export enum AlertType {
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export interface AlertProps {
  type: AlertType;
  children: ReactNode | ReactNode[];
}

const iconDictionary: Record<AlertType, string> = {
  [AlertType.error]: 'icon-error_outline',
  [AlertType.info]: 'icon-info',
  [AlertType.warning]: 'icon-warning_amber',
};

export const Alert = ({ children, type }: AlertProps): ReactElement => {
  const iconClass = useMemo<string>(() => {
    return iconDictionary[String(type) as AlertType];
  }, [type]);

  return (
    <AlertStyle type={type} data-testid="Alert">
      <i className={iconClass} />
      <Text>{children}</Text>
    </AlertStyle>
  );
};
