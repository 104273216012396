import * as TooltipPrimitives from '@radix-ui/react-tooltip';
import { styled } from '@uphillhealth/theme';

export const TooltipTrigger = styled(TooltipPrimitives.TooltipTrigger, {
  margin: 'inherit',
  padding: 'inherit',
  minWidth: 'inherit',
  maxWidth: 'inherit',
  color: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textAlign: 'inherit',
  textOverflow: 'inherit',
  overflow: 'inherit',
  whiteSpace: 'inherit',
  '&:focus': {
    outline: '2px solid $primary500',
    borderRadius: '$small',
  },
});

export const TooltipContent = styled(TooltipPrimitives.TooltipContent, {
  padding: '$2 $3',
  maxWidth: 250,
  color: '$neutral100',
  fontSize: '$small',
  backgroundColor: '$neutral900',
  borderRadius: '$small',
  zIndex: 19,
});

export const TooltipArrow = styled(TooltipPrimitives.TooltipArrow, {
  zIndex: 19,
  fill: '$neutral900',
});
