import PathwayCalculator from '../models/PathwayCalculator.js';
import PathwayCalculatorQuestion from '../models/PathwayCalculatorQuestion.js';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mathjs = require('../node_modules/mathjs/lib/browser/math.js');
mathjs.import({ ln: mathjs.log }, {});

const parseScore = (score: number | string) => {
  return typeof score === 'string' ? parseFloat(score) : score;
};

export const nthIndex = (str: string, pat: string, n: number) => {
  const L = str.length;
  let i = -1;
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i);
    if (i < 0) break;
  }
  return i;
};

const validateFormula = (firstValue: number, secondValue: number, operation: string) => {
  switch (operation) {
    case 'LARGER':
    case '>': {
      return firstValue > secondValue;
    }
    case 'LARGER_EQUAL':
    case '>=': {
      return firstValue >= secondValue;
    }
    case 'EQUAL':
    case '=': {
      return firstValue === secondValue;
    }
    case 'SMALLER_EQUAL':
    case '<=': {
      return firstValue <= secondValue;
    }
    case 'SMALLER':
    case '<': {
      return firstValue < secondValue;
    }
    default:
      return false;
  }
};

const getResultFromFormula = (formula: string, questions: PathwayCalculatorQuestion[]) => {
  let workedFormula = formula;
  while (workedFormula.includes('#')) {
    const answerToken = workedFormula.substring(workedFormula.indexOf('#'), nthIndex(workedFormula, '#', 2) + 1);
    const questionId = answerToken.replace(/#/g, '');
    const findQuestion = questions.find((q) => q.id.toString() == questionId);
    const answerScore = findQuestion ? findQuestion.answerScore : 0;
    if (answerScore !== null) {
      workedFormula = workedFormula.replace(answerToken, answerScore.toString());
    } else {
      break;
    }
  }
  try {
    const sanitizedFormula = workedFormula.replace(/[,]/g, '.').replace(/[.]00$/, '');
    return mathjs.evaluate(sanitizedFormula).toFixed(2);
  } catch (error) {
    return 'error';
  }
};

export const updateQuestionChoices = (questionId: number, answerId: number, currCalculator: PathwayCalculator) => {
  const questions = currCalculator.questions.map((q) => {
    if (q.id === questionId) {
      const answers = q.answers.map((a) => ({
        ...a,
        isSelected: a.id === answerId,
      }));
      const answerScore = answers.find((a) => a.isSelected)?.score || 0;
      return {
        ...q,
        answerScore,
        answers,
      };
    }
    return q;
  });
  return {
    ...currCalculator,
    questions: validateVisibleQuestions(questions),
  };
};

export const updateQuestionText = (questionId: number, value: number | string, currCalculator: PathwayCalculator) => {
  const questions = currCalculator.questions.map((q) => {
    if (q.id === questionId) {
      return {
        ...q,
        answerScore: value,
      };
    }
    return q;
  });
  return {
    ...currCalculator,
    questions: validateVisibleQuestions(questions),
  };
};

export const calculateScore = (calculator: PathwayCalculator): { result: string | number; units?: string } | undefined => {
  const isDisabled = !calculator.questions.reduce(
    (acc, q) => acc && ((q.answerScore != null && q.answerScore !== '') || !!q.isHidden),
    true,
  );
  if (isDisabled) {
    return undefined;
  }
  const { formulas, questions } = calculator;
  let result;
  let units;
  if (formulas) {
    const findFormula = formulas.find((formula) => {
      return formula.conditions.reduce((acc: boolean, condition) => {
        const findQuestion = questions.find((q) => q.id.toString() === condition.questionId);
        if (!findQuestion) {
          return acc;
        }
        const answerScore = parseScore(findQuestion.answerScore);
        return acc && validateFormula(answerScore, condition.score, condition.conditionOperator);
      }, true);
    });
    if (findFormula?.math) {
      result = getResultFromFormula(findFormula.math, questions);
      units = findFormula.units;
    } else {
      result = questions.reduce((acc, q) => acc + parseScore(q.answerScore), 0);
    }
    result = isNaN(result) ? 'error' : result;
  } else {
    result = questions.reduce((acc, q) => acc + parseScore(q.answerScore), 0);
  }
  return { result, units };
};

export const validateVisibleQuestions = (questions: PathwayCalculatorQuestion[]): PathwayCalculatorQuestion[] => {
  return questions
    .map((question) => {
      if (!question.conditions) {
        return { ...question, isHidden: false };
      }
      return {
        ...question,
        isHidden: !question.conditions.reduce((acc, condition) => {
          const findQuestion = questions.find((q) => q.id.toString() === condition.questionId);
          if (!findQuestion) {
            return acc;
          }
          const answerScore =
            typeof findQuestion.answerScore === 'string' ? parseFloat(findQuestion.answerScore) : findQuestion.answerScore;
          return acc && validateFormula(answerScore, condition.score, condition.conditionOperator);
        }, true),
      };
    })
    .sort((q1, q2) => (q1.position < q2.position ? -1 : 1));
};

export const scoreToText = ({ result, units }: { result: number | string; units?: string }): string => {
  let resultLabel;
  if (Number(result) >= 0) {
    if (result === 'error') {
      resultLabel = 'Not possible to calculate';
    } else {
      resultLabel = result + ' ' + (units ? units : 'points');
    }
  }
  return resultLabel ?? '';
};
