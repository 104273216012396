import React from 'react';
import { v4 } from 'uuid';

export const HillyAvatar = () => {
  const filter0 = v4();
  const filter1 = v4();
  const filter2 = v4();
  const filter3 = v4();
  const filter4 = v4();
  const filter5 = v4();
  const filter6 = v4();
  const filter7 = v4();
  const filter8 = v4();
  const filter9 = v4();
  const filter10 = v4();
  const filter11 = v4();

  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={filter0} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_1_9)" />
      </mask>
      <g mask={`url(#${filter0})`}>
        <circle cx="16" cy="16" r="16" fill="url(#paint1_linear_1_9)" />
        <path
          d="M3.19565 19.7845C2.95641 21.3308 2.8049 23.0704 2.80238 25C8 32 14.0338 32 16.0169 32C18 32 24 32 29.2315 25C29.1927 22.7351 28.9328 20.7318 28.5607 18.9957C27.361 13.3983 21.7759 10.7059 16.0514 10.7059C9.96609 10.7059 4.12605 13.7707 3.19565 19.7845Z"
          fill="white"
        />
        <path
          d="M27.871 17.0325C22.7166 17.0325 21.3962 19.2583 15.4839 19.2583C9.57149 19.2583 8.49044 17.0325 4.12902 17.0325C4.12902 12.4909 7.08195 4.19536 16 4.19582C25.4257 4.19627 27.871 12.4909 27.871 17.0325Z"
          fill={`url(#${filter1})`}
        />
        <ellipse cx="12.64" cy="12.6372" rx="1.28" ry="0.32" fill={`url(#${filter2})`} fillOpacity="0.2" />
        <ellipse cx="19.2" cy="12.6372" rx="1.28" ry="0.32" fill={`url(#${filter3})`} fillOpacity="0.2" />
        <path
          d="M14.88 13.3814C14.88 12.9971 14.9335 13.7988 16 13.7988C17.0666 13.7988 17.12 12.9971 17.12 13.3814C17.12 13.7656 16.6186 14.0771 16 14.0771C15.3815 14.0771 14.88 13.7656 14.88 13.3814Z"
          fill={`url(#${filter4})`}
        />
        <path
          d="M14.72 12.0531C14.72 12.4374 14.6666 11.6357 13.6 11.6357C12.5335 11.6357 12.48 12.4374 12.48 12.0531C12.48 11.6689 12.9815 11.3574 13.6 11.3574C14.2186 11.3574 14.72 11.6689 14.72 12.0531Z"
          fill={`url(#${filter5})`}
        />
        <path
          d="M19.52 12.0531C19.52 12.4374 19.4666 11.6357 18.4 11.6357C17.3335 11.6357 17.28 12.4374 17.28 12.0531C17.28 11.6689 17.7815 11.3574 18.4 11.3574C19.0186 11.3574 19.52 11.6689 19.52 12.0531Z"
          fill={`url(#${filter6})`}
        />
        <g filter={`url(#${filter7})`}>
          <circle cx="16" cy="25.2906" r="4.12903" fill="url(#paint8_radial_1_9)" />
        </g>
        <g filter={`url(#${filter8})`}>
          <circle cx="16" cy="25.2906" r="3.09677" fill="white" />
        </g>
        <path
          d="M14.2831 26.839C14.3435 26.839 14.3955 26.8293 14.4393 26.81C14.4831 26.7907 14.5191 26.7643 14.5475 26.7306C14.5758 26.6969 14.5968 26.6577 14.6104 26.6128C14.624 26.5679 14.6308 26.5195 14.6308 26.4676V26.0857H14.4498V26.4676C14.4498 26.4949 14.447 26.5218 14.4415 26.5481C14.436 26.5744 14.4266 26.5977 14.4134 26.618C14.4001 26.6384 14.3828 26.6547 14.3615 26.667C14.3401 26.6792 14.314 26.6854 14.2831 26.6854C14.2522 26.6854 14.2263 26.6794 14.2053 26.6675C14.1843 26.6556 14.1672 26.6394 14.154 26.6191C14.1408 26.5987 14.1312 26.5756 14.1253 26.5496C14.1194 26.5237 14.1165 26.4963 14.1165 26.4676V26.0857H13.9355V26.4676C13.9355 26.5167 13.9419 26.5637 13.9548 26.6086C13.9677 26.6535 13.9879 26.6929 14.0155 26.7269C14.0431 26.7609 14.0789 26.7881 14.1231 26.8085C14.1672 26.8288 14.2206 26.839 14.2831 26.839ZM14.995 26.8327V26.5917H15.1539C15.1914 26.5917 15.2257 26.5847 15.2566 26.5707C15.2875 26.5567 15.3138 26.5377 15.3355 26.5139C15.3572 26.49 15.3741 26.463 15.3862 26.4329C15.3984 26.4027 15.4044 26.3712 15.4044 26.3382C15.4044 26.3073 15.398 26.2768 15.3851 26.2467C15.3723 26.2165 15.3546 26.1895 15.3322 26.1656C15.3097 26.1418 15.2829 26.1225 15.2516 26.1078C15.2203 26.0931 15.1859 26.0857 15.1484 26.0857H14.814V26.8327H14.995ZM15.1429 26.4413H14.995V26.2372H15.1363C15.1466 26.2372 15.1569 26.2391 15.1672 26.243C15.1775 26.2468 15.1867 26.253 15.1948 26.2614C15.2028 26.2698 15.2093 26.2803 15.2141 26.2929C15.2188 26.3056 15.2212 26.3207 15.2212 26.3382C15.2212 26.3719 15.2135 26.3975 15.1981 26.415C15.1826 26.4325 15.1642 26.4413 15.1429 26.4413ZM15.7256 26.8327V26.5265H16.038V26.8327H16.219V26.0857H16.038V26.375H15.7256V26.0857H15.5446V26.8327H15.7256ZM16.5931 26.8327V26.0857H16.4121V26.8327H16.5931ZM17.3469 26.8327V26.6812H16.9672V26.0857H16.7862V26.8327H17.3469ZM18.0356 26.8327V26.6812H17.6559V26.0857H17.4749V26.8327H18.0356Z"
          fill="#021F59"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7033 23.8102L18.0072 25.206C18.0887 25.2933 18.0825 25.4287 17.9935 25.5085C17.9532 25.5447 17.9006 25.5647 17.846 25.5647H15.2456C15.1249 25.5647 15.027 25.4689 15.027 25.3506C15.027 25.2971 15.0475 25.2455 15.0844 25.206L16.3882 23.8102C16.4679 23.7249 16.603 23.7191 16.69 23.7971C16.6946 23.8012 16.6991 23.8056 16.7033 23.8102Z"
          fill={`url(#${filter9})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0486 25.1416L15.3004 23.8262C15.3992 23.7225 15.5651 23.7168 15.671 23.8136C15.777 23.9103 15.7828 24.0729 15.684 24.1767L14.4322 25.492C14.3334 25.5958 14.1675 25.6015 14.0616 25.5047C13.9557 25.4079 13.9499 25.2454 14.0486 25.1416Z"
          fill={`url(#${filter10})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2942 23.8262C15.3929 23.7225 15.5588 23.7168 15.6648 23.8136C15.7707 23.9103 15.7765 24.0729 15.6777 24.1767C15.5789 24.2805 15.413 24.2861 15.3071 24.1894C15.2012 24.0926 15.1954 23.93 15.2942 23.8262Z"
          fill={`url(#${filter11})`}
        />
      </g>
      <defs>
        <filter
          id={filter7}
          x="7.87096"
          y="17.1615"
          width="16.2581"
          height="16.2581"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.745833 0 0 0 0 0.887037 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_9" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_9" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.182396 0 0 0 0 0.689349 0 0 0 0 0.858333 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1_9" />
        </filter>
        <filter
          id={filter8}
          x="8.90321"
          y="18.1938"
          width="14.1935"
          height="14.1935"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.178333 0 0 0 0 0.709969 0 0 0 0 0.891667 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_9" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_9" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.772549 0 0 0 0 0.858824 0 0 0 0 0.941176 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1_9" />
        </filter>
        <linearGradient id="paint0_linear_1_9" x1="31.7267" y1="16.198" x2="0.248222" y2="16.198" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2534B7" />
          <stop offset="1" stopColor="#7CB8E4" />
        </linearGradient>
        <linearGradient id="paint1_linear_1_9" x1="14.2156" y1="-0.892193" x2="15.0483" y2="32.7138" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2534B7" />
          <stop offset="1" stopColor="#7CB8E4" stopOpacity="0" />
        </linearGradient>
        <linearGradient id={filter1} x1="4.50164" y1="14.2362" x2="28.2912" y2="8.28878" gradientUnits="userSpaceOnUse">
          <stop stopColor="#42597B" />
          <stop offset="0.1213" stopColor="#242E3E" />
          <stop offset="0.559507" stopColor="#384353" />
          <stop offset="0.756189" stopColor="#18191A" />
          <stop offset="0.913551" stopColor="#779FDB" />
          <stop offset="0.994836" stopColor="#434343" />
        </linearGradient>
        <radialGradient
          id={filter2}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.64 12.6372) rotate(90) scale(0.32 1.28)"
        >
          <stop stopColor="#5FB3E6" />
          <stop offset="1" stopColor="#53ABE2" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id={filter3}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.2 12.6372) rotate(90) scale(0.32 1.28)"
        >
          <stop stopColor="#5FB3E6" />
          <stop offset="1" stopColor="#53ABE2" stopOpacity="0" />
        </radialGradient>
        <linearGradient id={filter4} x1="14.88" y1="13.5205" x2="17.12" y2="13.5205" gradientUnits="userSpaceOnUse">
          <stop stopColor="#399AD6" />
          <stop offset="0.510417" stopColor="#97D7FF" />
          <stop offset="1" stopColor="#399AD6" />
        </linearGradient>
        <linearGradient id={filter5} x1="14.72" y1="11.914" x2="12.48" y2="11.914" gradientUnits="userSpaceOnUse">
          <stop stopColor="#399AD6" />
          <stop offset="0.510417" stopColor="#97D7FF" />
          <stop offset="1" stopColor="#399AD6" />
        </linearGradient>
        <linearGradient id={filter6} x1="19.52" y1="11.914" x2="17.28" y2="11.914" gradientUnits="userSpaceOnUse">
          <stop stopColor="#399AD6" />
          <stop offset="0.510417" stopColor="#97D7FF" />
          <stop offset="1" stopColor="#399AD6" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_1_9"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 25.2906) rotate(90) scale(4.12903)"
        >
          <stop stopColor="#C2F0FF" />
          <stop offset="1" stopColor="#7EDEFD" />
        </radialGradient>
        <linearGradient id={filter9} x1="15.0482" y1="24.6633" x2="18.0397" y2="24.6633" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5760E8" />
          <stop offset="0.999604" stopColor="#2D9ADD" />
        </linearGradient>
        <linearGradient id={filter10} x1="14.8656" y1="25.5701" x2="14.8656" y2="23.7708" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2534B7" />
          <stop offset="1" stopColor="#2C63B0" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id={filter11} x1="15.4857" y1="24.2574" x2="15.4857" y2="23.7521" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2638B7" />
          <stop offset="0.999604" stopColor="#003678" />
        </linearGradient>
      </defs>
    </svg>
  );
};
