import { Service } from '@uphillhealth/react-service-provider';
import { UseGetPathwayPhasesResponse } from './types';

export class ValuesListService extends Service {
  useGetPathwayPhases() {
    return this.useQuery<UseGetPathwayPhasesResponse[]>({
      headers: { 'Accept-Language': 'null' },
      method: 'GET',
      url: '/lov/institutions/[institutionId]/pathway-phases',
    });
  }
}
