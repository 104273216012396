import React, { ReactElement, ReactNode, useLayoutEffect, useRef } from 'react';

interface PreserveInitialHeightProps {
  children: ReactNode | ReactNode[];
}

export const PreserveInitialHeight = ({ children }: PreserveInitialHeightProps): ReactElement => {
  const referenceContainer = useRef<HTMLDivElement>(null);

  /* istanbul ignore next */
  useLayoutEffect(() => {
    setTimeout(() => {
      if (referenceContainer.current) {
        referenceContainer.current.style.minHeight = `${referenceContainer.current.offsetHeight}px`;
      }
    }, 75);
  }, []);

  return (
    <div ref={referenceContainer} data-testid="PreserveInitialHeight">
      {children}
    </div>
  );
};
