import {
  InstitutionId,
  JourneyDirectoryAPI,
  JourneyMoment,
  PatientData,
  PaginatedContent,
  PathwayId,
  ProgressPayload,
  AnyRouteElementProgress,
  ProgressElementStep,
  SortDirection,
  JourneyStatus,
} from '@/src/models';

export type PutJourneyBody = {
  categorySystem: string;
  categoryCode?: string;
};

export interface PostJourneyBody {
  id?: string;
  careTeamId?: number;
  category?: string;
  institutionId: InstitutionId;
  moments?: JourneyMoment[];
  patient: PatientData;
  patientAcceptsDataProcessing: boolean;
  pathwayId: PathwayId;
  pathwayVersion?: number;
  progresses: ProgressPayload[];
  routePackageId: string;
}

export interface PutJourneyOwnerBody {
  owner: string;
}

export type usePutJourneyProgressBody = {
  steps: ProgressElementStep[];
  pathPrefix?: string;
};

export interface usePutJourneyProgressResponse {
  sessionCanBeFinished?: boolean;
  carePlanStatus: JourneyStatus;
  currentStep: null | string;
  interactionDto?: {
    channelUrl: string;
    hcpSessionToken: string;
    hcpSendBirdUserId: string;
  } | null;
  modifiedAlerts: {
    alertId: string;
    alertSolved: boolean;
    communicationSuspended: boolean;
  }[];
  steps: Record<string, AnyRouteElementProgress>;
  preventAutomaticJourneyCompletion?: boolean;
  lastEncounterIdUpdated?: string;
}

export enum UseGetJourneysParamSortField {
  lastModifiedDate = 'LAST_MODIFIED_DATE',
  oldestAlertDate = 'OLDEST_ALERT_DATE',
  patientName = 'PATIENT_NAME',
  phase = 'PHASE',
}

export interface UseGetJourneysParams {
  categories?: string[];
  categoryIds: string[];
  invalidNumber?: boolean;
  journeyOwners: string[];
  hilly?: boolean;
  missingAnswers?: boolean;
  page: number;
  patient?: string;
  pathwaysIds: string[];
  phasesIds: string[];
  sizePerPage: number;
  sortDirection?: SortDirection;
  sortField?: UseGetJourneysParamSortField;
  teamPatients?: boolean;
  status: string[];
}

export interface UseGetJourneysFhirParams {
  categories?: string[];
  categoryIds: string[];
  invalidNumber?: boolean;
  journeyOwners: string[];
  hilly?: boolean;
  missingAnswers?: boolean;
  page: number;
  patient?: string;
  pathwaysIds: string[];
  phasesIds: string[];
  routePackageId?: string[];
  sizePerPage: number;
  sortDirection?: SortDirection;
  sortField?: UseGetJourneysParamSortField;
  teamPatients?: boolean;
  status: string[];
}

export interface UseGetJourneysByPhasesParams {
  tab?: string;
  institutionId?: number;
  page: number;
  routePackageId?: string;
  sizePerPage: number;
  sortDirection?: SortDirection;
  sortField?: UseGetJourneysParamSortField;
  context?: string;
  subContextCodes?: string;
  momentIds?: string;
  phasesIds?: string;
  categoryIds?: string;
  status?: string;
  pathwaysIds?: string;
  careTeamIds?: string;
  teamPatients?: boolean;
  hilly?: boolean;
  invalidNumber?: boolean;
  missingAnswers?: boolean;
  snoozed?: boolean;
  patient?: string;
  communicationState?: string;
}

export type useGetJourneysResponse = PaginatedContent<JourneyDirectoryAPI[]>;
