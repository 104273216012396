import { Service } from '@uphillhealth/react-service-provider';
// self
import { UseGetElementReponse, UseGetPatientSessionsResponse } from './types';
import { PathwayRoutePackage } from '@/src/models';
import { Context } from '@/src/models/context/Context';

export class PathwayService extends Service {
  useGetPathwayElement() {
    return this.useQuery<UseGetElementReponse>(
      {
        method: 'GET',
        url: `/pathways/v2/[pathwayId]/element/[elementId]?version=[version]`,
      },
      {
        cacheTime: 1000 * 60 * 1000,
      },
    );
  }

  useGetJourneysFilter(routePackageId?: string) {
    return this.useQuery<UseGetPatientSessionsResponse[]>(
      {
        headers: {
          'Accept-Language': 'null',
        },
        method: 'GET',
        params: { institutionId: this.getProp('institutionId'), routePackageId },
        url: '/pathways/patient-sessions',
      },
      {
        queryKey: [routePackageId],
      },
    );
  }

  useGetJourneysFilterByPackage() {
    return this.useMutation<UseGetPatientSessionsResponse[], { params: { routePackageId: string; institutionId: string } }>({
      headers: {
        'Accept-Language': 'null',
      },
      method: 'GET',
      url: '/pathways/patient-sessions',
    });
  }

  useGetInstitutionPackages() {
    return this.useQuery({
      method: 'GET',
      url: '/institutions/[institutionId]/route-packages',
    });
  }

  useGetInstitutionPackagesQueryIds(routePackageIds: string[]) {
    const flattenPackageIds = routePackageIds.join(',');

    return this.useQuery<PathwayRoutePackage[]>(
      {
        method: 'GET',
        params: { routePackageIds: flattenPackageIds },
        url: '/institutions/[institutionId]/route-packages',
      },
      {
        enabled: Boolean(routePackageIds.length),
        queryKey: [flattenPackageIds],
      },
    );
  }

  useGetInstitutionPackageById() {
    return this.useQuery({
      method: 'GET',
      url: '/institutions/[institutionId]/route-packages/[routePackageId]',
    });
  }

  useGetContext() {
    return this.useMutation<Context[], { params: { routePackageId: string; institutionId: string } }>({
      method: 'GET',
      url: 'pathways/contexts',
    });
  }
}
