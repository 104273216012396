import { createStitches } from '@stitches/react';
import { themeTokens } from '@uphillhealth/theme';

const ThemeProvider = createStitches({
  ...themeTokens,
  theme: {
    ...themeTokens.theme,
    colors: {
      ...themeTokens.theme.colors,
      momentsBackground: '#EEEFF3',
    },
    sizes: {
      routeMoments: '220px',
      routeElementMobile: 'calc(100% - $space$8)',
      routeElementFocused: '520px',
      routeElementIdle: 'calc($sizes$routeElementFocused - $space$8)',
      routeVirtualElementMobile: 'calc($sizes$routeElementMobile - $space$8)',
    },
    shadows: {
      ...themeTokens.theme.shadows,
      routeElement: themeTokens.theme.shadows.extraSmall,
      routeElementFocused: '0 0 1px 1px $colors$primary400, 1px 0px 8px $colors$primary200, 0px 1px 4px $colors$primary200',
      routeLatestDone: '1px 0px 8px $colors$success100, 0px 1px 4px $colors$success100',
      routeLatestDoneFocused: '0 0 1px 1px $colors$primary400, 1px 0px 8px $colors$primary200, 0px 1px 4px $colors$success200',
    },
    space: {
      ...themeTokens.theme.space,
      marginRouteElements: '$space$4',
    },
  },
});

export default ThemeProvider;
// to make easier imports
export const css = ThemeProvider.css;
export const globalCss = ThemeProvider.globalCss;
export const keyframes = ThemeProvider.keyframes;
export const styled = ThemeProvider.styled;

export const phasesColor: Record<string, string> = {
  DIAGNOSIS: '#6A9DFF',
  TREATMENT: '#77DEC1',
  TREATMENT_SURG: '#77DEC1',
  TREATMENT_ONCO: '#77DEC1',
  PREVENTION: '#B48EF0',
  FOLLOW_UP: 'hsla(17, 100%, 74%, 1)',
  FU_SO: 'hsla(17, 100%, 74%, 1)',
  FU_SUMC: 'hsla(17, 100%, 74%, 1)',
  FU_SUB: 'hsla(17, 100%, 74%, 1)',
  FU_CSP: 'hsla(17, 100%, 74%, 1)',
  FU_CH: 'hsla(17, 100%, 74%, 1)',
  FU_HD: 'hsla(17, 100%, 74%, 1)',
  FU_SURG: 'hsla(17, 100%, 74%, 1)',
  FU_CHEMO: 'hsla(17, 100%, 74%, 1)',
  FU_RADIO: 'hsla(17, 100%, 74%, 1)',
  REFERENCING: '#6A9DFF',
  VACCINATION: '#6A9DFF',
  CHECK_IN: '#6A9DFF',
  PROCEDURES: '#6A9DFF',
  DISEASE_EDUCATION: '#6A9DFF',
  PROGNOSIS: '#6A9DFF',
  ELIGIBILITY: '#6A9DFF',
  GENERAL_INFORMATION: '#6A9DFF',
  SCIENTIFIC_EVIDENCE: '#6A9DFF',
  MULTIDISCIPLINARY: '#6A9DFF',
  TREATMENT_SU: '#77DEC1',
  TREATMENT_INT: '#77DEC1',
  TREATMENT_HDIA: '#77DEC1',
  FOLLOW_UP_CH: 'hsla(17, 100%, 74%, 1)',
  FOLLOW_UP_CSP: 'hsla(17, 100%, 74%, 1)',
  FOLLOW_UP_URG: 'hsla(17, 100%, 74%, 1)',
  FOLLOW_UP_INT: 'hsla(17, 100%, 74%, 1)',
  FOLLOW_UP_HDIA: 'hsla(17, 100%, 74%, 1)',
  FU_REFUSAL: 'hsla(17, 100%, 74%, 1)',
  RMP_ENF: '#B48EF0',
  RMP_MFR: '#B48EF0',
  RMP_FISIO: '#B48EF0',
  RMP_URO: '#B48EF0',
  RMP_PSICO: '#B48EF0',
  REPORT: '#B48EF0',
  DIAGNOSIS_CSP: '#6A9DFF',
  DIAGNOSIS_CH: '#6A9DFF',
  DIAGNOSIS_SU: '#6A9DFF',
  DIAGNOSIS_INT: '#6A9DFF',
  DIAGNOSIS_HD: '#6A9DFF',
  PIC: '#F3AAED',
  NOT_ELIG: 'hsla(222, 20%, 69%, 1)',
  TREATMENT_CSP: '#77DEC1',
  TREATMENT_CH: '#77DEC1',
};
