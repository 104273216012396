import { styled } from '@uphillhealth/theme';

export const PaginationStyled = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
});

export const PagesContent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

export const BackNextButton = styled('button', {
  border: 'none',
  '&:disabled': {
    'svg > path': {
      fill: '$neutral300',
    },
  },
  'svg > path': {
    fill: '$neutral500',
  },
});
