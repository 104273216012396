import { DAY_NAME_OF_WEEK_LONG } from '@/constants';
import { TranslateMethod } from '@uphillhealth/i18n';

export interface Medication {
  type: 'DCI';
  dciptId: number;
  dciptName: string;
  formaFarm?: {
    id: number;
    name: string;
    dosagem: {
      name: string;
      embalagem: string;
    };
  };
  posology?: MedicationPosology;
  id: string;
  createdDate: Date;
}

export interface MedicationPosology {
  unities: number;
  frequencyId: number;
  frequencyDays: number[];
  interval: string;
  intervalId: number;
  intervalValue: string;
  intervalValueId: number;
}

const getPosology = (posology: MedicationPosology, translate: TranslateMethod, language: string) => {
  if (!posology) {
    return '';
  }

  let result = `${translate('Posology')}: ${posology?.unities} ${translate('Pill')},`;

  switch (posology.frequencyId.toString()) {
    case '1':
      result = `${result} ${posology.intervalValue} ${posology.interval}`;
      break;
    case '2':
      result = `${result} ${translate('at')} ${new Intl.ListFormat(language.split('-')[0], {
        style: 'long',
        type: 'conjunction',
      }).format(posology.frequencyDays.map((day) => translate(DAY_NAME_OF_WEEK_LONG[day - 1])))} `;
      break;
    case '3':
      result = `${result} ${translate('when necessary')}`;
      break;
  }

  return result;
};

export const getMedicationLabel = (medication: Medication, translate: TranslateMethod, language: string) =>
  `${medication.dciptName} - ${medication.formaFarm?.name}, ${medication.formaFarm?.dosagem.name}, ${medication.formaFarm?.dosagem
    .embalagem}, ${medication.posology ? getPosology(medication.posology, translate, language) : ''}`;
