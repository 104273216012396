import { styled } from '@/src/theme';

export const IconContainer = styled('div', {
  position: 'relative',
  padding: 2,
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(90deg, #FBBE75 0%, #D57EEB 100%)',
  borderRadius: '$full',
});

export const CommunicationStatus = styled('img', {
  position: 'absolute',
  top: 18,
  left: -10,
  width: 28,
  height: 28,
  borderRadius: '$full',
  boxShadow: '$extraSmall',
});

export const HillyAvatarContainer = styled('div', {
  width: '100%',
  height: '100%',
});
