import React, { ChangeEvent, FocusEvent, ReactElement, TextareaHTMLAttributes, useCallback, useEffect, useState } from 'react';
import { TextAreaStyled } from './style';

interface TextAreaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onBlur' | 'onChange' | 'onFocus'> {
  autoResize?: boolean;
  hasError?: boolean;
  maxRows?: number;
  minRows?: number;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = ({
  autoResize = true,
  hasError,
  maxRows = 100,
  minRows = 1,
  onBlur,
  onChange,
  onFocus,
  ...props
}: TextAreaProps): ReactElement => {
  const [textAreaRows, setTextAreaRows] = useState({
    rows: minRows,
    minRows,
    maxRows,
  });

  const handleAutoResize = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { minRows, maxRows } = textAreaRows;
      const numberOfTextLines = event.currentTarget.value.split(/\r|\r\n|\n/).length;

      const rows = numberOfTextLines > minRows ? (numberOfTextLines > maxRows ? maxRows : numberOfTextLines) : minRows;
      setTextAreaRows({
        ...textAreaRows,
        rows,
      });
    },
    [textAreaRows],
  );

  useEffect(() => {
    const initialValue = String(props.defaultValue) || String(props.value) || '';
    const numberOfLines = initialValue.split(/\r|\r\n|\n/).length;
    setTextAreaRows({
      ...textAreaRows,
      rows: numberOfLines < maxRows ? numberOfLines : maxRows,
    });
  }, []);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (autoResize) {
        handleAutoResize(event);
      }
      onChange && onChange(event);
    },
    [onChange],
  );

  const handleOnFocus = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      onFocus && onFocus(event);
    },
    [onFocus],
  );

  const handleOnBlur = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      onBlur && onBlur(event);
    },
    [onBlur],
  );

  return (
    <TextAreaStyled
      data-testid="TextArea"
      hasError={hasError}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      rows={textAreaRows.rows}
      {...props}
    />
  );
};
