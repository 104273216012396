export interface RouteCommunicationRecurrenceConfigurationItem {
  interval: string;
  intervalValue: number;
  startInInterval: string;
  startInValue: number;
  totalDuration: number;
}

export enum RouteCommunicationRecurrenceConfigurationType {
  oneTime = 'ONE_TIME',
  recurrent = 'RECURRENT',
}

export interface RouteCommunicationRecurrenceConfigurationOneTime {
  startInInterval: string;
  startInValue: number;
  type: RouteCommunicationRecurrenceConfigurationType.oneTime;
}

export interface RouteCommunicationRecurrenceConfigurationRecurrent {
  recurrence: Record<number, RouteCommunicationRecurrenceConfigurationItem>;
  type: RouteCommunicationRecurrenceConfigurationType.recurrent;
}

export interface RouteCommunicationRecurrenceConfiguration
  extends Omit<RouteCommunicationRecurrenceConfigurationOneTime, 'type'>,
    Omit<RouteCommunicationRecurrenceConfigurationRecurrent, 'type'> {
  type: RouteCommunicationRecurrenceConfigurationType;
}
