import React, { MouseEvent, ReactElement, useCallback } from 'react';
import { LeftContent, RightContent } from '../commons';
// self
import { ButtonStyled } from './styles';
import { ButtonProps } from './types';

export const Button = ({ children, leftContent, onClick, rightContent, type = 'button', ...buttonProps }: ButtonProps): ReactElement => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onClick && onClick(event);
    },
    [onClick],
  );

  return (
    <ButtonStyled {...buttonProps} onClick={handleClick} type={type} data-testid="Button">
      {leftContent && <LeftContent css={{ marginRight: '$3' }}>{leftContent}</LeftContent>}
      {children}
      {rightContent && <RightContent css={{ marginLeft: '$3' }}>{rightContent}</RightContent>}
    </ButtonStyled>
  );
};
