import { REDUX_STATUS } from '../constants';
import { getCorrectGenderByString } from '../helpers/session';
import { patientSessionHelper } from '../services/patientSessionHelper';
import { Gender } from './Common';
import { PatientContent } from './PatientSession';

export interface ReminderDTO {
  patientSessionId: string | number;
  id: number | string;
  description: string;
  date: string;
  completed: boolean;
  patient: PatientContent;
}
export interface Reminder {
  id: number | string;
  description: string;
  date: Date;
  patientSessionId: number | string;
  completed: boolean;
  completedStatus: REDUX_STATUS;
  completedError: string;
  patientBirthDate: Date;
  patientGender?: Gender;
  avatar: string;
}

export const ReminderHelper = {
  fromDto: (reminder: ReminderDTO): Reminder => ({
    id: reminder.id,
    description: reminder.description,
    date: new Date(reminder.date + 'T12:00:00'),
    patientSessionId: reminder.patientSessionId,
    patientBirthDate: new Date(reminder.patient.birthDate),
    completed: reminder.completed,
    completedStatus: REDUX_STATUS.IDLE,
    completedError: '',
    patientGender: getCorrectGenderByString(reminder.patient.gender),
    avatar: patientSessionHelper.getAvatar(
      getCorrectGenderByString(reminder.patient.gender),
      patientSessionHelper.calcAge(reminder.patient.birthDate),
    ),
  }),
};
