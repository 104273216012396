/* eslint-disable @typescript-eslint/no-explicit-any */
import { PATHWAY_ELEMENT_TYPES, PATHWAY_GATEWAY_TYPES } from '@/constants/pathway';
import PathwayAction from '../models/PathwayAction';
import PathwayCalculatorAnswer from '../models/PathwayCalculatorAnswer';
import PathwayCalculatorQuestion from '../models/PathwayCalculatorQuestion';
import { PathwayElement } from '@/models/PathwayElement';
import { ProgressStepsType } from '@/models/PathwayProgress';
import { pathwayActionStatusByItems } from '@/helpers/pathwayStatusesHelper';
import { pathwayActionActionItemsProgress, pathwayActionSelectedItemIds } from '@/helpers/pathwayActionHelper';

export const calculatorAnswerToCalculatorProgress = (
  calculatorAnswer: PathwayCalculatorAnswer,
  question: PathwayCalculatorQuestion,
): { questionId: number; answer: number } => ({
  questionId: question.id,
  answer: calculatorAnswer.id,
});

export const pathToSteps = (path: PathwayElement[], removeDefaultSkipped?: boolean): any =>
  path.reduce((merge: ProgressStepsType[], pathwayElement) => {
    const defaultData = {
      alertId: pathwayElement.alertId,
      elementId: pathwayElement.id,
      elementType: pathwayElement.type,
    };
    // ONLY GATEWAY
    if (pathwayElement?.type === PATHWAY_ELEMENT_TYPES.GATEWAY) {
      merge.forEach((stepItem) => {
        stepItem.skipped = stepItem.skipped !== undefined ? stepItem.skipped : Boolean(removeDefaultSkipped);
      });

      // GATEWAY EXCLUSIVE
      if (pathwayElement?.subtype !== PATHWAY_GATEWAY_TYPES.EXCLUSIVE) {
        if (pathwayElement?.isFinal) {
          return [...merge, defaultData];
        } else {
          const optionsIds = !pathwayElement?.options
            ? []
            : pathwayElement.options.filter((option) => option.completed).map((option) => option.id);

          return [
            ...merge,
            {
              ...defaultData,
              optionsIds,
              skipped: pathwayElement.skipped ? !optionsIds.length : pathwayElement.skipped,
            },
          ];
        }
        //  EVERY OTHER TYPE OF GATEWAY
      } else {
        const options =
          pathwayElement?.maxConditions > 1
            ? pathwayElement?.groups?.reduce((acc: any[], curr) => {
                return [...acc, ...curr?.options?.map((option) => option)];
              }, [])
            : pathwayElement?.options;

        const optionsIds = options ? options?.filter((o) => o.selected).map((o) => o.id) : [];

        return [
          ...merge,
          {
            ...defaultData,
            optionsIds,
            skipped: pathwayElement.skipped ? !optionsIds.length : pathwayElement.skipped,
          },
        ];
      }
    }

    // ONLY CALCULATOR REFERENCE
    if (pathwayElement?.type === PATHWAY_ELEMENT_TYPES.CALCULATOR_REFERENCE) {
      if (pathwayElement.isLocked) {
        const questions = pathwayElement?.questions.reduce((acc: { questionId: number; answer: string | number }[], curr) => {
          if (curr.type === 'MULTIPLE_CHOICE') {
            const selectedAnswer = curr.answers.find((a) => a.isSelected);
            if (selectedAnswer) {
              return [...acc, calculatorAnswerToCalculatorProgress(selectedAnswer, curr)];
            }
          }
          return [...acc, { questionId: curr.id, answer: curr.answerScore }];
        }, []);

        return [
          ...merge,
          {
            ...defaultData,
            questions,
          },
        ];
      } else {
        return merge;
      }
    }

    // ONLY ACTION
    if (pathwayElement.type === PATHWAY_ELEMENT_TYPES.ACTION) {
      const pathwayAction = pathwayElement as PathwayAction;

      const { skipped } = pathwayActionStatusByItems(pathwayAction);

      return [
        ...merge,
        {
          ...defaultData,
          actionItems: pathwayActionActionItemsProgress(pathwayAction),
          communicationSkipped: pathwayAction.communicationSkipped,
          itemsIds: pathwayActionSelectedItemIds(pathwayAction),
          logActivity: pathwayAction.logActivity,
          previousStatus: pathwayAction.previousStatus,
          skipped,
          status: pathwayAction.status,
          statusReason: pathwayAction.statusReason,
        },
      ];
    }

    return [
      ...merge,
      {
        ...defaultData,
        skipped: pathwayElement.skipped !== undefined ? pathwayElement.skipped : Boolean(removeDefaultSkipped),
      },
    ];
  }, []) as any;
