import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REDUX_STATUS } from '../../constants';
import { getInstitutions } from '../../services/api/institutions';
import { publicPageSliceType } from './types';

export const initialState: publicPageSliceType = {
  bannerUrl: null,
  contactEmail: null,
  contactName: null,
  defaultEmail: null,
  description: null,
  domain: null,
  id: null,
  imageUrl: null,
  mainImageUrl: null,
  name: null,
  validated: false,
  website: null,
  navHeaderTitleElement: null,
  status: REDUX_STATUS.IDLE,
};

export const getPublicPage = createAsyncThunk('publicPage/getData', async ({ domain }: { domain: string }, thunkAPI) => {
  try {
    const res = await getInstitutions();
    const inst = res?.data.filter((i: any) => i.domain.toLowerCase() === domain.toLowerCase())[0];
    if (inst) {
      return inst;
    } else {
      throw new Error();
    }
  } catch (err) {
    const error = err as { message: string };
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const publicPageSlice = createSlice({
  name: 'publicPage',
  initialState,
  reducers: {
    clearPublicPage: () => initialState,
    setPublicPageStatus: (state, action) => {
      state.status = action.payload;
    },
    setNavHeaderTitleElement: (state, action) => {
      state.navHeaderTitleElement = action.payload;
    },
    userLogout: () => initialState,
  },
  extraReducers: {
    [getPublicPage.pending.toString()]: (state) => {
      state.status = REDUX_STATUS.LOADING;
    },
    [getPublicPage.fulfilled.toString()]: (state, action) => {
      state.bannerUrl = action.payload.bannerUrl;
      state.contactEmail = action.payload.contactEmail;
      state.contactName = action.payload.contactName;
      state.defaultEmail = action.payload.defaultEmail;
      state.description = action.payload.description;
      state.domain = action.payload.domain;
      state.id = action.payload.id;
      state.imageUrl = action.payload.imageUrl;
      state.mainImageUrl = action.payload.mainImageUrl;
      state.name = action.payload.name;
      state.validated = action.payload.validated;
      state.website = action.payload.website;
      state.status = REDUX_STATUS.SUCCEEDED;
    },
    [getPublicPage.rejected.toString()]: (state) => {
      state.status = REDUX_STATUS.FAILED;
    },
  },
});

export const { setPublicPageStatus, clearPublicPage, setNavHeaderTitleElement } = publicPageSlice.actions;

export default publicPageSlice.reducer;
