import * as DropdownPrimitives from '@radix-ui/react-dropdown-menu';
import { styled } from '@uphillhealth/theme';

export const DropdownDownContentStyled = styled(DropdownPrimitives.DropdownMenuContent, {
  minWidth: 150,
  maxHeight: '65vh',
  backgroundColor: '$neutral100',
  borderRadius: '$medium',
  boxShadow: '$small',
  overflowY: 'auto',
  zIndex: 19,
  '& > *:first-child': {
    borderTopRightRadius: '$medium',
    borderTopLeftRadius: '$medium',
  },
  '& > *:last-child': {
    borderBottomRightRadius: '$medium',
    borderBottomLeftRadius: '$medium',
  },
});
