import React, { Fragment, ReactElement } from 'react';
import { translate } from '@uphillhealth/i18n';
import { CurrentUserAPI } from '@/src/models';
// app
import { Separator } from '../styles';
// self
import { Message } from './styles';
import { FlexContainer } from '@/src/theme/commons';

interface LoggedMessageProps {
  currentUser?: CurrentUserAPI;
  hideAnotherLogin?: boolean;
}

export const LoggedMessage = ({ currentUser, hideAnotherLogin }: LoggedMessageProps): ReactElement => {
  return currentUser ? (
    <Fragment>
      <Separator />
      <Message>
        {translate('YouAreLoggedAs')}
        {` ${(currentUser as CurrentUserAPI).email}`}
        {!hideAnotherLogin && (
          <FlexContainer css={{ marginTop: '$4', flexWrap: 'wrap', gap: '$1', '> *': { whiteSpace: 'nowrap' } }} center>
            <div>{translate('You might need to')}</div>
            <a href={`${process.env.NEXT_PUBLIC_UPID_APP}`}>{translate('login with another email')}</a>
            <div>{translate('or contact administration')}</div>
          </FlexContainer>
        )}
      </Message>
    </Fragment>
  ) : (
    <Fragment />
  );
};
