import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HospitalContextReducerType } from './types';
import { PatientData } from '@/src/models';

export const initialState: HospitalContextReducerType = {
  patient: null,
};

const hospitalContextSlice = createSlice({
  name: 'hospitalContext',
  initialState,
  reducers: {
    addPatientToContext: (state: HospitalContextReducerType, action: PayloadAction<PatientData>) => {
      state.patient = action.payload;
    },
    userLogout: () => initialState,
  },
});

export const { addPatientToContext } = hospitalContextSlice.actions;

export default hospitalContextSlice.reducer;
