import { configureStore, StoreEnhancer } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';

import algorithmSlice from './algorithm/slice';
import { AlgorithmsState } from './algorithm/types';
import authSlice from './auth/slice';
import { AuthReducerType } from './auth/types';
import progressSlice from './progress/slice';
import { ProgressSliceType } from './progress/types';
import publicPageSlice from './publicPage/slice';
import { publicPageSliceType } from './publicPage/types';
import remindersSlice from './reminders/slice';
import { RemindersState } from './reminders/types';
import searchSlice from './search/slice';
import { SearchSliceType } from './search/types';
import userSlice from './user/slice';
import { PropsUserReducerType } from './user/types';
import activeInstitutionSlice from './new/activeInstitution/slice';
import workspaceSlice from './workspaces/slice';
import { PropsWorkspaceReducerType } from './workspaces/types';
import teamSlice from './teams/slice';
import { TeamInitialState } from './teams/types';
import { HospitalContextReducerType } from './hospitalContext/types';
import hospitalContextSlice from './hospitalContext/slice';
import publicInstitutionsSlice from './new/publicInstitutions/slice';
import { ActiveInstitutionReducerType } from './new/activeInstitution/types';
import { PublicInstitutionsReducerType } from './new/publicInstitutions/types';
import modalsSlice from './modals/slice';
import { ModalsReducerType } from './modals/types';
import { uphillApi } from '@/src/services/uphill';

export const storeReducer = {
  auth: authSlice,
  modals: modalsSlice,
  workspace: workspaceSlice,
  user: userSlice,
  search: searchSlice,
  publicPage: publicPageSlice,
  progress: progressSlice,
  algorithm: algorithmSlice,
  reminders: remindersSlice,
  team: teamSlice,
  hospitalContext: hospitalContextSlice,
  activeInstitution: activeInstitutionSlice,
  publicInstitutions: publicInstitutionsSlice,
  [uphillApi.reducerPath]: uphillApi.reducer,
};

export const createSentryEnhancer = (): StoreEnhancer<Record<string, unknown>, CombinedType> => {
  const sentryEnhancer = createReduxEnhancer({
    actionTransformer: (action) => {
      if (action.type === 'user/fetchUser') {
        return {
          ...action,
          email: null,
          name: null,
          fullName: null,
        };
      }
      return action;
    },
    stateTransformer: (state: CombinedType) => {
      return {
        ...state,
        user: {
          ...state.user,
          name: null,
          email: null,
        },
      };
    },
  });
  return sentryEnhancer as StoreEnhancer<Record<string, unknown>, CombinedType>;
};

const store = configureStore({
  reducer: storeReducer,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [createSentryEnhancer()],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(uphillApi.middleware),
});

export interface CombinedType {
  auth: AuthReducerType;
  modals: ModalsReducerType;
  workspace: PropsWorkspaceReducerType;
  user: PropsUserReducerType;
  search: SearchSliceType;
  publicPage: publicPageSliceType;
  progress: ProgressSliceType;
  algorithm: AlgorithmsState;
  reminders: RemindersState;
  team: TeamInitialState;
  hospitalContext: HospitalContextReducerType;
  activeInstitution: ActiveInstitutionReducerType;
  publicInstitutions: PublicInstitutionsReducerType;
}

export { store };
