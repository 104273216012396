import axios, { AxiosError } from 'axios';

import { COMMUNICATION_CHANNEL_TYPES } from '@/constants/communication';
import { PathwayElementPosition } from '@/models/Pathway';
import { CommunicationApi } from '@/services/api/communication';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CombinedType } from '..';

export interface SendCommunicationProps {
  error?: string;
  elementId: string;
  position: PathwayElementPosition;
  communicationId: string;
  patientSessionId: string;
  communicationChannels: COMMUNICATION_CHANNEL_TYPES[];
  scheduledDate?: string;
}

export const fetchCommunicationAnswers = createAsyncThunk(
  'algorithmCommunication/fetchCommunicationAnswers',
  async (
    {
      patientSessionId,
      patientCommunicationId,
      language,
    }: {
      position: PathwayElementPosition;
      patientSessionId: string;
      patientCommunicationId: string;
      language: string;
    },
    { getState },
  ) => {
    const state = getState() as CombinedType;
    const institutionId = state.activeInstitution?.data?.id ?? -1;
    try {
      const response = await CommunicationApi.getCommunicationAnswers(institutionId, patientSessionId, patientCommunicationId, language);

      const userResponses = !response || !Array.isArray(response.data) ? [] : response.data;
      return { userResponses };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError<{ error: string }>;
        if (serverError && serverError.response) {
          return { error: serverError.response.data.error };
        }
      }
      return { error: 'GenericError' };
    }
  },
);
