import React, { ReactElement } from 'react';
import * as AccordionPrimitives from '@radix-ui/react-accordion';
// self
import { AccordionSingleProps } from './types';

/* Implementation from radix primitives.
 * https://www.radix-ui.com/docs/primitives/components/accordion
 */
export const AccordionSingle = ({ children, ...accordionProps }: AccordionSingleProps): ReactElement => {
  return (
    <AccordionPrimitives.Root collapsible type="single" data-testid="Accordion" {...accordionProps}>
      {children}
    </AccordionPrimitives.Root>
  );
};
