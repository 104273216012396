import * as PopoverPrimitives from '@radix-ui/react-popover';
import { keyframes, styled } from '@uphillhealth/theme';
import {
  BaseCloseIcon,
  BaseContent,
  BaseFooter,
  BaseHeader,
  BaseHeaderDescription,
  BaseHeaderTitle,
} from '@/uphillhealth/components/commons';
import { OverlayBaseStyle } from '@/uphillhealth/components/Dialog/styles';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const PopoverContainer = styled(PopoverPrimitives.Content, {
  position: 'relative',
  width: 'fit-content',
  maxWidth: '90vw',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$neutral100',
  borderRadius: '$large',
  boxShadow: '$routeElement',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: 15,
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
  '& > div:first-child': {
    borderTopRightRadius: '$medium',
    borderTopLeftRadius: '$medium',
  },
  '& > div:last-child': {
    borderBottomRightRadius: '$medium',
    borderBottomLeftRadius: '$medium',
  },
  '@medium': {
    maxWidth: '80vw',
  },
  '@large': {
    maxWidth: '550px',
  },
});

export const PopoverOverlay = styled('div', {
  ...OverlayBaseStyle,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 14,
});

export const PopoverClose = styled(PopoverPrimitives.Close, {
  ...BaseCloseIcon,
  position: 'absolute',
  top: '$3',
  right: '$3',
});

export const PopoverHeaderTitle = styled('div', BaseHeaderTitle);

export const PopoverHeaderDescription = styled('div', BaseHeaderDescription);

export const PopoverFooter = styled('div', BaseFooter);

export const PopoverContent = styled('div', BaseContent);

export const PopoverHeader = styled('div', {
  ...BaseHeader,
  [`& + ${PopoverFooter}`]: {
    borderTop: 'none',
  },
});
