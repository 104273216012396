import { styled } from '@uphillhealth/theme';
import { BadgeType } from './types';

export const BadgeStyled = styled('div', {
  padding: '3px 6px',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  gap: '$1',
  color: '$neutral100',
  fontSize: '$small',
  backgroundColor: '$neutral500',
  borderRadius: '$medium',
  variants: {
    type: {
      [BadgeType.critical]: {
        color: '$fireOpal500',
        backgroundColor: '$fireOpal200',
      },
      [BadgeType.warning]: {
        color: '$warning500',
        backgroundColor: '$warning200',
      },
    },
  },
});

export const BadgeIconContainer = styled('div', {
  width: 14,
  display: 'flex',
  alignItems: 'center',
  '> svg': {
    width: '100%',
  },
});
