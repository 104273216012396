import { CancelTokenSource } from 'axios';
import { OptionsObject } from 'notistack';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CATEGORY_ITEM, REDUX_STATUS } from '../../constants';
import { resourceTypeToType } from '../../helpers';
import { addUserBookmark, deleteUserBookmark, getUserBookmarks } from '../../services/api/bookmarks';
import { getUserData } from '../../services/api/user';
import { userLogout } from '../auth/slice';
import { PropsUserReducerType } from './types';
import { analyticsIdentify } from '@/src/helpers/analytics/segment';
import Workspace from '@/models/Workspace';
import { setWorkspacesPermissions } from '@/helpers/workspaces';
import DataStorage from '@/services/storage';
import { CurrentUserAPI, InstitutionId, Institution } from '@/src/models';

export const initialState: PropsUserReducerType = {
  email: null,
  name: null,
  gender: null,
  id: null,
  instituitions: [],
  institutions: [],
  language: '',
  specialityId: null,
  roles: [],
  pic: null,
  bookmarks: {
    status: REDUX_STATUS.IDLE,
    list: [],
  },
  devFlag: false,
  featureFlag: false,
  contextUser: false,
  fullName: null,
  photoPath: null,
  phoneCountry: null,
  phoneNumber: null,
};

export const fetchUserData = createAsyncThunk('user/fetchUser', async (_, thunkAPI) => {
  try {
    const res = await getUserData();
    if (res?.data) {
      analyticsIdentify(res?.data);
    }
    return res?.data;
  } catch (err) {
    const error = err as { message: string };
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const fetchBookmarks = createAsyncThunk(
  'user/fetchBookmarks',
  async ({ institutionId, cancelToken }: { institutionId: InstitutionId; cancelToken: CancelTokenSource }) => {
    const res = await getUserBookmarks(institutionId, cancelToken);
    return res?.data;
  },
);

export const removeBookmark = createAsyncThunk(
  'user/removeBookmark',
  async (
    {
      bookmarkId,
      callbackSnackbar,
      title,
      cancelToken,
      resourceId,
      resourceType,
      institutionId,
      viewDate,
    }: {
      bookmarkId: number;
      callbackSnackbar: (
        message: string,
        name?: string,
        resourceId?: number | string,
        resourceType?: CATEGORY_ITEM,
        viewDate?: string | number,
        options?: OptionsObject | undefined,
      ) => void;
      title?: string;
      cancelToken?: CancelTokenSource;
      resourceId?: number | string;
      resourceType?: CATEGORY_ITEM;
      institutionId: InstitutionId;
      viewDate?: number | string;
    },
    thunkAPI,
  ) => {
    try {
      const res = await deleteUserBookmark(bookmarkId, institutionId, cancelToken);
      callbackSnackbar('BookmarkDeleted', title, resourceId, resourceType, viewDate, { key: resourceId });
      return res?.data;
    } catch (err) {
      const error = err as { message: string };
      callbackSnackbar(error.message);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const addBookmark = createAsyncThunk(
  'user/addBookmark',
  async (
    {
      resource,
      language,
      viewDate,
      cancelToken,
      institutionId,
    }: {
      resource: any;
      language: string;
      viewDate?: number | string;
      cancelToken?: CancelTokenSource;
      institutionId: InstitutionId;
    },
    thunkAPI,
  ) => {
    try {
      const res = await addUserBookmark(
        resource.resourceId,
        resourceTypeToType(resource.resourceType),
        resource.name,
        institutionId,
        cancelToken,
      );
      return { ...resource, ...res?.data, viewDate, language };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: (error as { message: string }).message });
    }
  },
);

const setUserReducer = (state: PropsUserReducerType, action: PayloadAction<CurrentUserAPI>) => {
  if (action.payload.language) {
    DataStorage.setUserLanguage(action.payload.language);
  }

  state.uphillUser = Boolean(action.payload?.uphillUser);
  state.email = action.payload?.email;
  state.name = action.payload?.fullName;
  state.fullName = action.payload?.fullName;
  state.gender = action.payload?.gender;
  state.id = action.payload?.id;
  state.institutions = action.payload?.institutions;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state.instituitions = action.payload?.institutions.map((institution: Institution) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const workspace = action.payload.workspaces.find((w: Workspace) => w.institutionId === institution.id);
    if (!workspace) {
      return institution;
    }
    return {
      ...institution,
      workspace: { ...workspace, permissions: setWorkspacesPermissions(workspace) },
    };
  });
  state.pic = action.payload?.photoPath;
  state.photoPath = action.payload?.photoPath;
  state.language = action.payload?.language;
  state.specialityId = action.payload?.specialityId;
  state.roles = action.payload?.roles;
  state.featureFlag = action.payload.featureFlag ?? false;
  state.devFlag = action.payload.devFlag ?? false;
  state.contextUser = action.payload.contextUser ?? false;
  state.phoneCountry = action.payload?.phoneCountry;
  state.phoneNumber = action.payload?.phoneNumber;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteBookmarks: (state: PropsUserReducerType) => {
      state.bookmarks.list = [];
    },
    restartAlgorithm: () => initialState,
    setCurrentUser: setUserReducer,
  },
  extraReducers: {
    [fetchUserData.fulfilled.toString()]: setUserReducer,
    [fetchBookmarks.pending.toString()]: (state) => {
      state.bookmarks.status = REDUX_STATUS.LOADING;
    },
    [fetchBookmarks.fulfilled.toString()]: (state, action) => {
      state.bookmarks.list = action.payload;
      state.bookmarks.status = REDUX_STATUS.SUCCEEDED;
    },
    [fetchBookmarks.rejected.toString()]: (state) => {
      state.bookmarks.status = REDUX_STATUS.FAILED;
    },
    [removeBookmark.fulfilled.toString()]: (state, action) => {
      state.bookmarks.list = state.bookmarks.list.filter((b) => b.id?.toString() !== action?.meta?.arg?.bookmarkId.toString());
    },
    [removeBookmark.rejected.toString()]: () => {
      return;
    },
    [addBookmark.fulfilled.toString()]: (state, action) => {
      state.bookmarks.list.push({
        name: action.payload.name ?? action.payload.title,
        ...action.payload,
      });
    },
    [addBookmark.rejected.toString()]: () => {
      return;
    },
    [userLogout.toString()]: () => initialState,
  },
});

export const { deleteBookmarks, setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
