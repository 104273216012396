import React, { Fragment, ReactElement, ReactNode } from 'react';
import * as PopoverPrimitives from '@radix-ui/react-popover';
import { StitchesProps } from '@uphillhealth/theme';
// self
import { PopoverClose, PopoverContainer, PopoverOverlay } from './styles';

interface PopoverProps extends StitchesProps, Omit<PopoverPrimitives.PopoverProps, 'children'> {
  children: ReactNode | ReactNode[];
  hideClose?: boolean;
  trigger: ReactNode | ReactNode[];
}

export const Popover = ({ children, css, defaultOpen, hideClose = false, modal, open, trigger }: PopoverProps): ReactElement => {
  return (
    <PopoverPrimitives.Root defaultOpen={defaultOpen} modal={modal} open={open}>
      <PopoverPrimitives.Trigger asChild data-testid="PopoverTrigger">
        {trigger}
      </PopoverPrimitives.Trigger>
      <PopoverPrimitives.Portal>
        <Fragment>
          {modal && <PopoverOverlay />}
          <PopoverContainer collisionPadding={{ right: 16, bottom: 16, left: 16 }} css={css} sideOffset={5} data-testid="Popover">
            {children}
            {!hideClose && (
              <PopoverClose aria-label="Close">
                <i className="icon-close" />
              </PopoverClose>
            )}
          </PopoverContainer>
        </Fragment>
      </PopoverPrimitives.Portal>
    </PopoverPrimitives.Root>
  );
};
