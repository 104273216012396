import { AxiosResponse, CancelTokenSource } from 'axios';
// legacy
import { FHIRResponse } from '@/models/FHIR';
import { PathwayUserProgress, ProgressStepActionItems, ProgressStepLog } from '@/models/PathwayProgress';
import { PatientSession, PatientSessionDTO, PatientSessionGetAPI } from '@/models/PatientSession';
import { deleteRequest, getRequest, postRequest, putRequest } from './index';
import { StepLogActivity } from '@/models';
// app
import { InstitutionId, PatientData } from '@/src/models';
import { SymptomCheckerInfo } from '@/src/models/journey/care/SymptomChecker';

interface SessionCreationProps {
  id?: string;
  institutionId: InstitutionId;
  pathwayId: string;
  category?: string;
  progresses: PathwayUserProgress[];
  pathwayVersion?: number;
  patient: PatientData;
  patientAcceptsDataProcessing: boolean;
  careTeamId?: number;
}

interface SessionGetBodyProps {
  page: number;
  pathwaysIds?: string[];
  patientNameOrId?: string;
  phases?: string[];
  categories?: string[];
  categoryIds?: string[];
  sortDirection?: 'ASC' | 'DESC';
  sortField?: 'PATIENT_NAME' | 'PHASE' | 'LAST_MODIFIED_DATE' | 'ALERTS' | 'OLDEST_ALERT_DATE';
  teamPatients?: boolean;
  hilly?: boolean;
  missingAnswers?: boolean;
  invalidNumber?: boolean;
}

export const SessionAPI = {
  createSession: (body: SessionCreationProps, cancelToken?: CancelTokenSource, token?: string) => {
    return postRequest(
      `${body.institutionId}/patient-sessions`,
      {
        ...body,
      },
      cancelToken,
      token,
    );
  },
  getSession: (institutionId: InstitutionId, sessionId: string, language: string | null, _cancelToken?: CancelTokenSource) => {
    return getRequest<PatientSessionDTO>(`${institutionId}/patient-sessions/${sessionId}`, undefined, undefined, {
      'accept-language': language ?? 'null',
    });
  },
  getSessions: (institutionId: InstitutionId, body: SessionGetBodyProps, cancelToken?: CancelTokenSource) => {
    return getRequest<FHIRResponse<PatientSessionGetAPI>>(
      `${institutionId}/patient-sessions?institutionId=${institutionId}&page=` +
        body.page +
        (body.pathwaysIds?.length ? '&pathwaysIds=' + body.pathwaysIds : '') +
        (body.categories?.length ? '&categories=' + body.categories : '') +
        (body.categoryIds?.length ? '&categoryIds=' + body.categoryIds : '') +
        (body.phases?.length ? '&phasesIds=' + body.phases : '') +
        (body.patientNameOrId?.length ? '&patient=' + body.patientNameOrId : '') +
        (body.sortField ? '&sortField=' + body.sortField : '') +
        (body.sortDirection ? '&sortDirection=' + body.sortDirection : '') +
        (body.teamPatients ? '&teamPatients=' + body.teamPatients : '') +
        (body.hilly ? '&hilly=' + body.hilly : '') +
        (body.missingAnswers ? '&missingAnswers=' + body.missingAnswers : '') +
        (body.invalidNumber ? '&invalidNumber=' + body.invalidNumber : ''),
      cancelToken,
    );
  },
  putSessionProgress: (institutionId: InstitutionId, sessionId: string, body: any, _cancelToken?: CancelTokenSource) => {
    return putRequest<string[]>(`${institutionId}/patient-sessions/${sessionId}/progress`, body);
  },
  putSessionStepsItems: (
    institutionId: InstitutionId,
    sessionId: string,
    stepId: string,
    item: {
      alertId: string;
      elementId: string;
      communicationSkipped?: boolean;
      elementType: string;
      itemsIds?: string[];
      actionItems?: ProgressStepActionItems[];
      logActivity?: StepLogActivity;
      previousStatus?: string;
      skipped: boolean;
      optionsIds?: number[];
      status?: string;
      statusReason?: string;
    },
    _cancelToken?: CancelTokenSource,
  ) => {
    return putRequest<PatientSession>(`${institutionId}/patient-sessions/${sessionId}/progress/steps/${stepId}`, item);
  },
  putSessionSteps: (institutionId: InstitutionId, sessionId: string, stepId: string, _cancelToken?: CancelTokenSource) => {
    return putRequest<string[]>(`${institutionId}/patient-sessions/${sessionId}/progress/steps/${stepId}/completed`, {});
  },
  deleteSessionSteps: (institutionId: InstitutionId, sessionId: string, stepId: string, _cancelToken?: CancelTokenSource) =>
    deleteRequest<PatientSession>(`${institutionId}/patient-sessions/${sessionId}/progress/steps/${stepId}/completed`),
  getPathwaysSession: (institutionId: InstitutionId, _cancelToken?: CancelTokenSource) =>
    getRequest('pathways/patient-sessions?institutionId=' + institutionId, undefined, undefined, { 'accept-language': 'null' }),
  deleteSession: (institutionId: InstitutionId, sessionId: string, _cancelToken?: CancelTokenSource) =>
    deleteRequest<PatientSession>(`${institutionId}/patient-sessions/${sessionId}`),
  putSessionVersion: (institutionId: InstitutionId, sessionId: string, _cancelToken?: CancelTokenSource) =>
    putRequest<null>(`${institutionId}/patient-sessions/${sessionId}/version`, {}),
  loadSessions: (institutionId: InstitutionId, _cancelToken?: CancelTokenSource): Promise<AxiosResponse<any> | undefined> =>
    postRequest('institutions/' + institutionId + '/patient-sessions/load', {}),
  connectToSocket: (): Promise<AxiosResponse<any> | undefined> => getRequest('patient-session-api/websocket'),
  getFileUrl: (fileId: string) => {
    return getRequest(`100/files/${fileId}/url`);
  },
};

export const ValueListAPI = {
  getPathwayPhases: (_eligibleForPatientSession: boolean, _cancelToken?: CancelTokenSource) =>
    getRequest('lov/pathway-phases', _cancelToken, undefined, { 'accept-language': 'null' }),
  getCategories: (_cancelToken?: CancelTokenSource) =>
    getRequest('lov/patient-session-categories', _cancelToken, undefined, { 'accept-language': 'null' }),
};

export const SessionImpactAPI = {
  resolve: (institutionId: InstitutionId, sessionId: string, alertId: string): Promise<AxiosResponse<unknown>> => {
    return putRequest<unknown>(`${institutionId}/patient-sessions/${sessionId}/progress/solve-alert/${alertId}`, {}) as Promise<
      AxiosResponse<unknown>
    >;
  },
};

export const SessionStepLogsAPI = {
  getStepLogs: ({
    institutionId,
    sessionId,
    stepId,
  }: {
    institutionId: InstitutionId;
    sessionId: string;
    stepId: string;
  }): Promise<AxiosResponse<ProgressStepLog[]>> => {
    return getRequest<ProgressStepLog[]>(`${institutionId}/patient-sessions/${sessionId}/steps/${stepId}/logs`) as Promise<
      AxiosResponse<ProgressStepLog[]>
    >;
  },
};

export const SymptomCheckerApi = {
  getInfo: ({
    institutionId,
    patientSessionId,
  }: {
    institutionId: InstitutionId;
    patientSessionId: string;
  }): Promise<AxiosResponse<SymptomCheckerInfo> | undefined> =>
    getRequest(`${institutionId}/patient-sessions/${patientSessionId}/symptom-checker`),
};
