import React, { ReactElement, ReactNode } from 'react';
import { StitchesProps, styled } from '@uphillhealth/theme';

const SvgContainerStyled = styled('div');

interface SvgContainerProps extends StitchesProps {
  children: ReactNode;
  width?: number;
}

export const SvgContainer = ({ children, css = {}, width = 10 }: SvgContainerProps): ReactElement => {
  return <SvgContainerStyled css={{ ...css, width }}>{children}</SvgContainerStyled>;
};
