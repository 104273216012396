import React, { ReactElement, ReactNode, useCallback } from 'react';
import { LeftContent, RightContent } from '@/uphillhealth/components/commons';
import { StitchesProps } from '@uphillhealth/theme';
// self
import { DropdownItemStyled } from './styles';

interface DropdownItemProps extends StitchesProps {
  active?: boolean;
  children: ReactNode | ReactNode[];
  critical?: boolean;
  leftContent?: ReactNode;
  onClick?: (...args: any) => void;
  rightContent?: ReactNode;
}

export const DropdownItem = ({
  active,
  children,
  critical = false,
  css = {},
  leftContent,
  onClick,
  rightContent,
}: DropdownItemProps): ReactElement => {
  const onClickHandler = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <DropdownItemStyled
      active={active}
      critical={critical}
      css={{ ...css, ...(onClick ? { cursor: 'pointer' } : {}) }}
      onClick={onClickHandler}
    >
      <LeftContent>{leftContent}</LeftContent>
      {children}
      <RightContent>{rightContent}</RightContent>
    </DropdownItemStyled>
  );
};
