import React, { memo, ReactElement, useCallback } from 'react';
import { SvgContainer } from '@/uphillhealth/components';
// self
import { BackNextButton, PagesContent, PaginationStyled } from './styles';
import { PaginationProps } from './types';
import { PageSelector } from './partials';
import ArrowLeft from './arrowLeft.svg';
import ArrowRight from './arrowRight.svg';

export const Pagination = memo((props: PaginationProps): ReactElement => {
  const { currentPage, isFirst, isLast, setCurrentPage } = props;

  const nextPageCallback = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage]);

  const previousPageCallback = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage, setCurrentPage]);

  return (
    <PaginationStyled data-testid="Pagination">
      <PagesContent>
        <BackNextButton disabled={isFirst} onClick={previousPageCallback} data-testid="Pagination-previous">
          <SvgContainer width={6}>
            <ArrowLeft />
          </SvgContainer>
        </BackNextButton>
      </PagesContent>
      <PagesContent>
        <PageSelector {...props} />
      </PagesContent>
      <PagesContent>
        <BackNextButton disabled={isLast} onClick={nextPageCallback} data-testid="Pagination-next">
          <SvgContainer width={6}>
            <ArrowRight />
          </SvgContainer>
        </BackNextButton>
      </PagesContent>
    </PaginationStyled>
  );
});
