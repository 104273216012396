export enum PathwayContextCategory {
  'appointment' = 'AMB',
  'dayHospital' = 'SS',
  'emergency' = 'EMER',
  'homeHospitalization' = 'HH',
  'hospitalization' = 'IMP',
  'observation' = 'OBSENC',
  'shortTermHospitalization' = 'ACUTE',
  'virtual' = 'VR',
}
