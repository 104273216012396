import React, { ComponentProps, ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import * as SelectPrimitives from '@radix-ui/react-select';
// self
import { SelectItemStyled } from './styles';

interface SelectItemProps extends SelectPrimitives.SelectItemProps, ComponentProps<typeof SelectItemStyled> {
  children: ReactNode | ReactNode[];
}

export const SelectItem = forwardRef(
  ({ children, ...props }: SelectItemProps, forwardedRef?: ForwardedRef<HTMLDivElement>): ReactElement => {
    return (
      <SelectItemStyled {...props} ref={forwardedRef} data-testid="SelectItem">
        <SelectPrimitives.ItemText>{children}</SelectPrimitives.ItemText>
      </SelectItemStyled>
    );
  },
);
