import { styled } from '@uphillhealth/theme';
import * as AccordionPrimitives from '@radix-ui/react-accordion';

export const AccordionItemStyled = styled(AccordionPrimitives.Item, {});

export const AccordionItemHeader = styled(AccordionPrimitives.AccordionHeader, {
  fontSize: '$body',
});

export const Indicator = styled('div', {
  transition: '175ms',
  display: 'flex',
});

export const AccordionTrigger = styled(AccordionPrimitives.Trigger, {
  padding: '$2 $2',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '$body',
  textAlign: 'left',
  backgroundColor: '$neutral300',
  borderRadius: '$small',
  transition: 'background 75ms',
  '&:focus': {
    backgroundColor: '$neutral400',
  },
  '&[data-state=open]': {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    [` > ${Indicator}`]: {
      transform: 'rotate(180deg)',
    },
  },
});

export const AccordionContent = styled(AccordionPrimitives.Content, {
  padding: '$2 $2',
  backgroundColor: '$neutral200',
  borderBottomRightRadius: '$small',
  borderBottomLeftRadius: '$small',
});
