export enum REDUX_STATUS {
  LOADING = 'loading',
  IDLE = 'idle',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum CATEGORY_ITEM {
  PATHWAY = 'PATHWAY',
  CALCULATOR = 'CALCULATOR',
  EVENT = 'EVENT',
  ARTICLE = 'ARTICLE',
  RESOURCE = 'RESOURCE',
  COURSE = 'COURSE',
  CASE = 'CASE',
  GUIDELINE = 'GUIDELINE',
  COLLECTION = 'COLLECTION',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  EXTERNAL = 'EXTERNAL',
}

export const CATEGORY_TO_PERMISSION = {
  PATHWAY: 'pathways',
  CALCULATOR: 'calculators',
  EVENT: 'events',
  COURSE: 'courses',
  RESOURCE: 'resources',
  ARTICLE: 'articles',
  CASE: 'cases',
  GUIDELINE: 'resources',
  COLLECTION: 'collections',
  VIDEO: 'resources',
  PDF: 'resources',
  EXTERNAL: 'resources',
};

export const COOKIE_TIMER = 'session_timer';

export enum TIMER {
  ALWAYS = 'always',
  '30DAYS' = '30days',
  SESSION = 'session',
}

export const COMMUNICATION_COUNTDOWN_SECONDS = 5;

export const LANGUAGECORRECTLABELS: Record<string, string> = {
  'de-DE': 'Deutsch',
  'en-GB': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'pt-PT': 'Português',
  ca: 'Catalan',
};

export const DAY_NAME_OF_WEEK_LONG = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
