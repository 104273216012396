import {
  CommunicationInteractionState,
  CommunicationOverviewState,
  JourneyStatus,
  JourneyObservations,
  Medication,
  PatientData,
} from '@/src/models';

export enum CategorySystems {
  patientSessionCategory = 'patient-session-category',
}

export interface JourneyDirectoryAPI {
  additionalInformation?: { [key: string]: Medication };
  category: Record<string, string>;
  carePlanStatus: JourneyStatus;
  categories: Record<
    string,
    {
      categorySystem: string;
      categoryCode: string;
      type: string;
      cdrIds: null;
    }
  >;
  categoryId: null | string;
  communicationStatus: CommunicationOverviewState;
  createdDate: string;
  criticalAlerts: number;
  id: number | string;
  interactionStatus: CommunicationInteractionState;
  hasMeasurements: boolean;
  lastExamResultDate: null | string;
  lastModifiedDate: string;
  lastUserResponseDate: string;
  moderateAlerts: number;
  observations: JourneyObservations[];
  patient: PatientData;
  pathwayId: string;
  pathwayVersion: number;
  pathwayTitle: string;
  phaseId: string;
  phaseDescription: string;
  userResponsible?: string;
  patientUnreadMessageCount?: null | number;
  hcpUnreadMessageCount?: null | number;
  lastMessageSentDateTime?: null | number;
  lastMessageSeenDateTime?: null | number;
  hasChatInteraction?: null | boolean;
  revokeReason?: string;
}
