import React, { ReactElement, ReactNode } from 'react';
import { BadgePhaseStyled } from '@/src/components/commons/BadgePhase/styles';
import { StitchesProps } from '@uphillhealth/theme';
import { phasesColor } from '@/src/theme';
import { JourneyStatus } from '@/src/models';

interface BadgePhaseProps extends StitchesProps {
  children: ReactNode | ReactNode[];
  phaseId: string;
  status?: JourneyStatus.revoked | JourneyStatus.completed;
}

export const BadgePhase = ({ children, css, phaseId, status }: BadgePhaseProps): ReactElement => {
  const backgroundColor = Object.prototype.hasOwnProperty.call(phasesColor, phaseId) ? phasesColor[String(phaseId)] : '$neutral400';
  return (
    <BadgePhaseStyled css={{ ...css, backgroundColor }} status={status}>
      {children}
    </BadgePhaseStyled>
  );
};
