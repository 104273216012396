import { Service } from '@uphillhealth/react-service-provider';
// app
import { CommunicationUserResponses, JourneyAPI, JourneyFhirAPI, JourneyId, PaginatedContent, ProgressElementStep } from '@/src/models';
// self
import {
  PostJourneyBody,
  PutJourneyBody,
  PutJourneyOwnerBody,
  UseGetJourneysByPhasesParams,
  UseGetJourneysFhirParams,
  UseGetJourneysParams,
  useGetJourneysResponse,
  usePutJourneyProgressBody,
  usePutJourneyProgressResponse,
} from './types';
import { GetElementStepBody } from '../PathwayService/types';
import {
  UsePatchStepExtraKeysActionBody,
  UsePatchStepExtraKeysBody,
} from '@/src/models/services/PatientSessionService/UsePatchStepExtraKeys';

export class PatientSessionService extends Service {
  usePostJourney() {
    return this.useMutation<JourneyId, PostJourneyBody>({ method: 'POST', url: '/[institutionId]/patient-sessions' });
  }

  putJourney(data: PutJourneyBody) {
    return this.request<void, { patientSessionCategoryDto: PutJourneyBody }>({
      data: { patientSessionCategoryDto: data },
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]',
    });
  }

  useDeleteJourney() {
    return this.useMutation<JourneyAPI, undefined>({ method: 'DELETE', url: '/[institutionId]/patient-sessions/[journeyId]' });
  }

  useGetJourneyCommunicationsResponses() {
    return this.useQuery<CommunicationUserResponses[]>({
      method: 'GET',
      url: '/[institutionId]/patient-sessions/[journeyId]/communications/[communicationId]/responses',
    });
  }

  useGetJourneyCommunicationsResponsesCommId(communicationId?: string) {
    return this.useQuery<CommunicationUserResponses[]>(
      {
        method: 'GET',
        url: `/[institutionId]/patient-sessions/[journeyId]/communications/${communicationId}/responses`,
      },
      { enabled: Boolean(communicationId) },
    );
  }

  useGetJourneyStepExtraKeys() {
    return this.useMutation<GetElementStepBody>({
      method: 'GET',
      url: '/[institutionId]/patient-sessions/[journeyId]/steps/[stepId]/extra-keys',
    });
  }

  usePutJourneyOwner() {
    return this.useMutation<unknown, PutJourneyOwnerBody>({
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]/owner',
    });
  }

  usePutJourneyVersion() {
    return this.useMutation<unknown, undefined>({
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]/version',
    });
  }

  useGetJourneys(params: UseGetJourneysParams) {
    return this.useQuery<useGetJourneysResponse>(
      {
        method: 'GET',
        params: {
          institutionId: this.getProp('institutionId'),
          ...params,
        },
        url: '/[institutionId]/patient-sessions',
      },
      {
        cacheTime: 1000 * 60,
        queryKey: [Object.values(params)],
      },
    );
  }

  putJourneyProgress(data: usePutJourneyProgressBody) {
    return this.request<usePutJourneyProgressResponse, usePutJourneyProgressBody>({
      data,
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]/progress',
    });
  }

  putJourneyProgressStep(data: ProgressElementStep) {
    return this.request<usePutJourneyProgressResponse, ProgressElementStep>({
      data,
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]/progress/steps/[stepId]',
    });
  }

  useCancelJourney() {
    return this.useMutation<null, { revokeReason: string }>({
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]/revoke',
    });
  }

  useCompleteJourney() {
    return this.useMutation<null, undefined>({
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]/finish',
    });
  }

  usePatchStepExtraKeys() {
    return this.useMutation<undefined, UsePatchStepExtraKeysBody[]>({
      method: 'PATCH',
      url: '/[institutionId]/patient-sessions/[journeyId]/steps/[stepId]/extra-keys',
    });
  }

  usePatchStepExtraKeysAction() {
    return this.useMutation<undefined, UsePatchStepExtraKeysActionBody>({
      method: 'PATCH',
      url: '/[institutionId]/patient-sessions/[journeyId]/steps/[stepId]/extra-keys/action',
    });
  }

  usePatchStepShowInReport() {
    return this.useMutation<undefined, { showInReport: boolean }>({
      method: 'PATCH',
      url: '/[institutionId]/patient-sessions/[journeyId]/steps/[stepId]/showInReport',
    });
  }

  useGetJourneysFhir(params: UseGetJourneysFhirParams) {
    const allParams = {
      institutionId: this.getProp('institutionId'),
      ...params,
      phasesIds: params.phasesIds.join(),
    };

    const flattenParams = Object.values(allParams).filter((param) => {
      if (Array.isArray(param) && !param.length) {
        return false;
      }
      return Boolean(param);
    });

    return this.useQuery<JourneyFhirAPI>(
      {
        method: 'GET',
        params: allParams,
        url: '/[institutionId]/patient-sessions/fhir',
      },
      {
        queryKey: flattenParams,
      },
    );
  }

  useGetJourneysPhases() {
    const allParams = {
      institutionId: this.getProp('institutionId'),
    };

    const flattenParams = Object.values(allParams).filter((param) => {
      if (Array.isArray(param) && !param.length) {
        return false;
      }
      return Boolean(param);
    });

    return this.useMutation<Record<string, PaginatedContent<any>>, { params: UseGetJourneysByPhasesParams }, any>(
      {
        method: 'GET',
        params: allParams,
        url: '/[institutionId]/patient-sessions/phases',
      },
      {
        mutationKey: flattenParams,
      },
    );
  }

  useGetJourneysPhasesTotals() {
    const allParams = {
      institutionId: this.getProp('institutionId'),
    };

    const flattenParams = Object.values(allParams).filter((param) => {
      if (Array.isArray(param) && !param.length) {
        return false;
      }
      return Boolean(param);
    });

    return this.useMutation<Record<string, number>, { params: UseGetJourneysByPhasesParams }, any>(
      {
        method: 'GET',
        params: allParams,
        url: '/[institutionId]/patient-sessions/phases/patient-sessions',
      },
      {
        mutationKey: flattenParams,
      },
    );
  }

  patchSnoozeJourney(data: { snoozedUntil: string; reason: string }) {
    return this.request<null, { snoozedUntil: string; reason: string }>({
      data,
      method: 'PATCH',
      url: '/[institutionId]/patient-sessions/[journeyId]/snooze',
    });
  }
  deleteSnoozeJourney() {
    return this.request<null>({
      method: 'DELETE',
      url: '/[institutionId]/patient-sessions/[journeyId]/snooze',
    });
  }
  getSnoozeJourney() {
    return this.useMutation<{ patientSessionId: string; snoozedUntil: string }[], { params: { routePackageId: string } }>({
      method: 'GET',
      url: '/[institutionId]/patient-sessions/snoozed',
    });
  }

  useFinishMoment() {
    return this.request<null, undefined>({
      method: 'PUT',
      url: '/[institutionId]/patient-sessions/[journeyId]/moment/[momentId]/finish',
    });
  }
}
