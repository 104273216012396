/* eslint-disable @typescript-eslint/no-explicit-any */
import { WORKSPACE_PERMISSIONS_KEYS } from '../constants/workspaces';
import { WorkspaceType } from '../store/workspaces/types';

export interface WorkspaceApiType {
  permissions: { [key: string]: boolean };
  community: string;
  id: number;
  name: string;
  logo: string;
  color: string | null;
  institutionId: number;
  domain: string;
  icon: string | null;
  pathwayPatientSessions: boolean;
}

export const setWorkspaces = (workspaces: WorkspaceApiType[]): WorkspaceType[] =>
  workspaces?.map((workspace: WorkspaceApiType) => ({
    community: workspace.community,
    id: workspace.id,
    name: workspace.name,
    logo: workspace.logo,
    color: workspace.color,
    domain: workspace.domain,
    institutionId: workspace.institutionId,
    permissions: setWorkspacesPermissions(workspace),
    icon: workspace.icon,
    pathwayPatientSessions: workspace.pathwayPatientSessions,
  }));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setWorkspacesPermissions = (workspace: any): { [key: string]: boolean } => {
  let permissions = {};

  Object.keys(workspace)?.forEach((prop: string) => {
    if (WORKSPACE_PERMISSIONS_KEYS.includes(prop)) {
      permissions = { ...permissions, [prop]: workspace[prop] };
    }
  });
  return permissions;
};

export const getActiveWorkspaceId = (): string | null => localStorage.getItem('active-workspace');
