import React, { ReactElement, useCallback, useEffect, useReducer } from 'react';
import request, { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
// legacy
import { setPublicInstitutions } from '@/store/new/publicInstitutions/slice';
import { setCurrentUser as legacySetCurrentUser } from '@/store/user/slice';
import { setWorkspaceList } from '@/store/workspaces/slice';
import { setActiveInstitution } from '@/store/new/activeInstitution/slice';
import { analyticsIdentify } from '@/src/helpers/analytics/segment';
import LegacyInstitution from '@/models/Institution';
import DataStorage from '@/services/storage';
// app
import { AbstractReducer, CareTeamAPI, CurrentUserAPI, Institution, InstitutionCareTeam } from '@/src/models';
import { getAuthorizationToken } from '@/src/helpers/authentication';
// self
import { computeUserHasInstitutionWorkspace, replacePermissionContext } from './partials';
import { ApplicationContextProviderProps, ApplicationContextState, ApplicationContextStatus, ApplicationContextValues } from './types';
import { ApplicationContext, initialContextValue } from './context';

const simpleReducer: AbstractReducer<ApplicationContextState> = (currentState, modifiedState) => {
  return { ...currentState, ...modifiedState };
};

const resolvedGetInstitutions = (): Promise<{ error?: AxiosError; institutions: Institution[] }> => {
  return new Promise((resolve) => {
    request<Institution[]>({ baseURL: `${process.env.NEXT_PUBLIC_GATEWAY_SERVICE}`, method: 'GET', url: '/institutions' })
      .then((response) => {
        resolve({
          institutions: response.data.map((institution) => {
            return {
              ...institution,
              id: Number(institution.id),
            };
          }),
        });
      })
      .catch((error) => {
        console.error('ApplicationContextProvider.getInstitutions', error);
        resolve({ error: error, institutions: [] });
      });
  });
};

const resolvedGetUserCareTeam = (
  institutionId: number,
  userId: number,
): Promise<{
  error?: AxiosError;
  userCareTeam: InstitutionCareTeam[];
}> => {
  return new Promise((resolve) => {
    if (!userId) {
      resolve({ error: new AxiosError('Missing user id', '400'), userCareTeam: [] });
    } else {
      request<CareTeamAPI>({
        baseURL: `${process.env.NEXT_PUBLIC_GATEWAY_SERVICE}`,
        method: 'GET',
        headers: {
          Authorization: getAuthorizationToken(),
        },
        url: `/fhir/external/${institutionId}/CareTeam?participant=Practitioner/${userId}`,
      })
        .then((response) => {
          const adjustResponse = response.data.entry.reduce((merge: InstitutionCareTeam[], entry) => {
            return [
              ...merge,
              {
                id: entry.resource.id,
                name: entry.resource.name,
                practitioners: [],
                routePackageFhir:
                  entry.resource.category?.reduce((prev, curr) => [...prev, ...curr.coding], [] as { system: string; code: string }[]) ??
                  [],
              },
            ];
          }, []);
          resolve({
            userCareTeam: adjustResponse,
          });
        })
        .catch((error) => {
          console.error('ApplicationContextProvider.getInstitutions', error);
          resolve({ error: error, userCareTeam: [] });
        });
    }
  });
};

/*
 * ### Localhost flow
 * Expects that the token is inserted at proxy server, so it is not necessary to have access_token cookie.
 * If you want to simulate a user without login, just put a wrong access_token at the configuration file.
 *
 * ### Environment flow
 * Checks access_token cookie to make the request, otherwise does nothing.
 */
const resolvedGetUser = (
  authorizationToken?: string,
): Promise<{ authorizationToken?: string; error?: AxiosError; currentUser?: CurrentUserAPI }> => {
  const isNotLocalHost = location.hostname !== 'localhost';

  return new Promise((resolve) => {
    const authorizationFromCookie = getAuthorizationToken();
    const tokenToUse = authorizationToken || authorizationFromCookie;
    if (!tokenToUse && isNotLocalHost) {
      resolve({});
    } else {
      request<CurrentUserAPI>({
        baseURL: `${process.env.NEXT_PUBLIC_GATEWAY_SERVICE}`,
        method: 'GET',
        url: '/user',
        headers: {
          Authorization: tokenToUse,
        },
      })
        .then((response) => {
          resolve({ authorizationToken: tokenToUse ? tokenToUse : undefined, currentUser: response.data });
        })
        .catch((error: AxiosError) => {
          if (isNotLocalHost) {
            console.error('ApplicationContextProvider.getUser', error);
            resolve({ error: error });
          } else {
            resolve({});
          }
        });
    }
  });
};

export const ApplicationContextProvider = ({ children, components }: ApplicationContextProviderProps): ReactElement => {
  const [state, dispatcher] = useReducer(simpleReducer, {
    internalStatus: ApplicationContextStatus.loading,
    publicInstitutions: initialContextValue.publicInstitutions,
  });

  // for legacy reasons we still need to maintain redux state until everything is migrated
  const legacyReduxDispatcher = useDispatch();

  /*
   * @description Tests the given permission against the current user permissions.
   * @description Built-in context variables: institutionDomain, institutionId
   *
   * @param {ApplicationPermissions} permissions - Enum of all available permissions.
   * @param {`Record<string, number | string>`} [context] - additional context to replace at permission string.
   * @returns {boolean} true if there is a user, institution and the user has the permission in its roles, otherwise is false.
   *
   * @example single permission
   * const { currentUserHasPermission } = useApplicationContext()
   * const hasPermissionExample = currentUserHasPermission(ApplicationPermissions.single)
   *
   * @example multiple permissions
   * const { currentUserHasPermission } = useApplicationContext()
   * const hasPermissionsExample = currentUserHasPermission([ApplicationPermissions.multiple, ApplicationPermissions.permission])
   */
  const currentUserHasPermission: ApplicationContextValues['currentUserHasPermission'] = useCallback(
    (permissions, context): boolean => {
      if (state.currentInstitution && state.currentUser) {
        const permissionList = Array.isArray(permissions) ? permissions : [permissions];

        const contextToDestruct = context ? context : {};
        const permissionsContext = {
          institutionDomain: state.currentInstitution.domain,
          institutionId: state.currentInstitution.id,
          ...contextToDestruct,
        };

        const parameterizedPermissions = permissionList.map((permission) => {
          return replacePermissionContext(permission, permissionsContext);
        });

        return state.currentUser.roles.some((role) => parameterizedPermissions.includes(role.name));
      }

      return false;
    },
    [state.currentInstitution, state.currentUser],
  );

  const setCurrentInstitution = useCallback<ApplicationContextValues['setCurrentInstitution']>(
    (institution) => {
      const institutionId = Number(institution.id);
      const currentUserId = Number(state.currentUser?.id);

      const userWorkspace = computeUserHasInstitutionWorkspace({
        institutionId,
        userWorkspaces: state.currentUser?.workspaces,
      });

      const previous: Pick<ApplicationContextValues, 'previousInstitution' | 'previousWorkspace'> = {};
      if (state.currentInstitution) {
        previous.previousInstitution = state.currentInstitution;
        previous.previousWorkspace = state.currentWorkspace;
      }
      resolvedGetUserCareTeam(institutionId, currentUserId).then((userCareTeam) => {
        if (userCareTeam.error) {
          dispatcher({ currentInstitution: institution, currentWorkspace: userWorkspace, ...previous });
        } else {
          dispatcher({
            currentInstitution: institution,
            currentUserCareTeams: userCareTeam.userCareTeam,
            currentWorkspace: userWorkspace,
            ...previous,
          });
        }

        DataStorage.setActiveWorkspaceId(institution.domain);
        sessionStorage.setItem('active-workspace', institution.domain);
        legacyReduxDispatcher(setActiveInstitution({ institution: institution as LegacyInstitution }));
        Sentry.setTags({ institutionId: institution.id, institutionDomain: institution.domain });
      });
    },
    [state.currentUser, state.currentInstitution, state.currentWorkspace],
  );

  // This method allow to get a user in a later flow
  const resolveAndSetCurrentUser = useCallback<(authorizationToken?: string) => void>(
    (authorizationToken) => {
      resolvedGetUser(authorizationToken).then((currentUserResponse) => {
        if (currentUserResponse.currentUser) {
          analyticsIdentify(currentUserResponse.currentUser);
          Sentry.setUser({ id: String(currentUserResponse.currentUser.id) });
          DataStorage.setUser(currentUserResponse.currentUser);

          legacyReduxDispatcher(legacySetCurrentUser({ ...currentUserResponse.currentUser }));
          legacyReduxDispatcher(setWorkspaceList({ workspaces: currentUserResponse.currentUser.workspaces }));

          if (state.currentInstitution) {
            DataStorage.setActiveWorkspaceId(state.currentInstitution.domain);
          }

          const userWorkspace = computeUserHasInstitutionWorkspace({
            institutionId: Number(state.currentInstitution?.id),
            userWorkspaces: currentUserResponse.currentUser.workspaces,
          });

          resolvedGetUserCareTeam(Number(state.currentInstitution?.id), currentUserResponse.currentUser.id).then((userCareTeam) => {
            if (userCareTeam.error) {
              dispatcher({
                authorizationToken: currentUserResponse.authorizationToken,
                currentUser: currentUserResponse.currentUser,
                currentWorkspace: userWorkspace,
              });
            } else {
              dispatcher({
                authorizationToken: currentUserResponse.authorizationToken,
                currentUser: currentUserResponse.currentUser,
                currentUserCareTeams: userCareTeam.userCareTeam,
                currentWorkspace: userWorkspace,
              });
            }
          });
        }
      });
    },
    [state.currentInstitution],
  );

  const unsetCurrentUser = () => {
    dispatcher({ currentUser: undefined, currentWorkspace: undefined });
  };

  useEffect(() => {
    Promise.all([resolvedGetInstitutions(), resolvedGetUser()])
      .then((responses) => {
        const [institutionsResponse, currentUserResponse] = responses;

        const toUpdate: ApplicationContextState = {
          publicInstitutions: [],
          internalStatus: ApplicationContextStatus.success,
        };

        if (institutionsResponse.error) {
          toUpdate.internalStatus = ApplicationContextStatus.error;
        } else {
          toUpdate.publicInstitutions = institutionsResponse.institutions;
          // legacy redux state
          legacyReduxDispatcher(setPublicInstitutions({ institutions: institutionsResponse.institutions as LegacyInstitution[] }));
        }

        if (currentUserResponse.currentUser) {
          toUpdate.authorizationToken = currentUserResponse.authorizationToken;
          toUpdate.currentUser = currentUserResponse.currentUser;
          analyticsIdentify(currentUserResponse.currentUser);
          Sentry.setUser({ id: String(currentUserResponse.currentUser.id) });
          DataStorage.setUser(currentUserResponse.currentUser);

          legacyReduxDispatcher(legacySetCurrentUser({ ...currentUserResponse.currentUser }));
          legacyReduxDispatcher(setWorkspaceList({ workspaces: currentUserResponse.currentUser.workspaces }));
        }

        dispatcher(toUpdate);
      })
      .catch((error) => {
        console.error('ApplicationContextProvider.getInstitutions', error);
      });
  }, []);

  if (state.internalStatus === ApplicationContextStatus.loading) {
    return components?.loading ? components.loading : <div />;
  }

  if (state.internalStatus === ApplicationContextStatus.error) {
    return components?.error ? components.error : <div />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { internalStatus, ...forward } = state;
  const applicationContextValues: ApplicationContextValues = {
    ...forward,
    currentUserHasPermission,
    resolveAndSetCurrentUser,
    setCurrentInstitution,
    unsetCurrentUser,
  };

  return <ApplicationContext.Provider value={applicationContextValues}>{children}</ApplicationContext.Provider>;
};
