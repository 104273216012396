import { styled } from '@uphillhealth/theme';
import * as SelectPrimitives from '@radix-ui/react-select';

export const SelectTriggerStyled = styled(SelectPrimitives.SelectTrigger, {
  padding: '$4',
  width: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '$neutral900',
  fontSize: '$small',
  lineHeight: '$fontSizes$base',
  backgroundColor: '$neutral100',
  border: '1px solid $neutral300',
  borderRadius: '$medium',
  '&:disabled': {
    backgroundColor: '$neutral200',
    cursor: 'not-allowed',
    '&[data-placeholder]': { color: '$neutral500' },
  },
  '&[data-placeholder]': { color: '$neutral500' },
});
