import { Action } from '../../helpers/store';
import { createSlice } from '@reduxjs/toolkit';
import { ModalsReducerType, MODAL_TYPE } from './types';

export const initialState: ModalsReducerType = {
  open: false,
  type: null,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setModalOpen: (state: ModalsReducerType, action: Action<{ type: MODAL_TYPE }>) => {
      state.open = true;
      state.type = action.payload.type;
    },
    setModalType: (state: ModalsReducerType, action: Action<{ type: MODAL_TYPE }>) => {
      state.type = action.payload.type;
    },
    setModalClose: () => initialState,
    userLogout: () => initialState,
  },
});

export const { setModalOpen, setModalType, setModalClose } = modalsSlice.actions;

export default modalsSlice.reducer;
