import { styled } from '@/src/theme';
import { FlexContainer } from '@/src/theme/commons';

export const ExtraKeyHeader = styled('div', {
  margin: '$4 0 $2',
  color: '$neutral800',
  fontSize: '$base',
  fontWeight: 'bold',
});

export const ExtraKeyHeaderSubline = styled('div', {
  fontSize: '$small',
});

export const ExtraContent = styled('div', {
  padding: '0 $4',
  width: '100%',
  '&:last-child': {
    marginBottom: '$4',
  },
  '> p': {
    color: '$neutral700',
    fontSize: '$small',
    fontWeight: 'normal',
    lineHeight: '$fontSizes$large',
  },
  '> a': {
    margin: '$2 0',
    color: '$neutral500',
    fontWeight: 500,
    '&:hover': {
      color: '$primary500',
    },
  },
  '> img': {
    width: '100%',
    maxWidth: '100%',
  },
  '> iframe': {
    height: 'calc(100vh - 17rem)',
  },
});

export const DeleteButton = styled('button', {
  position: 'absolute',
  right: 0,
  transform: 'translateX(50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  background: 'white',
  border: '1px solid $colors$neutral300',
  borderRadius: '10px',
  '& > i': {
    fontSize: '20px',
    color: '$error500',
  },
  '&:hover > i': {
    color: '$error700',
  },
});

export const EditButton = styled(DeleteButton, {
  position: 'relative',
  right: '-6px',
  border: '1px solid $colors$neutral300',
  '& > i, &:hover > i': {
    color: '$primary500',
  },
  '&:not(:disabled):hover > i': {
    color: '$primary700',
  },
  '&:disabled': {
    background: '$neutral300',
    borderColor: 'white',
    '& > i': {
      color: '$neutral500',
    },
  },
});

export const EditableContainer = styled(FlexContainer, {
  position: 'relative',
  padding: '$4',
  maxHeight: '45vh',
  backgroundColor: '$neutral100',
  borderRadius: '10px',
  overscrollBehavior: 'contain',
});

export const RadioLikeIndicator = styled('div', {
  position: 'relative',
  width: 14,
  height: 14,
  border: '1px solid $neutral300',
  borderRadius: '$full',
  '&::before': {
    content: '',
    position: 'absolute',
    left: 2,
    top: 2,
    width: 8,
    height: 8,
    backgroundColor: '$neutral100',
    borderRadius: '$full',
  },
  variants: {
    active: {
      true: {
        '&::before': {
          backgroundColor: '$primary500',
        },
      },
    },
  },
});

export const StartEditButton = styled('button', {
  color: '$neutral600',
  fontSize: '$base',
  '&:hover': {
    color: '$neutral400',
  },
  '&:disabled': {
    color: '$neutral300',
  },
});
