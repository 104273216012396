export enum RouteElementSubtypes {
  eventFinal = 'FINAL',
  eventInitial = 'INITIAL',
  eventIntermediate = 'INTERMEDIATE',
  gatewayExclusive = 'EXCLUSIVE',
  gatewayInclusive = 'INCLUSIVE',
  gatewayParallel = 'PARALLEL',
  /*
   * gatewayRules is virtual.
   * To get the correct subtype use computeGatewaySubtype helper method.
   */
  gatewayRules = 'RULES',
}
