import { keyframes, styled } from '@uphillhealth/theme';
import * as DialogPrimitives from '@radix-ui/react-dialog';
import {
  BaseCloseIcon,
  BaseContent,
  BaseFooter,
  BaseHeader,
  BaseHeaderDescription,
  BaseHeaderTitle,
} from '@/uphillhealth/components/commons';
import { buttonTemplateStyle } from '@/uphillhealth/components/Button';

export const DialogRoot = styled(DialogPrimitives.Root, {});

const overlayAnimation = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export const OverlayBaseStyle = {
  backgroundColor: 'rgba(6, 27, 89, 0.5)',
  backdropFilter: 'blur(10px)',
  animation: `${overlayAnimation} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  inset: 0,
};

export const DialogOverlay = styled(DialogPrimitives.Overlay, {
  ...OverlayBaseStyle,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 15,
});

export const DialogContainer = styled(DialogPrimitives.DialogContent, {
  position: 'relative',
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$neutral100',
  borderRadius: '$medium',
  overflow: 'auto',
  '& > div:first-child': {
    borderTopRightRadius: '$medium',
    borderTopLeftRadius: '$medium',
  },
  '& > div:last-child': {
    borderBottomRightRadius: '$medium',
    borderBottomLeftRadius: '$medium',
    paddingBottom: '$4',
  },
  '@medium': {
    width: '75vw',
  },
  '@extraLarge': {
    width: '45vw',
  },
  '@huge': {
    width: '35vw',
  },
});

export const DialogClose = styled(DialogPrimitives.Close, {});

export const ButtonDialogClose = styled(DialogPrimitives.Close, buttonTemplateStyle);

export const DialogButtonClose = ButtonDialogClose;

export const DialogCloseIcon = styled(DialogPrimitives.Close, {
  ...BaseCloseIcon,
  position: 'absolute',
  top: '$4',
  right: '$4',
});

export const DialogHeaderTitle = styled(DialogPrimitives.Title, BaseHeaderTitle);

export const DialogHeaderDescription = styled(DialogPrimitives.Description, BaseHeaderDescription);

export const DialogFooter = styled('div', BaseFooter);

export const DialogContent = styled('div', BaseContent);

export const DialogHeader = styled('div', {
  ...BaseHeader,
  [`& + ${DialogFooter}`]: {
    borderTop: 'none',
  },
});
