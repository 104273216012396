import * as AvatarPrimitives from '@radix-ui/react-avatar';
import { styled } from '@uphillhealth/theme';
import { AvatarSize } from './types';

export const AvatarImage = styled(AvatarPrimitives.Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});

export const AvatarFallback = styled(AvatarPrimitives.AvatarFallback, {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$neutral100',
  lineHeight: 0,
  backgroundImage: 'linear-gradient(to right, $primary700, $primary900)',
  borderRadius: 'inherit',
});

export const AvatarRoot = styled(AvatarPrimitives.Root, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: '$full',
  overflow: 'hidden',
  variants: {
    light: {
      true: {
        [`${AvatarFallback}`]: {
          background: '$primary100',
          backgroundImage: 'initial',
          color: '$primary500',
        },
      },
    },
    size: {
      [AvatarSize.micro]: {
        minWidth: 10,
        width: 10,
        height: 10,
        fontSize: '0.25rem',
      },
      [AvatarSize.small]: {
        minWidth: 16,
        width: 16,
        height: 16,
        fontSize: '$micro',
      },
      [AvatarSize.base]: {
        minWidth: 24,
        width: 24,
        height: 24,
        fontSize: '$extraSmall',
      },
      [AvatarSize.large]: {
        minWidth: 32,
        width: 32,
        height: 32,
        fontSize: '$base',
      },
      [AvatarSize.huge]: {
        minWidth: 56,
        width: 56,
        height: 56,
        fontSize: '$extraLarge',
      },
    },
  },
});
