import React, { ReactElement, ReactNode } from 'react';
import * as DropdownPrimitives from '@radix-ui/react-dropdown-menu';

interface DropdownProps extends Omit<DropdownPrimitives.DropdownMenuProps, 'children'> {
  children: ReactNode | ReactNode[];
}

export const Dropdown = ({ children, ...props }: DropdownProps): ReactElement => {
  return <DropdownPrimitives.Root {...props}>{children}</DropdownPrimitives.Root>;
};
