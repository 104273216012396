import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { CustomLocation } from '../../models/CustomLocation';
import { PathwayUserProgress } from '../../models/PathwayProgress';
import { deleteRequest, getRequest, postRequest, putRequest } from './index';
import { InstitutionId } from '@/src/models';

export const getUserData = async (cancelToken?: CancelTokenSource): Promise<AxiosResponse | undefined> => getRequest('user', cancelToken);

export const getUserRecentItems = async (
  institutionId: InstitutionId,
  cancelToken?: CancelTokenSource,
  page?: number,
): Promise<AxiosResponse<any> | undefined> => await getRequest('history?institutionId=' + institutionId + '&page=' + page, cancelToken);

export const getMyLocation = async (): Promise<AxiosResponse<CustomLocation>> => {
  return await axios.get('https://ipapi.co/json/');
};

export const UserApi = {
  getPathwayProgress: (pathwayId: string, cancelToken?: CancelTokenSource) => getRequest<PathwayUserProgress>('user/pathways/' + pathwayId),
  putPathwayProgress: (pathwayId: string, body: PathwayUserProgress, cancelToken?: CancelTokenSource) =>
    putRequest('user/pathways/' + pathwayId, { ...body }),
  resetPathwayProgress: (pathwayId: string, cancelToken?: CancelTokenSource) => deleteRequest('user/pathways/' + pathwayId),
  putStepPathwayProgress: (
    pathwayId: string,
    stepId: string,
    body: {
      elementId: string;
      elementType: string;
      itemsIds?: number[];
      skipped: boolean;
      status?: string;
      statusReason?: string;
      optionsIds?: number[];
    },
    cancelToken?: CancelTokenSource,
  ) => putRequest('user/pathways/' + pathwayId + '/steps/' + stepId, { ...body }),
  postUserCookies: (body: any, cancelToken?: CancelTokenSource) => postRequest('user/cookies', body),
  putUserData: (body: { userId: number; email: string; language: string; name: string }) =>
    putRequest('user/update/' + body.userId, body, undefined, process.env.NEXT_PUBLIC_UPID_API, {
      'accept-language': String(null),
    }),
};
