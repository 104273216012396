import { styled } from '@uphillhealth/theme';
import * as SelectPrimitives from '@radix-ui/react-select';

export const SelectContentStyled = styled(SelectPrimitives.Content, {
  backgroundColor: '$neutral100',
  borderRadius: '$medium',
  boxShadow: '$small',
  overflow: 'hidden',
  zIndex: 20,
});
