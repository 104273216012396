export enum PatientTelecomSystem {
  email = 'email',
  fax = 'fax',
  other = 'other',
  pager = 'pager',
  phone = 'phone',
  sms = 'sms',
  url = 'url',
}

export type PatientTelecomSystems = ('EMAIL' | 'FAX' | 'OTHER' | 'PAGER' | 'PHONE' | 'SMS' | 'URL')[];

export type PatientTelecom = {
  system: PatientTelecomSystem;
  use?: string;
  value: string;
};
