import React, { memo, ReactElement, ReactNode } from 'react';
import { StitchesProps } from '@uphillhealth/theme';
// self
import { DropdownTriggerStyled, TriggerIcon } from './styles';

interface DropdownTriggerProps extends StitchesProps {
  children: ReactNode | ReactNode[];
  hideIcon?: boolean;
}

export const DropdownTrigger = memo(({ css = {}, children, hideIcon = false }: DropdownTriggerProps): ReactElement => {
  return (
    <DropdownTriggerStyled css={css}>
      {children}
      {!hideIcon && (
        <TriggerIcon>
          <i className="icon-arrow_drop_down" />
        </TriggerIcon>
      )}
    </DropdownTriggerStyled>
  );
});
