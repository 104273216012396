import { Action } from '../../helpers/store';
import { createSlice } from '@reduxjs/toolkit';

import { AuthReducerType } from './types';

export const initialState: AuthReducerType = {
  authRequest: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authRequest: (state: AuthReducerType, action: Action<boolean>) => {
      state.authRequest = action.payload;
    },
    userLogout: () => initialState,
  },
});

export const { authRequest, userLogout } = authSlice.actions;

export default authSlice.reducer;
