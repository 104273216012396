import { styled } from '@uphillhealth/theme';

export const TriggerDisplay = styled('div', {
  transform: 'translateY(1px)',
});

export const CountryDisplay = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$1',
  color: '$neutral700',
});

export const DisplayEmoji = styled('div', {
  width: 30,
});

export const DisplayCallingCode = styled('div', {
  width: 60,
  color: '$neutral900',
});
