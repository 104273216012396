import { StitchesProps } from '@uphillhealth/theme';

export enum AvatarSize {
  base = 'base',
  huge = 'huge',
  large = 'large',
  micro = 'micro',
  small = 'small',
}

export interface AvatarProps extends StitchesProps {
  avatar?: string;
  initials: string;
  size?: AvatarSize;
  light?: boolean;
}
