/*
 * @description ACTIVE: When something is done in any element of the moment
 * @description STARTED: When the moment just created. It is currently FE only
 */
export enum JourneyMomentStatus {
  active = 'ACTIVE',
  cancelled = 'CANCELLED',
  draft = 'DRAFT',
  finished = 'FINISHED',
  started = 'STARTED',
}
