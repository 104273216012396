import { styled } from '@uphillhealth/theme';

export const CheckboxContainer = styled('div', {
  padding: '$2 $3 $2 $2',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$2',
  color: '$neutral900',
  fontSize: '$small',
  lineHeight: '$fontSizes$base',
  backgroundColor: '$neutral200',
  borderRadius: '$medium',
  cursor: 'pointer',
});

export const CustomCheckbox = styled('div', {
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$neutral100',
  fontSize: '$extraSmall',
  backgroundColor: '$neutral100',
  border: '2px solid $primary400',
  borderRadius: '$small',
  'i:last-child': {
    display: 'none',
  },
});

export const CheckboxBehaviours = styled('label', {
  position: 'relative',
  width: 'fit-content',
  display: 'inline',
  '&:hover': {
    [`> ${CheckboxContainer}`]: {
      color: '$primary700',
      backgroundColor: '$primary50',
    },
  },
  '> input': {
    position: 'absolute',
    visibility: 'hidden',
    '&:checked': {
      [`& + ${CheckboxContainer}`]: {
        color: '$primary800',
        backgroundColor: '$primary100',
        [`> ${CustomCheckbox}`]: {
          backgroundColor: '$primary500',
          borderColor: '$primary500',
        },
        '&:hover': {
          backgroundColor: '$primary200',
          [`> ${CustomCheckbox}`]: {
            'i:first-child': {
              display: 'none',
            },
            'i:last-child': {
              display: 'initial',
            },
          },
        },
      },
      '&:disabled': {
        [`& + ${CheckboxContainer}`]: {
          color: '$neutral500',
          backgroundColor: '$neutral100',
          cursor: 'initial',
          [`> ${CustomCheckbox}`]: {
            backgroundColor: '$neutral400',
            borderColor: '$neutral400',
          },
          '&:hover': {
            [`> ${CustomCheckbox}`]: {
              'i:first-child': {
                display: 'initial',
              },
              'i:last-child': {
                display: 'none',
              },
            },
          },
        },
      },
    },
    '&:not(:checked):disabled': {
      [`& + ${CheckboxContainer}`]: {
        color: '$neutral500',
        backgroundColor: '$neutral100',
        [`> ${CustomCheckbox}`]: {
          backgroundColor: '$neutral200',
          borderColor: '$neutral400',
          i: {
            display: 'none',
          },
        },
      },
    },
  },
});
