import { AxiosResponse, CancelTokenSource } from 'axios';
import { GetServerSidePropsContext, NextPageContext } from 'next';

import Pathway, { PathwayCommunicationSuggestions } from '../../models/Pathway';
import { deleteRequest, getRequest, objectToQueryParam, postRequest, putRequest } from './index';
import { InstitutionId } from '@/src/models';

export const getTreeviewPathway = async (
  instituitionId: InstitutionId,
  lang?: string,
  cancelToken?: CancelTokenSource,
): Promise<AxiosResponse> =>
  (
    await getRequest(
      `pathways/treeview?institutionId=${instituitionId}`,
      cancelToken,
      undefined,
      lang ? { 'accept-language': 'null' } : undefined,
    )
  )?.data;

export const getFullPathwayById = async (
  params: { pathwayId: string | number; version?: number; institutionId: InstitutionId; institutionKey?: string; language?: string | null },
  cancelToken?: CancelTokenSource,
  ctx?: NextPageContext | GetServerSidePropsContext,
): Promise<AxiosResponse<Pathway> | undefined> => {
  const { language, pathwayId, ...queryParamsObj } = params;
  const queryParamsString = objectToQueryParam(queryParamsObj);

  return await getRequest(
    `pathways/${pathwayId}?${queryParamsString}`,
    cancelToken,
    ctx,
    language !== undefined ? { 'accept-language': String(language) } : undefined,
  );
};

export const getFullPathwayByIdAndEmbed = async (
  pathwayId: string | number,
  referrer: string,
  cancelToken?: CancelTokenSource,
  ctx?: NextPageContext | GetServerSidePropsContext,
): Promise<AxiosResponse<Pathway> | undefined> => {
  return await getRequest('pathways/' + pathwayId + '?embedded=true&requester=' + referrer, cancelToken, ctx);
};

export const getPathwayList = async (
  institutionId: InstitutionId,
  page: number,
  cancelToken?: CancelTokenSource,
  ctx?: NextPageContext | GetServerSidePropsContext,
): Promise<AxiosResponse | undefined> => await getRequest('pathways?institutionId=' + institutionId + '&page=' + page, cancelToken, ctx);

export const PathwaysApi = {
  getPathways: (institutionId: InstitutionId, search: string, page: number, cancelToken?: CancelTokenSource) =>
    getRequest('institutions/' + institutionId + '/pathways?title=' + search + '&page=' + page + '&sizePerPage=20', cancelToken),
  getPathwaysShort: (institutionId: number, search: string, eligibleForPatientSession = true, cancelToken?: CancelTokenSource) =>
    getRequest<{ pathwayId: string; title: string; version: number }[]>(
      'pathways/short?institutionId=' + institutionId + '&titleLike=' + search + '&eligibleForPatientSession=' + eligibleForPatientSession,
      cancelToken,
    ),
  getPathway: (institutionId: InstitutionId, pathwayId: string, cancelToken?: CancelTokenSource) =>
    getRequest('institutions/' + institutionId + '/pathways/' + pathwayId, cancelToken),
  postPathway: (institutionId: InstitutionId, body: any, cancelToken?: CancelTokenSource) =>
    postRequest('institutions/' + institutionId + '/pathways', body, cancelToken),
  putPathway: (institutionId: InstitutionId, pathwayId: string, body: any, cancelToken?: CancelTokenSource) =>
    putRequest('institutions/' + institutionId + '/pathways/' + pathwayId, body, cancelToken),
  deletePathway: (institutionId: InstitutionId, pathwayId: string, cancelToken?: CancelTokenSource) =>
    deleteRequest('institutions/' + institutionId + '/pathways/' + pathwayId, cancelToken),
  setPathwayVisible: (institutionId: InstitutionId, pathwayId: string, cancelToken?: CancelTokenSource) =>
    putRequest('institutions/' + institutionId + '/pathways/' + pathwayId + '/visible', {}, cancelToken),
  setPathwayHidden: (institutionId: InstitutionId, pathwayId: string, cancelToken?: CancelTokenSource) =>
    deleteRequest('institutions/' + institutionId + '/pathways/' + pathwayId + '/visible', cancelToken),
  getPathwaysCommunicationSuggestions: (institutionId: InstitutionId, cancelToken?: CancelTokenSource) =>
    getRequest<PathwayCommunicationSuggestions[]>('pathways/communication-suggestions?institutionId=' + institutionId, cancelToken),
};
