export interface CourseElementSimplifiedDTO {
  id: string;
  name: string;
  order: number;
  type: 'RESOURCE' | 'CASE' | 'PDF' | 'VIDEO' | 'QUIZ' | 'SCORM';
  precedenceElement: string[];
  complete: boolean;
  scoreWeight: number;
  externalUrl: boolean;
}

export interface CourseElementSimplified {
  id: string;
  name: string;
  order: number;
  type: 'RESOURCE' | 'CASE' | 'PDF' | 'VIDEO' | 'QUIZ' | 'SCORM';
  precedenceElement: string[];
  complete: boolean;
  externalUrl: boolean;
  scoreWeight: number;
  editing: boolean;
}

export interface CourseElementDTO {
  precedenceElement: string[];
  type: 'RESOURCE' | 'CASE' | 'PDF' | 'VIDEO' | 'QUIZ' | 'SCORM';
  courseElementAuthors: {
    name: string;
    imagePath: string;
    deleted: boolean;
  }[];
  id: string | null;
  deleted: boolean;
  description: string;
  duplicateId: number | null;
  estimatedTime: number;
  institutionsId: number;
  name: string;
  order: number;
  scoreWeight: number;
  scrollToPage: number;
  typeId: number | null;
  value: string | null;
  valuePreview: string | null;
  visible: boolean;
  mandatoryRepeat: boolean;
  externalUrl: boolean;
  complete?: boolean;
  minVisualization: number | null;
}
export interface CourseElement {
  precedenceElement: string[];
  type: 'RESOURCE' | 'CASE' | 'PDF' | 'VIDEO' | 'QUIZ' | 'SCORM';
  courseElementAuthors: {
    name: string;
    imagePath: string;
    deleted: boolean;
  }[];
  id: string | null;
  deleted: boolean;
  description: string;
  duplicateId: number | null;
  estimatedTime: number;
  institutionsId: number;
  name: string;
  order: number;
  scoreWeight: number;
  scrollToPage: number;
  typeId: number | null;
  value: string | null;
  valuePreview: string | null;
  visible: boolean;
  mandatoryRepeat: boolean;
  externalUrl: boolean;
  complete: boolean;
  editing: boolean;
  minVisualization: number | null;
}

export interface CourseElementForm {
  courseElementAuthors: {
    name: string;
    imagePath: string;
    deleted: boolean;
  }[];
  id: string | null;
  deleted: boolean;
  description: string;
  duplicateId: number | null;
  estimatedTime: number;
  institutionsId: number;
  name: string;
  order: number;
  scoreWeight: number | '';
  scrollToPage: number;
  type: string;
  typeId: number | null;
  value: string | null;
  valuePreview: string | null;
  visible: boolean;
  file: File | null;
  precedenceElement: { [key: string]: boolean };
  isLoading: boolean;
  errorMessage: string | null;
  hasPrecedents: string;
  mandatoryRepeat: boolean;
  externalUrl?: boolean;
  minVisualization: number | null;
}

export const CourseElementUtils = {
  fromDTO: (dto: CourseElementSimplifiedDTO): CourseElementSimplified => ({
    ...dto,
    editing: false,
  }),
  formToDTO: (form: CourseElementForm): CourseElementDTO => {
    const precedenceElement: string[] = [];
    Object.keys(form.precedenceElement).forEach((elementId) => {
      if (form.precedenceElement[elementId]) {
        precedenceElement.push(elementId);
      }
    });
    return {
      ...form,
      precedenceElement,
      type: form.type as 'RESOURCE' | 'CASE' | 'PDF' | 'VIDEO' | 'QUIZ' | 'SCORM',
      scoreWeight: form.scoreWeight ? +form.scoreWeight : 0,
      mandatoryRepeat: form.mandatoryRepeat,
      ...(!form.externalUrl
        ? { value: null }
        : { value: form.value?.includes('http://') || form.value?.includes('https://') ? form.value : `https://${form.value}` }),
      externalUrl: form.externalUrl ?? false,
    };
  },
  getBlankElement: (order: number, name: string, typeId?: number): CourseElementDTO => ({
    id: '',
    courseElementAuthors: [],
    typeId: typeId ?? null,
    type: 'QUIZ',
    value: null,
    name,
    precedenceElement: [],
    order: order,
    scrollToPage: 1,
    institutionsId: 1,
    description: '',
    visible: true,
    scoreWeight: 0,
    deleted: false,
    duplicateId: null,
    estimatedTime: 0,
    valuePreview: null,
    mandatoryRepeat: true,
    externalUrl: false,
    minVisualization: 0,
  }),
};
