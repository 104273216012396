/* eslint-disable max-len */
import React, { Fragment, ReactElement } from 'react';
import Head from 'next/head';
import { globalCss } from '@/src/theme';

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
}

const globalCssIntercom = globalCss({
  'div.intercom-launcher[role=button]': {
    background: '$primary500 !important',
    maskImage: 'none !important',
  },
});

const Meta = ({ title, description, image }: MetaProps): ReactElement => (
  <Fragment>
    <Head>
      {title && <title>{title}</title>}
      <meta name="description" content={description} />
      <meta name="referrer" content="origin" />
      <meta httpEquiv="x-ua-compatible" content="IE=edge" />
      <meta itemProp="name" content={title || 'UpHill'} />
      <meta
        itemProp="description"
        content={description || 'Clinical algorithms, medical events, scientific evidence and a simulation software in one place'}
      />
      <meta name="theme-color" content="#4066ce" />
      <meta itemProp="image" content={image || 'https://uphillhealth.com/images/png/uphill-logo-share.png'} />

      <link href="/favicon.ico" rel="icon" />
      <link rel="icon" type="image/png" sizes="32x32" href="https://www.uphillhealth.com/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="https://www.uphillhealth.com/favicon-16x16.png" />

      <meta property="og:site_name" content="UpHill Health" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || 'UpHill'} />
      <meta
        property="og:description"
        content={description || 'Clinical algorithms, medical events, scientific evidence and a simulation software in one place'}
      />
      <meta property="og:image" content={image || 'https://uphillhealth.com/images/png/uphill-logo-share.png'} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || 'UpHill'} />
      <meta
        name="twitter:description"
        content={description || 'Clinical algorithms, medical events, scientific evidence and a simulation software in one place'}
      />
      <meta name="twitter:image" content={image || 'https://uphillhealth.com/images/png/uphill-logo-share.png'} />

      {process.env.ENV === 'prod' && (
        <Fragment>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
              `,
            }}
          />

          <script
            src={'https://cdn.cookielaw.org/consent/' + process.env.NEXT_PUBLIC_COOKIE_CONSENT + '/OtAutoBlock.js'}
            type="text/javascript"
          />
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script={process.env.NEXT_PUBLIC_COOKIE_CONSENT}
          />
          <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
        </Fragment>
      )}

      {globalCssIntercom()}
    </Head>
    <style jsx global>{`
      html {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;
      }

      * {
        margin: 0;
        padding: 0;
      }

      a {
        text-decoration: none;
        display: block;
        color: #2e3a53;
      }

      p {
        color: #2e3a53;
      }

      b {
        font-weight: 500;
      }

      .hide-desktop {
        display: none;
      }

      ul {
        list-style-type: none;
      }

      video {
        outline: none;
      }

      button {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
      }

      input {
        outline: none;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      .MuiFormControlLabel-label.MuiTypography-body1 {
        font-size: 0.875rem;
        color: #2e3a53;
      }

      .MuiAccordion-root {
        box-shadow: none !important;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        display: none;
      }

      @media only screen and (max-width: 680px) {
        .hide-mobile {
          display: none;
        }

        .hide-desktop {
          display: block;
        }
      }
    `}</style>
  </Fragment>
);

export default Meta;
