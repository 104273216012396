import React from 'react';

export const SplashLoading = ({ exit }: { exit?: boolean }): JSX.Element => {
  return (
    <div role="splash-loader" className={`container ${exit ? 'exit' : ''}`}>
      <div className="circle-empty"></div>
      <div className="circle"></div>
      <Icon />
      <style jsx>{`
        .container {
          z-index: 999999;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #f7f8fa;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .container.exit {
          z-index: -999999;
          animation: exit 2s;
        }

        .circle-empty {
          z-index: -1;
          position: absolute;
          top: calc(50% - 55px);
          left: calc(50% - 50px);
          width: 100px;
          height: 100px;
          border-radius: 100%;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .circle-empty:after,
        .circle-empty:before {
          content: '';
          z-index: -1;
          position: absolute;
          top: calc(50% - 50px);
          left: calc(50% - 50px);
          width: 100px;
          height: 100px;
          border-radius: 100%;
          background-color: rgba(30, 90, 230, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .circle-empty:after {
          background-color: #f7f8fa;
          animation: splash 2s infinite;
          box-shadow: 0 0 20px 20px rgba(247, 248, 250, 1);
        }

        .circle-empty:before {
          animation: splashBlue 2s infinite;
          box-shadow: 0 0 2px 4px rgba(30, 90, 230, 0.6);
        }

        .circle {
          z-index: -1;
          position: absolute;
          top: calc(50% - 55px);
          left: calc(50% - 50px);
          width: 100px;
          height: 100px;
          border-radius: 100%;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .circle.exit {
          animation: scale 2s;
        }

        @keyframes splash {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(5);
          }
        }

        @keyframes splashBlue {
          from {
            transform: scale(1);
          }
          30% {
            opacity: 1;
          }
          to {
            opacity: 0;
            transform: scale(6);
          }
        }

        @keyframes exit {
          from {
            z-index: 999999;
          }
          to {
            z-index: -999999;
          }
        }
      `}</style>
    </div>
  );
};

const Icon = React.memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="347" height="392" fill="none" viewBox="0 0 347 392">
      <g fillRule="evenodd" clipPath="url(#clip0_1029_1400)" clipRule="evenodd">
        <path
          fill="url(#paint0_linear_1029_1400)"
          d="M180.012 179.149l16.5 18.1a2.793 2.793 0 01.472 2.999c-.22.492-.577.909-1.029 1.2a2.752 2.752 0 01-1.513.439h-32.835a2.751 2.751 0 01-2.53-1.654 2.781 2.781 0 01.497-2.992l16.463-18.099a2.693 2.693 0 013.81-.166l.165.173z"
        ></path>
        <path
          fill="url(#paint1_linear_1029_1400)"
          d="M146.494 196.404l15.802-17.043a3.316 3.316 0 012.344-1.24 3.313 3.313 0 013.282 1.991 3.349 3.349 0 01-.781 3.773l-15.802 17.044a3.3 3.3 0 01-4.514.002 3.336 3.336 0 01-1.05-2.198 3.341 3.341 0 01.719-2.329z"
        ></path>
        <path
          fill="url(#paint2_linear_1029_1400)"
          d="M162.453 179.171a3.302 3.302 0 015.117.32 3.35 3.35 0 01.13 3.703 3.306 3.306 0 01-5.838-.371 3.333 3.333 0 01-.09-2.548c.153-.409.384-.785.681-1.104z"
        ></path>
      </g>
      <g>
        <circle cx="174" cy="26" r="20" fill="#fff"></circle>
      </g>
      <mask id="mask0_1029_1400" style={{ maskType: 'alpha' }} width="12" height="12" x="163" y="23" maskUnits="userSpaceOnUse">
        <rect
          width="7.001"
          height="12.682"
          x="175.806"
          y="31.1"
          fill="url(#paint3_linear_1029_1400)"
          rx="3.501"
          transform="rotate(135.209 175.806 31.1)"
        ></rect>
      </mask>
      <g mask="url(#mask0_1029_1400)">
        <rect width="7.001" height="12.682" x="175.806" y="31.1" fill="#22AC75" rx="3.501" transform="rotate(135.209 175.806 31.1)"></rect>
      </g>
      <mask id="mask1_1029_1400" style={{ maskType: 'alpha' }} width="18" height="18" x="167" y="17" maskUnits="userSpaceOnUse">
        <rect
          width="7.001"
          height="21.593"
          fill="url(#paint4_linear_1029_1400)"
          rx="3.501"
          transform="scale(1 -1) rotate(-44.791 45.227 -216.877)"
        ></rect>
      </mask>
      <g mask="url(#mask1_1029_1400)">
        <rect width="7.001" height="21.593" fill="#22AC75" rx="3.501" transform="scale(1 -1) rotate(-44.791 45.227 -216.877)"></rect>
      </g>
      <ellipse cx="170.895" cy="31.082" fill="#22AC75" opacity="0.4" rx="3.5" ry="3.5"></ellipse>
      <circle cx="181.184" cy="20.709" r="3.5" fill="#22AC75" opacity="0.8"></circle>
      <g>
        <circle cx="32" cy="109" r="20" fill="#fff"></circle>
      </g>
      <mask id="mask2_1029_1400" style={{ maskType: 'alpha' }} width="16" height="16" x="20" y="97" maskUnits="userSpaceOnUse">
        <rect
          width="7"
          height="17.628"
          x="31.993"
          y="96.548"
          fill="url(#paint5_linear_1029_1400)"
          rx="3.5"
          transform="rotate(45 31.993 96.548)"
        ></rect>
      </mask>
      <g mask="url(#mask2_1029_1400)">
        <rect width="7" height="17.628" x="31.993" y="96.548" fill="#7E56F3" rx="3.5" transform="rotate(45 31.993 96.548)"></rect>
      </g>
      <mask id="mask3_1029_1400" style={{ maskType: 'alpha' }} width="8" height="22" x="28" y="98" maskUnits="userSpaceOnUse">
        <rect width="7.002" height="22" fill="url(#paint6_linear_1029_1400)" rx="3.501" transform="matrix(1 0 0 -1 28.498 120)"></rect>
      </mask>
      <g mask="url(#mask3_1029_1400)">
        <rect width="7.002" height="22" fill="#7E56F3" rx="3.501" transform="matrix(1 0 0 -1 28.498 120)"></rect>
      </g>
      <mask id="mask4_1029_1400" style={{ maskType: 'alpha' }} width="16" height="15" x="28" y="98" maskUnits="userSpaceOnUse">
        <rect
          width="7"
          height="17.621"
          fill="url(#paint7_linear_1029_1400)"
          rx="3.5"
          transform="scale(-1 1) rotate(45 -132.555 9.628)"
        ></rect>
      </mask>
      <g mask="url(#mask4_1029_1400)">
        <rect width="7" height="17.621" fill="#7E56F3" rx="3.5" transform="scale(-1 1) rotate(45 -132.555 9.628)"></rect>
      </g>
      <path fill="#7E56F3" d="M32 105a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"></path>
      <path
        fill="#7E56F3"
        d="M24.5 112.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM39.5 112.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
        opacity="0.4"
      ></path>
      <g>
        <circle cx="32" cy="270" r="20" fill="#fff"></circle>
      </g>
      <mask id="mask5_1029_1400" style={{ maskType: 'alpha' }} width="21" height="9" x="21" y="270" maskUnits="userSpaceOnUse">
        <rect
          width="8"
          height="20"
          x="41.991"
          y="270.727"
          fill="url(#paint8_linear_1029_1400)"
          rx="4"
          transform="rotate(90 41.991 270.727)"
        ></rect>
      </mask>
      <g mask="url(#mask5_1029_1400)">
        <rect width="8" height="20" x="41.991" y="270.727" fill="#1790E8" rx="4" transform="rotate(90 41.991 270.727)"></rect>
      </g>
      <mask id="mask6_1029_1400" style={{ maskType: 'alpha' }} width="15" height="19" x="28" y="260" maskUnits="userSpaceOnUse">
        <rect
          width="8"
          height="20.217"
          fill="url(#paint9_linear_1029_1400)"
          rx="4"
          transform="scale(-1 1) rotate(30.997 -483.385 68.852)"
        ></rect>
      </mask>
      <g mask="url(#mask6_1029_1400)">
        <rect width="8" height="20.217" fill="#1790E8" rx="4" transform="scale(-1 1) rotate(30.997 -483.385 68.852)"></rect>
      </g>
      <mask id="mask7_1029_1400" style={{ maskType: 'alpha' }} width="16" height="19" x="21" y="260" maskUnits="userSpaceOnUse">
        <rect
          width="8"
          height="20.216"
          x="30.91"
          y="258.776"
          fill="url(#paint10_linear_1029_1400)"
          rx="4"
          transform="rotate(30.997 30.91 258.776)"
        ></rect>
      </mask>
      <g mask="url(#mask7_1029_1400)">
        <rect width="8" height="20.216" x="30.91" y="258.776" fill="#1790E8" rx="4" transform="rotate(30.997 30.91 258.776)"></rect>
      </g>
      <circle cx="25.991" cy="274.727" r="4" fill="#1790E8" opacity="0.8" transform="rotate(-90 25.991 274.727)"></circle>
      <ellipse cx="38.492" cy="274.727" fill="#1790E8" opacity="0.5" rx="4" ry="4" transform="rotate(-90 38.492 274.727)"></ellipse>
      <ellipse cx="32.242" cy="264.26" fill="#1790E8" opacity="0.3" rx="4" ry="4" transform="rotate(-90 32.242 264.26)"></ellipse>
      <g>
        <circle cx="174" cy="354" r="20" fill="#fff"></circle>
      </g>
      <mask id="mask8_1029_1400" style={{ maskType: 'alpha' }} width="21" height="15" x="164" y="343" maskUnits="userSpaceOnUse">
        <rect
          width="7"
          height="21.55"
          fill="url(#paint11_linear_1029_1400)"
          rx="3.5"
          transform="scale(-1 1) rotate(63.306 -361.008 36.804)"
        ></rect>
      </mask>
      <g mask="url(#mask8_1029_1400)">
        <rect width="7" height="21.55" fill="#1E5AE6" rx="3.5" transform="scale(-1 1) rotate(63.306 -361.008 36.804)"></rect>
      </g>
      <mask id="mask9_1029_1400" style={{ maskType: 'alpha' }} width="21" height="15" x="164" y="350" maskUnits="userSpaceOnUse">
        <rect
          width="7"
          height="21.55"
          x="165.952"
          y="365.236"
          fill="url(#paint12_linear_1029_1400)"
          rx="3.5"
          transform="rotate(-116.694 165.952 365.236)"
        ></rect>
      </mask>
      <g mask="url(#mask9_1029_1400)">
        <rect width="7" height="21.55" x="165.952" y="365.236" fill="#1E5AE6" rx="3.5" transform="rotate(-116.694 165.952 365.236)"></rect>
      </g>
      <circle cx="180.512" cy="354" r="3.5" fill="#1E5AE6" opacity="0.6"></circle>
      <ellipse cx="167.512" cy="360.5" fill="#1E5AE6" opacity="0.4" rx="3.5" ry="3.5"></ellipse>
      <ellipse cx="167.512" cy="347.5" fill="#1E5AE6" rx="3.5" ry="3.5"></ellipse>
      <g>
        <circle cx="315" cy="270" r="20" fill="#fff"></circle>
      </g>
      <mask id="mask10_1029_1400" style={{ maskType: 'alpha' }} width="6" height="24" x="312" y="258" maskUnits="userSpaceOnUse">
        <rect width="6" height="22.5" fill="url(#paint13_linear_1029_1400)" rx="3" transform="matrix(1 0 0 -1 312 281.25)"></rect>
      </mask>
      <g mask="url(#mask10_1029_1400)">
        <rect width="6" height="22.5" fill="#E7684B" opacity="0.7" rx="3" transform="matrix(1 0 0 -1 312 281.25)"></rect>
      </g>
      <circle cx="315" cy="261.75" r="3" fill="#E7684B" opacity="0.2"></circle>
      <circle cx="315" cy="278.25" r="3" fill="#E7684B"></circle>
      <g>
        <circle cx="315" cy="109" r="20" fill="#fff"></circle>
      </g>
      <mask id="mask11_1029_1400" style={{ maskType: 'alpha' }} width="15" height="15" x="304" y="105" maskUnits="userSpaceOnUse">
        <rect
          width="7.001"
          height="16.912"
          fill="url(#paint14_linear_1029_1400)"
          rx="3.501"
          transform="scale(1 -1) rotate(45.205 302.764 317.882)"
        ></rect>
      </mask>
      <g mask="url(#mask11_1029_1400)">
        <rect width="7.001" height="16.912" fill="#FCBA5F" rx="3.501" transform="scale(1 -1) rotate(45.205 302.764 317.882)"></rect>
      </g>
      <mask id="mask12_1029_1400" style={{ maskType: 'alpha' }} width="15" height="15" x="311" y="98" maskUnits="userSpaceOnUse">
        <rect
          width="7.001"
          height="16.805"
          fill="url(#paint15_linear_1029_1400)"
          rx="3.501"
          transform="scale(1 -1) rotate(45.624 296.468 325.896)"
        ></rect>
      </mask>
      <g mask="url(#mask12_1029_1400)">
        <rect width="7.001" height="16.805" fill="#FCBA5F" rx="3.501" transform="scale(1 -1) rotate(45.624 296.468 325.896)"></rect>
      </g>
      <mask id="mask13_1029_1400" style={{ maskType: 'alpha' }} width="15" height="15" x="311" y="105" maskUnits="userSpaceOnUse">
        <rect
          width="7.001"
          height="16.926"
          fill="url(#paint16_linear_1029_1400)"
          rx="3.501"
          transform="scale(-1 1) rotate(-45 -31.932 449.177)"
        ></rect>
      </mask>
      <g mask="url(#mask13_1029_1400)">
        <rect width="7.001" height="16.926" fill="#FCBA5F" rx="3.501" transform="scale(-1 1) rotate(-45 -31.932 449.177)"></rect>
      </g>
      <mask id="mask14_1029_1400" style={{ maskType: 'alpha' }} width="15" height="15" x="304" y="98" maskUnits="userSpaceOnUse">
        <rect
          width="7.001"
          height="16.834"
          fill="url(#paint17_linear_1029_1400)"
          rx="3.501"
          transform="scale(-1 1) rotate(-45 -36.755 437.148)"
        ></rect>
      </mask>
      <g mask="url(#mask14_1029_1400)">
        <rect width="7.001" height="16.834" fill="#FCBA5F" rx="3.501" transform="scale(-1 1) rotate(-45 -36.755 437.148)"></rect>
      </g>
      <circle r="3.5" fill="#FCBA5F" opacity="0.2" transform="matrix(0 -1 -1 0 322.029 109.002)"></circle>
      <ellipse fill="#FCBA5F" opacity="0.4" rx="3.5" ry="3.5" transform="matrix(0 -1 -1 0 307.973 109.002)"></ellipse>
      <ellipse fill="#FCBA5F" opacity="0.8" rx="3.5" ry="3.5" transform="matrix(0 -1 -1 0 315.001 116)"></ellipse>
      <circle r="3.5" fill="#FCBA5F" opacity="0.4" transform="matrix(0 -1 -1 0 315.001 102.125)"></circle>
      <defs>
        <filter id="filter0_d_1029_1400" width="64" height="64" x="142" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="6"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1029_1400"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1029_1400" result="shape"></feBlend>
        </filter>
        <filter id="filter1_d_1029_1400" width="64" height="64" x="0" y="83" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="6"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1029_1400"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1029_1400" result="shape"></feBlend>
        </filter>
        <filter id="filter2_d_1029_1400" width="64" height="64" x="0" y="244" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="6"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1029_1400"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1029_1400" result="shape"></feBlend>
        </filter>
        <filter
          id="filter3_d_1029_1400"
          width="64"
          height="64"
          x="142"
          y="328"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="6"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1029_1400"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1029_1400" result="shape"></feBlend>
        </filter>
        <filter
          id="filter4_d_1029_1400"
          width="64"
          height="64"
          x="283"
          y="244"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="6"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1029_1400"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1029_1400" result="shape"></feBlend>
        </filter>
        <filter
          id="filter5_d_1029_1400"
          width="64"
          height="64"
          x="283"
          y="83"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="6"></feOffset>
          <feGaussianBlur stdDeviation="6"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1029_1400"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1029_1400" result="shape"></feBlend>
        </filter>
        <linearGradient id="paint0_linear_1029_1400" x1="157.275" x2="195.688" y1="189.331" y2="189.331" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5760E8"></stop>
          <stop offset="1" stopColor="#2D9ADD"></stop>
        </linearGradient>
        <linearGradient id="paint1_linear_1029_1400" x1="156.863" x2="156.863" y1="202.44" y2="178.7" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2534B7"></stop>
          <stop offset="1" stopColor="#2C63B0" stopOpacity="0.8"></stop>
        </linearGradient>
        <linearGradient id="paint2_linear_1029_1400" x1="166.159" x2="166.159" y1="185.225" y2="178.555" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2638B7"></stop>
          <stop offset="1" stopColor="#003678"></stop>
        </linearGradient>
        <linearGradient id="paint3_linear_1029_1400" x1="179.307" x2="179.307" y1="43.782" y2="22.377" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.2"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint4_linear_1029_1400" x1="3.501" x2="3.501" y1="21.593" y2="-14.853" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.2"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint5_linear_1029_1400" x1="35.493" x2="35.493" y1="114.176" y2="91.92" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint6_linear_1029_1400" x1="3.501" x2="3.501" y1="22" y2="-5.775" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint7_linear_1029_1400" x1="3.5" x2="3.5" y1="17.621" y2="-4.625" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint8_linear_1029_1400" x1="45.991" x2="45.991" y1="290.727" y2="265.477" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint9_linear_1029_1400" x1="4" x2="4" y1="20.217" y2="-5.307" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint10_linear_1029_1400" x1="34.91" x2="34.91" y1="278.992" y2="253.469" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint11_linear_1029_1400" x1="3.5" x2="3.5" y1="21.55" y2="0" gradientUnits="userSpaceOnUse">
          <stop offset="0.178" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.833" stopColor="#fff" stopOpacity="0.3"></stop>
        </linearGradient>
        <linearGradient id="paint12_linear_1029_1400" x1="169.452" x2="169.452" y1="386.785" y2="365.236" gradientUnits="userSpaceOnUse">
          <stop offset="0.178" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.833" stopColor="#fff" stopOpacity="0.2"></stop>
        </linearGradient>
        <linearGradient id="paint13_linear_1029_1400" x1="3" x2="3" y1="22.5" y2="6.75" gradientUnits="userSpaceOnUse">
          <stop offset="0.264" stopColor="#fff" stopOpacity="0.7"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint14_linear_1029_1400" x1="3.501" x2="3.501" y1="16.912" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.5"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint15_linear_1029_1400" x1="3.501" x2="3.501" y1="16.805" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.5"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint16_linear_1029_1400" x1="3.501" x2="3.501" y1="16.926" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint17_linear_1029_1400" x1="3.501" x2="3.501" y1="16.834" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity="0.4"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0_1029_1400">
          <path fill="#fff" d="M0 0H51.6V24H0z" transform="translate(145.602 178)"></path>
        </clipPath>
      </defs>
    </svg>
  );
});

export default SplashLoading;
