import { REDUX_STATUS } from '@/constants';
import Institution from '@/models/Institution';
import { createSlice } from '@reduxjs/toolkit';
import { Action } from 'redux-actions';
import { ActiveInstitutionReducerType } from './types';

export const initialState: ActiveInstitutionReducerType = {
  status: REDUX_STATUS.IDLE,
  data: null,
};

const activeInstitutionSlice = createSlice({
  name: 'activeInstitution',
  initialState,
  reducers: {
    setActiveInstitution: (state: ActiveInstitutionReducerType, action: Action<{ institution: Institution }>) => {
      state.data = action.payload.institution;
      state.status = REDUX_STATUS.SUCCEEDED;
    },
  },
  extraReducers: {},
});

export const { setActiveInstitution } = activeInstitutionSlice.actions;

export default activeInstitutionSlice.reducer;
