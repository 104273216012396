import { setWorkspaces, WorkspaceApiType } from '@/helpers/workspaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REDUX_STATUS } from '../../constants';
import { Action } from '../../helpers/store';
import { userLogout } from '../auth/slice';
import { fetchUserData } from '../user/slice';
import { PropsWorkspaceReducerType, WorkspaceAlertsType } from './types';
import { Workspace } from '@/src/models';

export const initialState: PropsWorkspaceReducerType = {
  workspaceList: null,
  status: REDUX_STATUS.IDLE,
  error: null,
  isMenuOpen: true,
  alerts: {
    own: 0,
    team: 0,
  },
  contentLanguage: null,
};

const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    toggleLeftMenu: (state: PropsWorkspaceReducerType, action: Action<boolean>) => {
      state.isMenuOpen = action.payload;
    },
    setWorkspaceStatus: (state: PropsWorkspaceReducerType, action: Action<REDUX_STATUS>) => {
      state.status = action.payload;
    },
    addWorkspaceAlerts: (state: PropsWorkspaceReducerType, action: Action<WorkspaceAlertsType>) => {
      state.alerts = action.payload;
    },
    setContentLanguage: (state: PropsWorkspaceReducerType, action: Action<string | null>) => {
      state.contentLanguage = action.payload;
    },
    setWorkspaceList: (state: PropsWorkspaceReducerType, action: PayloadAction<{ workspaces: Workspace[] }>) => {
      state.workspaceList = setWorkspaces(action.payload.workspaces as unknown as WorkspaceApiType[]);
      state.status = REDUX_STATUS.SUCCEEDED;
    },
  },
  extraReducers: {
    [fetchUserData.pending.toString()]: (state) => {
      state.status = REDUX_STATUS.LOADING;
    },
    [fetchUserData.fulfilled.toString()]: (state, action) => {
      const endpointData = setWorkspaces(action.payload?.workspaces);
      state.workspaceList = endpointData;

      state.status = REDUX_STATUS.SUCCEEDED;
    },
    [fetchUserData.rejected.toString()]: (state, action) => {
      state.status = REDUX_STATUS.FAILED;
      state.error = action.error.message;
    },
    [userLogout.toString()]: () => initialState,
  },
});

export const { setWorkspaceStatus, setWorkspaceList, toggleLeftMenu, addWorkspaceAlerts, setContentLanguage } = workspaceSlice.actions;

export default workspaceSlice.reducer;
