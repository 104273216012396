import { styled } from '@/src/theme';

export const EmptyStateStyled = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$6',
});

export const Placeholder = styled('div', {
  color: '$neutral500',
  fontSize: '$large',
  fontWeight: 500,
});
