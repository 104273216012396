import { AxiosResponse, CancelTokenSource } from 'axios';

import { COMMUNICATION_CHANNEL_TYPES } from '@/constants/communication';

import { UphillForm, UpHillFormGroup } from '@/models/UpHillForm';
import { getRequest, postRequest, putRequest } from './index';
import { UserResponses } from '@/models/Communication';
import { InstitutionId, JourneyId } from '@/src/models';

interface SendCommunicationProps {
  communicationId: string;
  communicationChannels: COMMUNICATION_CHANNEL_TYPES[];
  elementId: string;
  elementStepNumber: string;
  firstElementOfSubPath?: string;
  institutionId: InstitutionId;
  parentStepNumber?: string;
  patientSessionId: string;
  scheduledDate?: string;
}

export const CommunicationApi = {
  getCommunication: ({
    id,
    language,
  }: {
    id: string;
    institutionId?: InstitutionId;
    language?: string;
  }): Promise<AxiosResponse<UphillForm> | undefined> =>
    getRequest('webform/' + id, undefined, undefined, { 'accept-language': language ?? '' }),
  getSentCommunication: ({
    id,
    institutionId,
    language,
  }: {
    id: string;
    institutionId: InstitutionId;
    language?: string;
  }): Promise<AxiosResponse<UphillForm> | undefined> =>
    getRequest(`${institutionId}/webform/sent/${id}`, undefined, undefined, { 'accept-language': language ?? '' }),
  postSentCommunicationGroup: ({
    id,
    institutionId,
    groupNumber,
    answers,
    language,
  }: {
    id: string;
    institutionId: InstitutionId;
    groupNumber: number;
    answers: Record<string, string[]>;
    language: string;
  }): Promise<AxiosResponse<UpHillFormGroup> | undefined> => {
    return postRequest(
      `${institutionId}/webform/sent/${id}/next?groupNumber=${groupNumber}`,
      {
        answers,
      },
      undefined,
      undefined,
      { 'accept-language': language },
    );
  },
  getSentCommunicationGroupPreview: ({
    id,
    groupNumber,
    language,
  }: {
    id: string;
    institutionId: InstitutionId;
    groupNumber: number;
    answers: Record<string, string[]>;
    language: string;
  }): Promise<AxiosResponse<UpHillFormGroup> | undefined> => {
    return getRequest(`webform/${id}/next?groupNumber=${groupNumber}`, undefined, undefined, { 'accept-language': language });
  },
  postCommunication: ({
    id,
    institutionId,
    payload,
  }: {
    id: string;
    institutionId: InstitutionId;
    payload: Record<string, unknown>;
  }): Promise<AxiosResponse<string> | undefined> => {
    return postRequest(`${institutionId}/webform/sent/${id}`, payload);
  },
  sendCommunication: (body: SendCommunicationProps, cancelToken?: CancelTokenSource) => {
    const { institutionId, ...payload } = body;
    return postRequest(`${institutionId}/communications/order`, { ...payload }, cancelToken);
  },
  getCommunicationAnswers: (
    institutionId: InstitutionId,
    patientSessionId: JourneyId,
    patientCommunicationId: string,
    language?: string,
    cancelToken?: CancelTokenSource,
  ) => {
    return getRequest<UserResponses[]>(
      `${institutionId}/patient-sessions/${patientSessionId}/communications/${patientCommunicationId}/responses`,
      cancelToken,
      undefined,
      language
        ? {
            'accept-language': language,
          }
        : undefined,
    );
  },
  postSuspendCommunication: (
    {
      institutionId,
      patientSessionId,
      patientCommunicationId,
      suspendedFrom,
    }: {
      institutionId: InstitutionId;
      patientSessionId: JourneyId;
      patientCommunicationId: string;
      suspendedFrom?: string;
    },
    cancelToken?: CancelTokenSource,
  ) => {
    return putRequest(
      `${institutionId}/patient-sessions/${patientSessionId}/communications/${patientCommunicationId}/suspend`,
      { suspendedFrom },
      cancelToken,
    );
  },
  postResendCommunication: (
    institutionId: InstitutionId,
    patientSessionId: JourneyId,
    patientCommunicationId: string,
    scheduledDate: string,
    cancelToken?: CancelTokenSource,
  ) => {
    return putRequest(
      `${institutionId}/patient-sessions/${patientSessionId}/communications/${patientCommunicationId}/activate`,
      { scheduledDate },
      cancelToken,
    );
  },
  activateInboundChannel: (
    institutionId: InstitutionId,
    patientSessionId: JourneyId,
    patientCommunicationId: string,
    cancelToken?: CancelTokenSource,
  ) => {
    return putRequest(
      `${institutionId}/patient-sessions/${patientSessionId}/communications/${patientCommunicationId}/activate-inbound`,
      {},
      cancelToken,
    );
  },
  suspendInboundChannel: (
    {
      institutionId,
      patientSessionId,
      patientCommunicationId,
    }: {
      institutionId: InstitutionId;
      patientSessionId: JourneyId;
      patientCommunicationId: string;
    },
    cancelToken?: CancelTokenSource,
  ) => {
    return putRequest(
      `${institutionId}/patient-sessions/${patientSessionId}/communications/${patientCommunicationId}/suspend-inbound`,
      {},
      cancelToken,
    );
  },
};
