import React, { ReactElement } from 'react';
import { styled } from '@/src/theme';

const LazyComponentLoaderStyled = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: '$neutral200',
});

export const LazyComponentLoader = (): ReactElement => {
  return <LazyComponentLoaderStyled />;
};
