import { COMMUNICATION_STATUS, INTERACTION_STATUS } from '@/constants/communication';
import { CareTeam, JourneyObservations, PatientData, PatientIdentifiers } from '@/src/models';

import { Communication, CommunicationDTO } from './Communication';
import { PathwayImpactCategory, PathwayImpactCriticalElement, PathwayImpactImpacts, PathwayImpactType } from './Pathway';
import { Medication } from '@/src/models/journey/care/Medication';

export enum CategorySession {
  'RED' = 'RED',
  'ORANGE' = 'ORANGE',
  'YELLOW' = 'YELLOW',
  'GREEN' = 'GREEN',
  'BLUE' = 'BLUE',
}

export interface PatientSessionSteps {
  alertId?: string;
  createdBy?: number;
  createdDate: string;
  elementId: string;
  elementPosition: number;
  id: string;
  lastModifiedBy?: number;
  lastModifiedDate: string;
  optionsIds?: number[];
  number?: number;
  skipped: boolean;
  subPath: { [key: string]: PatientSessionSteps[] };
}

export interface Reminder {
  id: number;
  description: string;
  date: Date;
  completed: boolean;
  reminderElements: {
    elementId: string;
    elementStepNumber: number;
    firstElementOfSubPath?: string;
    parentStepNumber?: number;
  }[];
}

export interface PatientSessionPathwayImpact {
  category: PathwayImpactCategory;
  communicationElementId: string;
  communicationId: string;
  communicationsUsedInFormula?: string[];
  communicationsAndQuestionsUsedInFormula?: Record<string, string[]>;
  criticalElements?: PathwayImpactCriticalElement[];
  criticalElementsCompleted?: string[];
  date: string;
  description: string;
  firstAlertOfInboundTrigger: boolean;
  triggerDatetime?: number;
  firstElementId: string;
  id: string;
  impacts: PathwayImpactImpacts[];
  isLast?: boolean;
  matcherId: string;
  patientSessionId: string;
  position: {
    elementId: string;
    elementStepNumber: number;
    parentStepNumber: number;
    firstElementOfSubPath: string;
  };
  priority: number;
  questionsAndAnswersUsedInFormula?: Record<string, string[]>;
  skipped: boolean;
  solvedBy?: number;
  type: PathwayImpactType;
  resolutionDate: number | null;
  resolutionMechanism: RESOLUTION_MECHANISM | null;
}

export enum RESOLUTION_MECHANISM {
  SOLVED_HCP_DIRECT = 'SOLVED_HCP_DIRECT',
  SOLVED_HCP_PROGRESS = 'SOLVED_HCP_PROGRESS',
  SOLVED_HCP_POSTERIOR_ALERT = 'SOLVED_HCP_POSTERIOR_ALERT',
  DISMISSED_HCP = 'DISMISSED_HCP',
  COMMUNICATION_SUSPENDED = 'COMMUNICATION_SUSPENDED',
  EXPIRED = 'EXPIRED',
}

export interface PatientSession {
  pathwayImpacts: PatientSessionPathwayImpact[];
  age?: number;
  avatar?: string;
  category: CategorySession;
  categoryError: boolean;
  communications: Communication[];
  createdDate: string;
  id: string;
  initialPhaseId: string;
  lastModifiedDate: string;
  nextElementId: string;
  patient: PatientData;
  pathwayId: string;
  pathwayVersion: number;
  phaseId: string;
  reminders: Reminder[];
  steps: PatientSessionSteps[];
  createdBy: number;
  hasMeasurements: boolean;
  createdInContext: boolean;
}
export interface PatientSessionDTO {
  createdDate: string;
  id: string;
  initialPhaseId: string;
  nextElementId: string;
  pathwayId: string;
  pathwayVersion: number;
  phaseId: string;
  patient: PatientData;
  reminders: Reminder[];
  communications: CommunicationDTO[];
  steps: PatientSessionSteps[];
  careTeam: CareTeam;
  category: CategorySession;
  lastModifiedDate: string;
  categoryError: boolean;
}

export type PatientContactsType = 'phone' | 'fax' | 'email' | 'pager' | 'url' | 'sms' | 'other';

export interface PatientContacts {
  system: PatientContactsType;
  use?: string;
  value: string;
}

export interface PatientSessionGetAPI {
  content: PatientSessionAPIContent[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface PatientSessionAPIContent {
  additionalInformation?: { [key: string]: Medication };
  category: CategorySession;
  categoryId: string | null;
  communicationStatus: COMMUNICATION_STATUS;
  interactionStatus: INTERACTION_STATUS;
  createdDate: string;
  criticalAlerts: number;
  id: number | string;
  lastModifiedDate: string;
  lastUserResponseDate: string;
  moderateAlerts: number;
  patient: PatientContent;
  pathwayId: string;
  pathwayVersion: number;
  pathwayTitle: string;
  phaseId: string;
  phaseDescription: string;
  userResponsible?: string;
  hasMeasurements: boolean;
  lastExamResultDate: string | null;
  observations: JourneyObservations[];
}

export interface PatientContent {
  birthDate: string;
  gender: string;
  generalPractitioner: { reference: string }[];
  id: string | number;
  managingOrganization: { reference: string }[];
  meta: { versionId: string; lastUpdated: string; source: string };
  lastUpdated: string;
  source: string;
  versionId: string | number;
  name: { text: string }[];
  resourceType: string;
  text: {
    div: string;
    status: string;
  };
  identifier: PatientIdentifiers[];
}
