import { PathwayCommunication } from '@/models/PathwayCommunication';
import PathwayGateway from '@/models/PathwayGateway';
import { PatientSession, PatientSessionPathwayImpact, RESOLUTION_MECHANISM } from '@/models/PatientSession';

import { COMMUNICATION_STATUS, COMMUNICATION_TYPES } from '@/constants/communication';
import { PATHWAY_ELEMENT_TYPES, PATHWAY_EVENT_TYPES } from '@/constants/pathway';
import Pathway, { PathwayElementPosition, PathwayImpactType } from '../models/Pathway';
import PathwayAction from '../models/PathwayAction';
import { PathwayActionItemItem } from '../models/PathwayActionItemItem';
import PathwayCalculator from '../models/PathwayCalculator';
import PathwayCriterias from '../models/PathwayCriterias';
import { PathwayElement } from '@/models/PathwayElement';
import PathwayElements from '../models/PathwayElements';
import { getAlertLoopElements, hasBlockage, hasCriticalAction, hasSessionImpact } from './pathAlertsHelper';
import { getActionSelectedStateUpdated, mapAction } from './pathwayActionHelper';
import { mapGateway } from './pathwayGatewayMapper';
import { injectStatusLabelsByType } from '@/helpers/pathwayStatusesHelper';

export interface PathwayContextLegacy {
  alertId?: string;
  buildingPath?: PathwayElement[];
  builtPath?: PathwayElement[];
  createFromLoop?: boolean;
  pathway?: Pathway;
  session?: PatientSession;
  skipCriticals?: boolean;
}

export const fillContext = (currentContext?: PathwayContextLegacy, potentialContext?: PathwayContextLegacy): PathwayContextLegacy => {
  return {
    alertId: currentContext?.alertId || potentialContext?.alertId,
    builtPath: (currentContext?.builtPath || potentialContext?.builtPath) ?? [],
    buildingPath: (currentContext?.buildingPath || potentialContext?.buildingPath) ?? [],
    createFromLoop: currentContext?.createFromLoop || potentialContext?.createFromLoop,
    pathway: currentContext?.pathway || potentialContext?.pathway,
    session: currentContext?.session || potentialContext?.session,
    skipCriticals: currentContext?.skipCriticals || potentialContext?.skipCriticals,
  };
};

const initPathway = (pathway: Pathway, startingNode?: string | null, context?: PathwayContextLegacy): PathwayElement[] => {
  let initialElement;
  if (startingNode) {
    const completeContext = fillContext(context, { pathway });
    initialElement = getEnrichedPathwayElementById(pathway.pathway, startingNode, completeContext);
  }
  const startingId = initialElement ? startingNode : 'INITIAL_EVENT';
  return getNextPathwayElements(pathway, startingId as string, context);
};

const stopAtThisElement = (element: PathwayElement) => {
  if (!element) {
    return true;
  }
  if (element.type === PATHWAY_ELEMENT_TYPES.GATEWAY) {
    if (element.isFinal) {
      return true;
    }
    if (!element.isCompleted) {
      return true;
    }
  }
  if (element.type === PATHWAY_ELEMENT_TYPES.EVENT && element.subtype === PATHWAY_EVENT_TYPES.FINAL) {
    return true;
  }
  if (element.type === PATHWAY_ELEMENT_TYPES.CALCULATOR_REFERENCE || element.type === PATHWAY_ELEMENT_TYPES.PATHWAY_REFERENCE) {
    return true;
  }
  return false;
};

export const getPathwayElementById = (pathwayList: PathwayElements, nextElementId: string): PathwayElement | undefined => {
  let nextItemList: PathwayElement[] = [];

  if (nextElementId?.includes(PATHWAY_ELEMENT_TYPES.ACTION)) {
    nextItemList = pathwayList.actions;
  }
  if (nextElementId?.includes(PATHWAY_ELEMENT_TYPES.EVENT)) {
    nextItemList = pathwayList.events;
  }
  if (nextElementId?.includes(PATHWAY_ELEMENT_TYPES.GATEWAY)) {
    nextItemList = pathwayList.gateways;
  }
  if (nextElementId?.includes(PATHWAY_ELEMENT_TYPES.CALCULATOR_REFERENCE)) {
    nextItemList = pathwayList.calculators;
  }
  if (nextElementId?.includes(PATHWAY_ELEMENT_TYPES.PATHWAY_REFERENCE)) {
    nextItemList = pathwayList.pathwayReferences;
  }
  if (nextElementId?.includes(COMMUNICATION_TYPES.KEY_MOMENT)) {
    nextItemList = pathwayList.keyMoments;
  }

  return nextItemList?.find((item) => item.id === nextElementId);
};

export const getEnrichedPathwayElementById = (
  pathwayList: PathwayElements,
  nextElementId: string,
  context: PathwayContextLegacy,
): PathwayElement | null => {
  let item = getPathwayElementById(pathwayList, nextElementId);
  if (!item) {
    return null;
  }

  if (item.type === PATHWAY_ELEMENT_TYPES.ACTION) {
    item = mapAction(item);
    item = hasCriticalAction(item, context);
    item = injectStatusLabelsByType(item, pathwayList.statusLabels);
  }

  if (item.type === PATHWAY_ELEMENT_TYPES.ACTION || item.type === COMMUNICATION_TYPES.KEY_MOMENT) {
    item.communicationCountdown = null;
  }

  if (item.type === PATHWAY_ELEMENT_TYPES.GATEWAY) {
    item = hasBlockage(item, context);

    if (!item.final) {
      item = mapGateway(item);
    }
  }

  item = hasSessionImpact(item, context?.session);
  item = {
    ...item,
    alertId: context.alertId,
    createFromLoop: Boolean(context.createFromLoop),
    ...(context.skipCriticals
      ? {
          skipped: false,
          isDisabled: true,
          minified: true,
        }
      : {}),
  };

  return item;
};

const getNextPathwayElements = (pathway: Pathway, startingId: string, context?: PathwayContextLegacy): PathwayElement[] => {
  const pathwayList = pathway.pathway;
  let nextElements: PathwayElement[] = [];
  let element = getEnrichedPathwayElementById(pathwayList, startingId, fillContext(context, { pathway }));

  type AlertLoop = { afterId: string; positionElementId: string; solved: PatientSessionPathwayImpact[] };
  let alertLoop: AlertLoop = {
    afterId: '',
    positionElementId: '',
    solved: [],
  };

  if (element && startingId !== 'INITIAL_EVENT') {
    nextElements.push(element);
  }

  while (element && !stopAtThisElement(element)) {
    element = getEnrichedPathwayElementById(
      pathwayList,
      element.nextElementId,
      fillContext(context, { buildingPath: nextElements, pathway }),
    );

    if (element && (element as PathwayCommunication).pathwayImpacts?.length) {
      const { id, pathwayImpacts } = element as Required<PathwayCommunication>;

      alertLoop = {
        ...alertLoop,
        afterId: pathwayImpacts[0].firstElementId,
        positionElementId: id,
      };

      const solvedAlerts = pathwayImpacts?.filter((filterPathwayImpact) => {
        alertLoop.afterId = filterPathwayImpact.firstElementId;
        return (
          filterPathwayImpact.type === PathwayImpactType.alert &&
          context?.session?.steps.some((step) => step.alertId === filterPathwayImpact.id)
        );
      });
      if (
        solvedAlerts.length &&
        (solvedAlerts.slice(-1)[0].resolutionMechanism === RESOLUTION_MECHANISM.SOLVED_HCP_PROGRESS ||
          solvedAlerts.slice(-1)[0].resolutionMechanism === RESOLUTION_MECHANISM.SOLVED_HCP_DIRECT)
      ) {
        alertLoop = { ...alertLoop, solved: solvedAlerts };
      } else {
        alertLoop = { ...alertLoop, solved: solvedAlerts.slice(0, -1) };
      }
    }

    if (element) {
      if (alertLoop.afterId === element.id) {
        const communication = context?.session?.communications.find(
          (findCommunication) => findCommunication.position.elementId === alertLoop.positionElementId,
        );

        const alertsLength =
          context?.session?.pathwayImpacts.filter((filterImpacts) => filterImpacts.type === PathwayImpactType.alert).length ?? 0;
        const solvedAlertsLength = alertLoop.solved.length;

        const lastImpact = context?.session?.pathwayImpacts.slice(-1)[0];
        const isCommunicationFinished = communication?.overviewState === COMMUNICATION_STATUS.FINISHED;
        const isSolvedEqualsTotal = solvedAlertsLength === alertsLength;

        const removeLastWhenAlertAndSameLength =
          isCommunicationFinished &&
          lastImpact?.type === PathwayImpactType.alert &&
          lastImpact.resolutionMechanism === RESOLUTION_MECHANISM.SOLVED_HCP_PROGRESS;

        const removeLastWhenSingleAndSameLength = isCommunicationFinished && isSolvedEqualsTotal && solvedAlertsLength === 1;

        const loopSolved =
          removeLastWhenAlertAndSameLength || removeLastWhenSingleAndSameLength ? alertLoop.solved.slice(0, -1) : alertLoop.solved;

        loopSolved.forEach((eachPathwayImpact) => {
          const alertElements = getAlertLoopElements({
            context: fillContext(context, { buildingPath: nextElements, pathway }),
            pathway,
            pathwayImpact: eachPathwayImpact,
            skipCriticals: true,
          });
          nextElements = [...nextElements, ...alertElements];
        }, []);
      }
      nextElements.push(element);
    }
  }
  return nextElements;
};

const getPathwayElementsWithSelectedStateUpdated = (
  prevPathway: any,
  selectedAction?: PathwayAction | PathwayCriterias,
  actionItemItem?: PathwayActionItemItem,
): any => {
  return prevPathway.map((el: PathwayElement) => {
    if (el.type === PATHWAY_ELEMENT_TYPES.ACTION) {
      return getActionSelectedStateUpdated(el, selectedAction as PathwayAction, actionItemItem);
    } else {
      return el;
    }
  });
};

const findCalculatorOutcome = (calculator: PathwayCalculator) => {
  const totalScore = calculator.totalScore;
  return calculator.outcomes.find((outcome) => {
    if (totalScore !== null && totalScore !== undefined) {
      if (outcome.scoreMoreThan === outcome.scoreLessOrEqualThan) {
        return outcome.scoreMoreThan === totalScore.result;
      }
      return outcome.scoreMoreThan < Number(totalScore.result) && outcome.scoreLessOrEqualThan >= Number(totalScore.result);
    } else {
      return false;
    }
  });
};

const getNextElementIdFromCalculator = (calculator: PathwayCalculator, pathway: Pathway, context?: PathwayContextLegacy): any[] | null => {
  const totalScore = calculator.totalScore;
  const findOutcome = findCalculatorOutcome(calculator);

  if (findOutcome) {
    const findCondition = calculator.conditions.find((condition) => condition.outcomesIds.includes(findOutcome?.id));
    if (findCondition) {
      const updatedCalculator = { ...calculator, outcomeSelected: findOutcome, totalScore, isLocked: true, skipped: false };
      const nextId = findCondition.nextElementId;
      return [updatedCalculator, ...getNextPathwayElements(pathway, nextId, context)];
    }
  }
  return null;
};

const calcAlgorithmProgress = (pathway: PathwayElement[], countStartingNodes: number): number => {
  if (pathway.length > 0) {
    const firstPathwayItem = pathway[0] as any;
    const { depth } = firstPathwayItem;
    const startingPercentage = depth === 1 || !depth ? 0 : (depth / (depth + firstPathwayItem.stepsToLast)) * 100;
    return pathway.slice(countStartingNodes, pathway.length).reduce((prev, el: any) => {
      const availablePercentage = 100 - prev;
      if (typeof el.stepsToLast === 'number') {
        return prev + (availablePercentage * 1) / (1 + el.stepsToLast);
      } else {
        return prev;
      }
    }, startingPercentage);
  }
  return 0;
};

export const getElementByPosition = (pathway: PathwayElement[], position: PathwayElementPosition): PathwayElement | undefined => {
  const { subPathSelected, parentIndex, index } = position;
  let element;
  if (subPathSelected && parentIndex !== undefined) {
    element = (pathway[Number(parentIndex)] as PathwayGateway).subPaths?.[String(subPathSelected)].path[Number(index)];
  } else {
    element = pathway[Number(index)];
  }
  return element;
};

export default {
  initPathway,
  getNextPathwayElements,
  getPathwayElementsWithSelectedStateUpdated,
  getNextElementIdFromCalculator,
  findCalculatorOutcome,
  calcAlgorithmProgress,
  getElementById: getEnrichedPathwayElementById,
  getEnrichedPathwayElementById,
  getElementByPosition,
};
