import React, { ReactElement, ReactNode } from 'react';
import { ScrollAreaStyled } from './styles';
import { StitchesProps } from '@uphillhealth/theme';

interface ScrollAreaHorizontalProps extends StitchesProps {
  children: ReactNode | ReactNode[];
  maxWidth?: string;
}

export const ScrollAreaHorizontal = ({ children, css = {}, maxWidth = '100vw' }: ScrollAreaHorizontalProps): ReactElement => {
  return (
    <ScrollAreaStyled css={{ ...css, maxWidth }} data-testid="ScrollArea" orientation="horizontal">
      {children}
    </ScrollAreaStyled>
  );
};
