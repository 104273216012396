import React, { memo, ReactElement, useCallback } from 'react';
import { CheckboxBehaviours, CheckboxContainer, CustomCheckbox } from './styles';
import { CheckboxPillProps } from './types';

export const CheckboxPill = memo(({ children, onChange, css, ...inputProps }: CheckboxPillProps): ReactElement => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    },
    [onChange],
  );

  return (
    <CheckboxBehaviours data-testid="CheckboxPill" css={css}>
      <input {...inputProps} type="checkbox" onChange={handleChange} />
      <CheckboxContainer>
        <CustomCheckbox>
          <i className="icon-check" />
          <i className="icon-remove" />
        </CustomCheckbox>
        {children}
      </CheckboxContainer>
    </CheckboxBehaviours>
  );
});
