export enum RouteElementExtraKeyContentType {
  externalLink = 'EXTERNAL_LINK',
  image = 'IMAGE',
  markdown = 'markdown-virtual-type',
  pdf = 'PDF',
  text = 'TEXT',
  url = 'URL',
  video = 'VIDEO',
}

export enum RouteElementExtraKeyTarget {
  patient = 'PATIENT',
  all = 'ALL',
  practitioner = 'PRACTITIONER',
}

export type RouteElementExtraKey = {
  id: number | null;
  contentType: RouteElementExtraKeyContentType;
  editable?: boolean;
  value: {
    subtitle: string;
    title: string;
    value: string[];
  };
  target: RouteElementExtraKeyTarget;
  type: string;
};
