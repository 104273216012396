import { I18nAssets } from '@uphillhealth/i18n';

export const localeAssets: I18nAssets = {
  ca: () => import('./ca'),
  'de-DE': () => import('./de/de-DE'),
  de: () => import('./de/de-DE'),
  en: () => import('./en-GB'),
  'en-US': () => import('./en-GB'),
  'en-GB': () => import('./en-GB'),
  'es-ES': () => import('./es-ES'),
  es: () => import('./es-ES'),
  'fr-FR': () => import('./fr/fr-FR'),
  fr: () => import('./fr/fr-FR'),
  'pt-PT': () => import('./pt-PT'),
  pt: () => import('./pt-PT'),
  nl: () => import('./nl/nl'),
};
