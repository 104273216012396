import { PATHWAY_GATEWAY_TYPES } from '../constants/pathway';
import PathwayGateway from '../models/PathwayGateway';

const calcGatewayCount = (gateway: PathwayGateway) => {
  if (gateway.subtype === PATHWAY_GATEWAY_TYPES.EXCLUSIVE) {
    return 1;
  } else if (gateway.subtype === PATHWAY_GATEWAY_TYPES.INCLUSIVE) {
    if (gateway.minConditions < gateway.maxConditions) {
      return '+' + gateway.minConditions;
    } else {
      return gateway.minConditions;
    }
  } else if (gateway.subtype === PATHWAY_GATEWAY_TYPES.PARALLEL) {
    return gateway.conditions.length;
  }
  return 0;
};

const getGatewayDescription = (gateway: PathwayGateway) => {
  const criteria = gateway.criteria;
  if (!criteria) {
    switch (gateway.subtype) {
      case 'EXCLUSIVE':
        return 'Complete one route';
      case 'INCLUSIVE':
        return ['Complete at least', gateway.minConditions, 'routes'];
      case 'PARALLEL':
        return 'Complete all routes';
      default:
        return 'Select one of the following';
    }
  }
  return criteria;
};

export const mapGateway = (gateway: PathwayGateway): PathwayGateway => {
  let groups;
  if (gateway.groups) {
    groups = gateway.groups.map((group) => ({
      ...group,
      options: gateway.options ? gateway.options.filter((opt) => opt.groupId === group.id) : [],
    }));
  } else {
    groups = [
      {
        options: gateway.options ? gateway.options : [],
      },
    ];
  }

  return {
    ...gateway,
    conditionsCount: calcGatewayCount(gateway),
    criteria: getGatewayDescription(gateway),
    groups,
  };
};
