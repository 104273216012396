import { CurrentUserAPI, Institution, Workspace } from '@/src/models';
import { ReactElement, ReactNode } from 'react';

/*
 * @description It can be parameterized with some context to be dynamic per institution.
 * @example
 * WORKSPACE_PATIENTMANAGEMENT_INSTITUTIONID = WORKSPACE_PATIENTMANAGEMENT_[institutionId]
 * // The dynamic piece gets replaced at evaluation
 */
export enum ApplicationPermissions {
  clinicalCasecreator = 'CLINICAL_CASE_CREATOR',
  creator = 'CREATOR_[institutionId]',
  delivery = 'DELIVERY_[institutionId]',
  pathwaySpecialist = 'PATHWAY_SPECIALIST_[institutionId]',
  taskDesk = 'TASK_[institutionId]',
  taskAdministrative = 'TASK_ADMINISTRATIVE_[institutionId]',
  taskPhysician = 'TASK_PHYSICIAN_[institutionId]',
  uphillAdmin = 'UPHILL_ADMIN',
  workspaceAdmin = 'WORKSPACE_ADMIN_[institutionId]',
  workspacePatientManagement = 'WORKSPACE_PATIENTMANAGEMENT_[institutionId]',
}

export enum ApplicationContextStatus {
  error = 'error',
  loading = 'loading',
  success = 'success',
}

interface ApplicationContextSharedValues {
  authorizationToken?: string;
  currentInstitution?: Institution;
  currentUser?: CurrentUserAPI;
  currentUserCareTeams?: Institution['careTeams'];
  currentWorkspace?: Workspace;
  previousInstitution?: Institution;
  previousWorkspace?: Workspace;
  publicInstitutions: Institution[];
}

export interface ApplicationContextValues extends ApplicationContextSharedValues {
  currentUserHasPermission: (
    permissions: ApplicationPermissions | ApplicationPermissions[],
    context?: Record<string, number | string>,
  ) => boolean;
  resolveAndSetCurrentUser: (authorizationToken?: string) => void;
  setCurrentInstitution: (institution: Institution) => void;
  unsetCurrentUser: () => void;
}

export interface ApplicationContextState extends ApplicationContextSharedValues {
  internalStatus: ApplicationContextStatus;
}

export interface ApplicationContextProviderProps {
  children: ReactNode | ReactNode[];
  components?: {
    error?: ReactElement;
    loading?: ReactElement;
  };
}
