export enum RouteActionCategory {
  appointment = 'APPOINTMENT',
  communication = 'COMMUNICATION',
  diagnosis = 'DIAGNOSIS',
  drug = 'DRUG',
  exam = 'EXAM',
  maneuver = 'MANEUVER',
  monitoring = 'MONITORING',
  referral = 'REFERRAL',
  registry = 'REGISTRY',
  speciality = 'SPECIALITY',
  therapeutic = 'THERAPEUTIC',
}
