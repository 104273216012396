import { Root } from '@radix-ui/react-dropdown-menu';
import React, { ReactElement, ReactNode } from 'react';
import { DropdownMenuContentStyled, DropdownMenuPortalStyled, DropdownMenuTriggerStyled } from './styles';

interface DropdownMenuProps {
  trigger: ReactNode;
  children: ReactNode | ReactNode[];
}

export const DropdownMenu = ({ trigger, children }: DropdownMenuProps): ReactElement => {
  return (
    <Root>
      <DropdownMenuTriggerStyled>{trigger}</DropdownMenuTriggerStyled>
      <DropdownMenuPortalStyled>
        <DropdownMenuContentStyled align="end">{children}</DropdownMenuContentStyled>
      </DropdownMenuPortalStyled>
    </Root>
  );
};
