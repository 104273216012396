import React, { ReactElement, ReactNode } from 'react';
import * as DialogPrimitives from '@radix-ui/react-dialog';
// self
import { DialogOverlay, DialogRoot, DialogCloseIcon, DialogContainer } from './styles';
import { StitchesProps } from '@uphillhealth/theme';

export interface DialogProps extends Omit<DialogPrimitives.DialogProps, 'children'>, StitchesProps {
  children: ReactNode | ReactNode[];
  onClose?: () => void;
  showCloserIcon?: boolean;
  trigger?: ReactNode | ReactNode[];
  overlayProps?: StitchesProps;
}

/* Implementation from radix primitives.
 * https://www.radix-ui.com/docs/primitives/components/dialog
 */
export const Dialog = ({
  children,
  css,
  onClose,
  trigger,
  showCloserIcon = true,
  overlayProps,
  ...dialogProps
}: DialogProps): ReactElement => {
  return (
    <DialogRoot {...dialogProps} data-testid="Dialog">
      {trigger && <DialogPrimitives.Trigger asChild>{trigger}</DialogPrimitives.Trigger>}
      <DialogPrimitives.Portal>
        <DialogOverlay {...overlayProps}>
          <DialogContainer data-testid="DialogContent" css={css}>
            {children}
            {showCloserIcon && (
              <DialogCloseIcon onClick={onClose}>
                <i className="icon-close" />
              </DialogCloseIcon>
            )}
          </DialogContainer>
        </DialogOverlay>
      </DialogPrimitives.Portal>
    </DialogRoot>
  );
};
