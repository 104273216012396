import { styled } from '@uphillhealth/theme';
import * as SelectPrimitives from '@radix-ui/react-select';

export const SelectLabel = styled(SelectPrimitives.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: '$neutral500',
});

export const SelectSeparator = styled(SelectPrimitives.Separator, {
  height: 1,
  backgroundColor: '$primary500',
  margin: 5,
});
