import React, { ChangeEvent, useEffect, useState } from 'react';
import { TITLE_MENU } from '@/constants/palette';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  MenuProps,
  Select as MaterialSelect,
  TextField,
} from '@material-ui/core';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';

// TODO: REMOVE THIS COMPONENT!!!

/* istanbul ignore next */
const useStyles = makeStyles({
  root: {
    marginBottom: '1rem',
  },
  menuPaper: {
    overflow: 'hidden',
    '& ul': {
      paddingTop: 0,
      maxHeight: 200,
      overflow: 'auto',
    },
  },
  input: {
    width: '100%',
    padding: 10,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: 'white',
    '&:focus': {
      border: '2px solid hsla(224, 59%, 53%, 1)',
    },
  },
  menuItem: {
    whiteSpace: 'normal',
  },
  auto: {
    '& .MuiFormControl-fullWidth': {
      width: 'calc(100% - 2px)',
      margin: '1px',
    },

    '& fieldset': {
      border: `1px solid #a0aac0`,
    },
    '& .MuiOutlinedInput-root': {
      border: '2px solid transparent',
      boxShadow: '0 0 0 1px var(--colors-neutral500)',
      height: 64,

      '&.Mui-focused': {
        border: '2px solid var(--colors-primary500)',
        boxShadow: '0 0 0 1px transparent',
        '&.MuiOutlinedInput-notchedOutline': {
          border: `0px solid red`,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `none`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `none`,
      },
      '&.Mui-error:not(.Mui-focused)': {
        boxShadow: '0 0 0 1px var(--colors-error500)',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: '6px',
      color: TITLE_MENU,
    },
    '& input': {
      '&::placeholder': {
        color: 'var(--colors-neutral500)',
        opacity: 1,
      },
    },
  },
  popper: {
    pointerEvents: 'auto',
  },
});

/* istanbul ignore next */
interface SelectProps {
  id?: string;
  ref?: React.Ref<unknown | null>;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  label?: string;
  name?: string;
  value: any;
  items?: { label: string; value: string }[];
  required?: boolean;
  className?: any;
  error?: boolean;
  helperText?: string;
  searchable?: boolean;
  disabled?: boolean;
  style?: any;
  variant?: 'outlined' | 'filled';
  placeholder?: string;
  noOptionsText?: string;
  onSearch?: (search: string) => void;
  onChange?:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
  onChangeSearchable?:
    | ((
        event: ChangeEvent<any>,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined,
      ) => void)
    | undefined;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Select_DropUsage: React.FC<SelectProps> = ({
  id,
  ref,
  inputRef,
  label,
  name,
  value,
  items,
  required,
  error,
  helperText,
  searchable,
  style,
  disabled,
  variant,
  placeholder,
  noOptionsText,
  onSearch,
  onChange,
  onChangeSearchable,
  onBlur,
  ...props
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const menuProps: Partial<MenuProps> = {
    classes: {
      paper: classes.menuPaper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleOnSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onSearch?.(event.target.value);
  };
  if (searchable && items) {
    return (
      <Autocomplete
        ref={ref}
        noOptionsText={noOptionsText}
        value={value}
        className={classes.auto}
        classes={{
          popper: classes.popper,
        }}
        style={style}
        disabled={disabled}
        options={items}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            error={error}
            required={required}
            variant={variant}
            label={label}
            placeholder={placeholder}
            onChange={handleOnSearch}
          />
        )}
        renderOption={(value) => <React.Fragment key={value.value}>{value.label}</React.Fragment>}
        onChange={onChangeSearchable}
      />
    );
  }

  return !isLoading ? (
    <FormControl className={classes.root} error={error} required={required} style={style} disabled={disabled} variant={variant} {...props}>
      {label && <InputLabel id="creator-select-label">{label}</InputLabel>}
      <MaterialSelect
        id={id}
        ref={ref}
        labelId="creator-select-label"
        displayEmpty
        name={name ? name : undefined}
        value={value ?? ''}
        onChange={onChange}
        MenuProps={menuProps}
        onClose={undefined}
        onBlur={onBlur}
        label={label}
        renderValue={value ? undefined : () => <span className="placeholder">{placeholder ?? ''}</span>}
      >
        {items &&
          items.map((element, index) => (
            <MenuItem key={index} value={element.value} classes={{ root: classes.menuItem }}>
              {element.label}
            </MenuItem>
          ))}
      </MaterialSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <style jsx>{`
        .placeholder {
          color: #828b9d;
        }
      `}</style>
    </FormControl>
  ) : null;
};
