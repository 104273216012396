import React, { InputHTMLAttributes, memo, ReactElement, ReactNode, useMemo } from 'react';
import { StitchesProps } from '@uphillhealth/theme';
import { v4 as uuidv4 } from 'uuid';
// components
import { LeftContent, RightContent } from '../commons';
// self
import { InputContainer, InputBaseStyled, InputStyled, InputLabel } from './styles';

export interface InputBaseProps extends InputHTMLAttributes<HTMLInputElement>, StitchesProps {
  children?: ReactNode | ReactNode[];
  hasError?: boolean;
  label?: ReactNode | ReactNode[];
  leftContent?: ReactNode | ReactNode[];
  rightContent?: ReactNode | ReactNode[];
}

export const InputBase = memo(
  ({
    children,
    disabled,
    hasError,
    id,
    label,
    leftContent,
    placeholder = ' ',
    rightContent,
    ...inputProps
  }: InputBaseProps): ReactElement => {
    const inputId = useMemo(() => {
      return id ?? uuidv4();
    }, [id]);

    return (
      <InputContainer>
        <InputBaseStyled data-testid="InputBase">
          {leftContent && <LeftContent>{leftContent}</LeftContent>}

          <InputStyled
            {...inputProps}
            data-testid="InputBase-input"
            disabled={disabled}
            hasError={hasError}
            id={inputId}
            placeholder={placeholder}
          />
          {label && (
            <InputLabel data-testid="InputBase-label" htmlFor={inputId}>
              {label}
            </InputLabel>
          )}

          {rightContent && <RightContent>{rightContent}</RightContent>}
        </InputBaseStyled>
        {children}
      </InputContainer>
    );
  },
);
