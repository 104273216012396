import React, { ReactElement, ReactNode } from 'react';
// self
import { EmptyStateStyled, Placeholder } from './styles';

interface EmptyStateProps {
  children: ReactNode | ReactNode[];
  placeholder: string;
}

export const EmptyState = ({ children, placeholder }: EmptyStateProps): ReactElement => {
  return (
    <EmptyStateStyled>
      <div>{children}</div>
      <Placeholder>{placeholder}</Placeholder>
    </EmptyStateStyled>
  );
};
