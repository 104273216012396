import Pathway, { PathwayImpactCategory, PathwayImpactType } from '@/models/Pathway';
import PathwayGateway from '@/models/PathwayGateway';
import { PatientSession, PatientSessionPathwayImpact, PatientSessionSteps, RESOLUTION_MECHANISM } from '@/models/PatientSession';
import { PathwayElement } from '@/models/PathwayElement';
import { getNextElementsFromGateway } from './pathwayGatewayHelper';
import { getEnrichedPathwayElementById, PathwayContextLegacy } from './pathwayHelper';
import { PathwayCommunication } from '@/models/PathwayCommunication';
import { UserResponses } from '@/models/Communication';
import PathwayAction from '@/models/PathwayAction';
import { PATHWAY_ELEMENT_TYPES } from '@/constants/pathway';

interface CreateAlertLoopParams {
  context?: PathwayContextLegacy;
  pathway: Pathway;
  pathwayImpact: PatientSessionPathwayImpact;
  skipCriticals?: boolean;
}

type CreateAlertLoopFn = (payload: CreateAlertLoopParams) => PathwayElement[];

export const findLoopGatewayPath = ({
  gateway,
  gatewayIndex,
  pathway,
  pathwayImpact,
  steps,
}: {
  gateway: PathwayGateway;
  gatewayIndex: number;
  pathway: Pathway;
  pathwayImpact: PatientSessionPathwayImpact;
  steps: PatientSessionSteps[];
}): PathwayElement[] => {
  const progress = steps.find((step) => {
    return step.number === gatewayIndex && step.elementId === gateway.id && step.alertId === pathwayImpact.id;
  });

  if (progress) {
    const options = gateway.options?.filter((option) => progress.optionsIds?.includes(option.id)) ?? [];
    if (!options.length) {
      return [gateway];
    }

    const selectedOptions =
      gateway.options?.map((option) => {
        if (progress.optionsIds?.includes(option.id)) {
          return { ...option, selected: true };
        }
        return option;
      }) ?? [];

    gateway.alertId = pathwayImpact.id;
    gateway.createFromLoop = true;
    gateway.options = selectedOptions;
    gateway.groups = [{ options: selectedOptions }];
    // progress
    gateway.lastModifiedDate = progress.lastModifiedDate;
    gateway.progressId = progress.id;

    if (selectedOptions.length) {
      gateway.isLocked = true;
    }

    const gatewayPath = getNextElementsFromGateway([], gateway, options, pathway, null, []);
    const lastElement = gatewayPath.slice(-1)[0];
    return [
      ...gatewayPath,
      ...(lastElement.type === PATHWAY_ELEMENT_TYPES.GATEWAY
        ? findLoopGatewayPath({
            gateway: lastElement as PathwayGateway,
            gatewayIndex: gatewayIndex + gatewayPath.length - 1,
            pathway,
            pathwayImpact,
            steps,
          }).slice(1)
        : []),
    ];
  }
  return [gateway];
};

export const enrichLoopElement = ({
  element,
  pathwayImpact,
  skipCriticals,
  progress,
}: {
  element: PathwayElement;
  pathwayImpact: PatientSessionPathwayImpact;
  skipCriticals: boolean;
  progress?: PatientSessionSteps;
}): PathwayElement => {
  return {
    ...hasCriticalAction(element as PathwayAction, { session: { pathwayImpacts: [pathwayImpact] } as PatientSession }),
    alertId: pathwayImpact.id,
    createFromLoop: true,
    ...(skipCriticals
      ? {
          skipped: false,
          isDisabled: true,
          minified: true,
          skipCriticals: true,
        }
      : {}),
    ...(progress ? { lastModifiedDate: progress.lastModifiedDate, progressId: progress.id } : {}),
  };
};

export const getAlertLoopElements: CreateAlertLoopFn = ({ context, pathway, pathwayImpact, skipCriticals }) => {
  const gateway = getEnrichedPathwayElementById(pathway.pathway, pathwayImpact.firstElementId, {}) as PathwayGateway;

  const gatewayOptions =
    gateway.options?.map((option) => {
      if (pathwayImpact.impacts.find((impact) => impact.optionsIds.includes(option.id))) {
        return { ...option, selected: true };
      }
      return option;
    }) ?? [];

  const options = gatewayOptions.filter((option) => option.selected);

  gateway.alertId = pathwayImpact.id;
  gateway.createFromLoop = true;
  gateway.isDisabled = true;
  gateway.firstAlertOfInboundTrigger = Boolean(pathwayImpact.firstAlertOfInboundTrigger);
  gateway.triggerDate = pathwayImpact.triggerDatetime;
  gateway.skipped = false;
  gateway.hillyAnswered = true;
  gateway.hillyAnswers = options;
  gateway.options = gatewayOptions;
  gateway.skipCriticals = Boolean(skipCriticals);
  gateway.sessionImpact = pathwayImpact;

  const initialIndex = (context?.buildingPath?.length ?? 0) + (context?.builtPath?.length ?? 0);
  const steps = context?.session?.steps ? context?.session.steps : [];

  if (steps[Number(initialIndex)]) {
    const gatewayProgress = steps[Number(initialIndex)];
    gateway.lastModifiedDate = gatewayProgress.lastModifiedDate;
    gateway.progressId = gatewayProgress.id;
  }

  const gatewayPath = getNextElementsFromGateway([], gateway, options, pathway, null, [])
    .filter(
      (element) =>
        pathwayImpact.criticalElements?.find((criticalElement) => {
          const splittedIds = criticalElement.elementIds.reduce((merge: string[], elementIds) => {
            return [...merge, ...elementIds.split('=>')];
          }, []);
          return splittedIds.includes(element.id) || criticalElement.lastElementId === element.id;
        }),
    )
    .reduce((merge: PathwayElement[], element, reduceIndex) => {
      const elementIndex = initialIndex + reduceIndex + 1;
      const elementProgress = steps[Number(elementIndex)];

      // When having gteways inside a loop
      let progressPath = [];
      if (element.type === PATHWAY_ELEMENT_TYPES.GATEWAY) {
        progressPath = findLoopGatewayPath({
          gateway: element,
          gatewayIndex: elementIndex,
          pathway,
          pathwayImpact,
          steps,
        });

        const enrichedElements = progressPath.map((toEnrich) =>
          enrichLoopElement({
            element: toEnrich,
            pathwayImpact,
            skipCriticals: Boolean(skipCriticals),
            progress: elementProgress,
          }),
        );

        return [...merge, ...enrichedElements];
      }

      return [...merge, enrichLoopElement({ element, pathwayImpact, skipCriticals: Boolean(skipCriticals), progress: elementProgress })];
    }, []);

  return [gateway, ...gatewayPath];
};

export const criticalElementsAmount = (pathwayImpact: PatientSessionPathwayImpact): number =>
  Array.isArray(pathwayImpact.criticalElements)
    ? pathwayImpact.criticalElements.reduce(
        (merge, criticalElement) => (merge < criticalElement.elementIds.length ? criticalElement.elementIds.length : merge),
        0,
      )
    : 0;

export const hasPastUnsolvedAlert = (pathwayImpacts: PatientSessionPathwayImpact[]): boolean => {
  let unsolved = false;
  for (const pathwayImpact of pathwayImpacts) {
    if (pathwayImpact.type === PathwayImpactType.alert && !pathwayImpact.resolutionMechanism) {
      unsolved = true;
      break;
    }
  }
  return unsolved;
};

export const selectedAlertIsLast = (current: PatientSessionPathwayImpact, last: PatientSessionPathwayImpact): boolean => {
  return current.id === last.id;
};

export const hasSessionImpact = (element: PathwayElement, session?: PatientSession): PathwayElement => {
  if (session?.pathwayImpacts?.length) {
    const onlyForElement = session.pathwayImpacts.filter((pathwayImpact) => pathwayImpact.position.elementId === element.id);
    const elementAlerts = onlyForElement.filter((sessionImpact) => sessionImpact.type === PathwayImpactType.alert);

    if (!onlyForElement.length || !elementAlerts.length) {
      return element;
    }

    const lastPathwayImpact = onlyForElement.slice(-1)[0];

    /*
     * pathwayImpact MUST ALWAYS be of TYPE `PathwayImpactType.alert` or it can block alert resolution
     * an unsolved alert can be anywhere in the pathwayImpacts list because of mixed types
     */
    if (elementAlerts.length === 1) {
      const pathwayImpact = elementAlerts[0];
      return {
        ...element,
        pathwayImpact: { ...pathwayImpact, isLast: selectedAlertIsLast(pathwayImpact, lastPathwayImpact) },
        pathwayImpacts: elementAlerts,
      };
    }

    const onlyUnsolved = elementAlerts.filter((sessionImpact) => !sessionImpact.resolutionMechanism);
    if (onlyUnsolved.length) {
      const pathwayImpact = onlyUnsolved.slice(-1)[0];
      return {
        ...element,
        pathwayImpact: { ...pathwayImpact, isLast: selectedAlertIsLast(pathwayImpact, lastPathwayImpact) },
        pathwayImpacts: elementAlerts,
      };
    } else {
      const pathwayImpact = elementAlerts.slice(-1)[0];
      return {
        ...element,
        pathwayImpact: { ...pathwayImpact, isLast: selectedAlertIsLast(pathwayImpact, lastPathwayImpact) },
        pathwayImpacts: elementAlerts,
      };
    }
  }

  return element;
};

export type FormulaAnswer = { answers: string[]; category: PathwayImpactCategory; solved: boolean; unsolved: boolean };
type FindResponseAlertsReturn = Record<string, FormulaAnswer>;

export const findAlertFormulaAnswers = (
  sessionImpacts: PatientSessionPathwayImpact[] | undefined,
  userResponses: UserResponses,
): FindResponseAlertsReturn => {
  if (!sessionImpacts) return {};

  const alertId = userResponses.alertId ?? '';
  const communicationSentId = userResponses.communicationSentId ?? '';

  return sessionImpacts.reduce((mergeSession, sessionImpact) => {
    const useInFormula: Record<string, string[]> = sessionImpact.communicationsAndQuestionsUsedInFormula ?? {};

    if (
      sessionImpact.type === PathwayImpactType.alert &&
      (sessionImpact.id === alertId || Object.prototype.hasOwnProperty.call(useInFormula, communicationSentId))
    ) {
      const sentAnswers = Object.prototype.hasOwnProperty.call(useInFormula, communicationSentId)
        ? Object.entries(useInFormula[String(communicationSentId)])
        : Object.values(useInFormula);

      const formulaAnswers: Record<string, FormulaAnswer> = sentAnswers.reduce((commsMerge: Record<string, FormulaAnswer>, entry) => {
        const [id, answers] = entry;
        const answersList: string[] = Array.isArray(answers) ? answers : [answers];

        return {
          ...commsMerge,
          [String(id)]: {
            answers: answersList,
            category: sessionImpact.category,
            solved: resolutionMechanismSolved(sessionImpact.resolutionMechanism),
            unsolved: resolutionMechanismUnsolved(sessionImpact.resolutionMechanism),
          },
        };
      }, {});

      return { ...mergeSession, ...formulaAnswers };
    }

    return mergeSession;
  }, {});
};

export const hasBlockage = (element: PathwayGateway, context: PathwayContextLegacy): PathwayGateway => {
  const { builtPath, buildingPath } = context;
  if (!element || !buildingPath) return element;

  const existentPathsLength = builtPath?.length ?? 0;
  const keyMomentIndex = buildingPath?.findIndex((inPathElement) => {
    return (inPathElement as PathwayCommunication)?.communicationBlockedGateways?.find((idToBlock) => idToBlock === element.id);
  });

  return keyMomentIndex !== undefined && keyMomentIndex >= 0
    ? { ...element, isBlocked: true, blockedBy: { index: keyMomentIndex + existentPathsLength } }
    : element;
};

export const hasCriticalAction = (element: PathwayAction, context: PathwayContextLegacy): PathwayAction => {
  const { session } = context;
  if (!element || !session?.pathwayImpacts) return element;

  const sessionImpact = (!session?.pathwayImpacts ? [] : [...session.pathwayImpacts])
    ?.sort((eleA, eleB) => (eleB.date > eleA.date ? 0 : -1))
    .find(
      (pathwayImpact) =>
        pathwayImpact.type === PathwayImpactType.alert &&
        pathwayImpact.criticalElements?.find(
          (criticalElement) => element.type === PATHWAY_ELEMENT_TYPES.ACTION && criticalElement.elementIds.includes(element?.id),
        ),
    );

  return sessionImpact
    ? {
        ...element,
        alertId: sessionImpact.id,
        alertSolved: resolutionMechanismSolved(sessionImpact.resolutionMechanism),
        isCriticalAction: true,
        criticalActionTo: { index: sessionImpact.position.elementStepNumber, category: sessionImpact.category, id: sessionImpact.id },
      }
    : element;
};

export const getLastAlertId = (pathwayImpacts?: PatientSessionPathwayImpact[]): string => {
  if (!Array.isArray(pathwayImpacts)) return '';

  const unsolvedAlert = pathwayImpacts
    .filter((sessionImpact) => sessionImpact.type === PathwayImpactType.alert && !sessionImpact.resolutionMechanism)
    .slice(-1);
  return unsolvedAlert.length ? unsolvedAlert[0].id : '';
};

/*
 * Determines whether an alert should appear or not considering only the alert rules
 * Any other rule must be added to the expression outside
 */
export const showAlertDecision = (pathwayImpact?: PatientSessionPathwayImpact): boolean => {
  return Boolean(
    pathwayImpact &&
      pathwayImpact.type === PathwayImpactType.alert &&
      (!pathwayImpact.resolutionMechanism || (resolutionMechanismSolved(pathwayImpact.resolutionMechanism) && pathwayImpact.isLast)),
  );
};

export const resolutionMechanismSolved = (status: RESOLUTION_MECHANISM | null): boolean => {
  switch (status) {
    case RESOLUTION_MECHANISM.SOLVED_HCP_DIRECT:
    case RESOLUTION_MECHANISM.SOLVED_HCP_POSTERIOR_ALERT:
    case RESOLUTION_MECHANISM.SOLVED_HCP_PROGRESS:
      return true;

    default:
      return false;
  }
};

export const resolutionMechanismUnsolved = (status: RESOLUTION_MECHANISM | null): boolean => {
  switch (status) {
    case RESOLUTION_MECHANISM.COMMUNICATION_SUSPENDED:
    case RESOLUTION_MECHANISM.EXPIRED:
      return true;

    default:
      return false;
  }
};
