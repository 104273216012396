import React, { memo, ReactElement, ReactNode } from 'react';
import { InputMessageErrorStyled } from '@/uphillhealth/components/InputMessageError/styles';

interface InputMessageErrorProps {
  children: ReactNode | ReactNode[];
}

export const InputMessageError = memo(({ children }: InputMessageErrorProps): ReactElement => {
  return <InputMessageErrorStyled data-testid="InputMessageError">{children}</InputMessageErrorStyled>;
});
