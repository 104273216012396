import { ReactNode } from 'react';

export enum BadgeType {
  critical = 'critical',
  warning = 'warning',
}

export interface BadgeProps {
  children: ReactNode | ReactNode[];
  type?: BadgeType;
}
