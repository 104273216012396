import { AnalyticsBrowser } from '@segment/analytics-next';
import { CurrentUserAPI } from '@/src/models';

export const Analytics = AnalyticsBrowser.load({
  cdnURL: 'https://cdn.uphillhealth.com',
  writeKey: 'zaFPc8akshwnxe3LkTkypyRqCbpn5alG',
});

/* istanbul ignore next */
export const analyticsIdentify = (currentUser: CurrentUserAPI) => {
  const filteredWorkspaces = currentUser.workspaces.filter((workspace) => workspace.domain !== 'uphill');

  Analytics.identify(currentUser.email, {
    email: currentUser.email,
    first_name: String(currentUser.fullName).split(' ')[0],
    name: currentUser.fullName,
    id: currentUser.id,
    gender: currentUser.gender,
    language: currentUser.language,
    roles: currentUser.roles.map((role) => role.name),
    profession_id: currentUser.professionId,
    speciality_id: currentUser.specialityId,
    signup_institution: currentUser.signupInstitution,
    subscribed_to_daily_digest_email: currentUser.subscribedToDailyDigestEmail,
    version: 2,
    workspace_domains: filteredWorkspaces.map((workspace) => workspace.domain),
    workspace_names: filteredWorkspaces.map((workspace) => workspace.name),
  });
};

export default Analytics;
