import { Service } from '@uphillhealth/react-service-provider';
// app
import { TerminologyCategoriesAPI } from '@/src/models';

export class TerminologyService extends Service {
  useGetCategories() {
    return this.useQuery<TerminologyCategoriesAPI>({
      method: 'GET',
      url: '/fhir/ValueSet/$expand?url=http://uphill.pt/fhir/[institutionId]/valueset/patient-session-category',
    });
  }
}
