import { COMMUNICATION_SUBTYPES } from '../constants/communication';
import { Gender } from './Common';
import Institution from './Institution';
import { PathwayCriteria } from './PathwayCriterias';
import PathwayElements from './PathwayElements';
import PathwayPhase from './PathwayPhase';
import Speciality from './Speciality';

export interface PathwaySimplified {
  id: string;
  title: string;
  diseaseGroup: string;
  updateDate: Date;
  visible: boolean;
}

export interface PathwayDTO {
  clinicalEvidences: {
    name: string;
    url: string;
  }[];
  description: { [key: string]: string };
  diseaseGroupId: number | null;
  languages: string[];
  permissions: {
    name: string;
    type: string;
  }[];
  tags: string[];
  title: { [key: string]: string };
  visible: boolean;
  highlighted: boolean;
}

export enum PathwayImpactCategory {
  critical = 'CRITICAL',
  moderate = 'MODERATE',
}

export enum PathwayImpactType {
  alert = 'ALERT',
  impact = 'IMPACT',
}

export type PathwayImpactImpacts = {
  elementId: string;
  elementType: string;
  optionsIds: number[];
};

export type PathwayImpactCriticalElement = {
  elementIds: string[];
  lastElementId: string;
};
export interface PathwayImpact {
  category?: PathwayImpactCategory;
  communicationElementId: string;
  communicationId: string;
  criticalElements?: PathwayImpactCriticalElement[];
  impacts: PathwayImpactImpacts[];
  matcherId: string;
  priority: number;
  type: PathwayImpactType;
}

export default interface Pathway {
  pathwayImpacts?: PathwayImpact[];
  id: string;
  pathwayId: string;
  status: 'IN_DEVELOPMENT' | string;
  version: number;
  title: string;
  description: string;
  details: string;
  institution: Institution;
  pathway: PathwayElements;
  phases: PathwayPhase[];
  criterias: {
    inclusion: PathwayCriteria[];
    exclusion: PathwayCriteria[];
  };
  diagnostics: string[];
  specialities: Speciality[];
  creationDate: number;
  updateDate: number;
  highlighted: boolean;
  tags: string;
  image: string;
  mediaShareImage: string;
  patientSessionConfiguration?: {
    enabled?: boolean;
    defaultGender?: Gender;
    defaultAge?: string;
    communicationConfiguration?: {
      enabled?: boolean;
    };
  };
  promoVideo: string | null;
  clinicalEvidences: Array<{
    name: string;
    url: string;
    referenceName: string | null;
  }>;
  language: string;
  outdated: boolean;
  availableCommunications?: {
    [key in COMMUNICATION_SUBTYPES]?: string[];
  };
  availableLanguages: string[];
}

export interface PathwayForm {
  id: string | null;
  title: string;
  description: string;
  diseaseGroupId: number | null;
  permissions: {
    name: string;
    type: string;
  }[];
  highlighted: boolean;
  visible: boolean;
  tags: string[];
  clinicalEvidences: {
    name: string;
    url: string;
  }[];
  languages: string[];
  originalTitle?: any;
  originalDescription?: any;
}

export const PathwaysHelper = {
  fromDTOtoForm: (pathwayId: string, dto: PathwayDTO): PathwayForm => {
    const lang = dto.title['pt'] ? 'pt' : dto.title['en'] ? 'en' : 'pt';
    return {
      id: pathwayId,
      title: dto.title[lang],
      description: dto.description[lang],
      visible: dto.visible,
      highlighted: dto.highlighted,
      tags: dto.tags,
      clinicalEvidences: dto.clinicalEvidences,
      diseaseGroupId: dto.diseaseGroupId,
      permissions: dto.permissions,
      languages: dto.languages,
      originalTitle: dto.title,
      originalDescription: dto.description,
    };
  },
  formToDTO: (form: PathwayForm) => {
    const lang = form.originalTitle['pt'] ? 'pt' : form.originalTitle['en'] ? 'en' : 'pt';
    return {
      title: { ...(form.originalTitle && form.originalTitle), [lang]: form.title },
      description: { ...(form.originalDescription && form.originalDescription), [lang]: form.description },
      visible: form.visible,
      highlighted: form.highlighted,
      tags: form.tags,
      clinicalEvidences: form.clinicalEvidences.map(({ name, url }) => ({
        name,
        url: url.includes('http://') || url.includes('https://') ? url : `https://${url}`,
      })),
      diseaseGroupId: form.diseaseGroupId,
    };
  },
};

export interface PathwayElementPosition {
  elementId?: string;
  index: number;
  subPathSelected?: string;
  parentIndex?: number;
}

export interface PathwayCommunicationSuggestions {
  availableCommunications: {
    [key in COMMUNICATION_SUBTYPES]: string[];
  };
  diseaseGroup: string;
  pathwayId: string;
  title: string;
  version: number;
}
