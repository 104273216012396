import { styled, keyframes } from '@uphillhealth/theme';

const wave = keyframes({
  '0%': {
    backgroundPosition: '0 0',
  },
  '100%': {
    backgroundPosition: '-135% 0',
  },
});

export const SkeletonStyled = styled('div', {
  width: '100%',
  height: '100%',
  backgroundImage:
    'linear-gradient(to right, rgba(130, 130, 130, 0.075) 8%, rgba(130, 130, 130, 0.15) 18%, rgba(130, 130, 130, 0.075) 33%)',
  backgroundSize: '400% 400%',
  animation: `${wave} 1.2s infinite ease-in-out both`,
});
