import axios, { AxiosHeaderValue, AxiosRequestHeaders, HttpStatusCode, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { TokenApi } from '@/services/api/token';

import DataStorage from '../../services/storage';
import { ALLOWED_LANGS } from '../../services/variables';

axios.interceptors.request.use(function (config: InternalAxiosRequestConfig<unknown>): InternalAxiosRequestConfig<unknown> {
  // new service provider uses baseURL and legacy does not.
  // We do not want to keep using interceptors.
  if (config.baseURL) {
    return config;
  }

  let clientLang = '';

  if (typeof navigator !== 'undefined') {
    const navigatorLanguage = navigator.language ?? navigator.languages[0];
    clientLang = navigatorLanguage ? navigatorLanguage.substring(0, 2) : '';
  }

  let locale = Cookies.get('i18n') || clientLang || 'en';
  locale = Object.values(ALLOWED_LANGS).some((obj) => obj.id.includes(locale)) ? locale : 'en';
  let acceptLanguage: AxiosHeaderValue = '';

  if (config?.headers?.['accept-language'] !== undefined) {
    if (config?.headers?.['accept-language'] !== 'null') {
      if (config?.headers?.['accept-language']) {
        acceptLanguage = String(config?.headers?.['accept-language']).substring(0, 2);
      } else {
        acceptLanguage = '';
      }
    } else {
      acceptLanguage = null;
    }
  } else {
    acceptLanguage = locale;
  }

  const headers = {
    'Accept-Language': acceptLanguage as AxiosHeaderValue,
    ...(config?.headers ? config.headers : {}),
  } as unknown as AxiosRequestHeaders;

  return {
    ...config,
    headers,
  };
});

axios.interceptors.response.use(undefined, async (error) => {
  const config = error.config;

  const upidRedirect = () => {
    DataStorage.deleteAccessToken();
    DataStorage.deleteRefreshToken();

    const idpDomain = sessionStorage.getItem('active-workspace') || location.pathname.split('/')[1];
    window.location.href = `${process.env.NEXT_PUBLIC_UPID_APP}/signin?service=${
      process.env.NEXT_PUBLIC_DOMAIN
    }&idpdomain=${idpDomain}&continue=${encodeURIComponent(location.href)}`;
  };

  if (error.request?.responseURL.includes('token/renew')) {
    if (typeof window !== 'undefined') {
      upidRedirect();
    }
  }

  if (error?.response?.status === HttpStatusCode.Unauthorized) {
    config._retry = true;

    const refresh = DataStorage.getRefreshToken();
    return TokenApi.putRefreshToken(refresh ?? '')
      .then((res) => {
        if (res?.data) {
          DataStorage.setAccessToken(res?.data);
          config.headers['Authorization'] = 'Bearer ' + res?.data;
          return axios.request(config);
        } else {
          if (typeof window !== 'undefined') {
            upidRedirect();
          }
        }
      })
      .catch(() => {
        if (typeof window !== 'undefined') {
          upidRedirect();
        }
      });
  }

  return Promise.reject(error);
});
