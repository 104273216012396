import { CATEGORY_ITEM, CATEGORY_TO_PERMISSION } from '../constants';

export const getColorByElementType = (type: CATEGORY_ITEM): string => {
  switch (type) {
    case CATEGORY_ITEM.PATHWAY:
      return '#894EAC';
    case CATEGORY_ITEM.ARTICLE:
      return '#DD8E2A';
    case CATEGORY_ITEM.EVENT:
      return '#1F8D8E';
    case CATEGORY_ITEM.RESOURCE:
      return '#BA352C';
    case CATEGORY_ITEM.CASE:
      return '#7F8DDF';
    case CATEGORY_ITEM.GUIDELINE:
      return '#BA352C';
    case CATEGORY_ITEM.PDF:
      return '#BA352C';
    case CATEGORY_ITEM.EXTERNAL:
      return '#BA352C';
    case CATEGORY_ITEM.CALCULATOR:
      return '#7F8DDF';
    case CATEGORY_ITEM.COLLECTION:
      return '#3F65CE';
    default:
      return '#894EAC';
  }
};

export const getImgPathByElementType = (type: CATEGORY_ITEM): string => {
  switch (type) {
    case CATEGORY_ITEM.PATHWAY:
      return '/images/svg/pathway.svg';
    case CATEGORY_ITEM.ARTICLE:
      return '/images/svg/article.svg';
    case CATEGORY_ITEM.EVENT:
      return '/images/svg/event.svg';
    case CATEGORY_ITEM.RESOURCE:
      return '/images/svg/resource.svg';
    case CATEGORY_ITEM.GUIDELINE:
      return '/images/svg/resource.svg';
    case CATEGORY_ITEM.PDF:
      return '/images/svg/resource.svg';
    case CATEGORY_ITEM.EXTERNAL:
      return '/images/svg/resource.svg';
    case CATEGORY_ITEM.CASE:
      return '/images/svg/case.svg';
    case CATEGORY_ITEM.COLLECTION:
      return '/images/png/collection.png';
    case CATEGORY_ITEM.CALCULATOR:
      return '/images/svg/calculator.svg';
    default:
      return '';
  }
};

export const getTreeViewImgPathByElementType = (type: CATEGORY_ITEM | undefined): string => {
  switch (type) {
    case CATEGORY_ITEM.PATHWAY:
      return '/images/svg/treeview-pathway.svg';
    case CATEGORY_ITEM.ARTICLE:
      return '/images/svg/treeview-article.svg';
    case CATEGORY_ITEM.EVENT:
      return '/images/svg/treeview-event.svg';
    case CATEGORY_ITEM.RESOURCE:
      return '/images/svg/treeview-resource.svg';
    case CATEGORY_ITEM.GUIDELINE:
      return '/images/svg/treeview-resource.svg';
    case CATEGORY_ITEM.CASE:
      return '/images/svg/treeview-case.svg';
    case CATEGORY_ITEM.CALCULATOR:
      return '/images/svg/treeview-calculator.svg';
    case CATEGORY_ITEM.COLLECTION:
      return '/images/png/treeview-collections.png';
    default:
      return '';
  }
};

export const getTypeByResourceType = (type: CATEGORY_ITEM): CATEGORY_ITEM => {
  switch (type) {
    case CATEGORY_ITEM.PATHWAY:
      return CATEGORY_ITEM.PATHWAY;
    case CATEGORY_ITEM.ARTICLE:
      return CATEGORY_ITEM.ARTICLE;
    case CATEGORY_ITEM.EVENT:
      return CATEGORY_ITEM.EVENT;
    case CATEGORY_ITEM.COLLECTION:
      return CATEGORY_ITEM.COLLECTION;
    case CATEGORY_ITEM.CALCULATOR:
      return CATEGORY_ITEM.CALCULATOR;
    default:
      return CATEGORY_ITEM.RESOURCE;
  }
};
export const resourceTypeToType = (type: CATEGORY_ITEM): CATEGORY_ITEM => {
  switch (type) {
    case CATEGORY_ITEM.PATHWAY:
      return CATEGORY_ITEM.PATHWAY;
    case CATEGORY_ITEM.ARTICLE:
      return CATEGORY_ITEM.ARTICLE;
    case CATEGORY_ITEM.EVENT:
      return CATEGORY_ITEM.EVENT;
    case CATEGORY_ITEM.COLLECTION:
      return CATEGORY_ITEM.COLLECTION;
    case CATEGORY_ITEM.CALCULATOR:
      return CATEGORY_ITEM.CALCULATOR;
    default:
      return CATEGORY_ITEM.GUIDELINE;
  }
};

export const copyToClipboard = (content: string): void => {
  if (window.navigator.clipboard) {
    window.navigator.clipboard.writeText(content);
  } else {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
};

export const handleCopyClick = (
  message: string,
  url: string | undefined,
  activeWork: string | undefined,
  resourceId: string | number,
  enqueueSnackbar: (message: string) => void,
  type: CATEGORY_ITEM,
): void => {
  copyToClipboard(url ?? `${process.env.NEXT_PUBLIC_DOMAIN}/${activeWork}/${CATEGORY_TO_PERMISSION[type]}/` + resourceId);
  enqueueSnackbar(message);
};

export const getCategoryByType = (type: CATEGORY_ITEM): CATEGORY_ITEM => {
  if (type === CATEGORY_ITEM.RESOURCE || type === CATEGORY_ITEM.GUIDELINE || type === CATEGORY_ITEM.VIDEO) {
    return CATEGORY_ITEM.RESOURCE;
  }
  return type;
};

export const isUserAdmin = (roles: { id: number; name: string }[]): boolean => roles.some((r) => r.name === 'UPHILL_ADMIN');
